import { useState } from "react";
import { useMutation } from "react-query";
import { Link, Redirect } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import QRCode from "react-qr-code";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  ButtonGroup,
  Text,
  Button,
} from "@chakra-ui/react";
import { User } from "src/types";
import { MutationFeedback } from "src/components/MutationFeedback";
import { authConfirmVerifyEmail } from "src/api/mutations/authConfirmVerifyEmail";
import { SmolStackLayout } from "src/components/SmolStackLayout";

type Form = {
  password: string;
};

type Props = {
  user: User | undefined;
};

export const VerifyEmailPage = ({ user }: Props) => {
  const [token] = useQueryParam("token", StringParam);

  const [form, setForm] = useState<Form>({
    password: "",
  });

  const mutation = useMutation(authConfirmVerifyEmail);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <SmolStackLayout>
      {mutation.isSuccess ? (
        <Stack spacing={4}>
          <Heading size="md">Account Successfully Created</Heading>
          <Text>
            Please save your 2FA Seed! The 2FA Seed must be stored in an
            authenticator app like Google Authenticator or Authy. The 2FA Seed
            is not your 2FA Code. An authenticator app will provide you with the
            2FA Codes you need to login.
          </Text>
          <Stack spacing={1} pb={1}>
            <b>2FA Seed as QR Code:</b>
            <QRCode
              value={`otpauth://totp/BitCap?secret=${mutation.data?.["2fa_seed"]}`}
            />
          </Stack>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      ) : token ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            mutation.mutate({
              token: token,
              password: form.password,
            });
          }}
        >
          <Stack spacing={4}>
            <Heading size="md">Finish Creating Your Account</Heading>
            <Text>Please enter your desired password.</Text>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={form.password}
                onChange={(e) => {
                  const password = e.target.value;
                  setForm((f) => ({
                    ...f,
                    password,
                  }));
                }}
                disabled={mutation.isLoading}
              />
            </FormControl>
            <MutationFeedback mutation={mutation} />
            <ButtonGroup>
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={mutation.isLoading}
                disabled={mutation.isSuccess || mutation.isLoading}
              >
                Submit
              </Button>
            </ButtonGroup>
          </Stack>
        </form>
      ) : (
        <Stack spacing={4}>
          <Text>Something went wrong!</Text>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      )}
    </SmolStackLayout>
  );
};
