import { useQuery } from "react-query";
import { APIResponse, FormattedNumber } from "src/types";
import { apiClient } from "..";

type DataDashboardResponse = {
  group_id: number;
  group_name: string;
  running_count: number;
  total_count: number;
  gpu_count: number;
  cpu_count: number;
  asic_count: number;
  btc_price: FormattedNumber;
  eth_price: FormattedNumber;
  daily_revenue: FormattedNumber;
  monthly_revenue: FormattedNumber;
  power_usage: FormattedNumber;
  min_temperature: number;
  avg_temperature: number;
  max_temperature: number;
  coins: {
    name: string;
    count: number;
    hashrate: FormattedNumber;
    revenue: FormattedNumber;
  }[];
  algorithms: {
    name: string;
    count: number;
    hashrate: FormattedNumber;
    revenue: FormattedNumber;
  }[];
};

export const dataDashboardQueryKey = "/data/dashboard";

export const fetchDataDashboard = () =>
  apiClient
    .get<APIResponse<DataDashboardResponse>>(dataDashboardQueryKey)
    .then((res) => res.data.data);

export const useDataDashboardQuery = () =>
  useQuery(dataDashboardQueryKey, fetchDataDashboard);
