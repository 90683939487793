import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import {
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  Heading,
  HStack,
  Button,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Stack,
  VStack,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  useDataRevenueQuery,
  fetchDataRevenueExport,
} from "src/api/queries/fetchDataRevenue";
import { MutationFeedback } from "src/components/MutationFeedback";
import { RadioCardGroup } from "src/components/RadioCard";
import { networks } from "src/types";
import { Panel } from "src/components/Panel";
import { downloadCSV } from "src/lib/downloadFile";
import { trimDateToUTC } from "src/lib/standardizeDate";

const rollMonth = (date: Date, months: number): Date => {
  return new Date(date.getFullYear(), date.getMonth() + months, 1);
};

const ExportButton = ({
  startDate,
  endDate,
  estimateFuture,
}: {
  startDate: Date;
  endDate: Date;
  estimateFuture: boolean;
}) => {
  const [networkId, setNetworkId] = useState<number>(6);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mutation = useMutation(fetchDataRevenueExport, {
    onSuccess: (response) => {
      const network = networks.find((network) => network.id === networkId);
      const fileSlug = network != null ? network.name.toLowerCase() : "revenue";
      downloadCSV(`${fileSlug}.csv`, response.data);
      onClose();
    },
  });

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <VStack>
        <FormLabel textAlign="center" m="0">
          Export
        </FormLabel>
        <IconButton
          size="lg"
          colorScheme="blackAlpha"
          variant="ghost"
          aria-label="Export"
          icon={<DownloadIcon />}
          onClick={onOpen}
        />
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Network Revenue</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl maxW="10rem">
                <FormLabel>Network</FormLabel>
                <Select
                  value={networkId}
                  onChange={(e) => setNetworkId(parseInt(e.target.value))}
                >
                  {networks.map((network) => (
                    <option key={`network-${network.id}`} value={network.id}>
                      {network.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              size="lg"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading}
              onClick={() =>
                mutation.mutate({
                  affiliate: false,
                  start_date: trimDateToUTC(startDate),
                  end_date: trimDateToUTC(endDate),
                  network_id: networkId,
                  estimate: estimateFuture,
                })
              }
            >
              Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const RevenuePanel = () => {
  const currentDate = new Date();
  const defaultStartDate = rollMonth(currentDate, -1);
  const defaultEndDate = rollMonth(currentDate, 0);

  const [state, setState] = useState<{
    showContractDraw: boolean;
    estimateFuture: boolean;
    affiliate: boolean;
    showContracts: boolean;
    start_date: Date;
    end_date: Date;
    network_id: number;
  }>({
    showContractDraw: true,
    estimateFuture: false,
    affiliate: false,
    showContracts: false,
    start_date: new Date(defaultStartDate),
    end_date: new Date(defaultEndDate),
    network_id: 1,
  });

  const query = useDataRevenueQuery({
    affiliate: state.affiliate,
    start_date: trimDateToUTC(state.start_date),
    end_date: trimDateToUTC(state.end_date),
    estimate: state.estimateFuture,
  });

  return (
    <Panel title={"Revenue"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"center"}>
            <Button
              style={{ marginTop: "2rem" }}
              leftIcon={<ArrowLeftIcon fontSize={"sm"} />}
              onClick={() => {
                const startDate = new Date(state.start_date);
                const newStartDate = rollMonth(startDate, -1);
                const newEndDate = rollMonth(startDate, 0);
                setState((s) => ({
                  ...s,
                  estimateFuture:
                    newEndDate < new Date() ? false : state.estimateFuture,
                  start_date: newStartDate,
                  end_date: newEndDate,
                }));
              }}
            />
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <SingleDatepicker
                name={`invoices-start-date`}
                usePortal={true}
                date={state.start_date}
                maxDate={new Date()}
                onDateChange={(date) =>
                  setState((s) => ({ ...s, page: 0, start_date: date }))
                }
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                    borderColor: "grayAlpha.500",
                    bg: "whiteAlpha.400",
                  },
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <SingleDatepicker
                name={`invoices-end-date`}
                usePortal={true}
                date={state.end_date}
                minDate={state.start_date}
                onDateChange={(date) =>
                  setState((s) => ({
                    ...s,
                    page: 0,
                    estimateFuture:
                      state.end_date < new Date()
                        ? false
                        : state.estimateFuture,
                    end_date: date,
                  }))
                }
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                    borderColor: "grayAlpha.500",
                    bg: "whiteAlpha.400",
                  },
                }}
              />
            </FormControl>
            <Button
              style={{ marginTop: "2rem" }}
              rightIcon={<ArrowRightIcon fontSize={"sm"} />}
              isDisabled={state.end_date > new Date()}
              onClick={() => {
                const startDate = new Date(state.end_date);
                const newStartDate = rollMonth(startDate, 0);
                const newEndDate = rollMonth(startDate, 1);
                setState((s) => ({
                  ...s,
                  estimateFuture:
                    newEndDate < new Date() ? false : state.estimateFuture,
                  start_date: newStartDate,
                  end_date: newEndDate,
                }));
              }}
            />
            <FormControl maxW="15rem">
              <FormLabel textAlign="center">Type</FormLabel>
              <RadioCardGroup
                keys={["Location", "Affiliate"]}
                value={state.affiliate ? "Affiliate" : "Location"}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    affiliate: e === "Affiliate",
                    estimateFuture:
                      e === "Affiliate" ? false : state.estimateFuture,
                    showContracts:
                      e === "Location" ? false : state.showContracts,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="15rem">
              <FormLabel textAlign="center">Power Draw</FormLabel>
              <RadioCardGroup
                keys={["Contract", "Actual"]}
                value={state.showContractDraw ? "Contract" : "Actual"}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    showContractDraw: e === "Contract",
                  }))
                }
              />
            </FormControl>
            {!state.affiliate && (
              <FormControl maxW="15rem">
                <FormLabel textAlign="center">Future Earnings</FormLabel>
                <RadioCardGroup
                  keys={["Incomplete", "Estimate"]}
                  value={state.estimateFuture ? "Estimate" : "Incomplete"}
                  isDisabled={state.end_date < new Date()}
                  onChange={(e) =>
                    setState((f) => ({
                      ...f,
                      estimateFuture: e === "Estimate",
                    }))
                  }
                />
              </FormControl>
            )}
            {state.affiliate && (
              <FormControl maxW="15rem">
                <FormLabel textAlign="center">Show Contracts</FormLabel>
                <RadioCardGroup
                  keys={["Show", "Hide"]}
                  value={state.showContracts ? "Show" : "Hide"}
                  onChange={(e) =>
                    setState((f) => ({ ...f, showContracts: e === "Show" }))
                  }
                />
              </FormControl>
            )}
            <ExportButton
              startDate={state.start_date}
              endDate={state.end_date}
              estimateFuture={state.estimateFuture}
            />
          </HStack>
          <StatGroup m={4} justifyContent="center" alignItems="center">
            <Stat>
              <StatLabel>Contract Power Draw</StatLabel>
              <StatNumber>
                {data.total_contract_power_draw.formatted}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Actual Power Draw</StatLabel>
              <StatNumber>{data.total_actual_power_draw.formatted}</StatNumber>
            </Stat>
            {!state.affiliate && (
              <>
                <Stat>
                  <StatLabel>Uncontracted Power Draw</StatLabel>
                  <StatNumber>
                    {data.total_uncontracted_power_draw?.formatted}
                  </StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Eyedro Power Draw</StatLabel>
                  <StatNumber>{data.total_power?.formatted}</StatNumber>
                </Stat>
              </>
            )}
          </StatGroup>
          <StatGroup m={4} justifyContent="center" alignItems="center">
            <Stat>
              <StatLabel>Contract Power Usage</StatLabel>
              <StatNumber>{data.total_contract_power_usage} / kWh</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Contract Cost</StatLabel>
              <StatNumber>${data.total_contract_cost}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Actual Power Usage</StatLabel>
              <StatNumber>{data.total_actual_power_usage} / kWh</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Actual Cost</StatLabel>
              <StatNumber>${data.total_actual_cost}</StatNumber>
            </Stat>
          </StatGroup>
          <StatGroup m={4} justifyContent="center" alignItems="center">
            <Stat>
              <StatLabel>Miners</StatLabel>
              <StatNumber>{data.total_miner_count}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Avg Power Rate</StatLabel>
              <StatNumber>${data.total_avg_power_rate}</StatNumber>
            </Stat>
          </StatGroup>
          {data.notes?.length > 0 && (
            <VStack m={8}>
              <Heading size="sm">Miner Issues</Heading>
              {data.notes.map((note) => (
                <Text> {note}</Text>
              ))}
            </VStack>
          )}
          {state.affiliate && (
            <>
              <Heading p={4} size="sm">
                Affiliates
              </Heading>
              <Table size="sm" mb={4} variant={"simple"}>
                <Thead>
                  <Tr>
                    <Th>Affiliate</Th>
                    <Th>Commission Power Rate</Th>
                    <Th>Commission Percentage</Th>
                    {state.showContracts && <Th>Client Name</Th>}
                    {state.showContracts && <Th>Contract Name</Th>}
                    <Th>Miner Count</Th>
                    <Th>Avg Power Rate</Th>
                    <Th>Total Power Draw</Th>
                    <Th>Total Power Usage</Th>
                    <Th>Total Power Cost</Th>
                    <Th>Total Affiliate Cost</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.affiliates?.map((affiliate) => (
                    <>
                      <Tr key={affiliate.affiliate_id}>
                        <Td>{affiliate.affiliate_name}</Td>
                        <Td>
                          {affiliate.affiliate_commission_power_rate?.length ===
                          0
                            ? ""
                            : `$${affiliate.affiliate_commission_power_rate}`}
                        </Td>
                        <Td>
                          {affiliate.affiliate_commission_percentage?.length ===
                          0
                            ? ""
                            : `${affiliate.affiliate_commission_percentage}%`}
                        </Td>
                        {state.showContracts && <Td />}
                        {state.showContracts && <Td />}
                        <Td>{affiliate.miner_count}</Td>
                        <Td>${affiliate.avg_power_rate}</Td>
                        <Td>
                          {state.showContractDraw
                            ? affiliate.contract_power_draw.formatted
                            : affiliate.actual_power_draw.formatted}
                        </Td>
                        <Td>
                          {state.showContractDraw
                            ? affiliate.contract_power_usage
                            : affiliate.actual_power_usage}{" "}
                          / kWh
                        </Td>
                        <Td>
                          $
                          {state.showContractDraw
                            ? affiliate.contract_power_cost
                            : affiliate.actual_power_cost}
                        </Td>
                        <Td>
                          $
                          {state.showContractDraw
                            ? affiliate.contract_affiliate_cost
                            : affiliate.actual_affiliate_cost}
                        </Td>
                      </Tr>
                      {state.showContracts &&
                        affiliate.contracts?.map((contract) => (
                          <Tr key={contract.contract_id}>
                            <Td />
                            <Td />
                            <Td />
                            <Td>{contract.client_name}</Td>
                            <Td>{contract.contract_name}</Td>
                            <Td>{contract.miner_count}</Td>
                            <Td>${contract.avg_power_rate}</Td>
                            <Td>
                              {state.showContractDraw
                                ? contract.contract_power_draw.formatted
                                : contract.actual_power_draw.formatted}
                            </Td>
                            <Td>
                              {state.showContractDraw
                                ? contract.contract_power_usage
                                : contract.actual_power_usage}{" "}
                              / kWh
                            </Td>
                            <Td>
                              $
                              {state.showContractDraw
                                ? contract.contract_power_cost
                                : contract.actual_power_cost}
                            </Td>
                            <Td>
                              $
                              {state.showContractDraw
                                ? contract.contract_affiliate_cost
                                : contract.actual_affiliate_cost}
                            </Td>
                          </Tr>
                        ))}
                    </>
                  ))}
                </Tbody>
              </Table>
              <Heading p={4} size="sm">
                Locations
              </Heading>
            </>
          )}
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Miners</Th>
                <Th>Avg Power Rate</Th>
                {!state.affiliate && <Th>Uncontracted Power Draw</Th>}
                {!state.affiliate && <Th>Eyedro Power Draw</Th>}
                <Th>Power Draw</Th>
                <Th>Power Usage</Th>
                <Th>Power Cost</Th>
                {!state.affiliate && <Th>Total Power Percent</Th>}
                {!state.affiliate && <Th>Site Utilization Percent</Th>}
                {state.affiliate && <Th>Affiliate Cost</Th>}
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.networks.map((network) => (
                <>
                  <Tr key={network.network_id}>
                    <Td>{network.network_name}</Td>
                    {state.affiliate && state.showContracts && <Td />}
                    {state.affiliate && state.showContracts && <Td />}
                    <Td>{network.miner_count}</Td>
                    <Td>${network.avg_power_rate}</Td>
                    {!state.affiliate && (
                      <Td>{network.uncontracted_power_draw?.formatted}</Td>
                    )}
                    {!state.affiliate && (
                      <Td>{network.network_power?.formatted}</Td>
                    )}
                    <Td>
                      {state.showContractDraw
                        ? network.contract_power_draw.formatted
                        : network.actual_power_draw.formatted}
                    </Td>
                    <Td>
                      {state.showContractDraw
                        ? network.contract_power_usage
                        : network.actual_power_usage}{" "}
                      / kWh
                    </Td>
                    <Td>
                      $
                      {state.showContractDraw
                        ? network.contract_power_cost
                        : network.actual_power_cost}
                    </Td>
                    {!state.affiliate && (
                      <Td>
                        {state.showContractDraw
                          ? network.contract_power_percentage?.formatted
                          : network.actual_power_percentage?.formatted}
                      </Td>
                    )}
                    {!state.affiliate && (
                      <Td>
                        {state.showContractDraw
                          ? network.contract_capacity_percentage?.formatted
                          : network.actual_capacity_percentage?.formatted}
                      </Td>
                    )}
                    {state.affiliate && (
                      <Td>
                        $
                        {state.showContractDraw
                          ? network.contract_affiliate_cost
                          : network.actual_affiliate_cost}
                      </Td>
                    )}
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Panel>
  );
};
