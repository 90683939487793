import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "../";

type QueryArgs = {
  miner_id: string;
};

type AdminNote = {
  note: string;
};

export const fetchAdminNote = (args: QueryArgs) =>
  apiClient
    .get<APIResponse<AdminNote>>("/admin/notes", { params: args })
    .then((res) => res.data.data);

export const useAdminNotesQuery = (args: QueryArgs) =>
  useQuery(["/admin/notes", args], () => fetchAdminNote(args), {
    keepPreviousData: true,
  });
