import { useRef, useEffect } from "react";
import { Select, chakraComponents, SelectInstance } from "chakra-react-select";

export type MultiSelectItem = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  placeholder: string;
  values?: MultiSelectItem[];
  options: MultiSelectItem[];
  onSelect: (items: MultiSelectItem[]) => void;
  shouldClear?: boolean;
  isDisabled?: boolean;
};

export const MultiSelect = ({
  name,
  placeholder,
  values,
  options,
  onSelect,
  shouldClear = false,
  isDisabled = false,
}: Props) => {
  const selectRef = useRef<SelectInstance>(null);

  useEffect(() => {
    if (shouldClear === true) {
      if (selectRef?.current != null) {
        selectRef.current.clearValue();
      }
    }
  }, [shouldClear]);

  const components = {
    LoadingIndicator: (props: any) => (
      <chakraComponents.LoadingIndicator
        color="currentColor"
        emptyColor="transparent"
        spinnerSize="md"
        speed="0.45s"
        thickness="2px"
        {...props}
      />
    ),
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <Select
      isMulti
      ref={selectRef}
      name={name}
      placeholder={placeholder}
      components={components}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
      }}
      value={values}
      options={options}
      onChange={(e: any) => {
        if (e != null) {
          onSelect(
            e.map((item: any) => {
              return { value: item.value, label: item.label };
            })
          );
        }
      }}
      isDisabled={isDisabled}
    />
  );
};
