import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  type: string;
  file_id: string;
  email: string;
};

export type PDF = {
  name: string;
  url: string;
  expires_at: number;
};

export const fetchPdfUrl = (args: QueryArgs) =>
  apiClient.post<APIResponse<PDF>>("/pdf", args).then((res) => res.data.data);

export const usePdfUrlQuery = (args: QueryArgs) =>
  useQuery(["/pdf", args], () => fetchPdfUrl(args), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
