import { useState, useEffect } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import {
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  HStack,
  VStack,
  Text,
  Link,
  FormControl,
  FormLabel,
  Thead,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { addDateTimezone } from "src/lib/standardizeDate";
import { MutationFeedback } from "src/components/MutationFeedback";
import { useOpenTicketsQuery } from "src/api/queries/fetchOpenTickets";
import { Panel } from "src/components/Panel";
import { RadioCardGroup } from "src/components/RadioCard";
import { SimplePaginator } from "src/components/SimplePaginator";
import {
  TicketUpdate,
  useUpdateTicketMutation,
} from "src/api/mutations/ticketUpdate";

const UpdateTicketButton = ({
  id,
  solve_date,
}: {
  id: number;
  solve_date: Date;
}) => {
  const [state, setState] = useState<TicketUpdate>({ id, solve_date });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateTicketMutation();

  const handleUpdate = () => {
    mutation.mutate(state, {
      onSuccess: onClose,
    });
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({ id, solve_date });
    }
  }, [isOpen, mutation, id, solve_date]);

  useEffect(() => {
    if (!isOpen && (id !== state.id || solve_date !== state.solve_date)) {
      setState({ id, solve_date });
    }
  }, [isOpen, id, solve_date, state]);

  return (
    <>
      <Button colorScheme="teal" onClick={onOpen} size={"s"} p={2}>
        Solve Ticket
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Solve Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <FormControl id="name">
                <FormLabel>Solve Date</FormLabel>
                <SingleDatepicker
                  name={`ticket-solve-date`}
                  date={state.solve_date}
                  maxDate={new Date()}
                  onDateChange={(date) =>
                    setState((s) => ({ ...s, page: 0, solve_date: date }))
                  }
                  propsConfigs={{
                    inputProps: {
                      textAlign: "center",
                      borderColor: "grayAlpha.500",
                      bg: "whiteAlpha.400",
                    },
                  }}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUpdate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const TicketsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    solved?: boolean | null;
    online?: boolean | null;
    isEOK?: boolean | null;
    query?: string | null;
  }>({
    page: 0,
    size: 10,
    solved: false,
    online: true,
    isEOK: null,
    query: null,
  });

  const query = useOpenTicketsQuery({
    page: state.page,
    size: state.size,
    solved: state.solved,
    online: state.online,
    is_eok: state.isEOK,
    query: state.query,
  });

  return (
    <Panel title={"Tickets"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"start"}>
            <FormControl maxW="30rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      page: 0,
                      query:
                        e.target.value != null && e.target.value.length > 0
                          ? e.target.value
                          : undefined,
                    }))
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
            <FormControl maxW="20rem">
              <FormLabel textAlign="center">Solved</FormLabel>
              <RadioCardGroup
                keys={["All", "Solved", "Unsolved"]}
                value={
                  state.solved == null
                    ? "All"
                    : state.solved
                    ? "Solved"
                    : "Unsolved"
                }
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    solved: e === "All" ? null : e === "Solved" ? true : false,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="20rem">
              <FormLabel textAlign="center">Miner Status</FormLabel>
              <RadioCardGroup
                keys={["All", "Online", "Offline"]}
                value={
                  state.online == null
                    ? "All"
                    : state.online
                    ? "Online"
                    : "Offline"
                }
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    online: e === "All" ? null : e === "Online" ? true : false,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="20rem">
              <FormLabel textAlign="center">EOK</FormLabel>
              <RadioCardGroup
                keys={["All", "True", "False"]}
                value={
                  state.isEOK == null ? "All" : state.isEOK ? "True" : "False"
                }
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    isEOK: e === "All" ? null : e === "True" ? true : false,
                  }))
                }
              />
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Content</Th>
                <Th>Created At</Th>
                <Th>Modified At</Th>
                <Th>Solved At</Th>
                <Th>Miner Online At</Th>
                <Th>Miner ID</Th>
                <Th>Miner Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((ticket) => {
                return (
                  <Tr key={`ticket-${ticket.id}`}>
                    <Td>
                      <VStack>
                        <a
                          href={`https://glpi.bitcap.co/front/ticket.form.php?id=${ticket.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i>Ticket #{ticket.id}</i>
                        </a>
                        <Text w="full" textAlign="center">
                          {ticket.name}
                        </Text>
                        <Badge
                          variant="solid"
                          colorScheme={
                            ticket.status === "solved" ? "green" : "red"
                          }
                        >
                          {ticket.status}
                        </Badge>
                        {ticket.is_eok && (
                          <Badge variant="solid" colorScheme={"yellow"}>
                            EOK
                          </Badge>
                        )}
                      </VStack>
                    </Td>
                    <Td>
                      <VStack>
                        <Text w="full">
                          <b>
                            {addDateTimezone(
                              new Date(ticket.created_at)
                            ).toLocaleDateString()}
                          </b>
                          :{" "}
                          {ticket.first_content.length > 0
                            ? ticket.first_content
                            : "(no content)"}
                        </Text>
                        {ticket.last_content != null &&
                          ticket.last_content_at != null && (
                            <Text w="full">
                              <b>
                                {addDateTimezone(
                                  new Date(ticket.last_content_at)
                                ).toLocaleDateString()}
                                :{" "}
                              </b>
                              {ticket.last_content}
                            </Text>
                          )}
                      </VStack>
                    </Td>
                    <Td>{new Date(ticket.created_at).toLocaleDateString()}</Td>
                    <Td>
                      {ticket.modified_at != null
                        ? addDateTimezone(
                            new Date(ticket.modified_at)
                          ).toLocaleDateString()
                        : "N/A"}
                    </Td>
                    <Td>
                      {ticket.solved_at != null ? (
                        addDateTimezone(
                          new Date(ticket.solved_at)
                        ).toLocaleDateString()
                      ) : ticket.miner_online_at != null ? (
                        <UpdateTicketButton
                          id={ticket.id}
                          solve_date={new Date(ticket.miner_online_at)}
                        />
                      ) : (
                        "N/A"
                      )}
                    </Td>
                    <Td>
                      {ticket.miner_online_at != null
                        ? new Date(ticket.miner_online_at).toLocaleDateString()
                        : "N/A"}
                    </Td>
                    <Td>
                      {ticket.miner_id == null ? (
                        "Unknown"
                      ) : (
                        <Link
                          as={ReactRouterLink}
                          to={`/miners/${ticket.miner_id}`}
                          fontWeight="bold"
                        >
                          {ticket.miner_id}
                        </Link>
                      )}
                    </Td>
                    <Td>
                      <VStack>
                        {ticket.client_name != null && (
                          <Text w="full" textAlign="center">
                            <i>{ticket.client_name}</i>
                          </Text>
                        )}
                        {ticket.miner_name != null && (
                          <Text w="full" textAlign="center">
                            {ticket.miner_name}
                          </Text>
                        )}
                        <HStack>
                          <Badge
                            variant="solid"
                            colorScheme={ticket.miner_enabled ? "green" : "red"}
                          >
                            {ticket.miner_enabled ? "enabled" : "disabled"}
                          </Badge>
                          <Badge
                            variant="solid"
                            colorScheme={ticket.miner_online ? "green" : "red"}
                          >
                            {ticket.miner_online ? "online" : "offline"}
                          </Badge>
                        </HStack>
                      </VStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
