import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  page: number;
  size: number;
  type: string;
};

type MinerWithIssues = {
  id: string;
  name: string;
  location_id?: number;
  location_name?: string;
  location_alias?: string;
  ip_address?: string;
  client_id?: number;
  client_name?: string;
  enabled: boolean;
  online: boolean;
};

export const fetchDataMinersWithIssues = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<MinerWithIssues>>("/data/miner/issues", {
      params: args,
    })
    .then((res) => res.data.data);

export const useDataMinersWithIssuesQuery = (args: QueryArgs) =>
  useQuery(
    ["/data/miner/issues", args],
    () => fetchDataMinersWithIssues(args),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
