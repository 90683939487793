import { useState, useEffect, useRef } from "react";
import {
  Box,
  Divider,
  SimpleGrid,
  Flex,
  VStack,
  Text,
  IconButton,
  HStack,
  Switch,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import {
  useContractQuery,
  ContractMinerGroup,
  ContractMiner,
} from "src/api/queries/fetchContract";
import {
  AddIcon,
  LinkIcon,
  LockIcon,
  CheckIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  useDataMinersQuery,
  fetchDataMiners,
} from "src/api/queries/fetchDataMiners";
import {
  useContractMinerModelsQuery,
  fetchContractMinerModels,
  MinerModel,
} from "src/api/queries/fetchContractMinerModels";
import { useEditContractMutation } from "src/api/mutations/contractCreate";
import {
  SearchableItem,
  SearchableSelect,
} from "src/components/SearchableSelect";
import { MutationFeedback } from "src/components/MutationFeedback";

const parseLocalDate = (rawDate: string): Date => {
  const date = new Date(rawDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const localDate = new Date();
  const seconds = localDate.getHours();
  const minutes = localDate.getMinutes();
  const hours = localDate.getSeconds();

  return new Date(year, month, day, seconds, minutes, hours);
};

const formatDate = (date: Date) => {
  // const offset = date.getTimezoneOffset();
  // const formatted = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

const AddMinerGroupButton = ({
  contractId,
  revisionId,
}: {
  contractId: number;
  revisionId: number;
}) => {
  const [minerModel, setMinerModel] = useState<MinerModel | undefined>(
    undefined
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useEditContractMutation();

  const handleAdd = () => {
    if (minerModel !== undefined && minerModel.quantity !== undefined) {
      mutation.mutate(
        {
          contract_id: contractId,
          revision_id: revisionId,
          operations: [
            {
              operation: 2,
              contract_miner_model_id: minerModel.id,
              contract_miner_quantity: minerModel.quantity,
            },
          ],
        },
        {
          onSuccess: onClose,
        }
      );
    }
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  const [minerModelOptions, setMinerModelOptions] = useState<MinerModel[]>([]);
  const minerModelDefaultQuery = useContractMinerModelsQuery({
    query: null,
    page: 0,
    size: 10,
  });
  useEffect(() => {
    if (minerModelDefaultQuery?.data != null) {
      setMinerModelOptions(minerModelDefaultQuery.data.items);
    }
  }, [minerModelDefaultQuery.data]);

  const loadMinerModelOptions = async (query: string) => {
    const data = await fetchContractMinerModels({ query, page: 0, size: 10 });
    setMinerModelOptions(data.items);
    return data.items.map((item: any) => {
      let name = item.name;
      if (item.hashrate != null) {
        name += ` (${item.hashrate.formatted})`;
      }
      name += ` (${item.power_draw.formatted})`;
      if (item.employee) {
        name += " [E]";
      }

      return { value: item.id, label: name };
    });
  };

  return (
    <>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        variant="ghost"
        bg={"green.400"}
        aria-label="Add"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Miner Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <SearchableSelect
                  name={"miners"}
                  placeholder={"Add a miner"}
                  defaultOptions={minerModelOptions.map((opt) => {
                    return { value: String(opt.id), label: opt.name };
                  })}
                  loadOptions={loadMinerModelOptions}
                  onSelect={(item: SearchableItem) => {
                    const miner = minerModelOptions.find(
                      (miner) => String(miner.id) === String(item.value)
                    );
                    if (miner != null) {
                      setMinerModel(miner);
                    }
                  }}
                />
              </FormControl>
              <FormControl id="quantity">
                <FormLabel>Quantity</FormLabel>
                <NumberInput
                  step={1}
                  value={
                    minerModel?.quantity === undefined ||
                    minerModel.quantity === 0
                      ? ""
                      : minerModel.quantity
                  }
                  onChange={(e) =>
                    setMinerModel((s) =>
                      s === undefined
                        ? undefined
                        : { ...s, quantity: parseInt(e) }
                    )
                  }
                  isDisabled={minerModel === undefined}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleAdd}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditMinerGroupButton = ({
  contractId,
  revisionId,
  minerGroup,
  disabled,
}: {
  contractId: number;
  revisionId: number;
  minerGroup: ContractMinerGroup;
  disabled: boolean;
}) => {
  const [state, setState] = useState<ContractMinerGroup>(minerGroup);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useEditContractMutation();

  const handleUpdate = () => {
    mutation.mutate(
      {
        contract_id: contractId,
        revision_id: revisionId,
        operations: [
          {
            operation: 3,
            contract_miner_group_id: minerGroup.id,
            contract_miner_quantity: state.total,
          },
        ],
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(minerGroup);
    }
  }, [isOpen, mutation, minerGroup]);

  useEffect(() => {
    if (
      !isOpen &&
      (minerGroup.id !== state.id || minerGroup.total !== state.total)
    ) {
      setState(minerGroup);
    }
  }, [isOpen, minerGroup, state]);

  return (
    <>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        variant="ghost"
        bg={"yellow.400"}
        aria-label="Edit"
        icon={<EditIcon />}
        disabled={disabled}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Miner Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input type="name" value={state.name} isDisabled={true} />
              </FormControl>
              <FormControl id="quantity">
                <FormLabel>Quantity</FormLabel>
                <NumberInput
                  step={1}
                  value={state.total === 0 ? "" : state.total}
                  onChange={(e) => {
                    const quantity = parseInt(e);
                    setState((s) => ({
                      ...s,
                      total: isNaN(quantity) ? 0 : quantity,
                    }));
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUpdate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const UnbindMinerButton = ({
  contractId,
  revisionId,
  miner,
  disabled,
}: {
  contractId: number;
  revisionId: number;
  miner: ContractMiner;
  disabled: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useEditContractMutation();

  const handleUnbind = () => {
    mutation.mutate(
      {
        contract_id: contractId,
        revision_id: revisionId,
        operations: [
          {
            operation: 5,
            contract_miner_id: miner.id,
          },
        ],
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        bg={"yellow.400"}
        variant="ghost"
        aria-label="Unbind"
        icon={<LinkIcon />}
        disabled={disabled}
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Unbind Miner
      </IconButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unbind Miner
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want unbind miner <b>{miner.miner_name}</b>?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleUnbind}
                ml={3}
              >
                Unbind
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const DeactivateMinerButton = ({
  contractId,
  revisionId,
  miner,
  disabled,
}: {
  contractId: number;
  revisionId: number;
  miner: ContractMiner;
  disabled: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useEditContractMutation();

  const handleDeactivate = () => {
    mutation.mutate(
      {
        contract_id: contractId,
        revision_id: revisionId,
        operations: [
          {
            operation: 6,
            contract_miner_id: miner.id,
          },
        ],
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        bg={"red.400"}
        variant="ghost"
        aria-label="Unbind"
        icon={<LockIcon />}
        disabled={disabled}
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Unbind Miner
      </IconButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deactivate Miner
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want deactivate miner{" "}
                  <b>{miner.miner_name}</b>?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDeactivate}
                ml={3}
              >
                Deactivate
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

type Props = {
  clientId: number;
  contractId: number;
};

export const MinersPanel = ({ clientId, contractId }: Props) => {
  const dateExpr = /\d{4}-\d{2}-\d{2}/;

  const [enableDelete, setEnableDelete] = useState<boolean>(false);
  const [minerRecords, setMinerRecords] = useState<Record<number, string>>({});
  const [dateRecords, setDateRecords] = useState<Record<number, string>>({});

  const defaultQuery = useDataMinersQuery({
    unbound: true,
    client_id: clientId,
    page: 0,
    size: 50,
  });
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  useEffect(() => {
    if (defaultQuery?.data != null) {
      const items = defaultQuery.data.items.map((item: any) => {
        return { value: item.id, label: item.name };
      });
      setDefaultOptions(items);
    }
  }, [defaultQuery.data]);

  const loadSelectOptions = async (query: string) => {
    const data = await fetchDataMiners({
      unbound: true,
      client_id: clientId,
      query: query,
      page: 0,
      size: 50,
    });
    const items = data.items.filter(
      (item) =>
        Object.entries(minerRecords).find(([_, id]) => id === item.id) ===
        undefined
    );
    return items.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  const query = useContractQuery(contractId);
  const bindMutation = useEditContractMutation();

  return (
    <Panel title={"Miners"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack justifyContent="end" mb={4}>
            <HStack justifyItems="center" mx={2}>
              <FormLabel m={0}>Add a Miner Group</FormLabel>
              <AddMinerGroupButton
                contractId={contractId}
                revisionId={data.revision_id}
              />
            </HStack>
            <HStack mx={2}>
              <FormLabel m={0}>Delete Mode Enabled</FormLabel>
              <Switch
                isChecked={enableDelete}
                onChange={(e) => setEnableDelete(e.target.checked)}
              />
            </HStack>
          </HStack>
          <MutationFeedback mutation={bindMutation} />
          <SimpleGrid minChildWidth={"15rem"} gap={3}>
            {data.miner_groups.map((group) => {
              return (
                <Box
                  key={group.id}
                  minH="10rem"
                  bg={"blackAlpha.100"}
                  p={3}
                  borderRadius="md"
                  _hover={{ bg: "blackAlpha.200" }}
                >
                  <Flex justifyContent="space-between">
                    <Box>
                      <b>{group.name}</b>
                    </Box>
                    <HStack>
                      <Box>
                        {group.activated}/{group.total}
                      </Box>
                      <EditMinerGroupButton
                        contractId={contractId}
                        revisionId={data.revision_id}
                        minerGroup={group}
                        disabled={!enableDelete}
                      />
                    </HStack>
                  </Flex>
                  <Box>
                    <i>{group.power_draw}w</i>
                  </Box>
                  <Divider borderColor="blackAlpha.500" my={1} />
                  <Text textAlign="center">
                    <b>
                      <i>Bound Miners</i>
                    </b>
                  </Text>
                  <Divider borderColor="blackAlpha.500" my={1} />
                  <Flex flexWrap={"wrap"} justifyContent="center">
                    {group.miners
                      .filter((miner) => miner.miner_id != null)
                      .map((miner) => (
                        <VStack
                          key={miner.id}
                          p={1}
                          m={1}
                          borderRadius={"md"}
                          w="18rem"
                          bg={"whiteAlpha.500"}
                        >
                          <Text
                            p={3}
                            w="full"
                            borderRadius="md"
                            bg={"green.400"}
                            textAlign="center"
                          >
                            {miner.miner_name}
                          </Text>
                          <HStack justifyContent="space-between">
                            <Input
                              value={
                                miner.activated_at != null
                                  ? formatDate(new Date(miner.activated_at))
                                  : undefined
                              }
                              disabled={true}
                            />
                            <UnbindMinerButton
                              contractId={contractId}
                              revisionId={data.revision_id}
                              miner={miner}
                              disabled={!enableDelete}
                            />
                            <DeactivateMinerButton
                              contractId={contractId}
                              revisionId={data.revision_id}
                              miner={miner}
                              disabled={!enableDelete}
                            />
                          </HStack>
                        </VStack>
                      ))}
                  </Flex>
                  <Divider borderColor="blackAlpha.500" my={1} />
                  <Text textAlign="center">
                    <b>
                      <i>Unbound Miners</i>
                    </b>
                  </Text>
                  <Divider borderColor="blackAlpha.500" my={1} />
                  <Flex flexWrap={"wrap"} justifyContent="center">
                    {group.miners
                      .filter((miner) => miner.miner_id == null)
                      .map((miner) => {
                        return (
                          <VStack
                            key={miner.id}
                            p={1}
                            m={1}
                            w="18rem"
                            borderRadius={"md"}
                            bg={"whiteAlpha.500"}
                          >
                            <Box
                              p={1}
                              w="full"
                              borderRadius="md"
                              textAlign="center"
                            >
                              <SearchableSelect
                                key={`select-${group.id}`}
                                name={"miners"}
                                placeholder={"Bind a miner"}
                                defaultOptions={defaultOptions.filter(
                                  (opt) =>
                                    Object.entries(minerRecords).find(
                                      ([_, id]) => id === opt.value
                                    ) === undefined
                                )}
                                loadOptions={loadSelectOptions}
                                onSelect={(item: SearchableItem) =>
                                  setMinerRecords((s) => ({
                                    ...s,
                                    [miner.id]: item.value,
                                  }))
                                }
                              />
                            </Box>
                            <HStack justifyContent="space-between">
                              <Input
                                value={
                                  dateRecords[miner.id] === undefined
                                    ? formatDate(new Date())
                                    : dateRecords[miner.id]
                                }
                                onChange={(e) =>
                                  setDateRecords((s) => ({
                                    ...s,
                                    [miner.id]: e.target.value,
                                  }))
                                }
                              />
                              <IconButton
                                size="sm"
                                colorScheme="blackAlpha"
                                bg={"green.400"}
                                variant="ghost"
                                aria-label="Accept"
                                icon={<CheckIcon />}
                                disabled={
                                  minerRecords[miner.id] === undefined ||
                                  (dateRecords[miner.id] !== undefined &&
                                    !dateExpr.test(dateRecords[miner.id]))
                                }
                                onClick={() => {
                                  bindMutation.mutate({
                                    contract_id: contractId,
                                    revision_id: data.revision_id,
                                    operations: [
                                      {
                                        operation: 4,
                                        contract_miner_id: miner.id,
                                        miner_id: minerRecords[miner.id],
                                        activated_at:
                                          dateRecords[miner.id] === undefined
                                            ? new Date()
                                            : parseLocalDate(
                                                dateRecords[miner.id]!
                                              ),
                                      },
                                    ],
                                  });
                                }}
                              />
                            </HStack>
                          </VStack>
                        );
                      })}
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>
        </>
      )}
    </Panel>
  );
};
