import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { StringParam, NumberParam, useQueryParams } from "use-query-params";
import {
  Stack,
  Heading,
  ButtonGroup,
  Text,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { User } from "src/types";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SmolStackLayout } from "src/components/SmolStackLayout";
import { authConfirmChangeEmail } from "src/api/mutations/authConfirmChangeEmail";

type Props = {
  user: User | undefined;
};

export const ChangeEmailPage = ({ user }: Props) => {
  const [{ token, user_id }] = useQueryParams({
    token: StringParam,
    user_id: NumberParam,
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(authConfirmChangeEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries("/user");
    },
  });

  useEffect(() => {
    if (user_id && token && mutation.isIdle) {
      mutation.mutate({ user_id, token });
    }
  }, [user_id, token, mutation]);

  return (
    <SmolStackLayout>
      {mutation.isSuccess ? (
        <Stack spacing={4}>
          <Heading size="md">Email Successfully Changed</Heading>
          <Text>You may now login with your new email.</Text>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to {user ? "Overview" : "Login"}
            </Button>
          </ButtonGroup>
        </Stack>
      ) : mutation.isError ? (
        <Stack spacing={4}>
          <Heading size="md">Email Change Error</Heading>
          <MutationFeedback mutation={mutation} />
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to {user ? "Overview" : "Login"}
            </Button>
          </ButtonGroup>
        </Stack>
      ) : token && user_id ? (
        <Stack spacing={4}>
          <Heading size="md">Updating Email</Heading>
          <Spinner />
        </Stack>
      ) : (
        <Stack spacing={4}>
          <Text>Something went wrong!</Text>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      )}
    </SmolStackLayout>
  );
};
