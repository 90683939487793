import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export const useUpdateInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { invoice_id: number; checked: boolean }) => {
      return apiClient.post<APIResponse<null>>("/invoice", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/invoice");
      await queryClient.invalidateQueries("/invoices");
      await queryClient.invalidateQueries("/invoices/summary");
      await queryClient.invalidateQueries("/invoices/combined");
      await queryClient.invalidateQueries("/invoices/combined/shift");
    },
  });
};

export const useSaveShiftedCombinedInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: {
      client_id: number;
      start_month: number;
      checksum: string;
      skip_invoice_ids?: number[];
    }) => {
      return apiClient.put<APIResponse<null>>("/invoices/combined/shift", {
        client_id: body.client_id,
        start_month: body.start_month,
        checksum: body.checksum,
        skip_invoice_ids:
          body.skip_invoice_ids?.length === 0
            ? undefined
            : body.skip_invoice_ids!.join(","),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/invoice");
      await queryClient.invalidateQueries("/invoices");
      await queryClient.invalidateQueries("/invoices/summary");
      await queryClient.invalidateQueries("/invoices/combined");
      await queryClient.invalidateQueries("/invoices/combined/shift");
    },
  });
};

export const useApplyShiftedCombinedInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { contract_id: number }) => {
      return apiClient.post<APIResponse<null>>(
        "/invoices/combined/shift",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/invoice");
      await queryClient.invalidateQueries("/invoices");
      await queryClient.invalidateQueries("/invoices/summary");
      await queryClient.invalidateQueries("/invoices/combined");
      await queryClient.invalidateQueries("/invoices/combined/shift");
    },
  });
};
