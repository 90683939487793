import { StatGroup, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { useMonitoringSummaryQuery } from "src/api/queries/fetchMonitoringSummary";
import { Panel } from "src/components/Panel";

export const SummaryPanel = () => {
  const query = useMonitoringSummaryQuery();

  return (
    <Panel title="Summary" flex={3} query={query}>
      {(data) => (
        <StatGroup>
          <Stat>
            <StatLabel>AwesomeMiner Hosts Online</StatLabel>
            <StatNumber>
              {data.awesomeminer.online} / {data.awesomeminer.total}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Eyedros Online</StatLabel>
            <StatNumber>
              {data.eyedro.online} / {data.eyedro.total}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Watchmans Online</StatLabel>
            <StatNumber>
              {data.watchman.online} / {data.watchman.total}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>PDU Count</StatLabel>
            <StatNumber>{data.pdu.total}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Smart PDUs Online</StatLabel>
            <StatNumber>
              {data.pdu.online} / {data.pdu.smart}
            </StatNumber>
          </Stat>
        </StatGroup>
      )}
    </Panel>
  );
};
