import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  user_id: number;
  email: string;
};

export const userChangeEmail = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/user/change_email", {
    ...body,
  });
};
