import { useEffect, useState } from "react";
import {
  Badge,
  HStack,
  VStack,
  Text,
  Switch,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Textarea,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { Panel } from "src/components/Panel";
import { TicketMessage } from "src/api/queries/fetchTicket";
import { useUserContext } from "src/contexts/UserContext";
import {
  useCreateTicketMessageMutation,
  useUpdateTicketMessageMutation,
  useDeleteTicketMessageMutation,
} from "src/api/mutations/ticketCreate";
import { MutationFeedback } from "src/components/MutationFeedback";

const EditTicketMessageButton = ({ message }: { message: TicketMessage }) => {
  const [state, setState] = useState<TicketMessage>(message);
  const [files, setFiles] = useState<File[]>([]);
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateMutation = useUpdateTicketMessageMutation();
  const deleteMutation = useDeleteTicketMessageMutation();

  useEffect(() => {
    if (!isOpen && !updateMutation.isIdle) {
      updateMutation.reset();
      setState(message);
    }
  }, [isOpen, updateMutation, message]);

  useEffect(() => {
    if (
      !isOpen &&
      (message.id !== state.id ||
        message.public !== state.public ||
        message.message !== state.message)
    ) {
      setState(message);
    }
  }, [isOpen, message, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Message"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems="end">
              <FormControl id="message">
                <FormLabel>Message</FormLabel>
                <Textarea
                  value={state.message}
                  onChange={(e) =>
                    setState((s) => ({ ...s, message: e.target.value }))
                  }
                  onPaste={(e) => {
                    if (e.clipboardData.files.length > 0) {
                      const newFiles = Array.from(files).concat(
                        Array.from(e.clipboardData.files)
                      );
                      setFiles(newFiles);
                    }
                  }}
                  isDisabled={
                    updateMutation.isLoading || deleteMutation.isLoading
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Public</FormLabel>
                <Switch
                  size="lg"
                  isChecked={state.public}
                  onChange={(e) =>
                    setState((s) => ({ ...s, public: e.target.checked }))
                  }
                  isDisabled={
                    updateMutation.isLoading || deleteMutation.isLoading
                  }
                />
              </FormControl>
              <FormControl id="files" w="full">
                <FormLabel>Remove Files</FormLabel>
                <Flex flexWrap="wrap">
                  {state.attachments.map((attachment) => {
                    const deleted =
                      deleteFileIds.find(
                        (attachmentId) => attachmentId === attachment.id
                      ) != null;

                    return (
                      <HStack key={`attachment-${attachment.id}`}>
                        {!deleted && (
                          <IconButton
                            size="xs"
                            colorScheme="red"
                            variant="ghost"
                            aria-label="Delete Image"
                            icon={<DeleteIcon />}
                            onClick={() =>
                              setDeleteFileIds([
                                ...deleteFileIds,
                                attachment.id,
                              ])
                            }
                          />
                        )}
                        {deleted && (
                          <IconButton
                            size="xs"
                            colorScheme="green"
                            variant="ghost"
                            aria-label="Undo Delete Image"
                            icon={<RepeatIcon />}
                            onClick={() =>
                              setDeleteFileIds(
                                deleteFileIds.filter(
                                  (attachmentId) =>
                                    attachmentId !== attachment.id
                                )
                              )
                            }
                          />
                        )}
                        <Image
                          p={4}
                          boxSize="150px"
                          objectFit="contain"
                          src={attachment.url}
                          alt={attachment.file_name}
                          loading="lazy"
                        />
                      </HStack>
                    );
                  })}
                </Flex>
              </FormControl>
              <FormControl id="files" w="full">
                <FormLabel>Upload Files</FormLabel>
                <Input
                  textAlign="center"
                  alignSelf="center"
                  h="100px"
                  pt="35px"
                  type="file"
                  accept="image/*"
                  value=""
                  onChange={(e) => {
                    if (e.target.files != null) {
                      const newFiles = Array.from(files).concat(
                        Array.from(e.target.files)
                      );
                      setFiles(newFiles);
                    }
                  }}
                  multiple
                />
              </FormControl>
              <VStack w="full" justify="center">
                {files.map((file, idx) => (
                  <HStack key={`file-${idx}`}>
                    <IconButton
                      size="xs"
                      colorScheme="blackAlpha"
                      variant="ghost"
                      aria-label="Delete Image"
                      icon={<DeleteIcon />}
                      onClick={() =>
                        setFiles(files.filter((_, fileIdx) => fileIdx !== idx))
                      }
                    />
                    <Text>{file.name}</Text>
                  </HStack>
                ))}
              </VStack>
              <MutationFeedback mutation={updateMutation} />
              <MutationFeedback mutation={deleteMutation} />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={updateMutation.isLoading || deleteMutation.isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={updateMutation.isLoading || deleteMutation.isLoading}
              isDisabled={
                state.message?.length === 0 &&
                files.length === 0 &&
                state.attachments.filter(
                  (attachment) =>
                    deleteFileIds.find(
                      (deleteFileId) => attachment.id === deleteFileId
                    ) == null
                ).length === 0
              }
              onClick={() =>
                updateMutation.mutate(
                  {
                    message_id: state.id,
                    public: state.public,
                    message:
                      state.message?.length === 0 ? undefined : state.message,
                    files: files,
                    delete_files: deleteFileIds,
                  },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Submit
            </Button>
            <Button
              colorScheme="red"
              isLoading={updateMutation.isLoading || deleteMutation.isLoading}
              onClick={() =>
                deleteMutation.mutate(
                  { message_id: state.id },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const MessagesPanel = ({
  ticketId,
  messages,
}: {
  ticketId: number;
  messages: TicketMessage[];
}) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const [newPublicStatus, setNewPublicStatus] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const createMutation = useCreateTicketMessageMutation();
  const { user } = useUserContext();

  return (
    <Panel title={"Messages"}>
      <Flex
        alignItems="left"
        justifyContent="flex-start"
        flexDirection="column"
        mb="4rem"
      >
        {messages.map((message) => {
          const isUsersMessage = message.creator_id === user?.id;
          return (
            <VStack
              key={`message-${message.id}`}
              maxW="75%"
              bg="blackAlpha.200"
              borderRadius={"5px"}
              p={3}
              my={3}
              ml={isUsersMessage ? "25%" : 0}
              mr={!isUsersMessage ? "25%" : 0}
              alignItems={isUsersMessage ? "end" : "start"}
              justifyContent={isUsersMessage ? "flex-end" : "flex-start"}
            >
              <HStack
                mb={4}
                justify={isUsersMessage ? "space-between" : "start"}
                w="full"
              >
                <HStack>
                  {isUsersMessage && (
                    <EditTicketMessageButton message={message} />
                  )}
                  <Badge
                    variant="solid"
                    colorScheme={message.public ? "red" : "green"}
                  >
                    {message.public ? "public" : "private"}
                  </Badge>
                </HStack>
                <Text>
                  <b>{message.creator_name}</b> (
                  <i>{new Date(message.created_at).toLocaleString()}</i>)
                </Text>
              </HStack>
              <Text>{message.message}</Text>
              <Flex flexWrap="wrap" justify="center">
                {message.attachments.map((attachment) => (
                  <Image
                    key={`attachment-img-${attachment.id}`}
                    p={4}
                    maxW="full"
                    maxH="500px"
                    src={attachment.url}
                    alt={attachment.file_name}
                    loading="lazy"
                  />
                ))}
              </Flex>
            </VStack>
          );
        })}
        <VStack alignItems="end">
          <FormControl id="message">
            <FormLabel>Message</FormLabel>
            <Textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onPaste={(e) => {
                if (e.clipboardData.files.length > 0) {
                  const newFiles = Array.from(files).concat(
                    Array.from(e.clipboardData.files)
                  );
                  setFiles(newFiles);
                }
              }}
              isDisabled={createMutation.isLoading}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Public</FormLabel>
            <Switch
              size="lg"
              isChecked={newPublicStatus}
              onChange={(e) => setNewPublicStatus(e.target.checked)}
              isDisabled={createMutation.isLoading}
            />
          </FormControl>
          <HStack w="full">
            <FormControl id="files" w="50%">
              <FormLabel>Upload Files</FormLabel>
              <Input
                textAlign="center"
                alignSelf="center"
                h="100px"
                pt="35px"
                type="file"
                accept="image/*"
                value=""
                onChange={(e) => {
                  if (e.target.files != null) {
                    const newFiles = Array.from(files).concat(
                      Array.from(e.target.files)
                    );
                    setFiles(newFiles);
                  }
                }}
                multiple
              />
            </FormControl>
            <VStack w="50%" justify="center">
              {files.map((file, idx) => (
                <HStack key={`file-${idx}`}>
                  <IconButton
                    size="xs"
                    colorScheme="blackAlpha"
                    variant="ghost"
                    aria-label="Delete Image"
                    icon={<DeleteIcon />}
                    onClick={() =>
                      setFiles(files.filter((_, fileIdx) => fileIdx !== idx))
                    }
                  />
                  <Text>{file.name}</Text>
                </HStack>
              ))}
            </VStack>
          </HStack>
          <MutationFeedback mutation={createMutation} />
          <Button
            colorScheme="teal"
            isLoading={createMutation.isLoading}
            isDisabled={
              createMutation.isLoading ||
              (newMessage.length === 0 && files.length === 0)
            }
            onClick={() =>
              createMutation.mutate(
                {
                  ticket_id: ticketId,
                  public: newPublicStatus,
                  message: newMessage.length === 0 ? undefined : newMessage,
                  files: files,
                },
                {
                  onSuccess: () => {
                    setNewMessage("");
                    setNewPublicStatus(false);
                    setFiles([]);
                  },
                }
              )
            }
          >
            Create
          </Button>
        </VStack>
      </Flex>
    </Panel>
  );
};
