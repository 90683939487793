import { Text } from "@chakra-ui/react";

type DollarsProps = {
  value: string;
};

export const Dollars = ({ value }: DollarsProps) => {
  return (
    <Text as="span" whiteSpace="nowrap" fontWeight="medium">
      {value}
    </Text>
  );
};
