import { Stack, Grid, Box } from "@chakra-ui/react";
import { MinerPreviewResponse } from "src/api/queries/fetchDataMinerGroup";
import { MinerPreview } from "./MinerPreview";

type MinerPreviewListProps = {
  miners: MinerPreviewResponse[];
};
export const MinerPreviewList = ({ miners }: MinerPreviewListProps) => {
  return (
    <Stack overflowX="auto">
      <Grid
        mx={2}
        templateColumns="2fr repeat(4, 1fr)"
        gap={2}
        textTransform="uppercase"
        fontSize="sm"
        minW="40rem"
      >
        <Box>Description</Box>
        <Box>Status</Box>
        <Box>Earnings</Box>
        <Box>Hashrate</Box>
        <Box>Progress</Box>
      </Grid>
      {miners?.map((miner) => (
        <MinerPreview key={miner.id} miner={miner} />
      ))}
    </Stack>
  );
};
