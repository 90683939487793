import { Redirect } from "react-router-dom";
import { useMutation } from "react-query";
import { ReactNode, useState, useEffect } from "react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { Panel } from "src/components/Panel";
import { PanelRow } from "src/components/PanelRow";
import { SwitchButton } from "src/components/SwitchButton";
import {
  SearchableSelect,
  SearchableItem,
} from "src/components/SearchableSelect";
import { Dollars } from "src/components/Dollars";
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  FormLabel,
  Switch,
  NumberInput,
  NumberInputField,
  Input,
  Textarea,
  Table,
  Thead,
  Center,
  Tbody,
  Th,
  Tr,
  Td,
  Stat,
  HStack,
  StatNumber,
  StatLabel,
} from "@chakra-ui/react";
import {
  Contract,
  Client,
  useCreateContractMutation,
  previewContract,
  previewQuote,
} from "src/api/mutations/contractCreate";
import { fetchAdminUsers, AdminUser } from "src/api/queries/fetchAdminUsers";
import { validateEmail } from "src/lib/validateEmail";
import { downloadPDF } from "src/lib/downloadFile";
import { Contract as ContractData } from "src/api/queries/fetchContract";
import {
  useContractAffiliateModelsQuery,
  fetchContractAffiliateModels,
  AffiliateModel,
} from "src/api/queries/fetchContractAffiliateModels";
import {
  useContractMinerModelsQuery,
  fetchContractMinerModels,
  MinerModel,
} from "src/api/queries/fetchContractMinerModels";
import {
  fetchAdminClients,
  Client as AdminClient,
} from "src/api/queries/fetchAdminClients";
import { MutationFeedback } from "src/components/MutationFeedback";

const ANNUAL_FACTOR = 24 * 365;
const MONTHLY_FACTOR = ANNUAL_FACTOR / 12;

const calcProgressivePowerRate = (minerCount: number) => {
  if (minerCount < 6) {
    return 0.0825;
  } else if (minerCount < 34) {
    return 0.0818;
  } else if (minerCount < 67) {
    return 0.081;
  } else if (minerCount < 80) {
    return 0.0803;
  } else if (minerCount < 133) {
    return 0.0795;
  } else if (minerCount < 166) {
    return 0.0788;
  } else if (minerCount < 199) {
    return 0.078;
  } else if (minerCount < 231) {
    return 0.0773;
  } else if (minerCount < 265) {
    return 0.0765;
  } else if (minerCount < 297) {
    return 0.0758;
  } else if (minerCount < 331) {
    return 0.075;
  } else if (minerCount < 364) {
    return 0.0743;
  } else if (minerCount < 397) {
    return 0.0735;
  } else {
    return 0.0728;
  }
};

type SubPanelProps = {
  title?: string;
  children?: ReactNode;
  flex?: number;
};

const SubPanel = ({ title, children, flex = 1 }: SubPanelProps) => {
  return (
    <Box
      p={3}
      flex={flex}
      borderRadius="md"
      borderWidth={1}
      borderColor="blackAlpha.200"
    >
      {title != null && (
        <Heading pt={1} px={1} textTransform="capitalize" size="xs" mb={4}>
          {title}
        </Heading>
      )}
      <Flex flexWrap="wrap" gridGap={4} flexDir="column" alignItems="center">
        {children}
      </Flex>
    </Box>
  );
};

type CustomClause = {
  title: string;
  text: string;
};

export const ContractBuilderPanel = ({
  previousContract,
}: {
  previousContract?: ContractData;
}) => {
  const [contractCreated, setContractCreated] = useState<boolean>(false);
  const createContractMutation = useCreateContractMutation();
  const previewContractMutation = useMutation(previewContract, {
    onSuccess: (response) => downloadPDF("contract.pdf", response.data),
  });
  const previewQuoteMutation = useMutation(previewQuote, {
    onSuccess: (response) => downloadPDF("quote.pdf", response.data),
  });

  const [client, setClient] = useState<Client>({
    first_name: "",
    last_name: "",
    email: "",
    company: undefined,
  });
  const [form, setForm] = useState<Contract>({
    term: 2,
    duration_months: 12,
    deposit_months: 1,
    pre_payment_months: 2,
    power_rate: "0.085",
    wave_deployment_fee: previousContract !== null,
    is_batched: false,
    miners: [],
    affiliates: [],
    extra_titles: [],
    extra_clauses: [],
  });

  const handleCreate = () => {
    if (form.client_id != null && form.client_user_id) {
      createContractMutation.mutate({ ...form });
    } else {
      createContractMutation.mutate({ ...form, client: client });
    }
  };

  useEffect(() => {
    if (createContractMutation.isSuccess) {
      setContractCreated(true);
    }
  }, [createContractMutation.isSuccess]);

  const formIncomplete =
    ((form.client_id == null || form.client_user_id == null) &&
      (!client.email.length ||
        !client.first_name.length ||
        !client.last_name.length)) ||
    form.miners.length === 0;
  const [manualPowerRate, setManualPowerRate] = useState<number | null>(null);
  const [clearMiners, setClearMiners] = useState<boolean>(false);
  const [clearAffiliates, setClearAffiliates] = useState<boolean>(false);

  // client user options
  const [selectedUser, setSelectedUser] = useState<SearchableItem | undefined>(
    undefined
  );
  const [userOptions, setUserOptions] = useState<AdminUser[]>([]);
  const loadUserOptions = async (query: string) => {
    const data = await fetchAdminUsers({
      type: "all",
      query: query.length > 0 ? query : null,
      page: 0,
      size: 10,
    });
    setUserOptions(data.items);
    return data.items.map((item: any) => {
      return {
        value: String(item.id),
        label: item.first_name + " " + item.last_name + " - " + item.email,
      };
    });
  };

  const [selectedClient, setSelectedClient] = useState<
    SearchableItem | undefined
  >(undefined);
  const [clientOptions, setClientOptions] = useState<AdminClient[]>([]);
  const loadClientOptions = async (query: string) => {
    const data = await fetchAdminClients({
      query: query.length > 0 ? query : null,
      page: 0,
      size: 10,
    });
    setClientOptions(data.items);
    return data.items.map((item: any) => {
      return { value: String(item.id), label: item.name };
    });
  };

  // miner options
  const [miners, setMiners] = useState<MinerModel[]>([]);
  const [minerModelOptions, setMinerModelOptions] = useState<MinerModel[]>([]);
  const minerModelDefaultQuery = useContractMinerModelsQuery({
    query: null,
    page: 0,
    size: 10,
  });
  useEffect(() => {
    if (minerModelDefaultQuery?.data != null) {
      setMinerModelOptions(minerModelDefaultQuery.data.items);
    }
  }, [minerModelDefaultQuery.data]);

  const loadMinerModelOptions = async (query: string) => {
    const data = await fetchContractMinerModels({ query, page: 0, size: 10 });
    const items = data.items.filter(
      (item) => form.miners.find((miner) => miner.id === item.id) === undefined
    );
    setMinerModelOptions(items);
    return items.map((item: any) => {
      let name = item.name;
      if (item.hashrate != null) {
        name += ` (${item.hashrate.formatted})`;
      }
      name += ` (${item.power_draw.formatted})`;
      if (item.employee) {
        name += " [E]";
      }

      return { value: item.id, label: name };
    });
  };

  // affiliate options
  const [affiliates, setAffiliates] = useState<AffiliateModel[]>([]);
  const [affiliateOptions, setAffiliateOptions] = useState<AffiliateModel[]>(
    []
  );
  const affiliateDefaultQuery = useContractAffiliateModelsQuery({
    query: null,
    page: 0,
    size: 10,
  });
  useEffect(() => {
    if (affiliateDefaultQuery?.data != null) {
      setAffiliateOptions(affiliateDefaultQuery.data.items);
    }
  }, [affiliateDefaultQuery.data]);

  const loadAffiliateOptions = async (query: string) => {
    const data = await fetchContractAffiliateModels({
      query,
      page: 0,
      size: 10,
    });
    const items = data.items.filter(
      (item) =>
        affiliates.find((affiliate) => affiliate.id === item.id) === undefined
    );
    setAffiliateOptions(items);
    return items.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  useEffect(() => {
    if (
      previousContract == null ||
      form.previous_contract_id === previousContract.id ||
      previousContract.terminates_at == null
    ) {
      return;
    }

    setSelectedUser({
      value: previousContract.client_user_email,
      label: previousContract.client_user_email,
    });
    setClient((c) => ({
      ...c,
      first_name: previousContract.client_user_first_name,
      last_name: previousContract.client_user_last_name,
      email: previousContract.client_user_email,
    }));
    setForm((f) => ({
      ...f,
      date: new Date(previousContract.terminates_at!),
      previous_contract_id: previousContract.id,
      client_id: previousContract.client_id,
      client_user_id: previousContract.client_user_id,
      is_batched: previousContract.is_batched,
    }));
  }, [form, previousContract]);

  const [customClauses, setCustomClauses] = useState<CustomClause[]>([]);
  const [newCustomClause, setNewCustomClause] = useState<CustomClause>({
    title: "",
    text: "",
  });

  // calculated values
  const minerCount = miners.reduce((sum, miner) => sum + miner.quantity!, 0);
  const powerRate =
    manualPowerRate !== null
      ? manualPowerRate
      : calcProgressivePowerRate(minerCount);
  const sumPowerDraw = miners.reduce(
    (sum, miner) => sum + (miner.power_draw.value * miner.quantity!) / 1000,
    0
  );
  const annualCost = powerRate * sumPowerDraw * ANNUAL_FACTOR;
  const monthlyCost = powerRate * sumPowerDraw * MONTHLY_FACTOR;
  const deploymentCost = form.wave_deployment_fee
    ? 0
    : miners.reduce(
        (sum, miner) =>
          sum +
          miner.quantity! *
            (miner.deployment_type_name === "Immersion" ? 100 : 25),
        0
      );
  const depositCost =
    form.term === 1
      ? form.duration_months * monthlyCost
      : form.deposit_months * monthlyCost;
  const prePaymentCost =
    form.term === 1 ? 0 : form.pre_payment_months * monthlyCost;

  const wattFormatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  });
  const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    const powerRateFormatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 4,
    });

    setForm((s) => ({
      ...s,
      power_rate: powerRateFormatter.format(powerRate),
    }));
  }, [powerRate]);

  if (contractCreated) {
    return <Redirect to="/contracts" />;
  }

  return (
    <Panel title={"Contract Builder"}>
      <PanelRow>
        <SubPanel flex={1}>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Email
            </FormLabel>
            <SearchableSelect
              name={"emails"}
              placeholder={"Email"}
              value={selectedUser}
              loadOptions={loadUserOptions}
              onSelect={(item: SearchableItem) => {
                const user = userOptions.find(
                  (user) => String(user.id) === item.value
                );
                if (user != null) {
                  setSelectedUser(item);
                  setForm((f) => ({
                    ...f,
                    is_batched: user.client_is_batched,
                    client_id: user.client_id,
                    client_user_id: user.id,
                  }));
                  setClient({
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: item.label,
                    company: user.client_is_company
                      ? user.client_name
                      : undefined,
                  });
                }
              }}
              validateCreate={(input: string) =>
                validateEmail(input) &&
                userOptions.map((user) => String(user.id) === input).length ===
                  0
              }
              onCreate={(input: string) => {
                setSelectedUser({ value: input, label: input });
                setForm((f) => ({
                  ...f,
                  client_id: undefined,
                  client_user_id: undefined,
                }));
                setClient((c) => ({ ...c, email: input }));
              }}
            />
          </Stack>
          {((client.email != null &&
            client.email.length > 0 &&
            form.client_id == null) ||
            selectedClient != null) && (
            <Stack w="100%">
              <FormLabel textAlign="center" m="auto">
                Existing Client
              </FormLabel>
              <SearchableSelect
                name={"clients"}
                placeholder={"Search existing client"}
                value={selectedClient}
                loadOptions={loadClientOptions}
                onSelect={(item: SearchableItem) => {
                  const client = clientOptions.find(
                    (client) => String(client.id) === item.value
                  );
                  if (client != null) {
                    setSelectedClient(item);
                    setForm((f) => ({
                      ...f,
                      is_batched: client.batched_invoice_day != null,
                      client_id: client.id,
                    }));
                    setClient((c) => ({
                      ...c,
                      company: client.is_company ? client.name : undefined,
                    }));
                  }
                }}
              />
            </Stack>
          )}
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              First Name
            </FormLabel>
            <Input
              placeholder={"First Name"}
              value={client.first_name}
              onChange={(e) =>
                setClient((c) => ({ ...c, first_name: e.target.value }))
              }
              isDisabled={
                client.email.length === 0 || form.client_user_id != null
              }
            />
          </Stack>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Last Name
            </FormLabel>
            <Input
              placeholder={"Last Name"}
              value={client.last_name}
              onChange={(e) =>
                setClient((c) => ({ ...c, last_name: e.target.value }))
              }
              isDisabled={
                client.email.length === 0 || form.client_user_id != null
              }
            />
          </Stack>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Company (Optional)
            </FormLabel>
            <Input
              placeholder={"Company"}
              value={client.company}
              onChange={(e) =>
                setClient((c) => ({ ...c, company: e.target.value }))
              }
              isDisabled={
                client.email.length === 0 ||
                form.client_id != null ||
                form.client_user_id != null
              }
            />
          </Stack>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Subclient (Optional)
            </FormLabel>
            <Input
              placeholder={"Subclient"}
              value={form.subclient}
              onChange={(e) =>
                setForm((c) => ({ ...c, subclient: e.target.value }))
              }
              isDisabled={client.email.length === 0}
            />
          </Stack>
        </SubPanel>
        <SubPanel flex={1}>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Contract Term
              </FormLabel>
              <SwitchButton
                size="xs"
                value={form.term}
                onChange={(e) => {
                  const durationMonths =
                    e === 1 ? 12 : e === 3 ? 24 : form.duration_months;
                  const depositMonths = e === 3 ? 0 : form.deposit_months;
                  const prePaymentMonths =
                    e === 3 ? 0 : form.pre_payment_months;
                  const waveDeploymentFee =
                    e === 3 ? true : form.wave_deployment_fee;
                  if (e === 3) {
                    setManualPowerRate(0.0);
                  }

                  setForm((f) => ({
                    ...f,
                    duration_months: durationMonths,
                    deposit_months: depositMonths,
                    pre_payment_months: prePaymentMonths,
                    wave_deployment_fee: waveDeploymentFee,
                    term: e,
                  }));
                }}
                options={[
                  { label: "Annual", value: 1 },
                  { label: "Monthly", value: 2 },
                  { label: "Employee", value: 3 },
                ]}
                isDisabled={
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading
                }
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Deposit Months
              </FormLabel>
              <SwitchButton
                size="xs"
                value={form.deposit_months}
                onChange={(e) => setForm((f) => ({ ...f, deposit_months: e }))}
                options={[
                  { label: "None", value: 0 },
                  { label: "1 Month", value: 1 },
                  { label: "2 Months", value: 2 },
                  { label: "3 Months", value: 3 },
                ]}
                isDisabled={
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading ||
                  form.term !== 2
                }
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Contract Duration
              </FormLabel>
              <SwitchButton
                size="xs"
                value={form.duration_months}
                onChange={(e) => setForm((f) => ({ ...f, duration_months: e }))}
                options={[
                  { label: "1 Year", value: 12 },
                  { label: "2 Years", value: 24 },
                ]}
                isDisabled={
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading ||
                  form.term !== 2
                }
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Prepayment Months
              </FormLabel>
              <SwitchButton
                size="xs"
                value={form.pre_payment_months}
                onChange={(e) =>
                  setForm((f) => ({ ...f, pre_payment_months: e }))
                }
                options={[
                  { label: "None", value: 0 },
                  { label: "1 Month", value: 1 },
                  { label: "2 Months", value: 2 },
                  { label: "3 Months", value: 3 },
                ]}
                isDisabled={
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading ||
                  form.term !== 2
                }
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Contract Date
              </FormLabel>
              <SingleDatepicker
                name="date-input"
                usePortal={true}
                date={form.date == null ? new Date() : form.date}
                onDateChange={(date) => setForm((f) => ({ ...f, date: date }))}
                maxDate={new Date()}
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                  },
                }}
                disabled={
                  form.term === 3 ||
                  previousContract != null ||
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading
                }
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Power Rate
              </FormLabel>
              <NumberInput
                maxW={"10rem"}
                value={"$" + form.power_rate}
                onChange={(e) => {
                  const value = parseFloat(e);
                  if (!isNaN(value) || e.length === 0) {
                    const parts = e.split(".");
                    if (parts.length === 2 && parts[1].length > 4) {
                      e = parts[0] + "." + parts[1].substr(0, 4);
                    }

                    setManualPowerRate(isNaN(value) ? 0 : value);
                  }
                }}
                isDisabled={
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading ||
                  manualPowerRate === null
                }
              >
                <NumberInputField />
              </NumberInput>
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Wave Deployment Fee
              </FormLabel>
              <Switch
                isChecked={form.wave_deployment_fee}
                onChange={(e) =>
                  setForm((f) => ({
                    ...f,
                    wave_deployment_fee: e.target.checked,
                  }))
                }
                isDisabled={
                  form.term === 3 ||
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading
                }
                size="lg"
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Override Power Rate
              </FormLabel>
              <Switch
                isChecked={manualPowerRate !== null}
                onChange={(e) =>
                  setManualPowerRate(e.target.checked ? powerRate : null)
                }
                isDisabled={
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading
                }
                size="lg"
              />
            </GridItem>
            <GridItem display="flex" flexDirection="column" alignItems="center">
              <FormLabel textAlign="center" m="auto">
                Batch Contract
              </FormLabel>
              <Switch
                isChecked={form.is_batched}
                onChange={(e) =>
                  setForm((f) => ({
                    ...f,
                    is_batched: e.target.checked,
                  }))
                }
                isDisabled={
                  form.term === 3 ||
                  createContractMutation.isLoading ||
                  previewContractMutation.isLoading ||
                  previewQuoteMutation.isLoading
                }
                size="lg"
              />
            </GridItem>
          </Grid>
        </SubPanel>
      </PanelRow>
      <PanelRow>
        <SubPanel title={"Miners"} flex={3}>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th minW="200px">Name</Th>
                <Th>Quantity</Th>
                <Th>Power Draw</Th>
                <Th>Total Power Draw</Th>
                <Th>Monthly Cost</Th>
                <Th>Total Monthly Cost</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {miners.map((miner, idx) => {
                const minerPowerDraw = miner.power_draw.value / 1000;
                const sumMinerPowerDraw = minerPowerDraw * miner.quantity!;
                const minerMonthlyCost =
                  minerPowerDraw * powerRate * MONTHLY_FACTOR;
                const sumMinerMonthlyCost = minerMonthlyCost * miner.quantity!;

                return (
                  <Tr key={`miner-${idx}`}>
                    <Td>
                      {miner.name}
                      {miner.hashrate != null &&
                        ` (${miner.hashrate.formatted})`}
                      {` (${miner.power_draw.formatted})`}
                    </Td>
                    <Td>
                      <NumberInput
                        value={miner.quantity === 0 ? "" : miner.quantity}
                        onChange={(e) => {
                          const quantity = isNaN(parseInt(e)) ? 0 : parseInt(e);
                          setMiners(
                            miners.map((item) => {
                              if (item.id === miner.id) {
                                item.quantity = quantity;
                              }
                              return item;
                            })
                          );
                          setForm((f) => ({
                            ...f,
                            miners: form.miners.map((item) => {
                              if (item.id === miner.id) {
                                item.quantity = quantity;
                              }
                              return item;
                            }),
                          }));
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Td>
                    <Td>{`${wattFormatter.format(
                      minerPowerDraw * 1000
                    )} W`}</Td>
                    <Td>{`${wattFormatter.format(
                      sumMinerPowerDraw * 1000
                    )} W`}</Td>
                    <Td>{`${dollarFormatter.format(
                      minerMonthlyCost
                    )} / mo`}</Td>
                    <Td>{`${dollarFormatter.format(
                      sumMinerMonthlyCost
                    )} / mo`}</Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        type="submit"
                        size="sm"
                        onClick={() => {
                          setMiners(
                            miners.filter((item) => item.id !== miner.id)
                          );
                          setForm((f) => ({
                            ...f,
                            miners: form.miners.filter(
                              (item) => item.id !== miner.id
                            ),
                          }));
                        }}
                      >
                        Remove
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
              <Tr>
                <Td>
                  <Box width="full">
                    <SearchableSelect
                      name={"miners"}
                      placeholder={"Add a miner"}
                      shouldClear={clearMiners}
                      defaultOptions={minerModelOptions
                        .filter(
                          (opt) =>
                            form.miners.find((miner) => miner.id === opt.id) ===
                            undefined
                        )
                        .map((opt) => {
                          let name = opt.name;
                          if (opt.hashrate != null) {
                            name += ` (${opt.hashrate.formatted})`;
                          }
                          name += ` (${opt.power_draw.formatted})`;

                          return { value: String(opt.id), label: name };
                        })}
                      loadOptions={loadMinerModelOptions}
                      onSelect={(item: SearchableItem) => {
                        const miner = minerModelOptions.find(
                          (miner) => String(miner.id) === String(item.value)
                        );
                        if (miner != null) {
                          miner.quantity = 1;
                          setMiners([...miners, miner]);
                          setForm((f) => ({
                            ...f,
                            miners: [
                              ...form.miners,
                              { id: miner.id, quantity: miner.quantity! },
                            ],
                          }));
                          setClearMiners(true);
                        }
                        setTimeout(() => setClearMiners(false), 250);
                      }}
                    />
                  </Box>
                </Td>
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
              </Tr>
            </Tbody>
          </Table>
        </SubPanel>
      </PanelRow>
      <PanelRow>
        <SubPanel title={"Affiliates"} flex={3}>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th minW="200px">Name</Th>
                <Th>Power Rate</Th>
                <Th>Power Rate Percentage</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {affiliates.map((affiliate, idx) => (
                <Tr key={`affiliate-${idx}`}>
                  <Td>{affiliate.name}</Td>
                  <Td>
                    {affiliate.commission_power_rate != null &&
                      "$" + affiliate.commission_power_rate + " / kWh"}
                  </Td>
                  <Td>
                    {affiliate.commission_percentage != null &&
                      affiliate.commission_percentage + "%"}
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      type="submit"
                      size="sm"
                      onClick={() => {
                        setAffiliates(
                          affiliates.filter((item) => item.id !== affiliate.id)
                        );
                        setForm((f) => ({
                          ...f,
                          affiliates: form.affiliates.filter(
                            (id) => id !== affiliate.id
                          ),
                        }));
                      }}
                    >
                      Remove
                    </Button>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td minW="100px">
                  <SearchableSelect
                    name={"affiliates"}
                    placeholder={"Add an affiliate"}
                    shouldClear={clearAffiliates}
                    defaultOptions={affiliateOptions
                      .filter(
                        (opt) =>
                          affiliates.find(
                            (affiliate) => affiliate.id === opt.id
                          ) === undefined
                      )
                      .map((opt) => {
                        return { value: String(opt.id), label: opt.name };
                      })}
                    loadOptions={loadAffiliateOptions}
                    onSelect={(item: SearchableItem) => {
                      const affiliate = affiliateOptions.find(
                        (affiliate) =>
                          String(affiliate.id) === String(item.value)
                      );
                      if (affiliate != null) {
                        setAffiliates([...affiliates, affiliate]);
                        setForm((f) => ({
                          ...f,
                          affiliates: [...form.affiliates, affiliate.id],
                        }));
                        setClearAffiliates(true);
                      }
                      setTimeout(() => setClearAffiliates(false), 250);
                    }}
                  />
                </Td>
                <Td />
                <Td />
                <Td />
              </Tr>
            </Tbody>
          </Table>
        </SubPanel>
      </PanelRow>
      <PanelRow>
        <SubPanel title={"Custom Clauses"} flex={3}>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Text</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {customClauses.map((customClause, idx) => (
                <Tr key={`custom-clause-${idx}`}>
                  <Td>{customClause.title}</Td>
                  <Td>{customClause.text}</Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      type="submit"
                      size="sm"
                      onClick={() => {
                        const newCustomClauses = customClauses.filter(
                          (item) => item.title !== customClause.title
                        );
                        setCustomClauses(newCustomClauses);
                        setForm((f) => ({
                          ...f,
                          extra_titles: newCustomClauses.map(
                            (customClause) => customClause.title
                          ),
                          extra_clauses: newCustomClauses.map(
                            (customClause) => customClause.text
                          ),
                        }));
                      }}
                    >
                      Remove
                    </Button>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td>
                  <Input
                    placeholder={"Title"}
                    value={newCustomClause.title}
                    onChange={(e) =>
                      setNewCustomClause((c) => ({
                        ...c,
                        title: e.target.value,
                      }))
                    }
                  />
                </Td>
                <Td>
                  <Textarea
                    justifyContent="center"
                    placeholder={"Text"}
                    value={newCustomClause.text}
                    onChange={(e) =>
                      setNewCustomClause((c) => ({
                        ...c,
                        text: e.target.value,
                      }))
                    }
                  />
                </Td>
                <Td>
                  <Button
                    colorScheme="green"
                    type="submit"
                    size="sm"
                    onClick={() => {
                      const newCustomClauses = customClauses.concat([
                        newCustomClause,
                      ]);
                      setNewCustomClause({ title: "", text: "" });
                      setCustomClauses(newCustomClauses);
                      setForm((f) => ({
                        ...f,
                        extra_titles: newCustomClauses.map(
                          (customClause: CustomClause) => customClause.title
                        ),
                        extra_clauses: newCustomClauses.map(
                          (customClause: CustomClause) => customClause.text
                        ),
                      }));
                    }}
                    disabled={
                      newCustomClause.title === "" ||
                      newCustomClause.text === ""
                    }
                  >
                    Add
                  </Button>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </SubPanel>
      </PanelRow>
      <PanelRow>
        <SubPanel flex={1}>
          <Stat>
            <StatLabel textAlign="center">Monthly Cost</StatLabel>
            <StatNumber textAlign="center">
              <Dollars value={dollarFormatter.format(monthlyCost)} />
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel textAlign="center">Annual Cost</StatLabel>
            <StatNumber textAlign="center">
              <Dollars value={dollarFormatter.format(annualCost)} />
            </StatNumber>
          </Stat>
        </SubPanel>
        <SubPanel flex={1}>
          <Stat>
            <StatLabel textAlign="center">Deployment Cost</StatLabel>
            <StatNumber textAlign="center">
              <Dollars value={dollarFormatter.format(deploymentCost)} />
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel textAlign="center">Deposit Cost</StatLabel>
            <StatNumber textAlign="center">
              <Dollars value={dollarFormatter.format(depositCost)} />
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel textAlign="center">Pre-Payment Cost</StatLabel>
            <StatNumber textAlign="center">
              <Dollars value={dollarFormatter.format(prePaymentCost)} />
            </StatNumber>
          </Stat>
        </SubPanel>
        <SubPanel flex={1}>
          <Stat>
            <StatLabel textAlign="center">Total Power Draw (W)</StatLabel>
            <StatNumber textAlign="center">
              <Dollars
                value={wattFormatter.format(sumPowerDraw * 1000) + " W"}
              />
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel textAlign="center">Total Power Draw (kWh)</StatLabel>
            <StatNumber textAlign="center">
              <Dollars value={wattFormatter.format(sumPowerDraw) + " kWh"} />
            </StatNumber>
          </Stat>
        </SubPanel>
      </PanelRow>
      <Center>
        <Stack>
          <HStack spacing={8} flex={1} justify={"space-evenly"}>
            <Button
              colorScheme="teal"
              isLoading={
                createContractMutation.isLoading ||
                previewContractMutation.isLoading ||
                previewQuoteMutation.isLoading
              }
              isDisabled={
                createContractMutation.isLoading ||
                previewContractMutation.isLoading ||
                previewQuoteMutation.isLoading ||
                formIncomplete
              }
              onClick={() =>
                previewContractMutation.mutate({ ...form, client: client })
              }
            >
              Preview Contract
            </Button>
            <Button
              colorScheme="teal"
              isLoading={
                createContractMutation.isLoading ||
                previewContractMutation.isLoading ||
                previewQuoteMutation.isLoading
              }
              isDisabled={
                createContractMutation.isLoading ||
                previewQuoteMutation.isLoading ||
                formIncomplete
              }
              onClick={() => handleCreate()}
            >
              Create Contract
            </Button>
            <Button
              colorScheme="teal"
              isLoading={
                createContractMutation.isLoading ||
                previewContractMutation.isLoading ||
                previewQuoteMutation.isLoading
              }
              isDisabled={
                createContractMutation.isLoading ||
                previewContractMutation.isLoading ||
                previewQuoteMutation.isLoading ||
                formIncomplete
              }
              onClick={() =>
                previewQuoteMutation.mutate({ ...form, client: client })
              }
            >
              Create Quote
            </Button>
          </HStack>
          <MutationFeedback mutation={createContractMutation} />
          <MutationFeedback mutation={previewContractMutation} />
          <MutationFeedback mutation={previewQuoteMutation} />
        </Stack>
      </Center>
    </Panel>
  );
};
