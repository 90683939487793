import { useState } from "react";
import { useMutation } from "react-query";
import { Link as ReactRouterLink, Redirect } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
} from "@chakra-ui/react";
import { setTokens } from "src/lib/tokenStorage";
import { authLogin } from "src/api/mutations/authLogin";
import { User } from "src/types";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SmolStackLayout } from "src/components/SmolStackLayout";

type LoginForm = {
  email: string;
  password: string;
  code: string;
};

type LoginPageProps = {
  user: User | undefined;
  onSuccessfulAuth: () => any;
};

export const LoginPage = ({ user, onSuccessfulAuth }: LoginPageProps) => {
  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: "",
    code: "",
  });

  const mutation = useMutation(authLogin, {
    onSuccess: (axiosData) => {
      const tokens = axiosData.data.data.tokens;
      setTokens(tokens.auth, tokens.refresh);
      window.$chatwoot.reset();
      onSuccessfulAuth();
    },
  });

  if (user !== undefined) {
    return <Redirect to="/" />;
  }

  return (
    <SmolStackLayout>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate(form);
        }}
      >
        <Stack spacing={4}>
          <FormControl id="email">
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              value={form.email}
              onChange={(e) => {
                const email = e.target.value;
                setForm((f) => ({
                  ...f,
                  email,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={form.password}
              onChange={(e) => {
                const password = e.target.value;
                setForm((f) => ({
                  ...f,
                  password,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl id="2fa_code">
            <FormLabel>2FA Code</FormLabel>
            <Input
              value={form.code}
              onChange={(e) => {
                const code = e.target.value;
                setForm((f) => ({
                  ...f,
                  code,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <Stack
            direction={{ base: "column", sm: "row" }}
            align={"start"}
            justify={"space-between"}
          >
            <Link as={ReactRouterLink} to="/forgot">
              Forgot password?
            </Link>
            <Link as={ReactRouterLink} to="/request-2fa-reset">
              Request 2FA Reset
            </Link>
          </Stack>
          <MutationFeedback mutation={mutation} />
          <Stack spacing={10}>
            <Button
              type="submit"
              isLoading={mutation.isLoading}
              disabled={mutation.isSuccess || mutation.isLoading}
              colorScheme="teal"
            >
              Sign in
            </Button>
          </Stack>
        </Stack>
      </form>
    </SmolStackLayout>
  );
};
