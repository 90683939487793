import { useState } from "react";
import { useMutation } from "react-query";
import { Stack, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { adminInviteAdmin } from "src/api/mutations/adminInviteAdmin";
import { MutationFeedback } from "src/components/MutationFeedback";

type Form = {
  first_name: string;
  last_name: string;
  email: string;
};

export const InviteAdminPanel = () => {
  const [form, setForm] = useState<Form>({
    first_name: "",
    last_name: "",
    email: "",
  });

  const mutation = useMutation(adminInviteAdmin);

  const formIsDisabled = Object.values(form).some((v) => !v);

  return (
    <Panel title="Invite Admin">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate(form);
        }}
      >
        <Stack spacing={4} maxW="md" mb={4}>
          <FormControl id="first_name">
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              value={form.first_name}
              onChange={(e) =>
                setForm((f) => ({ ...f, first_name: e.target.value }))
              }
              disabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl id="last_name">
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              value={form.last_name}
              onChange={(e) =>
                setForm((f) => ({ ...f, last_name: e.target.value }))
              }
              disabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={form.email}
              onChange={(e) =>
                setForm((f) => ({ ...f, email: e.target.value }))
              }
              disabled={mutation.isLoading}
            />
          </FormControl>
          <MutationFeedback mutation={mutation} successMsg="Admin Created" />
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={mutation.isLoading}
            disabled={
              mutation.isSuccess || mutation.isLoading || formIsDisabled
            }
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Panel>
  );
};
