import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.css";

interface Props {
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (date: Date) => void;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
}

export const Datepicker = ({
  selectedDate,
  onChange,
  isDisabled = false,
  isClearable = false,
  showPopperArrow = false,
}: Props) => {
  return (
    <DatePicker
      portalId="root-portal"
      dateFormat="yyyy-MM-dd hh:mm"
      dropdownMode="select"
      selected={selectedDate}
      onChange={(date: Date) => onChange(date)}
      maxDate={new Date()}
      disabled={isDisabled}
      showTimeSelect
      withPortal
    />
  );
};
