import { useQuery } from "react-query";
import { APIResponse, FormattedNumber } from "src/types";
import { apiClient } from "..";

export type MinerGroupPreviewResponse = {
  id: string;
  name: string;
  running_count: number;
  total_count: number;
  gpu_count: number;
  cpu_count: number;
  asic_count: number;
  exchange_rate: FormattedNumber;
  hashrate: FormattedNumber;
  daily_revenue: FormattedNumber;
  monthly_revenue: FormattedNumber;
  power_usage: FormattedNumber;
  min_temperature: FormattedNumber;
  avg_temperature: FormattedNumber;
  max_temperature: FormattedNumber;
  coins: null;
  algorithms: null;
};

export type FarmResponse = MinerGroupPreviewResponse[];

export const fetchDataFarm = (type: string) =>
  apiClient
    .get<APIResponse<FarmResponse>>(`/data/farm?type=${type}`)
    .then((res) => res.data.data);

export const useDataFarmQuery = (type: string) => {
  return useQuery(["/data/farm", type], () => fetchDataFarm(type), {
    keepPreviousData: true,
  });
};
