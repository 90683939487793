import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type MonitoringLogResponse = {
  level: string;
  msg: string;
  timestamp: number;
};

export const fetchMonitoringLogs = (networkId: number, count: number) =>
  apiClient
    .get<APIResponse<MonitoringLogResponse[]>>(
      `/monitoring/logs?network_id=${networkId}&count=${count}`
    )
    .then((res) => res.data.data);

export const useMonitoringLogsQuery = (
  networkId: number,
  count: number,
  enabled: boolean
) => {
  return useQuery(
    ["/monitoring/logs", networkId, count],
    () => fetchMonitoringLogs(networkId, count),
    {
      enabled: enabled,
    }
  );
};
