import { Link as ReactRouterLink } from "react-router-dom";
import { useState } from "react";
import { Panel } from "src/components/Panel";
import {
  Badge,
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Select,
  HStack,
  Button,
  LinkOverlay,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useExpiringContractsQuery } from "src/api/queries/fetchExpiringContracts";
import { SimplePaginator } from "src/components/SimplePaginator";
import { RadioCardGroup } from "src/components/RadioCard";
import { SearchIcon } from "@chakra-ui/icons";
import { useSetContractRenewalStatusMutation } from "src/api/mutations/contractCreate";
import { MutationFeedback } from "src/components/MutationFeedback";

type RenewalPeriod = "expiring" | "expired";

export const RenewalsPanel = () => {
  const firstDate = new Date(2000, 3, 20);
  const currentDate = new Date();
  const lastDate = new Date(2100, 3, 20);

  const [state, setState] = useState<{
    query: null | string;
    period: RenewalPeriod;
    status: string;
    page: number;
    size: number;
    start_date: Date;
    end_date: Date;
    show_miners: boolean;
  }>({
    query: null,
    period: "expiring",
    status: "all",
    page: 0,
    size: 10,
    start_date: currentDate,
    end_date: lastDate,
    show_miners: false,
  });

  const query = useExpiringContractsQuery({
    start_date: state.start_date,
    end_date: state.end_date,
    sort_order: state.period === "expiring" ? "asc" : "desc",
    query: state.query,
    status: state.status,
    page: state.page,
    size: state.size,
  });

  const toggleRenewalDeclinedMutation = useSetContractRenewalStatusMutation();

  return (
    <Panel title={"Renewals"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        page: 0,
                        query:
                          e.target.value != null && e.target.value.length > 0
                            ? e.target.value
                            : null,
                      }))
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                      page: 0,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
              <FormControl maxW="20rem">
                <FormLabel textAlign="center">Period</FormLabel>
                <RadioCardGroup
                  keys={["Expired", "Active"]}
                  value={state.period === "expired" ? "Expired" : "Active"}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      period: e === "Expired" ? "expired" : "expiring",
                      start_date: e === "Expired" ? firstDate : currentDate,
                      end_date: e === "Expired" ? currentDate : lastDate,
                      page: 0,
                    }))
                  }
                />
              </FormControl>
              <FormControl maxW="15rem">
                <FormLabel>Status</FormLabel>
                <Select
                  value={state.status}
                  onChange={(e) => {
                    setState((s) => ({
                      ...s,
                      status: e.target.value,
                      page: 0,
                    }));
                  }}
                >
                  <option value={"all"}>All</option>
                  <option value={"declined"}>Declined</option>
                  <option value={"unsigned"}>Not Signed</option>
                  <option value={"signed"}>Signed</option>
                  <option value={"activated"}>Activated</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Show Miners</FormLabel>
                <Switch
                  isChecked={state.show_miners}
                  onChange={(e) =>
                    setState((f) => ({ ...f, show_miners: e.target.checked }))
                  }
                />
              </FormControl>
            </HStack>{" "}
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Client</Th>
                <Th>Email</Th>
                <Th>Activated At</Th>
                <Th>Expires At</Th>
                <Th>Renewal Status</Th>
                <Th>Renewal ID</Th>
                {state.show_miners && <Th>Miners</Th>}
                {state.show_miners && <Th>Renewal Miners</Th>}
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((contract) => {
                const isOverdue =
                  state.period === "expired" &&
                  !contract.renewal_declined &&
                  !contract.renewal_activated &&
                  !contract.renewal_signed;

                return (
                  <Tr key={`contract-${contract.id}`}>
                    <Td>{contract.name}</Td>
                    <Td>{contract.client_name}</Td>
                    <Td>{contract.client_email}</Td>
                    <Td>
                      {new Date(contract.activated_at).toLocaleDateString()}
                    </Td>
                    <Td>
                      {new Date(contract.terminates_at).toLocaleDateString()}
                    </Td>
                    <Td>
                      <HStack>
                        <Badge
                          colorScheme={
                            contract.renewal_declined
                              ? "blue"
                              : contract.renewal_name == null
                              ? "yellow"
                              : contract.renewal_activated
                              ? "green"
                              : "teal"
                          }
                          variant="solid"
                        >
                          {contract.renewal_declined
                            ? "Declined"
                            : contract.renewal_name == null
                            ? "Needs Renewal"
                            : contract.renewal_activated
                            ? "Activated"
                            : contract.renewal_signed
                            ? "Signed"
                            : contract.renewal_sent
                            ? "Sent"
                            : "Created"}
                        </Badge>
                        {isOverdue && (
                          <Badge colorScheme={"red"} variant="solid">
                            Overdue
                          </Badge>
                        )}
                      </HStack>
                    </Td>
                    <Td>{contract.renewal_name}</Td>
                    {state.show_miners && (
                      <>
                        <Td>
                          <Stack>
                            {contract.miner_groups.map((group) => (
                              <div key={`miner-${group.id}`}>
                                {`${group.name}: ${group.activated}/${group.total}`}
                              </div>
                            ))}
                          </Stack>
                        </Td>
                        <Td>
                          <Stack>
                            {contract.renewal_miner_groups?.map((group) => (
                              <div key={`miner-${group.id}`}>
                                {`${group.name}: ${group.activated}/${group.total}`}
                              </div>
                            ))}
                          </Stack>
                        </Td>
                      </>
                    )}
                    <Td>
                      {(contract.status === "Partially Activated" ||
                        contract.status === "Fully Activated" ||
                        contract.status === "Completed") &&
                        contract.renewal_name == null &&
                        !contract.renewal_declined && (
                          <HStack>
                            <Button colorScheme="teal">
                              <LinkOverlay
                                as={ReactRouterLink}
                                to={`/contract-builder/${contract.id}`}
                                fontWeight="bold"
                              >
                                Renew
                              </LinkOverlay>
                            </Button>
                            <Button
                              colorScheme="red"
                              disabled={toggleRenewalDeclinedMutation.isLoading}
                              isLoading={
                                toggleRenewalDeclinedMutation.isLoading
                              }
                              onClick={() => {
                                toggleRenewalDeclinedMutation.mutate({
                                  contract_id: contract.id,
                                  renewal_declined: !contract.renewal_declined,
                                });
                              }}
                            >
                              Decline
                            </Button>
                          </HStack>
                        )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
          <MutationFeedback mutation={toggleRenewalDeclinedMutation} />
        </>
      )}
    </Panel>
  );
};
