import { APIResponse, UserType } from "src/types";
import { apiClient } from "..";

type EmulateUserBody = {
  refresh_token: string;
  user_id: number;
};

export const authEmulateUser = (body: EmulateUserBody) => {
  return apiClient.post<
    APIResponse<{
      tokens: {
        auth: string;
        auth_exp: number;
        refresh: string;
        refresh_exp: number;
      };
      type: UserType;
    }>
  >("/emulate", body);
};
