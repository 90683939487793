import { useState, useEffect } from "react";
import { Panel } from "src/components/Panel";
import {
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Table,
  Text,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  HStack,
  VStack,
  IconButton,
  Button,
  NumberInput,
  NumberInputField,
  Badge,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useContractMinerModelsQuery,
  MinerModel,
} from "src/api/queries/fetchContractMinerModels";
import {
  CreateMinerModel,
  UpdateMinerModel,
  useCreateContractMinerModelMutation,
  useUpdateContractMinerModelMutation,
} from "src/api/mutations/contractCreateMinerModel";
import { MutationFeedback } from "src/components/MutationFeedback";
import { RadioCardGroup } from "src/components/RadioCard";
import { SimplePaginator } from "src/components/SimplePaginator";
import { AddIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";

const CreateMinerModelButton = () => {
  const [state, setState] = useState<CreateMinerModel>({
    name: "",
    deployment_type: 1,
    employee: false,
    power_draw: 0,
    hashrate: null,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateContractMinerModelMutation();

  const handleCreate = () => {
    mutation.mutate(state, { onSuccess: onClose });
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({
        name: "",
        deployment_type: 1,
        employee: false,
        power_draw: 0,
        hashrate: null,
      });
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Miner Model"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Miner Model</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Power Draw</FormLabel>
                  <NumberInput
                    value={state.power_draw}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        power_draw: isNaN(parseInt(e)) ? 0 : parseInt(e),
                      }))
                    }
                    isDisabled={mutation.isLoading}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Hashrate</FormLabel>
                  <Input
                    value={state.hashrate != null ? state.hashrate : undefined}
                    onChange={(e) =>
                      setState((s) => ({ ...s, hashrate: e.target.value }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Immersion</FormLabel>
                  <Switch
                    isChecked={state.deployment_type === 2}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        deployment_type: e.target.checked ? 2 : 1,
                      }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Employee</FormLabel>
                  <Switch
                    isChecked={state.employee}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        employee: e.target.checked,
                      }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
              </HStack>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading}
              onClick={handleCreate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const UpdateMinerModelButton = ({ model }: { model: MinerModel }) => {
  const [state, setState] = useState<UpdateMinerModel>({
    id: model.id,
    name: model.name,
    deployment_type: model.deployment_type,
    power_draw: model.power_draw.value,
    employee: model.employee,
    hashrate: model.hashrate != null ? model.hashrate.formatted : null,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateContractMinerModelMutation();

  const handleCreate = () => {
    mutation.mutate(state, { onSuccess: onClose });
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({
        id: model.id,
        name: model.name,
        deployment_type: model.deployment_type,
        power_draw: model.power_draw.value,
        employee: model.employee,
        hashrate: model.hashrate != null ? model.hashrate.formatted : null,
      });
    }
  }, [isOpen, mutation, model]);

  useEffect(() => {
    if (!isOpen && (model.id !== state.id || model.name !== state.name)) {
      setState({
        id: model.id,
        name: model.name,
        deployment_type: model.deployment_type,
        power_draw: model.power_draw.value,
        employee: model.employee,
        hashrate: model.hashrate != null ? model.hashrate.formatted : null,
      });
    }
  }, [isOpen, model, state]);

  return (
    <Stack>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Update Miner Model"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update {model.name}`</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Power Draw</FormLabel>
                  <NumberInput
                    value={state.power_draw}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        power_draw: isNaN(parseInt(e)) ? 0 : parseInt(e),
                      }))
                    }
                    isDisabled={mutation.isLoading}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Hashrate</FormLabel>
                  <Input
                    value={state.hashrate != null ? state.hashrate : undefined}
                    onChange={(e) =>
                      setState((s) => ({ ...s, hashrate: e.target.value }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Immersion</FormLabel>
                  <Switch
                    isChecked={state.deployment_type === 2}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        deployment_type: e.target.checked ? 2 : 1,
                      }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Employee</FormLabel>
                  <Switch
                    isChecked={state.employee}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        employee: e.target.checked,
                      }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
              </HStack>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading}
              onClick={handleCreate}
              ml={3}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export const MinersPanel = () => {
  const [state, setState] = useState<{
    page: number;
    query: null | string;
    employee?: boolean;
  }>({ page: 0, query: "" });

  const query = useContractMinerModelsQuery({
    query: state.query,
    page: state.page,
    size: 10,
    employee: state.employee,
  });

  return (
    <Panel title={"Miner Models"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <FormControl maxW="15rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      page: 0,
                      query:
                        e.target.value != null && e.target.value.length > 0
                          ? e.target.value
                          : null,
                    }))
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl maxW="15rem">
              <FormLabel textAlign="center">Type</FormLabel>
              <RadioCardGroup
                keys={["All", "Client", "Employee"]}
                value={
                  state.employee == null
                    ? "All"
                    : state.employee
                    ? "Employee"
                    : "Client"
                }
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    employee:
                      e === "All" ? undefined : e === "Employee" ? true : false,
                  }))
                }
              />
            </FormControl>
            <CreateMinerModelButton />
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>Power Draw</Th>
                <Th>Hashrate</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((miner) => (
                <Tr key={`miner-${miner.id}`}>
                  <Td>
                    {miner.name}
                    {miner.hashrate != null && ` (${miner.hashrate.formatted})`}
                    {` (${miner.power_draw.formatted})`}
                  </Td>
                  <Td>
                    <VStack>
                      <Text>{miner.deployment_type_name}</Text>
                      {miner.employee && (
                        <Badge colorScheme={"yellow"} variant="solid">
                          {"Employee"}
                        </Badge>
                      )}
                    </VStack>
                  </Td>
                  <Td>{miner.power_draw.formatted}</Td>
                  <Td>{miner.hashrate?.formatted}</Td>
                  <Td>
                    <UpdateMinerModelButton model={miner} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
