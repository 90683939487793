import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Switch,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { adminInviteUser } from "src/api/mutations/adminInviteUser";
import { MutationFeedback } from "src/components/MutationFeedback";
import { useAdminClientsQuery } from "src/api/queries/fetchAdminClients";

type Form = {
  first_name: string;
  last_name: string;
  email: string;
  client_id: number;
  is_billing_contact: boolean;
};

export const InviteUserPanel = () => {
  const [form, setForm] = useState<Form>({
    first_name: "",
    last_name: "",
    email: "",
    client_id: -1,
    is_billing_contact: true,
  });

  const qc = useQueryClient();
  const mutation = useMutation(adminInviteUser, {
    onSuccess: () => {
      qc.invalidateQueries("/admin/users");
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        client_id: -1,
        is_billing_contact: true,
      });
    },
  });

  const clientsQuery = useAdminClientsQuery();
  const formIsDisabled = !form.email || form.client_id < 0;

  return (
    <Panel title="Invite User">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate(form);
        }}
      >
        <Stack spacing={4} mb={4} direction={"column"} alignItems="center">
          <Stack spacing={4} mb={4} direction={"column"}>
            <Stack direction={"row"}>
              <FormControl id="first_name">
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  value={form.first_name}
                  onChange={(e) =>
                    setForm((f) => ({ ...f, first_name: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="last_name">
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  value={form.last_name}
                  onChange={(e) =>
                    setForm((f) => ({ ...f, last_name: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} spacing={4} maxW="2xl" mb={4}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={form.email}
                  onChange={(e) =>
                    setForm((f) => ({ ...f, email: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="group">
                <FormLabel>Client</FormLabel>
                <Select
                  value={form.client_id}
                  onChange={(e) =>
                    setForm((f) => ({
                      ...f,
                      client_id: parseInt(e.target.value),
                    }))
                  }
                >
                  <option disabled value={-1}>
                    Select a client
                  </option>
                  {clientsQuery.data?.items.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="billing">
                <FormLabel>Is a Billing Contact</FormLabel>
                <Switch
                  isChecked={form.is_billing_contact}
                  onChange={(e) =>
                    setForm((f) => ({
                      ...f,
                      is_billing_contact: e.target.checked,
                    }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
            </Stack>
          </Stack>
          <MutationFeedback mutation={mutation} successMsg="User Invited" />
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={mutation.isLoading}
            disabled={mutation.isLoading || formIsDisabled}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Panel>
  );
};
