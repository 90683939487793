import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { ConfigPanel } from "./panels/ConfigPanel";
import { EyedrosPanel } from "./panels/EyedrosPanel";
import { WatchmansPanel } from "./panels/WatchmansPanel";
import { PDUsPanel } from "./panels/PDUsPanel";
import { SummaryPanel } from "./panels/SummaryPanel";
import { TicketsPanel } from "./panels/TicketsPanel";

export const MonitoringPage = () => {
  return (
    <Page title="Monitoring">
      <PanelRow>
        <SummaryPanel />
      </PanelRow>
      <PanelRow>
        <ConfigPanel />
      </PanelRow>
      <PanelRow>
        <EyedrosPanel />
      </PanelRow>
      <PanelRow>
        <WatchmansPanel />
      </PanelRow>
      <PanelRow>
        <PDUsPanel />
      </PanelRow>
      <PanelRow>
        <TicketsPanel />
      </PanelRow>
    </Page>
  );
};
