import { Link as ReactRouterLink } from "react-router-dom";
import { useState } from "react";
import {
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Badge,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { useDataMinersQuery } from "src/api/queries/fetchDataMiners";

export const UnboundMinersPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: null | string;
    clientId: null | number;
  }>({ page: 0, size: 25, query: null, clientId: null });

  const query = useDataMinersQuery({
    unbound: true,
    client_id: state.clientId,
    query: state.query,
    page: state.page,
    size: state.size,
  });

  return (
    <Panel title="Unbound Miners" query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <FormControl maxW="30rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      page: 0,
                      query:
                        e.target.value != null && e.target.value.length > 0
                          ? e.target.value
                          : null,
                    }))
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
          </HStack>
          <Table size="sm" mb={4}>
            <Thead>
              <Tr>
                <Th minW="200px">ID</Th>
                <Th>Name</Th>
                <Th>Client Name</Th>
                <Th>IP Address</Th>
                <Th>Enabled</Th>
                <Th>Online</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((miner) => (
                <Tr key={miner.id}>
                  <Td>
                    <Link
                      as={ReactRouterLink}
                      to={`/miners/${miner.id}`}
                      fontWeight="bold"
                    >
                      {miner.id}
                    </Link>
                  </Td>
                  <Td>{miner.name}</Td>
                  <Td>{miner.client_name}</Td>
                  <Td>{miner.ip_address}</Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={miner.enabled ? "green" : "red"}
                    >
                      {miner.enabled ? "true" : "false"}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={miner.online ? "green" : "red"}
                    >
                      {miner.online ? "true" : "false"}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
