import { ReactNode, ChangeEventHandler } from "react";
import { UseQueryResult } from "react-query";
import { InfoOutlineIcon, SearchIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { SkeletonText } from "@chakra-ui/skeleton";
import {
  Alert,
  AlertIcon,
  HStack,
  Tooltip,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

interface PanelProps<T> {
  title?: string;
  info?: string;
  flex?: number;
  overflow?: string;
  minW?: string;
  query?: UseQueryResult<T>;
  children?: ReactNode | ((data: T) => ReactNode);
  searchState?: string;
  searchCallback?: ChangeEventHandler<HTMLInputElement>;
}

export const Panel = <T extends unknown>({
  title,
  info,
  flex = 1,
  overflow = undefined,
  minW = undefined,
  children,
  query,
  searchState,
  searchCallback,
}: PanelProps<T>) => {
  let contents =
    typeof children !== "function" || query === undefined ? (
      children
    ) : query.isLoading ? (
      <SkeletonText noOfLines={5} />
    ) : query.isError ? (
      <Alert status="warning">
        <AlertIcon />
        <Flex flexDir="column">
          <Box>There was an issue.</Box>
        </Flex>
      </Alert>
    ) : query.data ? (
      children(query.data)
    ) : null;

  return (
    <Box
      flex={flex}
      bg="white"
      minW={minW}
      p="3"
      borderRadius="md"
      boxShadow="md"
      overflowX={overflow === undefined ? "hidden" : undefined}
      overflow={overflow}
    >
      {title && (
        <Heading pt={1} px={1} textTransform="capitalize" size="xs" mb={4}>
          <HStack spacing={1}>
            <p>{title}</p>
            {info && (
              <Tooltip label={info}>
                <InfoOutlineIcon />
              </Tooltip>
            )}
          </HStack>
        </Heading>
      )}
      {searchCallback && (
        <FormControl mb={4} maxW="30rem">
          <InputGroup>
            <InputLeftElement color="gray.300" children={<SearchIcon />} />
            <Input value={searchState} onChange={searchCallback} />
          </InputGroup>
        </FormControl>
      )}
      <Box p={1} overflowX="auto" w="full">
        {contents}
      </Box>
    </Box>
  );
};
