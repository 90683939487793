import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { useDataDashboardQuery } from "src/api/queries/fetchDataDashboard";
import { Panel } from "src/components/Panel";

export const CoinsPanel = () => {
  const query = useDataDashboardQuery();

  return (
    <Panel title="Coins" flex={2} query={query}>
      {(data) => (
        <Table size="sm" variant="striped">
          <Tbody>
            {data.coins?.map((coin) => (
              <Tr key={coin.name}>
                <Td>{coin.name}</Td>
                <Td isNumeric>{coin.count} Active</Td>
                <Td isNumeric>{coin.hashrate.formatted}</Td>
                <Td isNumeric>{coin.revenue.formatted}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Panel>
  );
};
