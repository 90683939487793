import { Redirect } from "react-router-dom";
import { useMutation } from "react-query";
import { ReactNode, useState, useEffect } from "react";
import { Panel } from "src/components/Panel";
import { PanelRow } from "src/components/PanelRow";
import {
  SearchableSelect,
  SearchableItem,
} from "src/components/SearchableSelect";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  FormLabel,
  NumberInput,
  NumberInputField,
  Input,
  Center,
  HStack,
} from "@chakra-ui/react";
import {
  Client,
  useCreateConsultingAgreementMutation,
  previewConsultingAgreement,
} from "src/api/mutations/consultingAgreementCreate";
import { fetchAdminUsers, AdminUser } from "src/api/queries/fetchAdminUsers";
import { validateEmail } from "src/lib/validateEmail";
import { downloadPDF } from "src/lib/downloadFile";
import {
  fetchAdminClients,
  Client as AdminClient,
} from "src/api/queries/fetchAdminClients";
import { MutationFeedback } from "src/components/MutationFeedback";

type SubPanelProps = {
  title?: string;
  children?: ReactNode;
  flex?: number;
};

const SubPanel = ({ title, children, flex = 1 }: SubPanelProps) => {
  return (
    <Box
      p={3}
      flex={flex}
      borderRadius="md"
      borderWidth={1}
      borderColor="blackAlpha.200"
    >
      {title != null && (
        <Heading pt={1} px={1} textTransform="capitalize" size="xs" mb={4}>
          {title}
        </Heading>
      )}
      <Flex flexWrap="wrap" gridGap={4} flexDir="column" alignItems="center">
        {children}
      </Flex>
    </Box>
  );
};

export const ConsultingBuilderPanel = () => {
  const [agreementCreated, setAgreementCreated] = useState<boolean>(false);
  const createAgreementMutation = useCreateConsultingAgreementMutation();
  const previewAgreementMutation = useMutation(previewConsultingAgreement, {
    onSuccess: (response) =>
      downloadPDF("consulting_agreement.pdf", response.data),
  });

  const [clientId, setClientId] = useState<number | undefined>(undefined);
  const [clientUserId, setClientUserId] = useState<number | undefined>(
    undefined
  );
  const [cost, setCost] = useState<number>(100);
  const [client, setClient] = useState<Client>({
    first_name: "",
    last_name: "",
    email: "",
    company: undefined,
  });

  useEffect(() => {
    if (createAgreementMutation.isSuccess) {
      setAgreementCreated(true);
    }
  }, [createAgreementMutation.isSuccess]);

  // client user options
  const [selectedUser, setSelectedUser] = useState<SearchableItem | undefined>(
    undefined
  );
  const [userOptions, setUserOptions] = useState<AdminUser[]>([]);
  const loadUserOptions = async (query: string) => {
    const data = await fetchAdminUsers({
      type: "client",
      query: query.length > 0 ? query : null,
      page: 0,
      size: 10,
    });
    setUserOptions(data.items);
    return data.items.map((item: any) => {
      return {
        value: String(item.id),
        label: item.first_name + " " + item.last_name + " - " + item.email,
      };
    });
  };

  const [selectedClient, setSelectedClient] = useState<
    SearchableItem | undefined
  >(undefined);
  const [clientOptions, setClientOptions] = useState<AdminClient[]>([]);
  const loadClientOptions = async (query: string) => {
    const data = await fetchAdminClients({
      query: query.length > 0 ? query : null,
      page: 0,
      size: 10,
    });
    setClientOptions(data.items);
    return data.items.map((item: any) => {
      return { value: String(item.id), label: item.name };
    });
  };

  const formIncomplete = false;
  if (agreementCreated) {
    return <Redirect to="/consulting-agreements" />;
  }

  return (
    <Panel title={"Consulting Agreement Builder"}>
      <PanelRow>
        <SubPanel flex={1}>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Email
            </FormLabel>
            <SearchableSelect
              name={"emails"}
              placeholder={"Email"}
              value={selectedUser}
              loadOptions={loadUserOptions}
              onSelect={(item: SearchableItem) => {
                const user = userOptions.find(
                  (user) => String(user.id) === item.value
                );
                if (user != null) {
                  setSelectedUser(item);
                  setClientId(user.client_id);
                  setClientUserId(user.id);
                  setClient({
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: item.label,
                    company: user.client_is_company
                      ? user.client_name
                      : undefined,
                  });
                }
              }}
              validateCreate={(input: string) =>
                validateEmail(input) &&
                userOptions.map((user) => String(user.id) === input).length ===
                  0
              }
              onCreate={(input: string) => {
                setSelectedUser({ value: input, label: input });
                setClient((c) => ({ ...c, email: input }));
              }}
            />
          </Stack>
          {((client.email != null &&
            clientId == null &&
            client.email.length > 0) ||
            selectedClient != null) && (
            <Stack w="100%">
              <FormLabel textAlign="center" m="auto">
                Existing Client
              </FormLabel>
              <SearchableSelect
                name={"clients"}
                placeholder={"Search existing client"}
                value={selectedClient}
                loadOptions={loadClientOptions}
                onSelect={(item: SearchableItem) => {
                  const client = clientOptions.find(
                    (client) => String(client.id) === item.value
                  );
                  if (client != null) {
                    setSelectedClient(item);
                    setClientId(client.id);
                    setClient((c) => ({
                      ...c,
                      company: client.is_company ? client.name : undefined,
                    }));
                  }
                }}
              />
            </Stack>
          )}
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              First Name
            </FormLabel>
            <Input
              placeholder={"First Name"}
              value={client.first_name}
              onChange={(e) =>
                setClient((c) => ({ ...c, first_name: e.target.value }))
              }
              isDisabled={client.email.length === 0}
            />
          </Stack>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Last Name
            </FormLabel>
            <Input
              placeholder={"Last Name"}
              value={client.last_name}
              onChange={(e) =>
                setClient((c) => ({ ...c, last_name: e.target.value }))
              }
              isDisabled={client.email.length === 0}
            />
          </Stack>
          <Stack w="100%">
            <FormLabel textAlign="center" m="auto">
              Company (Optional)
            </FormLabel>
            <Input
              placeholder={"Company"}
              value={client.company}
              onChange={(e) =>
                setClient((c) => ({ ...c, company: e.target.value }))
              }
              isDisabled={client.email.length === 0}
            />
          </Stack>
          <Stack w="100%" alignItems="center">
            <FormLabel textAlign="center" m="auto">
              Cost
            </FormLabel>
            <NumberInput
              textAlign="center"
              maxW={"15rem"}
              value={"$" + cost}
              onChange={(e) => {
                const value = parseFloat(e);
                if (!isNaN(value) || e.length === 0) {
                  const parts = e.split(".");
                  if (parts.length === 2 && parts[1].length > 4) {
                    e = parts[0] + "." + parts[1].substr(0, 4);
                  }

                  setCost(isNaN(value) ? 0 : value);
                }
              }}
              isDisabled={
                createAgreementMutation.isLoading ||
                previewAgreementMutation.isLoading
              }
            >
              <NumberInputField />
            </NumberInput>
          </Stack>
        </SubPanel>
      </PanelRow>
      <Center>
        <Stack>
          <HStack spacing={8} flex={1} justify={"space-evenly"}>
            <Button
              colorScheme="teal"
              isLoading={
                createAgreementMutation.isLoading ||
                previewAgreementMutation.isLoading
              }
              isDisabled={
                createAgreementMutation.isLoading ||
                previewAgreementMutation.isLoading ||
                formIncomplete
              }
              onClick={() =>
                previewAgreementMutation.mutate({
                  client_id: clientId,
                  client_user_id: clientUserId,
                  cost: cost.toString(),
                  client: client,
                })
              }
            >
              Preview Consulting Agreement
            </Button>
            <Button
              colorScheme="teal"
              isLoading={
                createAgreementMutation.isLoading ||
                previewAgreementMutation.isLoading
              }
              isDisabled={
                createAgreementMutation.isLoading ||
                previewAgreementMutation.isLoading ||
                formIncomplete
              }
              onClick={() =>
                createAgreementMutation.mutate({
                  client_id: clientId,
                  client_user_id: clientUserId,
                  cost: cost.toString(),
                  client: client,
                })
              }
            >
              Create Consulting Agreement
            </Button>
          </HStack>
          <MutationFeedback mutation={createAgreementMutation} />
          <MutationFeedback mutation={previewAgreementMutation} />
        </Stack>
      </Center>
    </Panel>
  );
};
