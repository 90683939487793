import { useQuery } from "react-query";
import { APIResponse, FormattedNumber } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  affiliate: boolean;
  start_date: Date;
  end_date: Date;
  network_id?: number;
  estimate?: boolean;
};

export type RevenueContract = {
  contract_id: number;
  contract_name: string;
  client_id: number;
  client_name: string;
  affiliate_id: number;
  affiliate_name: string;
  miner_count: number;
  avg_power_rate: string;
  contract_power_draw: FormattedNumber;
  contract_power_usage: string;
  contract_power_cost: string;
  contract_affiliate_cost: string;
  actual_power_draw: FormattedNumber;
  actual_power_usage: string;
  actual_power_cost: string;
  actual_affiliate_cost: string;
};

export type RevenueAffiliate = {
  affiliate_id: number;
  affiliate_name: string;
  affiliate_commission_power_rate?: string;
  affiliate_commission_percentage?: string;
  miner_count: number;
  avg_power_rate: string;
  contract_power_draw: FormattedNumber;
  contract_power_usage: string;
  contract_power_cost: string;
  contract_affiliate_cost: string;
  actual_power_draw: FormattedNumber;
  actual_power_usage: string;
  actual_power_cost: string;
  actual_affiliate_cost: string;
  contracts?: RevenueContract[];
};

export type RevenueNetwork = {
  network_id: number;
  network_name: string;
  network_power?: FormattedNumber;
  miner_count: number;
  avg_power_rate: string;
  contract_power_draw: FormattedNumber;
  contract_power_usage: string;
  contract_power_cost: string;
  contract_affiliate_cost: string;
  contract_power_percentage?: FormattedNumber;
  contract_capacity_percentage?: FormattedNumber;
  actual_power_draw: FormattedNumber;
  actual_power_usage: string;
  actual_power_cost: string;
  actual_affiliate_cost: string;
  actual_power_percentage?: FormattedNumber;
  actual_capacity_percentage?: FormattedNumber;
  uncontracted_power_draw?: FormattedNumber;
};

export type Revenue = {
  total_power?: FormattedNumber;
  total_miner_count: number;
  total_avg_power_rate: string;
  total_contract_power_draw: FormattedNumber;
  total_contract_power_usage: string;
  total_contract_cost: string;
  total_actual_power_draw: FormattedNumber;
  total_actual_power_usage: string;
  total_actual_cost: string;
  total_uncontracted_power_draw?: FormattedNumber;
  networks: RevenueNetwork[];
  affiliates?: RevenueAffiliate[];
  notes: string[];
};

export const fetchDataRevenue = (args: QueryArgs) =>
  apiClient
    .get<APIResponse<Revenue>>(
      args.affiliate ? "/data/revenue/affiliate" : "/data/revenue",
      { params: args }
    )
    .then((res) => res.data.data);

export const useDataRevenueQuery = (args: QueryArgs) =>
  useQuery(
    [args.affiliate ? "/data/revenue/affiliate" : "/data/revenue", args],
    () => fetchDataRevenue(args),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

export const fetchDataRevenueExport = (args: QueryArgs) =>
  apiClient.post<Uint8Array>("/data/revenue/export", args, {
    responseType: "blob",
  });
