import { Link as ReactRouterLink } from "react-router-dom";
import { Heading, Button, Text } from "@chakra-ui/react";
import { SmolStackLayout } from "src/components/SmolStackLayout";

export const FourOhFourPage = () => {
  return (
    <SmolStackLayout>
      <Heading size="md">Page Not Found</Heading>
      <Text my={4}>How did you get here? 🤔</Text>
      <Button as={ReactRouterLink} to="/" colorScheme="teal">
        Return To Home
      </Button>
    </SmolStackLayout>
  );
};
