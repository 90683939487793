import { Box, Grid, Divider, Badge } from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { PDU } from "src/api/queries/fetchDataPDU";

export const InfoPanel = ({ pdu }: { pdu: PDU }) => {
  return (
    <Panel title={"PDU Info"}>
      <Box minW="40rem">
        <Grid
          templateColumns="1fr repeat(3, 1fr)"
          gap={2}
          textTransform="uppercase"
          fontSize="sm"
        >
          <Box>Network</Box>
          <Box>Hardware</Box>
          <Box>Monitoring</Box>
          <Box>Status</Box>
        </Grid>
        <Divider my={2} />
        <Grid
          fontSize="sm"
          templateColumns="1fr repeat(3, 1fr)"
          gap={2}
          minW="40rem"
        >
          <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            <Box fontWeight="bold">{pdu.name}</Box>
            <Box>{pdu.network_name}</Box>
            <Box>{pdu.host}</Box>
            <Box>{pdu.mac_address}</Box>
          </Box>
          <Box>
            <Box>{pdu.type_name}</Box>
            <Box>{pdu.model}</Box>
            <Box>{pdu.firmware}</Box>
            <Box>{pdu.uptime}</Box>
          </Box>
          <Box>
            <Box>
              <Badge
                variant="solid"
                colorScheme={pdu.metered ? "green" : undefined}
              >
                {pdu.metered ? "metered" : "not metered"}
              </Badge>
              <Box></Box>
              <Badge
                variant="solid"
                colorScheme={pdu.outlet_switched ? "green" : undefined}
              >
                {pdu.outlet_switched
                  ? "outlet switched"
                  : "not outlet switched"}
              </Badge>
              <Box></Box>
              <Badge
                variant="solid"
                colorScheme={pdu.outlet_metered ? "green" : undefined}
              >
                {pdu.outlet_metered ? "outlet metered" : "not outlet metered"}
              </Badge>
            </Box>
          </Box>
          <Box>
            <Box>
              <Badge
                variant="solid"
                colorScheme={
                  !pdu.metered ? undefined : pdu.enabled ? "green" : "red"
                }
              >
                {!pdu.metered ? "N/A" : pdu.online ? "enabled" : "disabled"}
              </Badge>
              <Box></Box>
              <Badge
                variant="solid"
                colorScheme={
                  !pdu.metered ? undefined : pdu.online ? "green" : "red"
                }
              >
                {!pdu.metered ? "N/A" : pdu.online ? "online" : "offline"}
              </Badge>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Panel>
  );
};
