export const downloadPDF = (name: string, data: Uint8Array) => {
  const file = new Blob([data], { type: "application/pdf" });
  const link = document.createElement("a");
  const href = URL.createObjectURL(file);
  link.setAttribute("download", name);
  link.setAttribute("target", "_blank");
  link.href = href;
  link.click();
  URL.revokeObjectURL(href);
};

export const downloadCSV = (name: string, data: Uint8Array) => {
  const file = new Blob([data], { type: "application/csv" });
  const link = document.createElement("a");
  const href = URL.createObjectURL(file);
  link.setAttribute("download", name);
  link.setAttribute("target", "_blank");
  link.href = href;
  link.click();
  URL.revokeObjectURL(href);
};
