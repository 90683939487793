import { Link as ReactRouterLink } from "react-router-dom";
import { useState } from "react";
import {
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  HStack,
  FormControl,
  FormLabel,
  Select,
  Badge,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { useDataPoolMinersQuery } from "src/api/queries/fetchDataPoolMiners";

export const PoolMinersPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    worker_name: string;
  }>({ page: 0, size: 25, worker_name: "unpaidbitcap" });

  const query = useDataPoolMinersQuery({
    page: state.page,
    size: state.size,
    worker_name: state.worker_name,
  });

  return (
    <Panel title="Miners by Pool" query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <FormControl maxW="10rem">
              <FormLabel>Type</FormLabel>
              <Select
                value={state.worker_name}
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    worker_name: e.target.value,
                  }))
                }
              >
                <option value={"unpaidbitcap"}>Unpaid</option>
                <option value={"testbitfix"}>Bitfix</option>
                <option value={"duplicates"}>Duplicates</option>
              </Select>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
          </HStack>
          <Table size="sm" mb={4}>
            <Thead>
              <Tr>
                <Th minW="200px">ID</Th>
                <Th>Name</Th>
                <Th>Client Name</Th>
                <Th>IP Address</Th>
                <Th>Enabled</Th>
                <Th>Online</Th>
                <Th>Pool URL</Th>
                <Th>Pool Worker</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((miner) => (
                <Tr key={miner.id}>
                  <Td>
                    <Link
                      as={ReactRouterLink}
                      to={`/miners/${miner.id}`}
                      fontWeight="bold"
                    >
                      {miner.id}
                    </Link>
                  </Td>
                  <Td>{miner.name}</Td>
                  <Td>{miner.client_name}</Td>
                  <Td>{miner.ip_address}</Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={miner.enabled ? "green" : "red"}
                    >
                      {miner.enabled ? "true" : "false"}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={miner.online ? "green" : "red"}
                    >
                      {miner.online ? "true" : "false"}
                    </Badge>
                  </Td>
                  <Td>
                    <Text wordBreak="break-all">{miner.pool_url}</Text>
                  </Td>
                  <Td>
                    <Text wordBreak="break-all">{miner.pool_worker}</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
