import { SimpleGrid } from "@chakra-ui/react";
import { MinerGroupPreviewResponse } from "src/api/queries/fetchDataFarm";
import { MinerGroupPreview } from "./MinerGroupPreview";

type MinerGroupPreviewListProps = {
  groups: MinerGroupPreviewResponse[];
  isClient?: boolean;
};
export const MinerGroupPreviewList = ({
  groups,
  isClient = false,
}: MinerGroupPreviewListProps) => {
  return (
    <SimpleGrid columns={3} minChildWidth={"15rem"} gap={3}>
      {groups.map((group) => (
        <MinerGroupPreview key={group.id} group={group} isClient={isClient} />
      ))}
    </SimpleGrid>
  );
};
