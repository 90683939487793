import { Text } from "@chakra-ui/react";

type DollarsLabelledProps = {
  label: string;
  value: string;
};

export const DollarsLabelled = ({ label, value }: DollarsLabelledProps) => {
  return (
    <Text as="span" whiteSpace="nowrap" fontWeight="medium">
      {label}: {value}
    </Text>
  );
};
