import { useEffect, useState } from "react";
import { Outlet } from "src/api/queries/fetchDataPDU";
import {
  useUpdateOutletMutation,
  useRebootOutletMutation,
} from "src/api/mutations/dataPDU";
import {
  useDataMinersQuery,
  fetchDataMiners,
} from "src/api/queries/fetchDataMiners";
import { Panel } from "src/components/Panel";
import {
  SearchableSelect,
  SearchableItem,
} from "src/components/SearchableSelect";
import { MutationFeedback } from "src/components/MutationFeedback";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Badge,
  Stack,
  Button,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";

type OutletPanelProps = {
  pduId: number;
  locationId: number;
  outlets: Outlet[];
};

export const OutletsPanel = ({
  pduId,
  locationId,
  outlets,
}: OutletPanelProps) => {
  const [clear, setClear] = useState<boolean>(false);
  const [items, setItems] = useState<
    Record<string, SearchableItem | undefined>
  >({});

  const updateMutation = useUpdateOutletMutation();
  const rebootMutation = useRebootOutletMutation();

  const defaultQuery = useDataMinersQuery({
    network_id: locationId,
    page: 0,
    size: 50,
  });
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);

  useEffect(() => {
    if (defaultQuery?.data != null) {
      const items = defaultQuery.data.items.map((item: any) => {
        return { value: item.id, label: item.name };
      });
      setDefaultOptions(items);
    }
  }, [defaultQuery.data]);

  const loadSelectOptions = async (query: string) => {
    const data = await fetchDataMiners({
      network_id: locationId,
      query: query,
      page: 0,
      size: 50,
    });
    return data.items.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  useEffect(() => {
    outlets.forEach((outlet) => {
      if (outlet.miner_id != null && outlet.miner_name != null) {
        setItems((s) => ({
          ...s,
          [outlet.id]: { value: outlet.miner_id!, label: outlet.miner_name! },
        }));
      }
    });
  }, [outlets]);

  return (
    <Panel title={"Outlets"}>
      <>
        <MutationFeedback mutation={updateMutation} />
        <MutationFeedback mutation={rebootMutation} />
        <Table size="sm" mb={4} variant={"striped"}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>Miner</Th>
              <Th>Online</Th>
              <Th>Current</Th>
              <Th>Power</Th>
            </Tr>
          </Thead>
          <Tbody>
            {outlets.map((outlet) => {
              return (
                <Tr key={outlet.id}>
                  <Td>{outlet.id}</Td>
                  <Td>{outlet.name}</Td>
                  <Td>
                    <Stack
                      spacing={1}
                      justify={"space-between"}
                      align={"center"}
                      direction="row"
                    >
                      <Box minW="12rem" w={"full"}>
                        <SearchableSelect
                          key={`select-${outlet.id}`}
                          name={"miners"}
                          placeholder={"Update miner"}
                          value={items[outlet.id]}
                          defaultOptions={defaultOptions}
                          loadOptions={loadSelectOptions}
                          shouldClear={clear}
                          onSelect={(item: SearchableItem) => {
                            setClear(false);
                            setItems((s) => ({ ...s, [outlet.id]: item }));
                            updateMutation.mutate({
                              pdu_id: pduId,
                              id: outlet.id,
                              miner_id: item.value,
                            });
                          }}
                        />
                      </Box>
                      {outlet.miner_pending && <RepeatIcon />}
                      {items[outlet.id] != null && (
                        <Button
                          colorScheme="red"
                          type="submit"
                          size="sm"
                          isLoading={updateMutation.isLoading}
                          isDisabled={
                            updateMutation.isLoading || rebootMutation.isLoading
                          }
                          onClick={() => {
                            setClear(true);
                            setItems((s) => ({ ...s, [outlet.id]: undefined }));
                            updateMutation.mutate({
                              pdu_id: pduId,
                              id: outlet.id,
                              miner_id: null,
                            });
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Stack>
                  </Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={outlet.online ? "green" : "red"}
                    >
                      {outlet.online ? "online" : "offline"}
                    </Badge>
                  </Td>
                  <Td>
                    {outlet.current != null ? outlet.current + " A" : "N/A"}
                  </Td>
                  <Td>{outlet.power != null ? outlet.power + " W" : "N/A"}</Td>
                  <Td>
                    <Button
                      colorScheme="teal"
                      type="submit"
                      isLoading={rebootMutation.isLoading}
                      disabled={
                        updateMutation.isLoading || rebootMutation.isLoading
                      }
                      onClick={() =>
                        rebootMutation.mutate({
                          pdu_id: pduId,
                          id: outlet.id,
                        })
                      }
                    >
                      Reboot
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </>
    </Panel>
  );
};
