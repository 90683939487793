import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  user_id: number;
};

export const adminResendVerifyEmail = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/admin/resend_verify_email", {
    ...body,
  });
};
