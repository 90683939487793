import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type CreateShipmentLocationArgs = {
  client_id?: number;
  network_id?: number;
  repair_facility_id?: number;
};

export type CreateShipmentArgs = {
  date: Date;
  from_location: CreateShipmentLocationArgs;
  to_location: CreateShipmentLocationArgs;
  tracking_number?: string;
};

export const useCreateShipmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: CreateShipmentArgs) => {
      return apiClient.post<APIResponse<null>>("/logistics/shipment", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/shipments");
    },
  });
};

export type UpdateShipmentArgs = {
  shipment_id: number;
  date: Date;
  from_location: CreateShipmentLocationArgs;
  to_location: CreateShipmentLocationArgs;
  tracking_number?: string;
};

export const useUpdateShipmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateShipmentArgs) => {
      return apiClient.patch<APIResponse<null>>("/logistics/shipment", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/shipments");
    },
  });
};

export type UpdateShipmentMinersArgs = {
  shipment_id: number;
  miner_set_ids: number[];
};

export const useUpdateShipmentMinersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateShipmentMinersArgs) => {
      return apiClient.patch<APIResponse<null>>(
        "/logistics/shipment/miners",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/shipments");
    },
  });
};

export type GenerateShipmentInvoiceArgs = {
  shipment_id: number;
  cost: string;
};

export const useGenerateShipmentInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: GenerateShipmentInvoiceArgs) => {
      return apiClient.post<APIResponse<null>>(
        "/logistics/shipment/invoice",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/shipments");
    },
  });
};

export const useDeleteShipmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { shipment_id: number }) => {
      return apiClient.delete<APIResponse<null>>("/logistics/shipment", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/shipments");
    },
  });
};
