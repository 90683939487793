import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type Client = {
  first_name: string;
  last_name: string;
  email: string;
  company?: string;
};

export type ConsultingAgreement = {
  client_id?: number;
  client_user_id?: number;
  client?: Client;
  cost: string;
};

export const useCreateConsultingAgreementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ConsultingAgreement) => {
      return apiClient.post<APIResponse<null>>("/consulting_agreement", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/consulting_agreement");
      await queryClient.invalidateQueries("/consulting_agreements");
    },
  });
};

export const useSendConsultingAgreementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { consulting_agreement_id: number }) => {
      return apiClient.post<APIResponse<null>>(
        "/consulting_agreement/send",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/consulting_agreement");
      await queryClient.invalidateQueries("/consulting_agreements");
    },
  });
};

export const useDeleteConsultingAgreementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { consulting_agreement_id: number }) => {
      return apiClient.delete<APIResponse<null>>("/consulting_agreement", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/consulting_agreement");
      await queryClient.invalidateQueries("/consulting_agreements");
    },
  });
};

export const previewConsultingAgreement = (body: ConsultingAgreement) =>
  apiClient.post<Uint8Array>("/consulting_agreement/preview", body, {
    responseType: "blob",
  });

export const downloadConsultingAgreement = (body: {
  consulting_agreement_id: number;
}) =>
  apiClient.post<Uint8Array>("/consulting_agreement/download", body, {
    responseType: "blob",
  });
