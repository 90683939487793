import { useEffect } from "react";
import { useMutation } from "react-query";
import { Link, Redirect } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import QRCode from "react-qr-code";
import {
  Stack,
  Heading,
  ButtonGroup,
  Text,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { User } from "src/types";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SmolStackLayout } from "src/components/SmolStackLayout";
import { authConfirmReset2FA } from "src/api/mutations/authConfirmReset2FA";

type Props = {
  user: User | undefined;
};

export const Reset2FAPage = ({ user }: Props) => {
  const [{ email, token }] = useQueryParams({
    email: StringParam,
    token: StringParam,
  });

  const mutation = useMutation(authConfirmReset2FA);

  useEffect(() => {
    if (email && token && mutation.isIdle) {
      mutation.mutate({ email, token });
    }
  }, [email, token, mutation]);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <SmolStackLayout>
      {mutation.isSuccess ? (
        <Stack spacing={4}>
          <Heading size="md">2FA Successfully Reset</Heading>
          <Text>
            Please save your 2FA Seed! The 2FA Seed must be stored in an
            authenticator app like Google Authenticator or Authy. The 2FA Seed
            is not your 2FA Code. An authenticator app will provide you with the
            2FA Codes you need to login.
          </Text>
          <Stack spacing={1} pb={1}>
            <b>New 2FA Seed as QR Code:</b>
            <QRCode
              value={`otpauth://totp/BitCap?secret=${mutation.data?.["2fa_seed"]}`}
            />
          </Stack>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      ) : mutation.isError ? (
        <Stack spacing={4}>
          <Heading size="md">2FA Reset Error</Heading>
          <MutationFeedback mutation={mutation} />
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      ) : token && email ? (
        <Stack spacing={4}>
          <Heading size="md">Resetting 2FA</Heading>
          <Spinner />
        </Stack>
      ) : (
        <Stack spacing={4}>
          <Text>Something went wrong!</Text>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      )}
    </SmolStackLayout>
  );
};
