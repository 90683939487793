export const trimDateToUTC = (date: Date) => {
  let newDate = new Date(date);
  newDate.setUTCHours(0, 0);
  newDate.setUTCMinutes(0, 0);
  newDate.setUTCSeconds(0, 0);
  newDate.setDate(newDate.getDate());

  return newDate;
};

export const addDateTimezone = (date: Date) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;

  return new Date(date.valueOf() + tzoffset);
};

export const removeDateTimezone = (date: Date) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;

  return new Date(date.valueOf() - tzoffset);
};
