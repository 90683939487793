import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  email: string;
};

export const authRequestReset2FA = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/auth/request_reset_2fa", {
    ...body,
  });
};
