import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type RepairFacility = {
  id: number;
  name: string;
};

export const fetchRepairFacilities = () =>
  apiClient
    .get<APIResponsePaginated<RepairFacility>>("/logistics/repair_facilities")
    .then((res) => res.data.data);

export const useRepairFacilitiesQuery = () => {
  return useQuery(
    ["/logistics/repair_facilities"],
    () => fetchRepairFacilities(),
    {
      keepPreviousData: true,
    }
  );
};
