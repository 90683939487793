import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type DeleteBody = {
  user_id: number;
};

export const useAdminDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteBody) => {
      return apiClient.delete<APIResponse<null>>("/admin/users", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/admin/users");
    },
  });
};
