import { useParams } from "react-router";
import { BreadcrumbValues, Page } from "src/components/Page";
import { useDataMinerQuery } from "src/api/queries/fetchDataMiner";
import { PanelRow } from "src/components/PanelRow";
import { useUserContext } from "src/contexts/UserContext";
import { DescriptionPanel } from "./panels/DescriptionPanel";
import { DevicesPanel } from "./panels/DevicesPanel";
import { PoolsPanel } from "./panels/PoolsPanel";
import { NotesPanel } from "./panels/NotesPanel";

export const MinerPage = () => {
  const { user } = useUserContext();
  const { id: minerId } = useParams<{ id: string }>();
  const query = useDataMinerQuery(minerId);
  const pageTitle = query.data?.name || "...";
  const groupName = query.data?.group_name || "Group";

  let crumbs: BreadcrumbValues[] =
    user.type === "client"
      ? [
          {
            text: "Miners",
            to: "/miners",
          },
          {
            text: pageTitle,
            to: undefined,
          },
        ]
      : [
          {
            text: "Miner Groups",
            to: "/groups",
          },
          {
            text: groupName,
            to: `/groups/${query.data?.group_id}`,
          },
          {
            text: pageTitle,
            to: undefined,
          },
        ];

  return (
    <Page title={pageTitle} breadcrumbs={crumbs}>
      <PanelRow>
        <DescriptionPanel minerId={minerId} />
      </PanelRow>
      <PanelRow>
        <PoolsPanel minerId={minerId} />
      </PanelRow>
      <PanelRow>
        <DevicesPanel deviceType={"GPU"} minerId={minerId} />
      </PanelRow>
      <PanelRow>
        <DevicesPanel deviceType={"ASIC"} minerId={minerId} />
      </PanelRow>
      {user.type !== "client" && (
        <PanelRow>
          <NotesPanel minerId={minerId} />
        </PanelRow>
      )}
    </Page>
  );
};
