import { useState, useEffect, useRef } from "react";
import { Panel } from "src/components/Panel";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
  HStack,
  Button,
  Input,
  IconButton,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { MutationFeedback } from "src/components/MutationFeedback";
import {
  TicketCategory,
  useTicketCategoriesQuery,
} from "src/api/queries/fetchTicketCategories";
import {
  useCreateTicketCategoryMutation,
  useUpdateTicketCategoryMutation,
  useDeleteTicketCategoryMutation,
} from "src/api/mutations/ticketCreate";

const CreateCategoryButton = () => {
  const [name, setName] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateTicketCategoryMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setName("");
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Category"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Create Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading || name.length === 0}
              onClick={() =>
                mutation.mutate({ name: name }, { onSuccess: onClose })
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const EditCategoryButton = ({ category }: { category: TicketCategory }) => {
  const [state, setState] = useState<TicketCategory>(category);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateTicketCategoryMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(category);
    }
  }, [isOpen, mutation, category]);

  useEffect(() => {
    if (!isOpen && category.name !== state.name) {
      setState(category);
    }
  }, [isOpen, category, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Category"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={state.name.length === 0}
              onClick={() =>
                mutation.mutate(
                  { id: state.id, name: state.name },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteCategoryButton = ({ category }: { category: TicketCategory }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useDeleteTicketCategoryMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent margin="auto">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Category
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete category <b>{category.name}</b>?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={() =>
                  mutation.mutate({ id: category.id }, { onSuccess: onClose })
                }
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const TicketCategoriesPanel = () => {
  const query = useTicketCategoriesQuery();

  return (
    <Panel title={"Categories"} query={query}>
      {(data) => (
        <>
          <HStack justify={"flex-end"}>
            <CreateCategoryButton />
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((category) => (
                <Tr key={`category-${category.id}`}>
                  <Td>{category.name}</Td>
                  <Td>
                    <HStack justify="flex-end">
                      <EditCategoryButton category={category} />
                      <DeleteCategoryButton category={category} />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Panel>
  );
};
