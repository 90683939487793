import { useState } from "react";
import { useMutation } from "react-query";
import { Stack, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { userChangePassword } from "src/api/mutations/userChangePassword";
import { MutationFeedback } from "src/components/MutationFeedback";

type Form = {
  current: string;
  new: string;
};

export const ChangePasswordPanel = () => {
  const [form, setForm] = useState<Form>({ current: "", new: "" });

  const mutation = useMutation(userChangePassword);

  const formIsDisabled = form.current === "" || form.new === "";

  return (
    <Panel title="Change Password">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate({
            current_password: form.current,
            new_password: form.new,
          });
        }}
      >
        <Stack spacing={4}>
          <FormControl id="current">
            <FormLabel>Current Password</FormLabel>
            <Input
              type="password"
              value={form.current}
              onChange={(e) => {
                const current = e.target.value;
                setForm((f) => ({
                  ...f,
                  current,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl id="new">
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={form.new}
              onChange={(e) => {
                const newPassword = e.target.value;
                setForm((f) => ({
                  ...f,
                  new: newPassword,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <MutationFeedback mutation={mutation} />
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={mutation.isLoading}
            disabled={
              mutation.isSuccess || mutation.isLoading || formIsDisabled
            }
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Panel>
  );
};
