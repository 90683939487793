export const setTokens = (access_token: string, refresh_token: string) => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const clearTokens = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("backup_access_token");
  localStorage.removeItem("backup_refresh_token");
};

export const backupTokens = () => {
  const accessToken = getAccessToken();
  if (accessToken !== null) {
    localStorage.setItem("backup_access_token", accessToken);
  }

  const refreshToken = getRefreshToken();
  if (refreshToken !== null) {
    localStorage.setItem("backup_refresh_token", refreshToken);
  }
};

export const restoreTokens = () => {
  const backupAccessToken = localStorage.getItem("backup_access_token");
  if (backupAccessToken !== null) {
    localStorage.setItem("access_token", backupAccessToken);
    localStorage.removeItem("backup_access_token");
  }

  const backupRefreshToken = localStorage.getItem("backup_refresh_token");
  if (backupRefreshToken !== null) {
    localStorage.setItem("refresh_token", backupRefreshToken);
    localStorage.removeItem("backup_refresh_token");
  }
};
