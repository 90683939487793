import { Stat, StatNumber } from "@chakra-ui/react";
import { useDataDashboardQuery } from "src/api/queries/fetchDataDashboard";
import { Panel } from "src/components/Panel";

export const ActiveMinersPanel = () => {
  const query = useDataDashboardQuery();

  return (
    <Panel title="Active Miners" flex={1} query={query}>
      {(data) => (
        <Stat>
          <StatNumber>
            {data.running_count} / {data.total_count}
          </StatNumber>
        </Stat>
      )}
    </Panel>
  );
};
