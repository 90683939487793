import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  invoice_id: number;
};

export type InvoicePayment = {
  id: number;
  contract_id: number;
  invoice_id: number;
  quickbooks_id: string;
  value: string;
  method: string;
  note?: string;
  extra_note?: string;
  created_at: Date;
};

export type Response = {
  items: InvoicePayment[];
  total_value: string;
};

export const fetchInvoicePayments = (args: QueryArgs) =>
  apiClient
    .get<APIResponse<Response>>("/invoice/payments", { params: args })
    .then((res) => res.data.data);

export const useInvoicePaymentsQuery = (args: QueryArgs) =>
  useQuery(["/invoices/payments", args], () => fetchInvoicePayments(args), {
    keepPreviousData: true,
  });
