import { useEffect, useState, useRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  HStack,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Link,
  Input,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Switch,
  IconButton,
  FormControl,
  FormLabel,
  Thead,
  Badge,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  useCreatePDUMutation,
  useUpdatePDUMutation,
  useDeletePDUMutation,
} from "src/api/mutations/dataPDU";
import { networks, pduTypes } from "src/types";
import { PDU, useDataPDUsQuery } from "src/api/queries/fetchDataPDUs";
import { Panel } from "src/components/Panel";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SimplePaginator } from "src/components/SimplePaginator";

const formatColor = (value: number, warn: number, alert: number) => {
  if (value === 0) {
    return "gray";
  } else if (value >= alert) {
    return "red";
  } else if (value >= warn) {
    return "#D69E2E";
  }
  return undefined;
};

type NewPDU = {
  name?: string;
  host?: string | null;
  enabled?: boolean | null;
  smart: boolean;
  network_id?: number | null;
  type_id?: number | null;
  lines?: number | null;
  branches?: number | null;
  outlets?: number | null;
};

const CreatePDUButton = () => {
  const [state, setState] = useState<NewPDU>({ enabled: true, smart: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreatePDUMutation();

  const handleCreate = () => {
    if (state.name !== undefined && state.host !== undefined) {
      mutation.mutate(
        {
          name: state.name,
          host: state.smart ? state.host : null,
          enabled: state.smart ? state.enabled : false,
          smart: state.smart,
          network_id: state.network_id ? state.network_id : null,
          type: state.type_id ? state.type_id : null,
          lines: state.lines ? state.lines : null,
          branches: state.branches ? state.branches : null,
          outlets: state.outlets ? state.outlets : null,
        },
        {
          onSuccess: onClose,
        }
      );
    }
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({ enabled: true, smart: true });
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <Text minW="10rem" align="center" fontWeight="medium">
        Create a new PDU
      </Text>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create PDU"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create PDU</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              size="sm"
              colorScheme="teal"
              onChange={(idx) =>
                setState((s) => ({
                  ...s,
                  smart: idx === 0,
                  enabled: idx === 0 ? state.enabled : null,
                  host: idx === 0 ? state.host : null,
                  network_id: idx === 0 ? null : 1,
                  type: idx === 0 ? null : -1,
                  lines: idx === 0 ? null : 3,
                  branches: idx === 0 ? null : 3,
                  outlets: idx === 0 ? null : 24,
                }))
              }
            >
              <TabList>
                <Tab>Smart</Tab>
                <Tab>Dumb</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Stack>
                    <FormControl id="name">
                      <FormLabel>Name</FormLabel>
                      <Input
                        type="name"
                        value={state.name}
                        onChange={(e) =>
                          setState((s) => ({ ...s, name: e.target.value }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Host</FormLabel>
                      <Input
                        type="name"
                        value={state.host != null ? state.host : ""}
                        onChange={(e) =>
                          setState((s) => ({ ...s, host: e.target.value }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Enabled</FormLabel>
                      <Switch
                        isChecked={state.enabled != null ? state.enabled : true}
                        onChange={(e) =>
                          setState((s) => ({ ...s, enabled: e.target.checked }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <MutationFeedback mutation={mutation} />
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack>
                    <FormControl id="name">
                      <FormLabel>Name</FormLabel>
                      <Input
                        type="name"
                        value={state.name}
                        onChange={(e) =>
                          setState((s) => ({ ...s, name: e.target.value }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Network</FormLabel>
                      <Select
                        value={state.network_id != null ? state.network_id : 1}
                        onChange={(e) =>
                          setState((f) => ({
                            ...f,
                            network_id: parseInt(e.target.value),
                          }))
                        }
                      >
                        {networks.map((network) => (
                          <option
                            key={`network-${network.id}`}
                            value={network.id}
                          >
                            {network.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Type</FormLabel>
                      <Select
                        value={state.type_id != null ? state.type_id : -1}
                        onChange={(e) =>
                          setState((f) => ({
                            ...f,
                            type_id: parseInt(e.target.value),
                          }))
                        }
                      >
                        {pduTypes.map((pduType) => (
                          <option key={`type-${pduType.id}`} value={pduType.id}>
                            {pduType.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Lines</FormLabel>
                      <Input
                        type="number"
                        value={state.lines != null ? state.lines : 0}
                        onChange={(e) =>
                          setState((s) => ({
                            ...s,
                            lines: parseInt(e.target.value),
                          }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Branches</FormLabel>
                      <Input
                        type="number"
                        value={state.branches != null ? state.branches : 0}
                        onChange={(e) =>
                          setState((s) => ({
                            ...s,
                            branches: parseInt(e.target.value),
                          }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Outlets</FormLabel>
                      <Input
                        type="number"
                        value={state.outlets != null ? state.outlets : 0}
                        onChange={(e) =>
                          setState((s) => ({
                            ...s,
                            outlets: parseInt(e.target.value),
                          }))
                        }
                        isDisabled={mutation.isLoading}
                      />
                    </FormControl>
                    <MutationFeedback mutation={mutation} />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={state.name === undefined || state.host === undefined}
              onClick={handleCreate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const EditPDUButton = ({ pdu }: { pdu: PDU }) => {
  const [state, setState] = useState<PDU>(pdu);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdatePDUMutation();

  const handleUpdate = () => {
    mutation.mutate(
      {
        pdu_id: state.id,
        name: state.name,
        host: state.host,
        network_id: state.network_id,
        enabled: state.enabled,
        type: state.type_id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(pdu);
    }
  }, [isOpen, mutation, pdu]);

  useEffect(() => {
    if (
      !isOpen &&
      (pdu.name !== state.name ||
        pdu.host !== state.host ||
        pdu.enabled !== state.enabled)
    ) {
      setState(pdu);
    }
  }, [isOpen, pdu, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit PDU"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit PDU</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              {pdu.smart && (
                <>
                  <FormControl>
                    <FormLabel>Host</FormLabel>
                    <Input
                      type="name"
                      value={state.host}
                      onChange={(e) =>
                        setState((s) => ({ ...s, host: e.target.value }))
                      }
                      isDisabled={mutation.isLoading}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Enabled</FormLabel>
                    <Switch
                      isChecked={state.enabled}
                      onChange={(e) =>
                        setState((s) => ({ ...s, enabled: e.target.checked }))
                      }
                      isDisabled={mutation.isLoading}
                    />
                  </FormControl>
                </>
              )}
              {!pdu.smart && (
                <>
                  <FormControl>
                    <FormLabel>Network</FormLabel>
                    <Select
                      value={state.network_id != null ? state.network_id : 1}
                      onChange={(e) =>
                        setState((f) => ({
                          ...f,
                          network_id: parseInt(e.target.value),
                        }))
                      }
                    >
                      {networks.map((network) => (
                        <option value={network.id}>{network.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      value={state.type_id != null ? state.type_id : -1}
                      onChange={(e) =>
                        setState((f) => ({
                          ...f,
                          type: parseInt(e.target.value),
                        }))
                      }
                    >
                      {pduTypes.map((pduType) => (
                        <option value={pduType.id}>{pduType.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUpdate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeletePDUButton = ({ pdu }: { pdu: PDU }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useDeletePDUMutation();

  const handleDelete = () => {
    mutation.mutate(
      {
        pdu_id: pdu.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete PDU
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete PDU{" "}
                  <b>
                    {pdu.name} ({pdu.network_name})
                  </b>
                  ?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const PDUsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: string | undefined;
    online: boolean | undefined;
    smart: boolean | undefined;
  }>({
    page: 0,
    size: 10,
    query: undefined,
    online: undefined,
    smart: undefined,
  });

  const query = useDataPDUsQuery({
    page: state.page,
    size: state.size,
    query: state.query,
    online: state.online,
    smart: state.smart,
  });

  return (
    <Panel title={"PDUs"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        page: 0,
                        query:
                          e.target.value != null && e.target.value.length > 0
                            ? e.target.value
                            : undefined,
                      }))
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Type</FormLabel>
                <Select
                  value={
                    state.smart == null ? "all" : state.smart ? "smart" : "dumb"
                  }
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      smart:
                        e.target.value === "all"
                          ? undefined
                          : e.target.value === "smart"
                          ? true
                          : false,
                    }))
                  }
                >
                  <option value={"all"}>All</option>
                  <option value={"smart"}>Smart</option>
                  <option value={"dumb"}>Dumb</option>
                </Select>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Status</FormLabel>
                <Select
                  value={
                    state.online == null
                      ? "all"
                      : state.online
                      ? "online"
                      : "offline"
                  }
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      online:
                        e.target.value === "all"
                          ? undefined
                          : e.target.value === "online"
                          ? true
                          : false,
                    }))
                  }
                >
                  <option value={"all"}>All</option>
                  <option value={"online"}>Online</option>
                  <option value={"offline"}>Offline</option>
                </Select>
              </FormControl>
            </HStack>
            <CreatePDUButton />
          </HStack>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Location</Th>
                <Th>Host</Th>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Lines</Th>
                <Th>Branches</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((pdu) => {
                return (
                  <Tr key={`pdu-${pdu.id}`}>
                    <Td>
                      <Link
                        as={ReactRouterLink}
                        to={`/pdus/${pdu.id}`}
                        fontWeight="bold"
                      >
                        {pdu.id}
                      </Link>
                    </Td>
                    <Td>{pdu.network_name}</Td>
                    <Td>
                      <a
                        href={
                          pdu.type_id === 7 || pdu.type_id === 8
                            ? `http://${pdu.host}/home.html`
                            : `https://${pdu.host}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {pdu.host}
                      </a>
                    </Td>
                    <Td>{pdu.name}</Td>
                    <Td>
                      {pdu.type_name} {pdu.type_id}
                    </Td>
                    <Td>
                      {!pdu.smart && (
                        <Badge variant="solid" colorScheme={"gray"}>
                          N/A
                        </Badge>
                      )}
                      {pdu.smart && (
                        <VStack>
                          <Badge
                            variant="solid"
                            colorScheme={pdu.enabled ? "green" : "red"}
                          >
                            {pdu.enabled ? "enabled" : "disabled"}
                          </Badge>
                          <Badge
                            variant="solid"
                            colorScheme={pdu.online ? "green" : "red"}
                          >
                            {pdu.online ? "online" : "offline"}
                          </Badge>
                        </VStack>
                      )}
                    </Td>
                    <Td>
                      <SimpleGrid minChildWidth="27px" spacing="5px">
                        {pdu.online &&
                          pdu.smart &&
                          pdu.lines.map((l) => {
                            const current = l.current != null ? l.current : 0;
                            return (
                              <Text
                                key={`line-${l.id}`}
                                textAlign="center"
                                textColor={formatColor(
                                  current,
                                  l.current_warn,
                                  l.current_alert
                                )}
                              >
                                {current.toFixed(0)}
                              </Text>
                            );
                          })}
                      </SimpleGrid>
                    </Td>
                    <Td>
                      <SimpleGrid minChildWidth="20px" spacing="5px">
                        {pdu.online &&
                          pdu.smart &&
                          pdu.branches.map((b) => {
                            const current = b.current != null ? b.current : 0;
                            return (
                              <Text
                                key={`branch-${b.id}`}
                                textAlign="center"
                                textColor={formatColor(
                                  current,
                                  b.current_warn,
                                  b.current_alert
                                )}
                              >
                                {current.toFixed(0)}
                              </Text>
                            );
                          })}
                      </SimpleGrid>
                    </Td>
                    <Td>
                      <EditPDUButton pdu={pdu} />
                      <DeletePDUButton pdu={pdu} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
