import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type DeleteBody = {
  client_id: number;
  group_id: string;
};

export const useDeleteAdminGroupsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteBody) => {
      return apiClient.delete<APIResponse<null>>("/admin/groups", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/admin/clients");
      await queryClient.invalidateQueries("/admin/groups/unclaimed");
    },
  });
};

type CreateLinkArgs = {
  client_id: number;
  group_ids: string[];
};

export const useCreateAdminGroupsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (args: CreateLinkArgs) => {
      return Promise.all(
        args.group_ids.map((group_id) =>
          apiClient.post<APIResponse<null>>("/admin/groups", {
            group_id,
            client_id: args.client_id,
          })
        )
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/admin/clients");
      await queryClient.invalidateQueries("/admin/groups/unclaimed");
    },
  });
};
