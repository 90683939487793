import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { ShipmentsPanel } from "./panels/ShipmentsPanel";
import { MinerSetsPanel } from "./panels/MinerSetsPanel";
import { RepairFacilitiesPanel } from "./panels/RepairFacilitiesPanel";

export const LogisticsPage = () => {
  return (
    <Page title="Logistics" maxW={"container.2xl"}>
      <PanelRow>
        <ShipmentsPanel />
      </PanelRow>
      <PanelRow>
        <MinerSetsPanel />
      </PanelRow>
      <PanelRow>
        <RepairFacilitiesPanel />
      </PanelRow>
    </Page>
  );
};
