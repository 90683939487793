import { Branch } from "src/api/queries/fetchDataPDU";
import { Text, HStack, Divider } from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import {
  StatGroup,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon, WarningTwoIcon } from "@chakra-ui/icons";

const formatColor = (value: number, warn: number, alert: number) => {
  if (value === 0) {
    return "gray";
  } else if (value >= alert) {
    return "red";
  } else if (value >= warn) {
    return "#D69E2E";
  }
  return undefined;
};

export const BranchesPanel = ({ branches }: { branches: Branch[] }) => {
  const size = 6;
  const count = branches.length;

  let groups: Branch[][] = [];
  for (let i = 0; i < count; i += size) {
    let group: Branch[] = [];
    for (let j = i; j < i + size; j++) {
      if (j < count) {
        group.push(branches![j]);
      }
    }
    groups.push(group);
  }

  return (
    <Panel title={"Branches"}>
      {groups.map((group, idx) => (
        <Box key={`divider-${idx}`}>
          {idx !== 0 && <Divider />}
          <StatGroup my={4}>
            {group.map((branch) => {
              const color =
                branch.current == null
                  ? "gray"
                  : formatColor(
                      branch.current,
                      branch.current_warn,
                      branch.current_alert
                    );
              const percent =
                branch.current == null
                  ? ""
                  : (
                      (branch.current / (branch.current_alert / 0.9)) *
                      100
                    ).toFixed(1) + "%";

              return (
                <Stat key={`stat-${idx}-${branch.id}`}>
                  <StatLabel>{branch.name}</StatLabel>
                  <StatNumber color={color}>
                    {branch.current != null
                      ? branch.current.toFixed(2) + " A"
                      : "N/A"}
                  </StatNumber>
                  <StatHelpText>
                    <HStack key={`stat-branch-${idx}-${branch.id}`}>
                      <Text>{percent}</Text>
                      {branch.current != null &&
                        (branch.current >= branch.current_warn ? (
                          <InfoIcon />
                        ) : branch.current >= branch.current_alert ? (
                          <WarningTwoIcon />
                        ) : (
                          <CheckCircleIcon />
                        ))}
                    </HStack>
                  </StatHelpText>
                </Stat>
              );
            })}
          </StatGroup>
        </Box>
      ))}
    </Panel>
  );
};
