import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  password: string;
  token: string;
  email: string;
};

export const authConfirmResetPassword = async (body: PostBody) => {
  return apiClient
    .post<APIResponse<null>>("/auth/confirm_reset_password", body)
    .then((res) => res.data.data);
};
