import { createContext, FC, useContext } from "react";
import { User } from "src/types";

type UserContextValue = {
  user: User;
  logout: () => void;
};

const fallbackUserContextValue: UserContextValue = {
  user: {
    id: -1,
    type: "client",
    emulated: false,
    groups: [],
    last_login: "",
    created_at: "",
    updated_at: "",
    revoked: false,
    verified: true,
    email: {
      current: "",
      pending: null,
    },
    first_name: "",
    last_name: "",
    client_id: null,
    client_name: null,
  },
  logout: () => {},
};

export const UserContext = createContext<UserContextValue>(
  fallbackUserContextValue
);

export const UserProvider: FC<{ user?: User; onLogout: () => void }> = ({
  user,
  onLogout,
  children,
}) => {
  return (
    <UserContext.Provider
      value={{
        user: user as User,
        logout: onLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
