import {
  VStack,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { useInvoicePaymentsQuery } from "src/api/queries/fetchInvoicePayments";

export const PaymentsPanel = ({ invoiceId }: { invoiceId: number }) => {
  const query = useInvoicePaymentsQuery({ invoice_id: invoiceId });

  return (
    <Panel title={"Payments"} query={query}>
      {(data) => (
        <>
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th>QBO ID</Th>
                <Th>Timestamp</Th>
                <Th>Method</Th>
                <Th>Note</Th>
                <Th>Value</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((payment) => (
                <Tr>
                  <Td>
                    <a
                      href={`https://app.qbo.intuit.com/app/recvpayment?txnId=${payment.quickbooks_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>{payment.quickbooks_id}</b>
                    </a>
                  </Td>
                  <Td>{new Date(payment.created_at).toLocaleDateString()}</Td>
                  <Td>{payment.method}</Td>
                  <Td>
                    <VStack>
                      {payment.note != null && (
                        <Text wordBreak="break-all">{payment.note}</Text>
                      )}
                      {payment.extra_note != null && (
                        <Text wordBreak="break-all">{payment.extra_note}</Text>
                      )}
                    </VStack>
                  </Td>
                  <Td>${payment.value}</Td>
                </Tr>
              ))}
              <Tr>
                <Td />
                <Td />
                <Td />
                <Td />
                <Td>${data.total_value}</Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
    </Panel>
  );
};
