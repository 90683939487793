import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Panel } from "src/components/Panel";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Select,
  Stack,
  HStack,
  Button,
  LinkBox,
  LinkOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useConsultingAgreementsQuery,
  ConsultingAgreement,
} from "src/api/queries/fetchConsultingAgreements";
import {
  useSendConsultingAgreementMutation,
  useDeleteConsultingAgreementMutation,
} from "src/api/mutations/consultingAgreementCreate";
import { SimplePaginator } from "src/components/SimplePaginator";
import { SearchIcon } from "@chakra-ui/icons";
import { MutationFeedback } from "src/components/MutationFeedback";

const DeleteConsultingAgreementButton = ({
  consultingAgreement,
}: {
  consultingAgreement: ConsultingAgreement;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useDeleteConsultingAgreementMutation();

  const handleDelete = () => {
    mutation.mutate(
      {
        consulting_agreement_id: consultingAgreement.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="md"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Consulting Agreement
            </AlertDialogHeader>
            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete consulting agreement{" "}
                  <b>
                    {consultingAgreement.name} (
                    {consultingAgreement.client_name})
                  </b>
                  ?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const ConsultingAgreementsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: null | string;
  }>({ page: 0, size: 10, query: null });

  const query = useConsultingAgreementsQuery({
    query: state.query,
    page: state.page,
    size: state.size,
  });

  const sendMutation = useSendConsultingAgreementMutation();
  return (
    <Panel title={"Consulting Agreements"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <FormControl maxW="30rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      page: 0,
                      query:
                        e.target.value != null && e.target.value.length > 0
                          ? e.target.value
                          : null,
                    }))
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Client</Th>
                <Th>Creator</Th>
                <Th>Status</Th>
                <Th>Cost</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((consultingAgreement) => (
                <Tr key={`consulting-agreement-${consultingAgreement.id}`}>
                  <Td>{consultingAgreement.name}</Td>
                  <Td>{consultingAgreement.client_name}</Td>
                  <Td>{consultingAgreement.creator_name}</Td>
                  <Td>{consultingAgreement.status}</Td>
                  <Td>${consultingAgreement.cost}</Td>
                  <Td>
                    <HStack>
                      <LinkBox
                        bg="blackAlpha.100"
                        p={2}
                        borderRadius="md"
                        _hover={{ bg: "blackAlpha.200" }}
                        textAlign="center"
                        minW="5rem"
                      >
                        <LinkOverlay
                          fontWeight="bold"
                          as={ReactRouterLink}
                          to={`/pdf/consulting-agreement/${consultingAgreement.file_id}`}
                          isExternal
                        >
                          PDF
                        </LinkOverlay>
                      </LinkBox>
                      {consultingAgreement.status === "Created" && (
                        <Button
                          colorScheme="teal"
                          isLoading={sendMutation.isLoading}
                          isDisabled={sendMutation.isLoading}
                          onClick={() =>
                            sendMutation.mutate({
                              consulting_agreement_id: consultingAgreement.id,
                            })
                          }
                        >
                          Send Consulting Agreement
                        </Button>
                      )}
                      <DeleteConsultingAgreementButton
                        consultingAgreement={consultingAgreement}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
