import { Link as ReactRouterLink } from "react-router-dom";
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import { Panel } from "src/components/Panel";

export const SummaryPanel = () => {
  return (
    <Panel title="Summary">
      <VStack>
        <LinkBox
          bg="blackAlpha.100"
          p={3}
          borderRadius="md"
          _hover={{ bg: "blackAlpha.200" }}
          width="fit-content"
          minWidth="100%"
        >
          <LinkOverlay
            fontWeight="bold"
            as={ReactRouterLink}
            to={`/consulting-agreement-builder`}
          >
            Create a new consulting agreement
          </LinkOverlay>
        </LinkBox>
      </VStack>
    </Panel>
  );
};
