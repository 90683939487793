import { Link as RRLink } from "react-router-dom";
import { useEffect, useRef, useState, ChangeEvent } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Code,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { GroupsInput } from "src/components/GroupsInput";
import {
  useCreateAdminGroupsMutation,
  useDeleteAdminGroupsMutation,
} from "src/api/mutations/adminGroups";
import {
  Client,
  useAdminClientsQuery,
} from "src/api/queries/fetchAdminClients";
import {
  useAdminDeleteClientMutation,
  useUpdateClientMutation,
} from "src/api/mutations/adminClients";
import { MutationFeedback } from "src/components/MutationFeedback";

export const ClientsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    query: null | string;
  }>({ page: 0, query: null });

  const query = useAdminClientsQuery({
    query: state.query,
    page: state.page,
    size: 10,
  });

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query: e.target.value,
    }));
  };

  return (
    <Panel
      title="Clients"
      query={query}
      searchState={state.query ? state.query : ""}
      searchCallback={searchCallback}
    >
      {(data) => (
        <>
          <Table size="sm" mb={4}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Groups</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((client) => (
                <Tr key={client.id}>
                  <Td>
                    <Flex alignItems="center" gridGap={1}>
                      {client.name}
                      <RenameClientButton client={client} />
                    </Flex>
                  </Td>
                  <Td>
                    <Flex
                      gridGap={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack spacing={0}>
                        {client.groups.map((group) => (
                          <Flex key={group.id} alignItems="center" gridGap={1}>
                            <Link as={RRLink} to={`/groups/${group.id}`}>
                              {group.name}
                            </Link>
                            <UnlinkGroupButton client={client} group={group} />
                          </Flex>
                        ))}
                      </Stack>
                      <AddGroupsButton client={client} />
                    </Flex>
                  </Td>
                  <Td>
                    <Stack direction="row">
                      <DeleteClientButton client={client} />
                    </Stack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};

const RenameClientButton = ({ client }: { client: Client }) => {
  const [name, setName] = useState(client.name);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateClientMutation();

  const handleUnlink = () => {
    mutation.mutate(
      {
        client_id: client.id,
        name,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setName(client.name);
    }
  }, [isOpen, mutation, client.name]);

  useEffect(() => {
    if (!isOpen && client.name !== name) {
      setName(client.name);
    }
  }, [isOpen, client.name, name]);

  return (
    <>
      <IconButton
        size="xs"
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Rename Client"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>New Name</FormLabel>
                <Input
                  type="name"
                  value={name}
                  onChange={(e) => {
                    const name = e.target.value;
                    setName(name);
                  }}
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUnlink}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const UnlinkGroupButton = ({
  group,
  client,
}: {
  group: Client["groups"][0];
  client: Client;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useDeleteAdminGroupsMutation();

  const handleUnlink = () => {
    mutation.mutate(
      {
        group_id: group.id,
        client_id: client.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <IconButton
        size="xs"
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Remove Group"
        icon={<DeleteIcon />}
        onClick={onOpen}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unlink Group
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to unlink <b>{group.name}</b> from{" "}
              <Code>{client.name}</Code>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleUnlink}
                ml={3}
              >
                Unlink
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const AddGroupsButton = ({ client }: { client: Client }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateAdminGroupsMutation();

  const [groupIds, setGroupsIds] = useState<string[]>([]);
  useEffect(() => {
    setGroupsIds([]);
  }, [isOpen]);

  const handleSubmitLink = () => {
    mutation.mutate(
      {
        client_id: client.id,
        group_ids: groupIds,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="xs"
        aria-label="Add Groups"
        icon={<AddIcon />}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Link Groups to <Code>{client.name}</Code>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Unclaimed Groups</FormLabel>
              <GroupsInput value={groupIds} onChange={setGroupsIds} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                onClick={onClose}
                disabled={mutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="teal"
                disabled={groupIds.length < 1}
                onClick={handleSubmitLink}
                isLoading={mutation.isLoading}
              >
                Link Groups ({groupIds.length})
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteClientButton = ({ client }: { client: Client }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useAdminDeleteClientMutation();

  const handleDelete = () => {
    mutation.mutate(
      {
        client_id: client.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Client
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete client <b>{client.name}</b>?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
