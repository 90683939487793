import { Box, Stack } from "@chakra-ui/react";
import { useAdminNotesQuery } from "src/api/queries/fetchAdminNotes";
import { Panel } from "src/components/Panel";

type NotesPanelProps = {
  minerId: string;
};

export const NotesPanel = ({ minerId }: NotesPanelProps) => {
  const query = useAdminNotesQuery({ miner_id: minerId });

  return (
    <Panel query={query} title="Notes">
      {(data) => (
        <Stack>
          <Box
            borderWidth={1}
            borderRadius="sm"
            bg="gray.50"
            whiteSpace="pre"
            fontSize={"sm"}
            lineHeight="inherit"
            fontFamily="monospace"
            p={4}
            overflow="auto"
            height="18.5rem"
          >
            {data.note}
          </Box>
        </Stack>
      )}
    </Panel>
  );
};
