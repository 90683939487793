import { useQuery } from "react-query";
import { APIResponsePaginated, FormattedNumber } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  page: number;
  size: number;
  employee?: boolean;
};

export type MinerModel = {
  id: number;
  name: string;
  deployment_type: number;
  deployment_type_name: string;
  employee: boolean;
  power_draw: FormattedNumber;
  hashrate?: FormattedNumber;
  quantity?: number;
  created_at: string;
};

export const fetchContractMinerModels = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<MinerModel>>("/contract/miner/models", {
      params: args,
    })
    .then((res) => res.data.data);

export const useContractMinerModelsQuery = (args: QueryArgs) =>
  useQuery(
    ["/contract/miner/models", args],
    () => fetchContractMinerModels(args),
    {
      keepPreviousData: true,
    }
  );
