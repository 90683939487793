import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "../";

export type AdminTaskStatus = "pending" | "accepted" | "rejected";

type QueryArgs = {
  type?: AdminTaskStatus;
  page: number;
  size: number;
};

type AdminTask = {
  id: number;
  user: {
    id: number;
    email: string;
    group_id: number;
    group_name: string;
    payout_address: string;
    payout_coin: string;
  };
  type: string; // reset-2fa ...
  status: AdminTaskStatus;
  created_at: string;
  updated_at: string;
};

export const fetchAdminTasks = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<AdminTask>>("/admin/tasks", { params: args })
    .then((res) => res.data.data);

export const useAdminTasksQuery = (args: QueryArgs) =>
  useQuery(["/admin/tasks", args], () => fetchAdminTasks(args), {
    keepPreviousData: true,
  });
