import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type Phase = {
  phase: number;
  current?: number | null;
  voltage?: number | null;
  power?: number | null;
};

export type Eyedro = {
  id: number;
  location: string;
  host: string;
  name: string;
  enabled: boolean;
  online: boolean;
  phases: Phase[];
  phases_flipped: boolean;
  pf_enabled: boolean;
  current_capacity: number;
  current_warn: number;
  current_alert: number;
  timestamp: number;
};

export const fetchDataEyedros = (
  page: number = 0,
  size: number = 10,
  query?: string,
  networkId?: number,
  online?: boolean
) => {
  let qs = `page=${page}&size=${size}`;
  if (query != null) {
    qs += `&query=${query}`;
  }
  if (networkId != null) {
    qs += `&network_id=${networkId}`;
  }
  if (online != null) {
    qs += `&online=${online}`;
  }

  return apiClient
    .get<APIResponsePaginated<Eyedro>>(`/data/eyedros?${qs}`)
    .then((res) => res.data.data);
};

export const useDataEyedrosQuery = ({
  page = 0,
  size = 10,
  query,
  networkId,
  online,
}: {
  page?: number;
  size?: number;
  query?: string | undefined;
  networkId?: number | undefined;
  online?: boolean | undefined;
} = {}) => {
  return useQuery(
    ["/data/eyedros", page, size, query, networkId, online],
    () => fetchDataEyedros(page, size, query, networkId, online),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
