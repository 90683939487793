import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { AdminTasksPanel } from "./AdminTasksPanel";

export const TasksPage = () => {
  return (
    <Page title="Admin Tasks">
      <PanelRow>
        <AdminTasksPanel />
      </PanelRow>
    </Page>
  );
};
