import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { SummaryPanel } from "./panels/SummaryPanel";
import { ConsultingAgreementsPanel } from "./panels/ConsultingAgreementsPanel";

export const ConsultingAgreementsPage = () => {
  return (
    <Page title="Consulting Agreements">
      <PanelRow>
        <SummaryPanel />
      </PanelRow>
      <PanelRow>
        <ConsultingAgreementsPanel />
      </PanelRow>
    </Page>
  );
};
