import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  first_name: string;
  last_name: string;
  email: string;
};

export const adminInviteAdmin = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/admin", {
    ...body,
  });
};
