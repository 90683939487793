import { useState } from "react";
import { Page } from "src/components/Page";
import { useDataMinerGroupQuery } from "src/api/queries/fetchDataMinerGroup";
import { Panel } from "src/components/Panel";
import { SearchIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { MinerPreviewList } from "src/components/MinerPreviewList";
import { useDebounce } from "src/hooks/useDebounce";

export const ClientMinersPage = () => {
  const query = useDataMinerGroupQuery();
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, 250);

  return (
    <Page title="Miners">
      <Panel query={query}>
        {(data) => (
          <>
            <FormControl mb={4} maxW="30rem">
              <FormLabel>Search Miners</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <MinerPreviewList
              miners={data.miners?.filter((miner) =>
                miner.name.toLowerCase().includes(debouncedFilter.toLowerCase())
              )}
            />
          </>
        )}
      </Panel>
    </Page>
  );
};
