import { Link as ReactRouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { Panel } from "src/components/Panel";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  Stack,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Select,
  Switch,
  Button,
  IconButton,
  Badge,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { SimplePaginator } from "src/components/SimplePaginator";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SearchableItem } from "src/components/SearchableSelect";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { useDataMinerSetsQuery } from "src/api/queries/fetchDataMinerSets";
import {
  BookMinerArgs,
  useBookMinerMutation,
} from "src/api/mutations/bookMiner";
import { ClientSelect } from "./ShipmentsPanel";

const CreateMinerSetPanel = () => {
  const [state, setState] = useState<BookMinerArgs>({
    client_id: 0,
    name: "",
    serial: "",
    expected_hashrate: 1,
  });
  const [clientItem, setClientItem] = useState<SearchableItem | undefined>(
    undefined
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useBookMinerMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({
        client_id: 0,
        name: "",
        serial: "",
        expected_hashrate: 1,
      });
      setClientItem(undefined);
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Book Miner"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Miner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <HStack>
                <FormLabel w="5rem">Client</FormLabel>
                <Box width="20rem">
                  <ClientSelect
                    clientItem={clientItem}
                    setClient={(item: SearchableItem) => {
                      setClientItem(item);
                      setState((s) => ({
                        ...s,
                        client_id: parseInt(item.value),
                      }));
                    }}
                  />
                </Box>
              </HStack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>Serial</FormLabel>
                <Input
                  value={state.serial}
                  onChange={(e) =>
                    setState((s) => ({ ...s, serial: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>MAC</FormLabel>
                <Input
                  value={state.mac_address}
                  onChange={(e) =>
                    setState((s) => ({ ...s, mac_address: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={
                mutation.isLoading ||
                state.name.length === 0 ||
                state.client_id === 0 ||
                state.serial.length === 0
              }
              onClick={() => mutation.mutate(state, { onSuccess: onClose })}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export const MinerSetsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: null | string;
    showMiners: boolean;
  }>({ page: 0, size: 10, query: null, showMiners: false });

  const query = useDataMinerSetsQuery({
    query: state.query,
    page: state.page,
    size: state.size,
  });

  return (
    <Panel title={"Miners"} minW="400px" query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        page: 0,
                        query:
                          e.target.value != null && e.target.value.length > 0
                            ? e.target.value
                            : null,
                      }))
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
              <FormControl maxW="10rem" px={4}>
                <FormLabel>Show Miners</FormLabel>
                <Switch
                  size="lg"
                  isChecked={state.showMiners}
                  onChange={(e) =>
                    setState((s) => ({ ...s, showMiners: e.target.checked }))
                  }
                />
              </FormControl>
            </HStack>
            <CreateMinerSetPanel />
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Client</Th>
                <Th>Contract</Th>
                <Th>MAC Address</Th>
                <Th>Serial</Th>
                <Th>Switch</Th>
                {state.showMiners && <Th>Sub Miner</Th>}
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((minerSet) => (
                <>
                  <Tr key={`miner-set-${minerSet.id}`}>
                    <Td>
                      {minerSet.miner_id != null ? (
                        <Link
                          as={ReactRouterLink}
                          to={`/miners/${minerSet.miner_id}`}
                          fontWeight="bold"
                        >
                          {minerSet.name}
                        </Link>
                      ) : (
                        minerSet.name
                      )}
                    </Td>
                    <Td>{minerSet.client_name}</Td>
                    <Td>{minerSet.contract_name}</Td>
                    <Td>{minerSet.mac}</Td>
                    <Td>{minerSet.serial}</Td>
                    <Td>{minerSet.switch}</Td>
                    {state.showMiners && <Td />}
                    {state.showMiners && <Td />}
                  </Tr>
                  {state.showMiners &&
                    minerSet.miners.map((miner) => (
                      <Tr key={`miner-set-miner-${miner.id}`}>
                        <Td />
                        <Td />
                        <Td />
                        <Td />
                        <Td />
                        <Td />
                        <Td>
                          <Link
                            as={ReactRouterLink}
                            to={`/miners/${miner.id}`}
                            fontWeight="bold"
                          >
                            {miner.name}
                          </Link>
                        </Td>
                        <Td>
                          <VStack>
                            <Text>{miner.ip_address}</Text>
                            <HStack>
                              <Badge
                                variant="solid"
                                colorScheme={miner.enabled ? "green" : "red"}
                              >
                                {miner.enabled ? "enabled" : "disabled"}
                              </Badge>
                              <Badge
                                variant="solid"
                                colorScheme={miner.online ? "green" : "red"}
                              >
                                {miner.online ? "online" : "offline"}
                              </Badge>
                            </HStack>
                          </VStack>
                        </Td>
                      </Tr>
                    ))}
                </>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
