import { Link as ReactRouterLink } from "react-router-dom";
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import {
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  Flex,
  Heading,
  Divider,
} from "@chakra-ui/react";
import {
  useContractsSummaryQuery,
  SingleContractSummaryResponse,
} from "src/api/queries/fetchContractsSummary";
import { Panel } from "src/components/Panel";

const SingleSummaryStatsList = ({
  summary,
}: {
  summary: SingleContractSummaryResponse;
}) => (
  <Flex flexWrap={"wrap"} justifyContent="center">
    <VStack>
      <Heading size="md" mt="2">
        Contracts
      </Heading>
      <StatGroup>
        <Stat mx="6" my="4">
          <StatLabel>Created / Signed / Activated</StatLabel>
          <StatNumber>
            {summary.contracts_created} / {summary.contracts_signed} /{" "}
            {summary.contracts_activated}
          </StatNumber>
        </Stat>
      </StatGroup>
    </VStack>
    <VStack>
      <Heading size="md" mt="2">
        Renewals
      </Heading>
      <StatGroup>
        <Stat mx="6" my="4">
          <StatLabel>Created / Signed / Activated</StatLabel>
          <StatNumber>
            {summary.renewals_created} / {summary.renewals_signed} /{" "}
            {summary.renewals_activated}
          </StatNumber>
        </Stat>
      </StatGroup>
    </VStack>
    <VStack>
      <Heading size="md" mt="2">
        Miners
      </Heading>
      <StatGroup>
        <Stat mx="6" my="4">
          <StatLabel>New / Renewed / Terminated</StatLabel>
          <StatNumber>
            {summary.miners_new_total} / {summary.renewed_new_total} /{" "}
            {summary.terminated_new_total}
          </StatNumber>
        </Stat>
      </StatGroup>
    </VStack>
    <VStack>
      <Heading size="md" mt="2">
        Deposits
      </Heading>
      <StatGroup>
        <Stat mx="6" my="4">
          <StatLabel textAlign="center">Paid / Total</StatLabel>
          <StatNumber>
            ${summary.deposits_paid} / ${summary.deposits_total}
          </StatNumber>
        </Stat>
      </StatGroup>
    </VStack>
  </Flex>
);

export const SummaryPanel = () => {
  const query = useContractsSummaryQuery();

  return (
    <Panel title="Summary" query={query}>
      {(data) => (
        <VStack>
          <Heading size="md">Last 30 Days</Heading>
          <Divider my={2} />
          <SingleSummaryStatsList summary={data.month} />
          <Heading size="md">Last 365 Days</Heading>
          <Divider my={2} />
          <SingleSummaryStatsList summary={data.year} />
          <Divider my={2} />
          <LinkBox
            bg="blackAlpha.100"
            p={3}
            borderRadius="md"
            _hover={{ bg: "blackAlpha.200" }}
            width="fit-content"
            minWidth="100%"
          >
            <LinkOverlay
              fontWeight="bold"
              as={ReactRouterLink}
              to={`/contract-builder`}
            >
              Create a new contract
            </LinkOverlay>
          </LinkBox>
        </VStack>
      )}
    </Panel>
  );
};
