import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  page: number;
  size: number;
  solved?: boolean | null;
  online?: boolean | null;
  is_eok?: boolean | null;
  query?: string | null;
};

type OpenTicket = {
  id: number;
  name: string;
  status: string;
  is_eok: boolean;
  first_content: string;
  last_content?: string;
  last_content_at?: Date;
  client_name?: string;
  miner_id?: string;
  miner_name?: string;
  miner_enabled?: string;
  miner_online?: string;
  miner_online_at?: Date;
  miner_activated_at?: Date;
  created_at: Date;
  modified_at?: Date;
  solved_at?: Date;
};

export const fetchOpenTickets = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<OpenTicket>>("/tickets/glpi/open", {
      params: args,
    })
    .then((res) => res.data.data);

export const useOpenTicketsQuery = (args: QueryArgs) =>
  useQuery(["/tickets/glpi/open", args], () => fetchOpenTickets(args), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
