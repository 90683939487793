import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import {
  Badge,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Thead,
} from "@chakra-ui/react";
import { adminTaskUpdate } from "src/api/mutations/adminTaskUpdate";
import {
  AdminTaskStatus,
  useAdminTasksQuery,
} from "src/api/queries/fetchAdminTasks";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";

export const AdminTasksPanel = () => {
  const [state, setState] = useState<{
    page: number;
    status: AdminTaskStatus | "all";
  }>({ status: "all", page: 0 });

  const query = useAdminTasksQuery({
    type: state.status === "all" ? undefined : state.status,
    page: state.page,
    size: 10,
  });

  const qc = useQueryClient();
  const actionMutation = useMutation(adminTaskUpdate, {
    onSettled: () => {
      qc.invalidateQueries("/admin/tasks");
    },
  });

  return (
    <Panel flex={3} query={query}>
      {(data) => (
        <>
          <FormControl id="status" mb={4} display="flex" alignItems="center">
            <FormLabel my={0}>Task Status</FormLabel>
            <Select
              maxW="10rem"
              flex="1"
              value={state.status}
              onChange={(e) => {
                setState((s) => ({
                  ...s,
                  page: 0,
                  status: e.target.value as AdminTaskStatus,
                }));
              }}
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
            </Select>
          </FormControl>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>Type</Th>
                <Th>User</Th>
                <Th>Status</Th>
                <Th>Created At</Th>
                <Th>Updated At</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((task) => (
                <Tr key={task.id}>
                  <Td textTransform="capitalize">
                    {task.type.replace("-", " ")}
                  </Td>
                  <Td>{task.user.email}</Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={
                        task.status === "accepted"
                          ? "green"
                          : task.status === "rejected"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {task.status}
                    </Badge>
                  </Td>
                  <Td>{new Date(task.created_at).toLocaleString()}</Td>
                  <Td>{new Date(task.updated_at).toLocaleString()}</Td>
                  <Td>
                    {task.status === "pending" && (
                      <Stack direction="row">
                        <Button
                          size="xs"
                          isLoading={!actionMutation.isIdle}
                          onClick={() => {
                            actionMutation.mutate({
                              task: task.id,
                              action: "accept",
                            });
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          size="xs"
                          isLoading={!actionMutation.isIdle}
                          onClick={() => {
                            actionMutation.mutate({
                              task: task.id,
                              action: "reject",
                            });
                          }}
                        >
                          Reject
                        </Button>
                      </Stack>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
