import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  email: string;
  token: string;
};

export const authConfirmReset2FA = async (body: PostBody) => {
  return apiClient
    .post<
      APIResponse<{
        "2fa_seed": string;
      }>
    >("/auth/confirm_reset_2fa", body)
    .then((res) => res.data.data);
};
