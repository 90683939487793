import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { CombinedBuilderPanel } from "./panels/CombinedBuilderPanel";

export const CombinedBuilderPage = () => {
  return (
    <Page title="Combined Client Builder" maxW={"contrainer.2xl"}>
      <PanelRow>
        <CombinedBuilderPanel />
      </PanelRow>
    </Page>
  );
};
