import { useQuery } from "react-query";
import { APIResponse, APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  start_date?: Date;
  end_date?: Date;
  term?: number | null;
  status?: number | null;
  query?: string | null;
  page: number;
  size: number;
  sort_by_client?: boolean | null;
  batched?: boolean | null;
  overdue?: boolean | null;
  checked?: boolean | null;
};

type InvoiceMinerTicket = {
  id: number;
  status: string;
  start_date: Date;
  solve_date?: Date;
};

export type InvoiceMiner = {
  id: number;
  miner_id?: string;
  name?: string;
  online?: boolean;
  uptime?: number;
  coverage?: number;
  internal_uptime?: number;
  internal_coverage?: number;
  uptime_discount?: number;
  activation_discount?: number;
  bitfix_discount?: number;
  unpaid_discount?: number;
  note?: string;
  uptime_checked: boolean;
  bitfix_checked: boolean;
  is_unpaid: boolean;
  is_bitfix: boolean;
  activated_at?: Date;
  tickets: InvoiceMinerTicket[];
};

export type InvoiceMinerGroup = {
  id: number;
  name: string;
  power_draw: number;
  activated: number;
  total: number;
  monthly_cost: string;
  total_monthly_cost: string;
  miners: InvoiceMiner[];
};

export type Invoice = {
  id: number;
  quickbooks_id: string;
  file_id?: string;
  contract_id: number;
  contract_name: string;
  contract_active?: boolean;
  contract_miners?: boolean;
  contract_online_miners?: boolean;
  contract_mining_miners?: boolean;
  client_name: string;
  name: string;
  idx: number;
  status: string;
  overdue: boolean;
  power_rate: string;
  estimated_cost: string;
  cost: string;
  balance: string;
  miner_count: number;
  online_miner_count: number;
  qbo_miner_count: number;
  uptime?: number;
  checked: boolean;
  invoice_at: string;
  due_at: string;
  groups?: InvoiceMinerGroup[];
};

export type CombinedInvoice = {
  id: number;
  file_id?: string;
  name: string;
  client_id: number;
  client_name: string;
  status: string;
  overdue: boolean;
  cost: string;
  tax: string;
  balance: string;
  checked: boolean;
  sent: boolean;
  invoice_at: Date;
  due_at: Date;
  end_at: Date;
  invoices: Invoice[];
};

export type ShiftedInvoice = {
  id: number;
  quickbooks_id: string;
  combined_idx: number;
  name: string;
  cost: string;
  new_cost: string;
  tax: string;
  factor: number;
  balance: string;
  miner_count: number;
  invoice_at: Date;
  due_at: Date;
  new_due_at?: Date;
  end_at: Date;
  new_end_at?: Date;
  sent_at: Date;
};

export type ShiftedContract = {
  id: number;
  name: string;
  activated_at: Date;
  terminates_at: Date;
  monthly_cost: string;
  activated_cost: string;
  groups: InvoiceMinerGroup[];
  invoices: ShiftedInvoice[];
};

export type ShiftedCombinedInvoice = {
  id: number;
  name: string;
  checksum: string;
  saved: boolean;
  invoice_day: number;
  invoice_date: Date;
  invoice_cost: string;
  next_invoice_date: Date;
  next_invoice_cost: string;
  contracts: ShiftedContract[];
};

export const fetchInvoices = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Invoice>>("/invoices", { params: args })
    .then((res) => res.data.data);

export const useInvoicesQuery = (args: QueryArgs) =>
  useQuery(["/invoices", args], () => fetchInvoices(args), {
    keepPreviousData: true,
  });

export const fetchInvoicesExport = (args: QueryArgs) =>
  apiClient.post<Uint8Array>("/invoices/export", args, {
    responseType: "blob",
  });

export const fetchOverdueInvoices = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Invoice>>("/invoices/overdue", { params: args })
    .then((res) => res.data.data);

export const useOverdueInvoicesQuery = (args: QueryArgs) =>
  useQuery(["/invoices/overdue", args], () => fetchOverdueInvoices(args), {
    keepPreviousData: true,
  });

export const fetchCombinedInvoices = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<CombinedInvoice>>("/invoices/combined", {
      params: args,
    })
    .then((res) => res.data.data);

export const useCombinedInvoicesQuery = (args: QueryArgs) =>
  useQuery(["/invoices/combined", args], () => fetchCombinedInvoices(args), {
    keepPreviousData: true,
  });

export const fetchShiftCombinedInvoices = (
  clientId: number,
  startMonth: number,
  allowSaved: boolean,
  skipInvoiceIds: number[]
) =>
  apiClient
    .get<APIResponse<ShiftedCombinedInvoice>>("/invoices/combined/shift", {
      params: {
        client_id: clientId,
        start_month: startMonth,
        allow_saved: allowSaved,
        skip_invoice_ids:
          skipInvoiceIds.length === 0 ? undefined : skipInvoiceIds.join(","),
      },
    })
    .then((res) => res.data.data);

export const useShiftCombinedInvoicesQuery = (
  clientId: number,
  startMonth: number,
  allowSaved: boolean,
  skipInvoiceIds: number[]
) =>
  useQuery(
    ["/invoices/combined", clientId, startMonth, allowSaved, skipInvoiceIds],
    () =>
      fetchShiftCombinedInvoices(
        clientId,
        startMonth,
        allowSaved,
        skipInvoiceIds
      ),
    {
      keepPreviousData: true,
    }
  );
