import { useState } from "react";
import { BreadcrumbValues, Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { ContractPanel } from "./panels/ContractPanel";
import { MinersPanel } from "./panels/MinersPanel";

export const ActivationPage = () => {
  let crumbs: BreadcrumbValues[] = [
    {
      text: "Contracts",
      to: "/contracts",
    },
    {
      text: "Activation",
      to: undefined,
    },
  ];

  const [clientId, setClientId] = useState<number | null>(null);
  const [contractId, setContractId] = useState<number | null>(null);

  return (
    <Page title={`Activate Miners`} breadcrumbs={crumbs} maxW={"container.2xl"}>
      <PanelRow>
        <ContractPanel
          setClientId={(clientId: number) => setClientId(clientId)}
          setContractId={(contractId: number | null) =>
            setContractId(contractId)
          }
        />
      </PanelRow>
      <PanelRow>
        {clientId != null && contractId != null && (
          <MinersPanel clientId={clientId} contractId={contractId} />
        )}
      </PanelRow>
    </Page>
  );
};
