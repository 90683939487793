import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  unbound?: boolean | null;
  network_id?: number | null;
  client_id?: number | null;
  query?: string | null;
  page: number;
  size: number;
};

export type Miner = {
  id: string;
  name: string;
  client_id?: number | null;
  client_name?: string | null;
  ip_address: string;
  mac_address?: string | null;
  enabled: boolean;
  online: boolean;
};

export const fetchDataMiners = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Miner>>("/data/miner/search", {
      params: args,
    })
    .then((res) => res.data.data);

export const useDataMinersQuery = (args: QueryArgs) =>
  useQuery(["/data/miner/search", args], () => fetchDataMiners(args), {
    keepPreviousData: true,
  });
