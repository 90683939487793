import { useState } from "react";
import { useMutation } from "react-query";
import { Link, Redirect } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  ButtonGroup,
  Text,
  Button,
} from "@chakra-ui/react";
import { User } from "src/types";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SmolStackLayout } from "src/components/SmolStackLayout";
import { authConfirmResetPassword } from "src/api/mutations/authConfirmResetPassword";

type Form = {
  password: string;
};

type Props = {
  user: User | undefined;
};

export const ResetPasswordPage = ({ user }: Props) => {
  const [{ token, email }] = useQueryParams({
    token: StringParam,
    email: StringParam,
  });

  const [form, setForm] = useState<Form>({
    password: "",
  });

  const mutation = useMutation(authConfirmResetPassword);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <SmolStackLayout>
      {mutation.isSuccess ? (
        <Stack spacing={4}>
          <Heading size="md">Password Successfully Updated</Heading>
          <Text>You may now login with your new password.</Text>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      ) : token && email ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            mutation.mutate({
              email,
              token,
              password: form.password,
            });
          }}
        >
          <Stack spacing={4}>
            <Heading size="md">Password Reset</Heading>
            <Text>Please enter your new password.</Text>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={form.password}
                onChange={(e) => {
                  const password = e.target.value;
                  setForm((f) => ({
                    ...f,
                    password,
                  }));
                }}
                disabled={mutation.isLoading}
              />
            </FormControl>
            <MutationFeedback mutation={mutation} />
            <ButtonGroup>
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={mutation.isLoading}
                disabled={mutation.isSuccess || mutation.isLoading}
              >
                Submit
              </Button>
            </ButtonGroup>
          </Stack>
        </form>
      ) : (
        <Stack spacing={4}>
          <Text>Something went wrong!</Text>
          <ButtonGroup>
            <Button colorScheme="teal" as={Link} to="/">
              Return to Login
            </Button>
          </ButtonGroup>
        </Stack>
      )}
    </SmolStackLayout>
  );
};
