import { AxiosInstance } from "axios";
import { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { History } from "history";
import {
  getAccessToken,
  getRefreshToken,
  setTokens,
} from "src/lib/tokenStorage";
import { apiClient } from "./";

export const RegisterAxiosInterceptors: FC = ({ children }) => {
  const history = useHistory();
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    const ejectInterceptors = registerInterceptors(apiClient, history);
    setRegistered(true);
    return ejectInterceptors;
  }, [history]);

  if (registered) {
    return <>{children}</>;
  } else {
    return null;
  }
};

const registerInterceptors = (instance: AxiosInstance, history: History) => {
  const requestEjectionMarker = instance.interceptors.request.use((config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  });

  const responseEjectionMarker = instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      if (
        error.response?.status === 401 &&
        originalRequest.url === "/auth/refresh"
      ) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          return instance
            .post<any>("/auth/refresh", {
              refresh_token: refreshToken,
            })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // 1) put token to LocalStorage
                setTokens(
                  res.data.data.tokens.auth,
                  res.data.data.tokens.refresh
                );

                // 2) Change Authorization header
                instance.defaults.headers.common["Authorization"] =
                  "Bearer " + getAccessToken();

                // 3) return originalRequest object with instance.
                return instance(originalRequest);
              }
            });
        }
      }

      return Promise.reject(error);
    }
  );

  const ejectInterceptors = () => {
    instance.interceptors.request.eject(requestEjectionMarker);
    instance.interceptors.response.eject(responseEjectionMarker);
  };

  return ejectInterceptors;
};
