import { useState } from "react";
import {
  HStack,
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { useInvoiceEventsQuery } from "src/api/queries/fetchInvoiceEvents";

export const EventsPanel = ({ invoiceId }: { invoiceId: number }) => {
  const [state, setState] = useState<{
    types: number[];
    page: number;
    size: number;
  }>({
    types: [],
    page: 0,
    size: 10,
  });

  const query = useInvoiceEventsQuery({
    invoice_id: invoiceId,
    page: state.page,
    size: state.size,
  });

  return (
    <Panel title={"Events"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify="flex-start">
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th>Type</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Timestamp</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((view) => (
                <Tr>
                  <Td>{view.type}</Td>
                  <Td>
                    {view.user_first_name} {view.user_last_name}
                  </Td>
                  <Td>{view.user_email}</Td>
                  <Td>{new Date(view.created_at).toLocaleString()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
