import { Line } from "src/api/queries/fetchDataPDU";
import { Divider, Text, HStack } from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import {
  StatGroup,
  Stat,
  Box,
  StatLabel,
  StatNumber,
  StatHelpText,
} from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon, WarningTwoIcon } from "@chakra-ui/icons";

const formatColor = (value: number, warn: number, alert: number) => {
  if (value === 0) {
    return "gray";
  } else if (value >= alert) {
    return "red";
  } else if (value >= warn) {
    return "#D69E2E";
  }
  return undefined;
};

export const LinesPanel = ({ lines }: { lines: Line[] }) => {
  const size = 6;
  const count = lines.length;

  let groups: Line[][] = [];
  for (let i = 0; i < count; i += size) {
    let group: Line[] = [];
    for (let j = i; j < i + size; j++) {
      if (j < count) {
        group.push(lines![j]);
      }
    }
    groups.push(group);
  }

  return (
    <Panel title={"Lines"}>
      {groups.map((group, idx) => (
        <Box key={`group-${idx}`}>
          {idx !== 0 && <Divider />}
          <StatGroup my={4}>
            {group.map((line) => {
              const color =
                line.current == null
                  ? "gray"
                  : formatColor(
                      line.current,
                      line.current_warn,
                      line.current_alert
                    );
              const percent =
                line.current == null
                  ? ""
                  : ((line.current / (line.current_alert / 0.9)) * 100).toFixed(
                      1
                    ) + "%";

              return (
                <Stat key={`stat-${idx}-${line.id}`}>
                  <StatLabel>{line.name}</StatLabel>
                  <StatNumber color={color}>
                    {line.current != null
                      ? line.current.toFixed(2) + " A"
                      : "N/A"}
                  </StatNumber>
                  <StatHelpText>
                    <HStack>
                      <Text>{percent}</Text>
                      {line.current != null &&
                        (line.current >= line.current_warn ? (
                          <InfoIcon />
                        ) : line.current >= line.current_alert ? (
                          <WarningTwoIcon />
                        ) : (
                          <CheckCircleIcon />
                        ))}
                    </HStack>
                  </StatHelpText>
                </Stat>
              );
            })}
          </StatGroup>
        </Box>
      ))}
    </Panel>
  );
};
