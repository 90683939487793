import { Link as ReactRouterLink } from "react-router-dom";
import { LinkBox, LinkOverlay, Box, Flex, Divider } from "@chakra-ui/react";
import { MinerGroupPreviewResponse } from "src/api/queries/fetchDataFarm";
import { Dollars } from "./Dollars";
import { Temperature } from "./Temperature";

type MinerGroupPreviewProps = {
  group: MinerGroupPreviewResponse;
  isClient?: boolean;
};
export const MinerGroupPreview = ({
  group,
  isClient = false,
}: MinerGroupPreviewProps) => {
  return (
    <LinkBox
      maxW="24rem"
      bg="blackAlpha.100"
      p={3}
      minW="100%"
      borderRadius="md"
      _hover={{ bg: "blackAlpha.200" }}
    >
      <Flex justifyContent="space-between">
        <LinkOverlay
          as={ReactRouterLink}
          to={isClient ? `/contracts/${group.id}` : `/groups/${group.id}`}
          fontWeight="bold"
        >
          {group.name}
        </LinkOverlay>
        <Box>
          {group.running_count}/{group.total_count}
        </Box>
      </Flex>
      <Divider borderColor="blackAlpha.500" my={1} />
      <Flex justifyContent="space-between">
        <Box>
          <Box>
            Avg: <Temperature value={group.avg_temperature.formatted} />
          </Box>
          <Box>{group.hashrate.formatted}</Box>
          <Box>
            <Dollars value={group.daily_revenue.formatted} />
          </Box>
        </Box>
        <Box textAlign="right">
          <Box>
            High: <Temperature value={group.max_temperature.formatted} />
          </Box>
          <Box>{group.power_usage.formatted}</Box>
        </Box>
      </Flex>
    </LinkBox>
  );
};
