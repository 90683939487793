import { useRef, useEffect } from "react";
import {
  AsyncSelect,
  AsyncCreatableSelect,
  chakraComponents,
  SelectInstance,
} from "chakra-react-select";

export type SearchableItem = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  placeholder: string;
  value?: SearchableItem;
  defaultOptions?: SearchableItem[];
  loadOptions: (query: string) => Promise<SearchableItem[]>;
  onSelect: (item: SearchableItem) => void;
  onCreate?: (input: string) => void;
  validateCreate?: (input: string) => boolean;
  shouldClear?: boolean;
  isDisabled?: boolean;
};

export const SearchableSelect = ({
  name,
  placeholder,
  value,
  defaultOptions,
  loadOptions,
  onSelect,
  onCreate,
  validateCreate,
  shouldClear = false,
  isDisabled = false,
}: Props) => {
  const selectRef = useRef<SelectInstance>(null);

  useEffect(() => {
    if (shouldClear === true) {
      if (selectRef?.current != null) {
        selectRef.current.clearValue();
      }
    }
  }, [shouldClear]);

  const components = {
    LoadingIndicator: (props: any) => (
      <chakraComponents.LoadingIndicator
        color="currentColor"
        emptyColor="transparent"
        spinnerSize="md"
        speed="0.45s"
        thickness="2px"
        {...props}
      />
    ),
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  if (onCreate != null) {
    return (
      <AsyncCreatableSelect
        ref={selectRef}
        name={name}
        placeholder={placeholder}
        components={components}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
        }}
        value={value}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={(e: any) => {
          if (e != null) {
            onSelect({ value: e.value, label: e.label });
          }
        }}
        isValidNewOption={validateCreate}
        onCreateOption={onCreate}
        allowCreateWhileLoading={true}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <AsyncSelect
      ref={selectRef}
      name={name}
      placeholder={placeholder}
      components={components}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
      }}
      value={value}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={(e: any) => {
        if (e != null) {
          onSelect({ value: e.value, label: e.label });
        }
      }}
      isDisabled={isDisabled}
    />
  );
};
