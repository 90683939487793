import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  HStack,
  Link,
  FormControl,
  FormLabel,
  Thead,
  Badge,
} from "@chakra-ui/react";
import { networks } from "src/types";
import { useDataFlaggedMinersQuery } from "src/api/queries/fetchDataFlaggedMiners";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";

export const FlaggedMinersPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    status: string;
    period: number;
    network_id: number;
    threshold: number;
  }>({
    page: 0,
    size: 10,
    period: 1,
    status: "underhashing",
    network_id: -1,
    threshold: 0.95,
  });

  const query = useDataFlaggedMinersQuery({
    page: state.page,
    size: state.size,
    period: state.period,
    network_id: state.network_id === -1 ? null : state.network_id,
    threshold: state.threshold,
  });

  return (
    <Panel title={"Flagged Miners"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"start"}>
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
            <FormControl maxW="15rem">
              <FormLabel>Status</FormLabel>
              <Select
                value={state.status}
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    page: 0,
                    status: e.target.value,
                    threshold:
                      e.target.value === "nothashing"
                        ? 0
                        : e.target.value === "underhashing"
                        ? 0.95
                        : 1.05,
                  }))
                }
              >
                <option value={"underhashing"}>Under Hashing</option>
                <option value={"overhashing"}>Over Hashing</option>
                <option value={"nothashing"}>Not Hashing</option>
              </Select>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Period</FormLabel>
              <Select
                value={state.period}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (!isNaN(value)) {
                    setState((s) => ({
                      ...s,
                      page: 0,
                      period: value,
                    }));
                  }
                }}
              >
                <option value={"0"}>1 Minute</option>
                <option value={"1"}>5 Minute</option>
                <option value={"2"}>Hourly</option>
                <option value={"3"}>Daily</option>
              </Select>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Network</FormLabel>
              <Select
                value={state.network_id}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    network_id: parseInt(e.target.value),
                  }))
                }
              >
                <option value={-1}>All</option>
                {networks.map((network) => (
                  <option key={`network-${network.id}`} value={network.id}>
                    {network.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Uptime Threshold</FormLabel>
              <Select
                value={state.threshold}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value)) {
                    setState((s) => ({
                      ...s,
                      page: 0,
                      threshold: value,
                    }));
                  }
                }}
                disabled={state.status === "nothashing"}
              >
                {state.status === "underhashing" && (
                  <>
                    <option value={"0.99"}>99%</option>
                    <option value={"0.98"}>98%</option>
                    <option value={"0.97"}>97%</option>
                    <option value={"0.96"}>96%</option>
                    <option value={"0.95"}>95%</option>
                    <option value={"0.9"}>90%</option>
                    <option value={"0.75"}>75%</option>
                    <option value={"0.5"}>50%</option>
                  </>
                )}
                {state.status === "overhashing" && (
                  <>
                    <option value={"1.01"}>101%</option>
                    <option value={"1.02"}>102%</option>
                    <option value={"1.03"}>103%</option>
                    <option value={"1.04"}>104%</option>
                    <option value={"1.05"}>105%</option>
                    <option value={"1.1"}>110%</option>
                    <option value={"1.25"}>125%</option>
                    <option value={"1.5"}>150%</option>
                  </>
                )}
              </Select>
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Client</Th>
                <Th>IP Address</Th>
                <Th>Online</Th>
                <Th>Uptime</Th>
                <Th>Hashrate</Th>
                <Th>Expected Hashrate</Th>
                <Th>Hashrate Ratio</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((miner) => {
                return (
                  <Tr key={`miner-${miner.id}`}>
                    <Td>
                      <Link
                        as={ReactRouterLink}
                        to={`/miners/${miner.id}`}
                        fontWeight="bold"
                      >
                        {miner.id}
                      </Link>
                    </Td>
                    <Td>{miner.name}</Td>
                    <Td>{miner.client_name}</Td>
                    <Td>
                      <a
                        href={`http://${miner.ip_address}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {miner.ip_address}
                      </a>
                    </Td>
                    <Td>
                      <Badge
                        variant="solid"
                        colorScheme={miner.online ? "green" : "red"}
                      >
                        {miner.online ? "online" : "offline"}
                      </Badge>
                    </Td>
                    <Td>{miner.uptime}</Td>
                    <Td>{miner.hashrate.formatted}</Td>
                    <Td>{miner.expected_hashrate.formatted}</Td>
                    <Td>{miner.hashrate_ratio.formatted}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
