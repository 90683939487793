import { Link as ReactRouterLink } from "react-router-dom";
import {
  Badge,
  Box,
  HStack,
  Grid,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { useInvoiceQuery } from "src/api/queries/fetchInvoice";

export const SummaryPanel = ({ invoiceId }: { invoiceId: number }) => {
  const query = useInvoiceQuery(invoiceId);

  return (
    <Panel title={"Summary"} query={query}>
      {(data) => (
        <>
          <Grid
            fontSize="sm"
            templateColumns="2fr repeat(4, 1fr)"
            gap={2}
            minW="40rem"
          >
            <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              <Box>
                <i>{data.client_name}</i>
              </Box>
              <Box>
                <b>Contract:</b> {data.contract_name}
              </Box>
              <Box>
                <b>Invoice:</b> {data.name}
              </Box>
              <HStack>
                {data.file_id != null && (
                  <LinkBox
                    bg="blackAlpha.100"
                    p={2}
                    borderRadius="md"
                    _hover={{ bg: "blackAlpha.200" }}
                    textAlign="center"
                    minW="5rem"
                  >
                    <LinkOverlay
                      fontWeight="bold"
                      as={ReactRouterLink}
                      to={`/pdf/invoice/${data.file_id}`}
                      isExternal
                    >
                      PDF
                    </LinkOverlay>
                  </LinkBox>
                )}
                {data.quickbooks_id != null && (
                  <LinkBox
                    bg="blackAlpha.100"
                    p={2}
                    borderRadius="md"
                    _hover={{ bg: "blackAlpha.200" }}
                    textAlign="center"
                    minW="5rem"
                  >
                    <LinkOverlay
                      fontWeight="bold"
                      href={`https://app.qbo.intuit.com/app/invoice?txnId=${data.quickbooks_id}`}
                      isExternal
                    >
                      QBO
                    </LinkOverlay>
                  </LinkBox>
                )}
              </HStack>
            </Box>
            <Box>
              <Box>
                <b>Invoice At:</b>{" "}
                {new Date(data.invoice_at).toLocaleDateString()}
              </Box>
              <Box>
                <b>Due At:</b> {new Date(data.due_at).toLocaleDateString()}
              </Box>
              <Box>
                {data.idx === 0 ? (
                  <b>Deposit Invoice</b>
                ) : (
                  <Text>
                    <b>Month:</b> #{data.idx}
                  </Text>
                )}
              </Box>
            </Box>
            <Box>
              <Box>
                <b>Uptime:</b>{" "}
                {data.idx === 0
                  ? "N/A"
                  : data?.uptime != null
                  ? data.uptime!.toFixed(2) + "%"
                  : "0%"}
              </Box>
              <Box minW="100px">
                <b>Power Rate:</b> ${data.power_rate}
              </Box>
              <HStack my={2}>
                <Badge
                  variant="solid"
                  colorScheme={data.overdue ? "red" : "green"}
                >
                  {data.status}
                </Badge>
                <Badge
                  variant="solid"
                  colorScheme={data.checked ? "green" : "red"}
                >
                  {data.checked ? "checked" : "unchecked"}
                </Badge>
              </HStack>
            </Box>
            <Box>
              {data.idx > 0 && (
                <Box minW="100px">
                  <b>Estimated Cost:</b> ${data.estimated_cost}
                </Box>
              )}
              <Box minW="100px">
                <b>Invoice Cost:</b> ${data.cost}
              </Box>
              <Box minW="100px">
                <b>Invoice Balance:</b> ${data.balance}
              </Box>
            </Box>
            <Box></Box>
          </Grid>
        </>
      )}
    </Panel>
  );
};
