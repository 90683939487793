import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export const useUpdateInvoiceMinerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: {
      invoice_id: number;
      miner_ids: number[];
      uptime?: number;
      coverage?: number;
      note?: string;
      uptime_checked?: boolean;
      bitfix_checked?: boolean;
    }) => {
      return apiClient.post<APIResponse<null>>("/invoice/miner", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/invoice");
      await queryClient.invalidateQueries("/invoices");
      await queryClient.invalidateQueries("/invoices/summary");
      await queryClient.invalidateQueries("/invoices/combined");
      await queryClient.invalidateQueries("/invoices/combined/shift");
    },
  });
};
