import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  task: number; // task id
  action: "accept" | "reject";
};

export const adminTaskUpdate = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/admin/tasks", {
    ...body,
  });
};
