import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query?: string | null;
  ticketed?: boolean | null;
  page: number;
  size: number;
};

export type Miner = {
  id: string;
  name: string;
  ip_address: string;
  mac_address: string;
  client_id?: number;
  client_name?: string;
  enabled: boolean;
  online: boolean;
};

export type MinerSet = {
  id: string;
  name: string;
  miner_id?: string;
  mac_id?: number;
  glpi_id?: number;
  client_id?: number;
  client_name?: string;
  contract_id?: number;
  contract_name?: string;
  serial?: string;
  mac?: string;
  switch?: string;
  miners: Miner[];
};

export const fetchDataMinerSets = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<MinerSet>>("/data/miner/sets", {
      params: args,
    })
    .then((res) => res.data.data);

export const useDataMinerSetsQuery = (args: QueryArgs) =>
  useQuery(["/data/miner/sets", args], () => fetchDataMinerSets(args), {
    keepPreviousData: true,
  });
