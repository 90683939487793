import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { Panel } from "src/components/Panel";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Link,
  Badge,
  IconButton,
  Box,
  Flex,
  Select,
  Stack,
  HStack,
  VStack,
  Button,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  SearchableItem,
  SearchableSelect,
} from "src/components/SearchableSelect";
import { MultiSelectItem, MultiSelect } from "src/components/MultiSelect";
import {
  useTicketsQuery,
  fetchTickets,
  Ticket,
} from "src/api/queries/fetchTickets";
import {
  useTicketCategoriesQuery,
  TicketCategory,
} from "src/api/queries/fetchTicketCategories";
import {
  CreateTicketArgs,
  useCreateTicketMutation,
} from "src/api/mutations/ticketCreate";
import {
  MinerSet,
  useDataMinerSetsQuery,
  fetchDataMinerSets,
} from "src/api/queries/fetchDataMinerSets";
import { RadioCardGroup } from "src/components/RadioCard";
import { ticketStatuses } from "src/types";
import { SimplePaginator } from "src/components/SimplePaginator";
import { SearchIcon, CheckIcon } from "@chakra-ui/icons";
import { MutationFeedback } from "src/components/MutationFeedback";

const CreateTicketButton = () => {
  const [state, setState] = useState<CreateTicketArgs>({
    client_id: 0,
    miner_set_id: 0,
    name: "",
    categories: [],
    costs: [],
  });
  const [clientName, setClientName] = useState<string | undefined>(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateTicketMutation();
  const categoriesQuery = useTicketCategoriesQuery();

  const resetState = useCallback(() => {
    mutation.reset();
    setState({
      client_id: 0,
      miner_set_id: 0,
      name: "",
      categories: [],
      costs: [],
    });
    setClientName(undefined);
  }, [mutation]);

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      resetState();
    }
  }, [isOpen, mutation, resetState]);

  const [previousTickets, setPreviousTickets] = useState<Ticket[]>([]);
  useEffect(() => {
    const fetchPreviousTickets = async () => {
      const tickets = await fetchTickets({
        query: "",
        page: 0,
        size: 3,
        miner_set_id: state.miner_set_id,
      });
      setPreviousTickets(tickets.items);
    };

    if (state.miner_set_id !== 0) {
      fetchPreviousTickets();
    }
  }, [state.miner_set_id, setPreviousTickets]);

  const defaultQuery = useDataMinerSetsQuery({
    page: 0,
    size: 50,
  });
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  const [minerOptions, setMinerOptions] = useState<MinerSet[]>([]);
  useEffect(() => {
    if (defaultQuery?.data != null) {
      const items = defaultQuery.data.items.map((item: any) => {
        return { value: item.id, label: item.name };
      });
      setDefaultOptions(items);
      setMinerOptions(defaultQuery.data.items);
    }
  }, [defaultQuery.data]);

  const loadSelectOptions = async (query: string) => {
    const data = await fetchDataMinerSets({
      query: query,
      page: 0,
      size: 50,
    });
    setMinerOptions(data.items);
    return data.items.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  return (
    <Stack>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Ticket"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        size="xl"
        onClose={() => {
          resetState();
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Create Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <Box p={1} w="full" borderRadius="md" textAlign="center">
                  <SearchableSelect
                    name={"miners"}
                    placeholder={"Select a miner"}
                    defaultOptions={defaultOptions}
                    loadOptions={loadSelectOptions}
                    onSelect={(item: SearchableItem) => {
                      const miner = minerOptions.find(
                        (miner) => miner.id === item.value
                      );
                      if (miner != null && miner.client_id != null) {
                        setState((s) => ({
                          ...s,
                          miner_set_id: parseInt(miner.id),
                          client_id: miner.client_id!,
                        }));
                        setClientName(
                          miner.client_name != null
                            ? miner.client_name
                            : undefined
                        );
                      }
                    }}
                  />
                </Box>
              </FormControl>
              <VStack m="auto" p="4">
                <FormLabel>Previous Tickets</FormLabel>
                <Box overflow={"scroll"}>
                  <Table size="sm" mb={4} variant={"striped"}>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Status</Th>
                        <Th>Created At</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {previousTickets.map((ticket) => (
                        <>
                          <Tr>
                            <Td>
                              <Link
                                as={ReactRouterLink}
                                to={`/tickets/${ticket.id}`}
                                fontWeight="bold"
                              >
                                {ticket.name}
                              </Link>
                            </Td>
                            <Td>{ticket.status_name}</Td>
                            <Td>
                              {new Date(ticket.created_at).toLocaleDateString()}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={3}>
                              <Flex justifyContent="center">
                                {ticket.categories.map((category) => (
                                  <Badge
                                    variant="solid"
                                    colorScheme={"red"}
                                    m={1}
                                    key={`cost-status-${category.id}`}
                                  >
                                    {category.name}
                                  </Badge>
                                ))}
                                {ticket.costs
                                  ?.filter(
                                    (cost) =>
                                      !cost.name
                                        .toLowerCase()
                                        .includes("logistics")
                                  )
                                  .map((cost) => (
                                    <Badge
                                      variant="solid"
                                      colorScheme={"green"}
                                      m={1}
                                      key={`cost-status-${cost.id}`}
                                    >
                                      {cost.name}
                                    </Badge>
                                  ))}
                              </Flex>
                            </Td>
                          </Tr>
                        </>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </VStack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      name: e.target.value,
                    }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="client">
                <FormLabel>Client</FormLabel>
                <Input
                  value={clientName == null ? "No Client" : clientName}
                  isDisabled={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Categories</FormLabel>
                <Select
                  placeholder={"Add a category"}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      categories: [
                        ...state.categories,
                        parseInt(e.target.value),
                      ],
                    }))
                  }
                >
                  {categoriesQuery.data?.items
                    .filter(
                      (category) =>
                        state.categories.find(
                          (categoryId) => categoryId === category.id
                        ) == null
                    )
                    .map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <Flex flexWrap="wrap" justify="center">
                {state.categories.map((categoryId) => {
                  const category = categoriesQuery.data?.items.find(
                    (category) => category.id === categoryId
                  );
                  if (category == null) {
                    return <div key={`category-select-${categoryId}`} />;
                  }

                  return (
                    <HStack key={`category-select-${categoryId}`} mx={2} my={1}>
                      <IconButton
                        size="xs"
                        colorScheme="gray"
                        variant="ghost"
                        aria-label="Delete Category"
                        icon={<CloseIcon />}
                        onClick={() =>
                          setState((s) => ({
                            ...s,
                            categories: state.categories.filter(
                              (categoryId) => categoryId !== category.id
                            ),
                          }))
                        }
                      />
                      <Badge variant="solid" colorScheme={"green"}>
                        {category.name}
                      </Badge>
                    </HStack>
                  );
                })}
              </Flex>
              <FormControl id="message">
                <FormLabel>Message</FormLabel>
                <Textarea
                  value={state.message}
                  onChange={(e) =>
                    setState((s) => ({ ...s, message: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={mutation.isLoading}
              onClick={() => {
                resetState();
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={
                mutation.isLoading ||
                state.client_id === 0 ||
                state.miner_set_id === 0 ||
                state.name.length === 0
              }
              onClick={() => mutation.mutate(state, { onSuccess: onClose })}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export const TicketsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: null | string;
    miners_online: null | boolean;
    checked: null | boolean;
  }>({ page: 0, size: 10, query: null, miners_online: null, checked: null });

  const [selectedStatuses, setSelectedStatuses] = useState<MultiSelectItem[]>(
    ticketStatuses
      .filter(
        (ticketStatus) =>
          ticketStatus.name.toLowerCase() !== "completed" &&
          ticketStatus.name.toLowerCase() !== "billed"
      )
      .map((ticketStatus) => {
        return { value: String(ticketStatus.id), label: ticketStatus.name };
      })
  );
  const [selectedCategories, setSelectedCategories] = useState<
    MultiSelectItem[]
  >([]);
  const [ticketCategories, setTicketCategories] = useState<TicketCategory[]>(
    []
  );

  const categoriesQuery = useTicketCategoriesQuery();
  useEffect(() => {
    if (categoriesQuery?.data != null) {
      setTicketCategories(categoriesQuery.data.items);
    }
  }, [categoriesQuery.data]);

  const ticketsQuery = useTicketsQuery({
    query: state.query,
    page: state.page,
    size: state.size,
    status_ids:
      selectedStatuses.length === 0
        ? undefined
        : selectedStatuses.map((status) => parseInt(status.value)).join(","),
    category_ids:
      selectedCategories.length === 0
        ? undefined
        : selectedCategories
            .map((category) => parseInt(category.value))
            .join(","),
    miners_online:
      state.miners_online == null ? undefined : state.miners_online,
    checked: state.checked == null ? undefined : state.checked,
  });

  return (
    <Panel title={"Tickets"} query={ticketsQuery}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl minW="10rem" maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        page: 0,
                        query:
                          e.target.value != null && e.target.value.length > 0
                            ? e.target.value
                            : null,
                      }))
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl minW="10rem" maxW="20rem">
                <FormLabel>Status</FormLabel>
                <MultiSelect
                  name={"status"}
                  placeholder={"Select a status"}
                  values={selectedStatuses}
                  options={ticketStatuses.map((item) => {
                    return { value: String(item.id), label: item.name };
                  })}
                  onSelect={(items) => setSelectedStatuses(items)}
                />
              </FormControl>
              <FormControl minW="12rem" maxW="20rem">
                <FormLabel>Category</FormLabel>
                <MultiSelect
                  name={"category"}
                  placeholder={"Select a category"}
                  values={selectedCategories}
                  options={ticketCategories.map((item) => {
                    return { value: String(item.id), label: item.name };
                  })}
                  onSelect={(items) => setSelectedCategories(items)}
                />
              </FormControl>
              <FormControl maxW="20rem">
                <FormLabel textAlign="center">Miner Status</FormLabel>
                <RadioCardGroup
                  keys={["All", "Online", "Offline"]}
                  value={
                    state.miners_online == null
                      ? "All"
                      : state.miners_online
                      ? "Online"
                      : "Offline"
                  }
                  onChange={(e) =>
                    setState((f) => ({
                      ...f,
                      page: 0,
                      miners_online:
                        e === "All" ? null : e === "Online" ? true : false,
                    }))
                  }
                />
              </FormControl>
              <FormControl maxW="20rem">
                <FormLabel textAlign="center">Checked Status</FormLabel>
                <RadioCardGroup
                  keys={["All", "Checked", "Unchecked"]}
                  value={
                    state.checked == null
                      ? "All"
                      : state.checked
                      ? "Checked"
                      : "Unchecked"
                  }
                  onChange={(e) =>
                    setState((f) => ({
                      ...f,
                      page: 0,
                      checked:
                        e === "All" ? null : e === "Checked" ? true : false,
                    }))
                  }
                />
              </FormControl>
              <FormControl minW="5rem" maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
            </HStack>
            <CreateTicketButton />
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Client</Th>
                <Th>Creator</Th>
                <Th>Miner</Th>
                <Th>Status</Th>
                <Th>Categories</Th>
                <Th>Cost</Th>
                <Th>Created At</Th>
                <Th>Completed At</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((ticket) => (
                <Tr key={`ticket-${ticket.id}`}>
                  <Td>
                    <HStack>
                      <Link
                        as={ReactRouterLink}
                        to={`/tickets/${ticket.id}`}
                        fontWeight="bold"
                      >
                        {ticket.name}
                      </Link>
                      {ticket.checked && (
                        <CheckIcon color={"green"} boxSize={6} />
                      )}
                    </HStack>
                  </Td>
                  <Td>{ticket.client_name}</Td>
                  <Td>{ticket.creator_name}</Td>
                  <Td>
                    <Link
                      as={ReactRouterLink}
                      to={`/miners/${ticket.miner_id}`}
                      fontWeight="bold"
                    >
                      {ticket.miner_name}
                    </Link>
                  </Td>
                  <Td>{ticket.status_name}</Td>
                  <Td>
                    {ticket.categories.map((category) => (
                      <Badge
                        variant="solid"
                        colorScheme={"green"}
                        key={`category-status-${category.id}`}
                      >
                        {category.name}
                      </Badge>
                    ))}
                  </Td>
                  <Td>${ticket.cost}</Td>
                  <Td>{new Date(ticket.created_at).toLocaleString()}</Td>
                  <Td>
                    {ticket.completed_at != null &&
                      new Date(ticket.completed_at).toLocaleString()}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {ticketsQuery.data && ticketsQuery.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
