import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import {
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
  HStack,
  Select,
} from "@chakra-ui/react";
import { Page } from "src/components/Page";
import { Panel } from "src/components/Panel";
import { useDataFarmQuery } from "src/api/queries/fetchDataFarm";
import { SearchIcon } from "@chakra-ui/icons";
import { MinerGroupPreviewList } from "src/components/MinerGroupPreviewList";

export const MinerGroupsPage = () => {
  const { id: slug } = useParams<{ id: string }>();
  const type = slug != null ? slug : "groups";

  const [filter, setFilter] = useState<string>("");
  const query = useDataFarmQuery(type);
  const history = useHistory();
  const navigate = (path: string) => {
    history.push(path);
  };

  return (
    <Page title="Miner Groups">
      <Panel query={query}>
        {(data) => (
          <>
            <HStack mb={4} justify={"space-between"}>
              <FormControl maxW="10rem">
                <FormLabel>Aggregation Type</FormLabel>
                <Select
                  value={type}
                  onChange={(e) =>
                    e.target.value === "groups"
                      ? navigate("/groups")
                      : navigate("/groups/filter/" + e.target.value)
                  }
                >
                  <option value={"groups"}>Group</option>
                  <option value={"clients"}>Client</option>
                  <option value={"contracts"}>Contract</option>
                </Select>
              </FormControl>
              <FormControl mb={4} maxW="30rem" pl={1}>
                <FormLabel>Search Miner Groups</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
            </HStack>
            <MinerGroupPreviewList
              groups={data.filter((group) =>
                group.name.toLowerCase().includes(filter.toLowerCase())
              )}
            />
          </>
        )}
      </Panel>
    </Page>
  );
};
