import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  status: number | null;
  page: number;
  size: number;
};

export type ContractMinerGroup = {
  id: number;
  name: string;
  power_draw: number;
  activated: number;
  total: number;
  monthly_cost: string;
};

export type Contract = {
  id: number;
  revision_id: number;
  name: string;
  client_id: number;
  client_name: string;
  client_email?: string;
  subclient_name?: string;
  creator_id: number;
  creator_name: string;
  hellosign_id: string | null;
  file_id: string;
  term: number;
  duration_months: number;
  deposit_months: number;
  pre_payment_months: number;
  power_rate: string;
  wave_deployment_fee: boolean;
  is_batched: boolean;
  active_monthly_cost: string;
  total_monthly_cost: string;
  status: string;
  miner_groups: ContractMinerGroup[];
  created_at: string;
  activated_at?: string;
  terminates_at?: string;
};

export const fetchContracts = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Contract>>("/contracts", { params: args })
    .then((res) => res.data.data);

export const useContractsQuery = (args: QueryArgs) =>
  useQuery(["/contracts", args], () => fetchContracts(args), {
    keepPreviousData: true,
  });
