import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { TicketsPanel } from "./panels/TicketsPanel";
import { TicketKPIsPanel } from "./panels/TicketKPIsPanel";
import { TicketCategoriesPanel } from "./panels/TicketCategoriesPanel";
import { TicketCostModelsPanel } from "./panels/TicketCostModelsPanel";

export const TicketsPage = () => {
  return (
    <Page title="Tickets" maxW={"container.2xl"}>
      <PanelRow>
        <TicketsPanel />
      </PanelRow>
      <PanelRow>
        <TicketKPIsPanel />
      </PanelRow>
      <PanelRow>
        <TicketCategoriesPanel />
      </PanelRow>
      <PanelRow>
        <TicketCostModelsPanel />
      </PanelRow>
    </Page>
  );
};
