import { useQuery } from "react-query";
import { APIResponsePaginated, FormattedNumber } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  page: number;
  size: number;
  period: number;
  threshold: number;
  network_id?: number | null;
};

type FlaggedMiner = {
  id: string;
  name: string;
  client_name?: string;
  ip_address?: string;
  enabled: boolean;
  online: boolean;
  uptime?: string;
  hashrate: FormattedNumber;
  expected_hashrate: FormattedNumber;
  hashrate_ratio: FormattedNumber;
};

export const fetchDataFlaggedMiners = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<FlaggedMiner>>("/data/miner/flagged", {
      params: args,
    })
    .then((res) => res.data.data);

export const useDataFlaggedMinersQuery = (args: QueryArgs) =>
  useQuery(["/data/miner/flagged", args], () => fetchDataFlaggedMiners(args), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
