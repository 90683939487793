import { useState, useEffect } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Text,
  Link,
  Select,
  Flex,
  Stack,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Button,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import {
  SearchableSelect,
  SearchableItem,
} from "src/components/SearchableSelect";
import { MultiSelectItem, MultiSelect } from "src/components/MultiSelect";
import { Datepicker } from "src/components/Datepicker";
import {
  fetchAdminUsers,
  useAdminUsersQuery,
} from "src/api/queries/fetchAdminUsers";
import { Ticket } from "src/api/queries/fetchTicket";
import {
  useTicketCategoriesQuery,
  TicketCategory,
} from "src/api/queries/fetchTicketCategories";
import { useUpdateTicketMutation } from "src/api/mutations/ticketCreate";
import { useRepairFacilitiesQuery } from "src/api/queries/fetchLogisticsRepairFacilities";
import { useUserContext } from "src/contexts/UserContext";
import { networks, ticketStatuses } from "src/types";

export const SummaryPanel = ({ ticket }: { ticket: Ticket }) => {
  const { user } = useUserContext();
  const [state, setState] = useState<Ticket>(ticket);
  const mutation = useUpdateTicketMutation();
  const repairFacilitiesQuery = useRepairFacilitiesQuery();

  // categories select state and options
  const [selectedCategories, setSelectedCategories] = useState<
    MultiSelectItem[]
  >(
    ticket.categories.map((category) => {
      return { value: String(category.id), label: category.name };
    })
  );
  const [ticketCategories, setTicketCategories] = useState<TicketCategory[]>(
    []
  );

  const categoriesQuery = useTicketCategoriesQuery();
  useEffect(() => {
    if (categoriesQuery?.data != null) {
      setTicketCategories(categoriesQuery.data.items);
    }
  }, [categoriesQuery.data]);

  // users select state and options
  const defaultUsersQuery = useAdminUsersQuery({
    query: null,
    type: "admin",
    page: 0,
    size: 100,
    prioritize_caller: true,
  });

  const [defaultUserOptions, setDefaultUserOptions] = useState<
    SearchableItem[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<SearchableItem | undefined>(
    undefined
  );
  useEffect(() => {
    if (defaultUsersQuery?.data != null) {
      const items = defaultUsersQuery.data.items.map((item: any) => {
        let postfix = "";
        if (user != null && user.id === item.id) {
          postfix = " (You)";
        }

        return {
          value: item.id,
          label: item.first_name + " " + item.last_name + postfix,
        };
      });

      if (state.assignee_id != null) {
        const assignedUser = items.filter(
          (item) => parseInt(item.value) === state.assignee_id
        );
        if (assignedUser.length === 1) {
          setSelectedUser(assignedUser[0]);
        }
      }

      setDefaultUserOptions(items);
    }
  }, [defaultUsersQuery.data, state.assignee_id, user]);

  const loadSelectUserOptions = async (query: string) => {
    const data = await fetchAdminUsers({
      type: "admin",
      query: query,
      page: 0,
      size: 100,
      prioritize_caller: true,
    });
    return data.items.map((item: any) => {
      let postfix = "";
      if (user != null && user.id === item.id) {
        postfix = " (You)";
      }

      return {
        value: item.id,
        label: item.first_name + " " + item.last_name + postfix,
      };
    });
  };

  return (
    <Panel title={"Info"}>
      <Flex
        w="full"
        justify="space-around"
        flexWrap="wrap"
        gridGap={8}
        flexDir="row"
        alignItems="center"
      >
        <VStack>
          <Text w="full" textAlign="left">
            <b>Creator</b>: {ticket.creator_name}
          </Text>
          <Text w="full" textAlign="left">
            <b>Client</b>: {ticket.client_name}
          </Text>
          <Text w="full" textAlign="left">
            <b>Miner</b>:{" "}
            <Link
              as={ReactRouterLink}
              to={`/miners/${ticket.miner_id}`}
              fontWeight="bold"
            >
              {ticket.miner_name}
            </Link>
          </Text>
          <FormControl id="name">
            <HStack>
              <FormLabel m={0}>
                <b>Name</b>
              </FormLabel>
              <Input
                value={state.name}
                onChange={(e) =>
                  setState((s) => ({ ...s, name: e.target.value }))
                }
                isDisabled={ticket.completed_at != null}
              />
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <FormLabel m={0}>
                <b>RMA ID</b>
              </FormLabel>
              <Input
                value={state.rma_id}
                onChange={(e) =>
                  setState((s) => ({ ...s, rma_id: e.target.value }))
                }
              />
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <FormLabel m={0}>
                <b>Status</b>
              </FormLabel>
              <Select
                pl={0}
                value={state.status_id}
                isDisabled={state.billed_at != null}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  const status = ticketStatuses.find(
                    (status) => status.id === value
                  );

                  let completedAt: Date | undefined = state.completed_at;
                  if (
                    status == null ||
                    status.name.toLowerCase() !== "completed"
                  ) {
                    completedAt = undefined;
                  } else {
                    completedAt = new Date();
                  }

                  let assigneeId: number | undefined = state.assignee_id;
                  if (
                    status == null ||
                    status.name.toLowerCase() === "created"
                  ) {
                    assigneeId = undefined;
                    setSelectedUser(undefined);
                  }

                  setState((s) => ({
                    ...s,
                    status_id: value,
                    completed_at: completedAt,
                    assignee_id: assigneeId,
                  }));
                }}
              >
                {ticketStatuses
                  .filter(
                    (status) =>
                      (state.assignee_id != null ||
                        status.name.toLowerCase() !== "assigned") &&
                      !(
                        state.status_name.toLowerCase() !== "billed" &&
                        status.name.toLowerCase() === "billed"
                      )
                  )
                  .map((ticketStatus) => (
                    <option
                      key={`status-${ticketStatus.id}`}
                      value={ticketStatus.id}
                    >
                      {ticketStatus.name}
                    </option>
                  ))}
              </Select>
            </HStack>
          </FormControl>
        </VStack>
        <VStack>
          <FormControl>
            <Stack w="100%">
              <FormLabel textAlign="left" mx={0} my="auto">
                <b>Assignee</b>
              </FormLabel>
              <SearchableSelect
                name={"assignees"}
                placeholder={"Select a user"}
                value={selectedUser}
                defaultOptions={defaultUserOptions}
                loadOptions={loadSelectUserOptions}
                onSelect={(item: SearchableItem) => {
                  setState((s) => ({
                    ...s,
                    assignee_id: parseInt(item.value),
                    status_id: ticketStatuses.find(
                      (status) => status.name.toLowerCase() === "assigned"
                    )!.id,
                  }));
                }}
                isDisabled={ticket.completed_at != null}
              />
            </Stack>
          </FormControl>
          <FormControl>
            <Stack w="100%" maxW="30rem">
              <FormLabel textAlign="left" mx={0} my="auto">
                <b>Categories</b>
              </FormLabel>
              <MultiSelect
                name={"category"}
                placeholder={"Select a category"}
                values={selectedCategories}
                options={ticketCategories.map((item) => {
                  return { value: String(item.id), label: item.name };
                })}
                onSelect={(items) => setSelectedCategories(items)}
              />
            </Stack>
          </FormControl>
        </VStack>
        <VStack>
          <FormControl>
            <Stack w="100%">
              <FormLabel textAlign="left" mx={0} my="auto">
                <b>Location</b>
              </FormLabel>
              <Select
                w="15rem"
                value={
                  state.location_id == null ? undefined : state.location_id
                }
                onChange={(e) => {
                  let value: number | undefined = parseInt(e.target.value);
                  if (isNaN(value)) {
                    value = undefined;
                  }
                  setState((s) => ({ ...s, location_id: value }));
                }}
              >
                <option value={undefined}>N/A</option>
                {networks.map((network) => (
                  <option key={`network-${network.id}`} value={network.id}>
                    {network.name}
                  </option>
                ))}
              </Select>
            </Stack>
          </FormControl>
          <FormControl>
            <Stack w="100%">
              <FormLabel textAlign="left" mx={0} my="auto">
                <b>Repair Facility</b>
              </FormLabel>
              <Select
                w="15rem"
                value={
                  state.repair_facility_id == null
                    ? undefined
                    : state.repair_facility_id
                }
                onChange={(e) => {
                  let value: number | undefined = parseInt(e.target.value);
                  if (isNaN(value)) {
                    value = undefined;
                  }

                  setState((s) => ({ ...s, repair_facility_id: value }));
                }}
              >
                <option value={undefined}>N/A</option>
                {repairFacilitiesQuery.data?.items.map((repairFacility) => (
                  <option
                    key={`repair-facility-${repairFacility.id}`}
                    value={repairFacility.id}
                  >
                    {repairFacility.name}
                  </option>
                ))}
              </Select>
            </Stack>
          </FormControl>
          <Button
            colorScheme="green"
            onClick={() => {
              let categoryList = selectedCategories.map((category) =>
                parseInt(category.value)
              );
              const newCategories = categoryList.filter(
                (categoryId) =>
                  ticket.categories.find(
                    (category) => category.id === categoryId
                  ) == null
              );
              const deleteCategories = ticket.categories.filter(
                (category) =>
                  categoryList.find(
                    (categoryId) => category.id === categoryId
                  ) == null
              );

              mutation.mutate({
                ticket_id: state.id,
                assignee_id: state.assignee_id,
                location_id: state.location_id,
                repair_facility_id: state.repair_facility_id,
                rma_id:
                  state.rma_id != null && state.rma_id.length > 0
                    ? state.rma_id
                    : undefined,
                status: state.status_id,
                name: state.name,
                categories: newCategories,
                delete_categories: deleteCategories.map(
                  (category) => category.id
                ),
                costs: [],
                delete_costs: [],
                completed_at: state.completed_at,
                checked: state.checked,
              });
            }}
            disabled={
              mutation.isLoading ||
              (state.name === ticket.name &&
                state.status_id === ticket.status_id &&
                state.assignee_id == ticket.assignee_id && // eslint-disable-line
                state.location_id == ticket.location_id && // eslint-disable-line
                state.repair_facility_id == ticket.repair_facility_id && // eslint-disable-line
                state.rma_id == ticket.rma_id && // eslint-disable-line
                state.completed_at == ticket.completed_at && // eslint-disable-line
                state.checked == ticket.checked && // eslint-disable-line
                JSON.stringify(
                  selectedCategories
                    .map((category) => parseInt(category.value))
                    .sort()
                ) ===
                  JSON.stringify(
                    ticket.categories.map((category) => category.id).sort()
                  ))
            }
          >
            Update Ticket
          </Button>
        </VStack>
        <VStack>
          <Text w="full" textAlign="left">
            <b>Total cost</b>: ${ticket.cost}
          </Text>
          <Text w="full" textAlign="left">
            <b>Created At:</b> {new Date(ticket.created_at).toLocaleString()}
          </Text>
          <Text w="full" textAlign="left">
            <b>Assigned At:</b>{" "}
            {ticket.assigned_at != null &&
              new Date(ticket.assigned_at).toLocaleString()}
          </Text>
          <FormControl>
            <HStack>
              <FormLabel minW="7rem">
                <b>Completed At:</b>
              </FormLabel>
              <Datepicker
                selectedDate={
                  state.completed_at == null
                    ? undefined
                    : new Date(state.completed_at)
                }
                onChange={(date: any) =>
                  setState((s) => ({ ...s, page: 0, completed_at: date }))
                }
                isDisabled={
                  state.completed_at == null || state.billed_at != null
                }
              />
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <FormLabel>
                <b>Checked</b>
              </FormLabel>
              <Switch
                size="lg"
                isChecked={state.checked}
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    page: 0,
                    checked: e.target.checked,
                  }))
                }
                isDisabled={state.completed_at == null || mutation.isLoading}
              />
            </HStack>
          </FormControl>
        </VStack>
      </Flex>
    </Panel>
  );
};
