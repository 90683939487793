import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type BookMinerArgs = {
  client_id: number;
  name: string;
  serial: string;
  mac_address?: string;
  expected_hashrate: number;
};

export const useBookMinerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: BookMinerArgs) => {
      return apiClient.post<APIResponse<null>>("/data/miner/book", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/miner/sets");
    },
  });
};
