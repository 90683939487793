import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { SummaryPanel } from "./panels/SummaryPanel";
import { InvoicesPanel } from "./panels/InvoicesPanel";
import { OverduePanel } from "./panels/OverduePanel";
import { CombinedPanel } from "./panels/CombinedPanel";

export const InvoicesPage = () => {
  return (
    <Page title="Invoices" maxW={"contrainer.2xl"}>
      <PanelRow>
        <SummaryPanel />
      </PanelRow>
      <PanelRow>
        <InvoicesPanel />
      </PanelRow>
      <PanelRow>
        <OverduePanel />
      </PanelRow>
      <PanelRow>
        <CombinedPanel />
      </PanelRow>
    </Page>
  );
};
