import { useQuery } from "react-query";
import { APIResponsePaginated, UserType } from "src/types";
import { apiClient } from "..";

export type AdminTaskStatus = "pending" | "accepted" | "rejected";

type QueryArgs = {
  type: string;
  query: string | null;
  page: number;
  size: number;
  prioritize_caller?: boolean;
};

export type AdminUser = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  pending_email?: string;
  type: UserType;
  client_name: string;
  client_id: number;
  client_is_company: boolean;
  client_is_batched: boolean;
  is_billing_contact: boolean;
  verified: boolean;
  revoked: boolean;
  last_login: string;
  created_at: string;
  updated_at: string;
};

export const fetchAdminUsers = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<AdminUser>>("/admin/users", { params: args })
    .then((res) => res.data.data);

export const useAdminUsersQuery = (args: QueryArgs) =>
  useQuery(["/admin/users", args], () => fetchAdminUsers(args));
