import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type TicketUpdate = {
  id: number;
  solve_date: Date;
};

export const useUpdateTicketMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: TicketUpdate) => {
      return apiClient.post<APIResponse<null>>("/ticket/glpi", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/glpi/open");
    },
  });
};
