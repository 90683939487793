import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type InvoicesSummaryResponse = {
  contracts: {
    awaiting: number;
    annual: number;
    monthly_batched: number;
    monthly_billed: number;
    monthly_unbilled: number;
  };
  overdue: {
    balance: string;
    count: number;
    balance_7d: string;
    count_7d: number;
    balance_14d: string;
    count_14d: number;
    balance_1mo: string;
    count_1mo: number;
  };
  upcoming: {
    balance: string;
    count: number;
    unsent_balance: string;
    unsent_count: number;
    sent_balance: string;
    sent_count: number;
    paid_balance: string;
    paid_count: number;
  };
};

export const fetchInvoicesSummary = () =>
  apiClient
    .get<APIResponse<InvoicesSummaryResponse>>(`/invoices/summary`)
    .then((res) => res.data.data);

export const useInvoicesSummaryQuery = () => {
  return useQuery(["/invoices/summary"], () => fetchInvoicesSummary());
};
