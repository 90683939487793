import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";
import { ChakraProvider } from "@chakra-ui/react";
import { App } from "./App";
import { bitcapTheme } from "./theme";
import { RegisterAxiosInterceptors } from "./api";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={bitcapTheme}>
      <Router>
        <QueryParamProvider>
          <RegisterAxiosInterceptors>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </RegisterAxiosInterceptors>
        </QueryParamProvider>
      </Router>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
