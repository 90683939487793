import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  name: string;
  host: string;
  enabled: boolean;
  flipped: boolean;
  pf_enabled: boolean;
  current_capacity: number;
  current_alert: number;
  current_warn: number;
};

export const useCreateEyedroMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PostBody) => {
      return apiClient.post<APIResponse<null>>("/data/eyedro", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/eyedros");
    },
  });
};

type PatchBody = {
  eyedro_id: number;
  name?: string | null;
  host?: string | null;
  enabled?: boolean | null;
  flipped?: boolean | null;
  pf_enabled?: boolean | null;
  current_capacity?: number | null;
  current_alert?: number | null;
  current_warn?: number | null;
};

export const useUpdateEyedroMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PatchBody) => {
      return apiClient.patch<APIResponse<null>>("/data/eyedro", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/eyedros");
    },
  });
};

type DeleteBody = {
  eyedro_id: number;
};

export const useDeleteEyedroMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteBody) => {
      return apiClient.delete<APIResponse<null>>("/data/eyedro", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/eyedros");
    },
  });
};
