import { ReactNode, useState } from "react";
import { Redirect, NavLink, useLocation } from "react-router-dom";
import {
  IconButton,
  Box,
  CloseButton,
  Button,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Link,
  Divider,
  Center,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { authedRoutes } from "src/pages/authed";
import { useUserContext } from "src/contexts/UserContext";
import { useUserQuery } from "src/api/queries/fetchUser";
import { restoreTokens } from "src/lib/tokenStorage";

export const SIDEBAR_MD_SIZE = 48;

export const SidebarLayout = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Sidebar
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Box>
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent display={{ md: "none" }}>
            <Sidebar onClose={onClose} />
          </DrawerContent>
        </Drawer>
      </Box>
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: SIDEBAR_MD_SIZE }} px={{ base: 0, md: 4 }} py={4}>
        {children}
      </Box>
    </Box>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
}
const Sidebar = ({ onClose, ...rest }: SidebarProps) => {
  const [completed, setCompleted] = useState(false);

  const userQuery = useUserQuery();
  const { user } = useUserContext();

  const endEmulation = () => {
    restoreTokens();
    userQuery.refetch();
    setCompleted(true);
  };

  if (completed) {
    return <Redirect to="/users" />;
  }

  return (
    <Box
      color={"whiteAlpha.900"}
      bg="gray.900"
      boxShadow={"lg"}
      w={{ base: "full", md: SIDEBAR_MD_SIZE }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image
          maxH="8"
          src={`${process.env.PUBLIC_URL}/BitCapLngLogo_342.png`}
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box p={4} pt={0}>
        <Divider borderColor="whiteAlpha.500" />
      </Box>
      {authedRoutes
        .filter((rt) => rt.userTypesToSidebar.includes(user.type))
        .map((route) => (
          <NavItem key={route.path} to={route.path} onClick={onClose}>
            {route.sidebarLabel}
          </NavItem>
        ))}

      {user.emulated && (
        <>
          <Box p={4} pt={4}>
            <Divider borderColor="whiteAlpha.500" />
          </Box>
          <Center>
            <Button colorScheme="red" onClick={endEmulation}>
              End User Emulation
            </Button>
          </Center>
        </>
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  children: ReactNode;
  to: string;
}
const NavItem = ({ children, to, ...rest }: NavItemProps) => {
  const { pathname } = useLocation();
  const active = pathname === to;
  return (
    <Link
      as={NavLink}
      to={to}
      style={{ textDecoration: "none", outline: "none" }}
      exact
      _focus={{}}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="md"
        role="group"
        cursor="pointer"
        fontSize="lg"
        bg={active ? "whiteAlpha.100" : undefined}
        _hover={{
          bg: "whiteAlpha.100",
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      top={0}
      position="sticky"
      zIndex={1}
      ml={{ base: 0, md: SIDEBAR_MD_SIZE }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg="gray.900"
      boxShadow="lg"
      color={"whiteAlpha.900"}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<HamburgerIcon />}
      />

      <Image
        maxH="8"
        ml="8"
        src={`${process.env.PUBLIC_URL}/BitCapLngLogo_342.png`}
      />
    </Flex>
  );
};
