import { useQuery } from "react-query";
import { APIResponse, FormattedNumber } from "src/types";
import { apiClient } from "..";

export type MinerPreviewResponse = {
  id: number;
  compound_id: string;
  group_id: string;
  contract_id?: number;
  contract_name?: string;
  name: string;
  hardware?: string;
  host_name: string;
  network_ip_address?: string;
  mac_address?: string;
  pool_name: string;
  software: string;
  enabled: boolean;
  running: boolean;
  offline: boolean;
  min_temperature: FormattedNumber;
  avg_temperature: FormattedNumber;
  max_temperature: FormattedNumber;
  status: string;
  uptime: string;
  shares: {
    accepted: number;
    rejected: number;
    hw_errors: number;
  };
  hashrate: FormattedNumber;
  avg_hashrate: FormattedNumber;
  power_usage: FormattedNumber;
  coin: string;
  algorithm: string;
  daily_revenue: FormattedNumber;
  monthly_revenue: FormattedNumber;
  gpus: null;
  pools: null;
  updated_at: string;
};

export type MinerGroupResponse = {
  id: number;
  compound_id: string;
  name: string;
  running_count: number;
  total_count: number;
  hashrate: FormattedNumber;
  daily_revenue: FormattedNumber;
  monthly_revenue: FormattedNumber;
  power_usage: FormattedNumber;
  min_temperature: FormattedNumber;
  avg_temperature: FormattedNumber;
  max_temperature: FormattedNumber;
  miners: MinerPreviewResponse[];
};

export const fetchDataMinerGroup = (groupId?: string) =>
  apiClient
    .get<APIResponse<MinerGroupResponse>>("/data/group", {
      params: { group_id: groupId },
    })
    .then((res) => res.data.data);

export const useDataMinerGroupQuery = (groupId?: string) =>
  useQuery(["/data/group", groupId], () => fetchDataMinerGroup(groupId), {
    refetchOnWindowFocus: false,
  });
