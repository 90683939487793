import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Flex,
  Stack,
  HStack,
  VStack,
  Box,
  Checkbox,
  NumberInput,
  NumberInputField,
  Center,
  Button,
} from "@chakra-ui/react";
import { useShiftCombinedInvoicesQuery } from "src/api/queries/fetchInvoices";
import {
  useSaveShiftedCombinedInvoiceMutation,
  useApplyShiftedCombinedInvoiceMutation,
} from "src/api/mutations/invoiceUpdate";
import { Panel } from "src/components/Panel";
import { MutationFeedback } from "src/components/MutationFeedback";
import { RadioCardGroup } from "src/components/RadioCard";
import { addDateTimezone } from "src/lib/standardizeDate";
import { combinedClients } from "src/types";

export const CombinedBuilderPanel = () => {
  const [clientId, setClientId] = useState<number>(168);
  const [startMonth, setStartMonth] = useState<number>(8);
  const [allowSaved, setAllowSaved] = useState<boolean>(true);
  const [skipInvoiceIdx, setSkipInvoiceIdx] = useState<
    Record<number, boolean | undefined>
  >({});
  const skipInvoiceIds = Object.entries(skipInvoiceIdx)
    .filter(([invoiceId, skip]) => skip)
    .map(([invoiceId, skip]) => parseInt(invoiceId));
  const query = useShiftCombinedInvoicesQuery(
    clientId,
    startMonth,
    allowSaved,
    skipInvoiceIds
  );
  const saveMutation = useSaveShiftedCombinedInvoiceMutation();
  const applyMutation = useApplyShiftedCombinedInvoiceMutation();

  return (
    <Panel title={"Combined Invoice Builder"} query={query}>
      {(data) => (
        <>
          <HStack p={2} mb={4} justifyContent="space-around">
            <VStack>
              <HStack>
                <FormControl maxW="25rem">
                  <Select
                    value={clientId}
                    onChange={(e) => setClientId(Number(e.target.value))}
                  >
                    {combinedClients.map((combinedClient) => (
                      <option key={combinedClient.id} value={combinedClient.id}>
                        {combinedClient.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Start Month</FormLabel>
                  <NumberInput
                    value={startMonth}
                    onChange={(e) => setStartMonth(parseInt(e))}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl maxW="15rem">
                  <FormLabel textAlign="center">Allow Saved</FormLabel>
                  <RadioCardGroup
                    keys={["Allow", "Prevent"]}
                    value={allowSaved ? "Allow" : "Prevent"}
                    onChange={(e) =>
                      setAllowSaved(e === "Allow" ? true : false)
                    }
                  />
                </FormControl>
              </HStack>
              <Box>
                Checksum:{" "}
                <b>
                  <i>{data.checksum}</i>
                </b>
              </Box>
            </VStack>
            <VStack>
              <Box>
                Next Combined Date:{" "}
                <b>
                  {addDateTimezone(
                    new Date(data.invoice_date)
                  ).toLocaleDateString()}
                </b>
              </Box>
              <Box>
                Next Combined Cost: <b>${data.invoice_cost}</b>
              </Box>
            </VStack>
            <VStack>
              <Box>
                Following Combined Date:{" "}
                <b>
                  {addDateTimezone(
                    new Date(data.next_invoice_date)
                  ).toLocaleDateString()}
                </b>
              </Box>
              <Box>
                Following Combined Cost: <b>${data.next_invoice_cost}</b>
              </Box>
            </VStack>
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={saveMutation.isLoading}
              disabled={
                saveMutation.isLoading ||
                data.checksum.length === 0 ||
                data.saved ||
                !allowSaved
              }
              onClick={() =>
                saveMutation.mutate({
                  client_id: clientId,
                  start_month: startMonth,
                  checksum: data.checksum,
                  skip_invoice_ids: skipInvoiceIds,
                })
              }
            >
              Save
            </Button>
          </HStack>
          <MutationFeedback
            mutation={saveMutation}
            successMsg="Invoice shifts saved"
          />
          <MutationFeedback
            mutation={applyMutation}
            successMsg="Invoice shifts applied"
          />
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th>Contract</Th>
                <Th>Miners</Th>
                <Th>Monthly Cost</Th>
                <Th>Activated Cost</Th>
                <Th>Activated At</Th>
                <Th>Terminates At</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.contracts.map((contract) => {
                const hasChanges =
                  contract.invoices.filter(
                    (invoice) =>
                      invoice.new_due_at != null || invoice.new_end_at != null
                  ).length > 0;
                return (
                  <>
                    <Tr>
                      <Td>{contract.name}</Td>
                      <Td>
                        <Stack>
                          {contract.groups.map((group) => (
                            <div key={`miner-${group.id}`}>
                              {`${group.name}: ${group.activated}/${group.total}`}
                            </div>
                          ))}
                        </Stack>
                      </Td>
                      <Td>${contract.monthly_cost}</Td>
                      <Td>${contract.activated_cost}</Td>
                      <Td>
                        {addDateTimezone(
                          new Date(contract.activated_at)
                        ).toLocaleDateString()}
                      </Td>
                      <Td>
                        {addDateTimezone(
                          new Date(contract.terminates_at)
                        ).toLocaleDateString()}
                      </Td>
                      <Td>
                        <Button
                          colorScheme="teal"
                          type="submit"
                          isLoading={applyMutation.isLoading}
                          disabled={
                            applyMutation.isLoading ||
                            data.checksum.length === 0 ||
                            !data.saved ||
                            !hasChanges
                          }
                          onClick={() =>
                            applyMutation.mutate({ contract_id: contract.id })
                          }
                        >
                          Apply
                        </Button>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td colSpan={7} pr={0}>
                        <Flex flexWrap={"wrap"} justifyContent="left">
                          {contract.invoices.map((invoice) => (
                            <VStack
                              key={invoice.id}
                              p={2}
                              m={1}
                              borderRadius={"md"}
                              borderWidth="1px"
                              bg={
                                invoice.combined_idx === 0
                                  ? "whiteAlpha.500"
                                  : invoice.combined_idx === 1
                                  ? "blackAlpha.400"
                                  : "blackAlpha.200"
                              }
                              textAlign="center"
                              alignItems="center"
                              maxW="25rem"
                            >
                              <VStack w="full">
                                <HStack w="full">
                                  <VStack w="full">
                                    <Text w="full" textAlign="center">
                                      <a
                                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.quickbooks_id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <b>{invoice.name}</b>
                                      </a>
                                    </Text>
                                  </VStack>
                                  <VStack w="full">
                                    <Text w="full" textAlign="center">
                                      <b>
                                        Factor:{" "}
                                        {(invoice.factor / 100).toFixed(2)}
                                      </b>
                                    </Text>
                                    <Text w="full" textAlign="center">
                                      <b>
                                        Cost: $
                                        {hasChanges
                                          ? invoice.new_cost
                                          : invoice.cost}
                                      </b>
                                    </Text>
                                  </VStack>
                                  <Center>
                                    <Checkbox
                                      isChecked={
                                        skipInvoiceIdx[invoice.id] !== true
                                      }
                                      onChange={(e) =>
                                        setSkipInvoiceIdx((s) => ({
                                          ...s,
                                          [invoice.id]: !(
                                            skipInvoiceIdx[invoice.id] === true
                                          ),
                                        }))
                                      }
                                    />
                                  </Center>
                                </HStack>
                                <HStack>
                                  <Text w="full" textAlign="center">
                                    {`Due: ${addDateTimezone(
                                      new Date(invoice.due_at)
                                    ).toLocaleDateString()}`}
                                    {invoice.new_due_at != null && (
                                      <>
                                        <b>{`  ->  `}</b>
                                        {`${addDateTimezone(
                                          new Date(invoice.new_due_at)
                                        ).toLocaleDateString()}`}
                                      </>
                                    )}
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Text w="full" textAlign="center">
                                    {`Ends: ${addDateTimezone(
                                      new Date(invoice.end_at)
                                    ).toLocaleDateString()}`}
                                    {invoice.new_end_at != null && (
                                      <>
                                        <b>{`  ->  `}</b>
                                        {`${addDateTimezone(
                                          new Date(invoice.new_end_at)
                                        ).toLocaleDateString()}`}
                                      </>
                                    )}
                                  </Text>
                                </HStack>
                              </VStack>
                            </VStack>
                          ))}
                        </Flex>
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Panel>
  );
};
