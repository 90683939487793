import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { RevenuePanel } from "./panels/RevenuePanel";

export const RevenuePage = () => {
  return (
    <Page title="Revenue" maxW={"contrainer.2xl"}>
      <PanelRow>
        <RevenuePanel />
      </PanelRow>
    </Page>
  );
};
