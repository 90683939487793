import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Panel } from "src/components/Panel";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Select,
  Stack,
  HStack,
  VStack,
  Button,
  useDisclosure,
  LinkBox,
  LinkOverlay,
  NumberInput,
  NumberInputField,
  InputLeftAddon,
  Center,
} from "@chakra-ui/react";
import { useContractsQuery, Contract } from "src/api/queries/fetchContracts";
import {
  useSendContractMutation,
  useTerminateContractMutation,
  useDeleteContractMutation,
} from "src/api/mutations/contractCreate";
import { SimplePaginator } from "src/components/SimplePaginator";
import { contractStatuses } from "src/types";
import { SearchIcon } from "@chakra-ui/icons";
import { MutationFeedback } from "src/components/MutationFeedback";

const capitalizeString = (input: string): string => {
  return input.length === 0
    ? ""
    : input.charAt(0).toUpperCase() + input.slice(1);
};

const TerminateContractButton = ({ contract }: { contract: Contract }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useTerminateContractMutation();
  const [terminationFee, setTerminationFee] = useState<string>("1");
  const [terminationConfirmed, setTerminationConfirmed] =
    useState<boolean>(false);
  const [terminationExecutionConfirmed, setTerminationExecutionConfirmed] =
    useState<boolean>(false);
  const fullOnClose = () => {
    setTerminationConfirmed(false);
    setTerminationExecutionConfirmed(false);
    onClose();
  };

  const handleTerminate = () => {
    mutation.mutate(
      {
        contract_id: contract.id,
        termination_fee: terminationFee,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="md"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Terminate
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={fullOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Terminate Contract
            </AlertDialogHeader>

            <AlertDialogBody justifyContent="center">
              <Stack alignItems="center">
                <Text textAlign="center">
                  Are you sure you want terminate contract{" "}
                  <b>
                    {contract.name} ({contract.client_name})
                  </b>
                  ?
                </Text>
                <Center>
                  <InputGroup size="sm" alignItems="center">
                    <InputLeftAddon>Cost Per Miner</InputLeftAddon>
                    <NumberInput
                      min={0}
                      step={1}
                      value={"$" + terminationFee}
                      onChange={(e) => setTerminationFee(e)}
                    >
                      <NumberInputField padding={0} textAlign="center" />
                    </NumberInput>
                  </InputGroup>
                </Center>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter justifyContent="center">
              <VStack>
                <HStack>
                  <Button
                    ref={cancelRef}
                    isDisabled={mutation.isLoading}
                    onClick={fullOnClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    isLoading={mutation.isLoading}
                    onClick={() => setTerminationConfirmed(true)}
                    isDisabled={terminationFee === "" || terminationConfirmed}
                    ml={3}
                  >
                    Terminate
                  </Button>
                </HStack>
                {terminationConfirmed && (
                  <>
                    <Text>
                      Are you really sure you want to terminate this contract?
                      This is an irreversible action.
                    </Text>
                    <Button
                      colorScheme="teal"
                      isLoading={mutation.isLoading}
                      onClick={() => setTerminationExecutionConfirmed(true)}
                      isDisabled={
                        terminationFee === "" ||
                        !terminationConfirmed ||
                        terminationExecutionConfirmed
                      }
                      ml={3}
                    >
                      Yes
                    </Button>
                    <Button
                      colorScheme="red"
                      isLoading={mutation.isLoading}
                      onClick={handleTerminate}
                      isDisabled={
                        terminationFee === "" ||
                        !terminationConfirmed ||
                        !terminationExecutionConfirmed
                      }
                      ml={3}
                    >
                      Confirm Termination
                    </Button>
                  </>
                )}
              </VStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const DeleteContractButton = ({ contract }: { contract: Contract }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useDeleteContractMutation();
  const action = contract.status === "Signed" ? "void" : "delete";

  const handleDelete = () => {
    mutation.mutate(
      {
        contract_id: contract.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="md"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        {capitalizeString(action)}
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {capitalizeString(action)} Contract
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want {action} contract{" "}
                  <b>
                    {contract.name} ({contract.client_name})
                  </b>
                  ?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                {capitalizeString(action)}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const ContractsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: null | string;
    status: null | number;
  }>({ page: 0, size: 10, query: null, status: null });

  const query = useContractsQuery({
    status: state.status,
    query: state.query,
    page: state.page,
    size: state.size,
  });

  const sendMutation = useSendContractMutation();
  return (
    <Panel title={"Contracts"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        page: 0,
                        query:
                          e.target.value != null && e.target.value.length > 0
                            ? e.target.value
                            : null,
                      }))
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
              <FormControl maxW="15rem">
                <FormLabel>Status</FormLabel>
                <Select
                  value={state.status != null ? state.status : undefined}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      status: value > 0 ? value : null,
                    }));
                  }}
                >
                  <option value={0}>All</option>
                  {contractStatuses.map((contractStatus) => (
                    <option key={contractStatus.id} value={contractStatus.id}>
                      {contractStatus.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>{" "}
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Client</Th>
                <Th>Creator</Th>
                <Th>Term</Th>
                <Th>Status</Th>
                <Th>Power Rate</Th>
                <Th>Miners</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((contract) => (
                <Tr key={`contract-${contract.id}`}>
                  <Td>{contract.name}</Td>
                  <Td>{contract.client_name}</Td>
                  <Td>{contract.creator_name}</Td>
                  <Td>
                    {contract.term === 1
                      ? "Annual"
                      : contract.term === 3
                      ? "Employee"
                      : "Monthly"}
                  </Td>
                  <Td>{contract.status}</Td>
                  <Td>{"$" + contract.power_rate}</Td>
                  <Td>
                    <Stack>
                      {contract.miner_groups.map((group) => (
                        <div key={`miner-${group.id}`}>
                          {`${group.name}: ${group.activated}/${group.total}`}
                        </div>
                      ))}
                    </Stack>
                  </Td>
                  <Td>
                    <HStack>
                      <LinkBox
                        bg="blackAlpha.100"
                        p={2}
                        borderRadius="md"
                        _hover={{ bg: "blackAlpha.200" }}
                        textAlign="center"
                        minW="5rem"
                      >
                        <LinkOverlay
                          fontWeight="bold"
                          as={ReactRouterLink}
                          to={`/pdf/contract/${contract.file_id}`}
                          isExternal
                        >
                          PDF
                        </LinkOverlay>
                      </LinkBox>
                      {contract.status === "Created" && (
                        <Button
                          colorScheme="teal"
                          isLoading={sendMutation.isLoading}
                          isDisabled={sendMutation.isLoading}
                          onClick={() =>
                            sendMutation.mutate({ contract_id: contract.id })
                          }
                        >
                          Send Contract
                        </Button>
                      )}
                      {(contract.status === "Created" ||
                        contract.status === "Sent" ||
                        contract.status === "Partially Signed" ||
                        contract.status === "Signed") && (
                        <DeleteContractButton contract={contract} />
                      )}
                      {(contract.status === "Partially Activated" ||
                        contract.status === "Fully Activated" ||
                        contract.status === "Completed") && (
                        <Button colorScheme="teal">
                          <LinkOverlay
                            as={ReactRouterLink}
                            to={`/contract-builder/${contract.id}`}
                            fontWeight="bold"
                          >
                            Renew
                          </LinkOverlay>
                        </Button>
                      )}
                      {(contract.status === "Partially Activated" ||
                        contract.status === "Fully Activated") && (
                        <TerminateContractButton contract={contract} />
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
