import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export const useCreateTicketCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { name: string }) => {
      return apiClient.post<APIResponse<null>>("/tickets/category", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/categories");
    },
  });
};

export const useUpdateTicketCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number; name: string }) => {
      return apiClient.patch<APIResponse<null>>("/tickets/category", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/categories");
    },
  });
};

export const useDeleteTicketCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number }) => {
      return apiClient.delete<APIResponse<null>>("/tickets/category", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/categories");
    },
  });
};

export const useCreateTicketCostModelCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { name: string }) => {
      return apiClient.post<APIResponse<null>>(
        "/tickets/cost_model_category",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/cost_models");
    },
  });
};

export const useUpdateTicketCostModelCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number; name: string }) => {
      return apiClient.patch<APIResponse<null>>(
        "/tickets/cost_model_category",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/cost_models");
    },
  });
};

export const useDeleteTicketCostModelCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number }) => {
      return apiClient.delete<APIResponse<null>>(
        "/tickets/cost_model_category",
        { data: body }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/cost_models");
    },
  });
};

export const useCreateTicketCostModelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: {
      cost_category_id: number;
      name: string;
      cost: string;
    }) => {
      return apiClient.post<APIResponse<null>>("/tickets/cost_model", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/cost_models");
    },
  });
};

export const useUpdateTicketCostModelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: {
      id: number;
      cost_category_id: number;
      name: string;
      cost: string;
    }) => {
      return apiClient.patch<APIResponse<null>>("/tickets/cost_model", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/cost_models");
    },
  });
};

export const useDeleteTicketCostModelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number }) => {
      return apiClient.delete<APIResponse<null>>("/tickets/cost_model", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets/cost_models");
    },
  });
};

export type CostArgs = {
  model_id: number;
  cost: string;
  quantity: number;
};

export type CreateTicketArgs = {
  client_id: number;
  miner_set_id: number;
  name: string;
  categories: number[];
  costs: CostArgs[];
  message?: string;
  location_id?: number;
  repair_facility_id?: number;
};

export const useCreateTicketMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: CreateTicketArgs) => {
      return apiClient.post<APIResponse<null>>("/ticket", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};

export type UpdateTicketArgs = {
  ticket_id: number;
  assignee_id?: number;
  status: number;
  name: string;
  categories: number[];
  delete_categories: number[];
  costs: CostArgs[];
  delete_costs: number[];
  completed_at?: Date;
  location_id?: number;
  repair_facility_id?: number;
  checked?: boolean;
  rma_id?: string;
};

export const useUpdateTicketMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateTicketArgs) => {
      return apiClient.patch<APIResponse<null>>("/ticket", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};

export type CreateTicketItemArgs = {
  ticket_id: number;
  type: number;
  operation: number;
  index: number;
  serial: string;
};

export const useCreateTicketItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: CreateTicketItemArgs) => {
      return apiClient.post<APIResponse<null>>("/ticket/item", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};

export const useDeleteTicketItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number }) => {
      return apiClient.delete<APIResponse<null>>("/ticket/item", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};

export type CreateTicketMessageArgs = {
  ticket_id: number;
  public: boolean;
  message?: string;
  files: File[];
};

export const useCreateTicketMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: CreateTicketMessageArgs) => {
      var formData = new FormData();
      body.files.forEach((file) => formData.append("files", file));
      formData.append("ticket_id", JSON.stringify(body.ticket_id));
      formData.append("public", JSON.stringify(body.public));
      if (body.message != null) {
        formData.append("message", body.message);
      }

      return apiClient.post<APIResponse<null>>("/ticket/message", formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};

export type UpdateTicketMessageArgs = {
  message_id: number;
  public: boolean;
  message?: string;
  files: File[];
  delete_files: number[];
};

export const useUpdateTicketMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateTicketMessageArgs) => {
      var formData = new FormData();
      body.files.forEach((file) => formData.append("files", file));
      formData.append("message_id", JSON.stringify(body.message_id));
      formData.append("public", JSON.stringify(body.public));
      if (body.message != null) {
        formData.append("message", body.message);
      }
      body.delete_files.forEach((fileId) =>
        formData.append("delete_files", JSON.stringify(fileId))
      );

      return apiClient.patch<APIResponse<null>>("/ticket/message", formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};

export const useDeleteTicketMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { message_id: number }) => {
      return apiClient.delete<APIResponse<null>>("/ticket/message", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/tickets");
      await queryClient.invalidateQueries("/ticket");
    },
  });
};
