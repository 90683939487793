import { useParams } from "react-router";
import { BreadcrumbValues, Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { SummaryPanel } from "./panels/SummaryPanel";
import { PaymentsPanel } from "./panels/PaymentsPanel";
import { EventsPanel } from "./panels/EventsPanel";
import { MinersPanel } from "./panels/MinersPanel";

export const InvoicePage = () => {
  const { id: rawId } = useParams<{ id: string }>();
  const invoiceId = parseInt(rawId);

  let crumbs: BreadcrumbValues[] = [
    {
      text: "Invoices",
      to: "/invoices",
    },
    {
      text: "Invoice",
      to: undefined,
    },
  ];

  return (
    <Page title={`Invoice`} breadcrumbs={crumbs}>
      <PanelRow>
        <SummaryPanel invoiceId={invoiceId} />
      </PanelRow>
      <PanelRow>
        <PaymentsPanel invoiceId={invoiceId} />
        <EventsPanel invoiceId={invoiceId} />
      </PanelRow>
      <PanelRow>
        <MinersPanel invoiceId={invoiceId} />
      </PanelRow>
    </Page>
  );
};
