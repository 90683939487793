import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  period: string;
};

type CurrentKPI = {
  created: number;
  created_age: number;
  assigned: number;
  assigned_age: number;
  testing: number;
  testing_age: number;
  internal: number;
  external: number;
};

type SumKPI = {
  created: number;
  completed: number;
  billed: number;
  days_to_complete: number;
  avg_cost: string;
  total_cost: string;
  internal: number;
  external: number;
};

export type KPIResponse = {
  current: CurrentKPI;
  period: SumKPI;
  total: SumKPI;
};

export const fetchTicketKPIs = (args: QueryArgs) =>
  apiClient
    .get<APIResponse<KPIResponse>>("/tickets/kpis", { params: args })
    .then((res) => res.data.data);

export const useTicketKPIsQuery = (args: QueryArgs) => {
  return useQuery(["/tickets/kpis", args], () => fetchTicketKPIs(args), {
    keepPreviousData: true,
  });
};
