import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type Branch = {
  id: string;
  line_1?: string | null;
  line_2?: string | null;
  current: number;
  current_capacity: number;
  current_warn: number;
  current_alert: number;
};

export type Line = {
  id: string;
  current?: number;
  current_capacity: number;
  current_warn: number;
  current_alert: number;
};

export type PDU = {
  id: number;
  network_id: number;
  network_name: string;
  host: string;
  name: string;
  type_id: number;
  type_name: string;
  enabled: boolean;
  online: boolean;
  smart: boolean;
  branches: Branch[];
  lines: Line[];
  timestamp: number;
};

export const fetchDataPDUs = (
  page: number = 0,
  size: number = 10,
  query?: string,
  networkId?: number,
  online?: boolean,
  smart?: boolean
) => {
  let qs = `page=${page}&size=${size}`;
  if (query != null) {
    qs += `&query=${query}`;
  }
  if (networkId != null) {
    qs += `&network_id=${networkId}`;
  }
  if (online != null) {
    qs += `&online=${online}`;
  }
  if (smart != null) {
    qs += `&smart=${smart}`;
  }

  return apiClient
    .get<APIResponsePaginated<PDU>>(`/data/pdus?${qs}`)
    .then((res) => res.data.data);
};

export const useDataPDUsQuery = ({
  page = 0,
  size = 10,
  query,
  networkId,
  online,
  smart,
}: {
  page?: number;
  size?: number;
  query?: string | undefined;
  networkId?: number | undefined;
  online?: boolean | undefined;
  smart?: boolean | undefined;
} = {}) => {
  return useQuery(
    ["/data/pdus", page, size, query, networkId, online, smart],
    () => fetchDataPDUs(page, size, query, networkId, online, smart),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
