import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { fetchContract, Contract } from "src/api/queries/fetchContract";
import { ContractBuilderPanel } from "./panels/ContractBuilderPanel";
import { MinersPanel } from "./panels/MinersPanel";
import { AffiliatesPanel } from "./panels/AffiliatesPanel";

export const ContractBuilderPage = () => {
  const { id } = useParams<{ id?: string }>();
  const prevContractId = id != null ? parseInt(id) : null;

  const [previousContract, setPreviousContract] = useState<
    Contract | undefined
  >(undefined);
  useEffect(() => {
    if (prevContractId == null || isNaN(prevContractId)) {
      return;
    }

    const fetchPreviousContract = async () => {
      const contract = await fetchContract(prevContractId);
      if (contract != null) {
        setPreviousContract(contract);
      }
    };

    fetchPreviousContract();
  }, [prevContractId]);

  return (
    <Page title="Contract Builder">
      <PanelRow>
        <ContractBuilderPanel previousContract={previousContract} />
      </PanelRow>
      <PanelRow>
        <MinersPanel />
        <AffiliatesPanel />
      </PanelRow>
    </Page>
  );
};
