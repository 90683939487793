import { useQueryClient } from "react-query";
import { Route, Switch, Redirect } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { clearTokens } from "./lib/tokenStorage";
import { useUserQuery } from "./api/queries/fetchUser";
import { AuthenticatedRouter } from "./pages/authed";
import {
  ForgotPasswordPage,
  LoginPage,
  VerifyEmailPage,
  ResetPasswordPage,
  ChangeEmailPage,
  Request2FAResetPage,
  Reset2FAPage,
  PdfPage,
} from "./pages/unauthed";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";

export const App = () => {
  useGoogleAnalytics();
  const queryClient = useQueryClient();
  const userQuery = useUserQuery();

  if (userQuery.isLoading) {
    return null;
  }

  const handleLogout = () => {
    clearTokens();
    window.$chatwoot.reset();
    queryClient.setQueryData("/user", undefined);
  };

  return (
    <Switch>
      <Route path="/login">
        <LoginPage user={userQuery.data} onSuccessfulAuth={userQuery.refetch} />
      </Route>
      <Route path="/verify-email">
        <VerifyEmailPage user={userQuery.data} />
      </Route>
      <Route path="/forgot">
        <ForgotPasswordPage user={userQuery.data} />
      </Route>
      <Route path="/request-2fa-reset">
        <Request2FAResetPage user={userQuery.data} />
      </Route>
      <Route path="/reset-password">
        <ResetPasswordPage user={userQuery.data} />
      </Route>
      <Route path="/change-email">
        <ChangeEmailPage user={userQuery.data} />
      </Route>
      <Route path="/reset-2fa">
        <Reset2FAPage user={userQuery.data} />
      </Route>
      <Route
        exact
        path="/pdf/:fileId"
        render={(props) => (
          <Redirect to={`/pdf/invoice/${props.match.params.fileId}`} />
        )}
      ></Route>
      <Route path="/pdf/:fileType/:fileId">
        <PdfPage user={userQuery.data} />
      </Route>
      <Route path="*">
        <UserProvider user={userQuery.data} onLogout={handleLogout}>
          <AuthenticatedRouter />
        </UserProvider>
      </Route>
    </Switch>
  );
};
