import { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Select,
  FormLabel,
  FormControl,
  Badge,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import {
  useAdminClientsQuery,
  fetchAdminClients,
} from "src/api/queries/fetchAdminClients";
import { useContractsQuery, Contract } from "src/api/queries/fetchContracts";
import {
  SearchableSelect,
  SearchableItem,
} from "src/components/SearchableSelect";

type ClientSelectProps = {
  setClient: (id: number, name: string) => void;
};

const ClientSelect = ({ setClient }: ClientSelectProps) => {
  const defaultQuery = useAdminClientsQuery();
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);

  useEffect(() => {
    if (defaultQuery?.data != null) {
      const items = defaultQuery.data.items.map((item: any) => {
        return { value: item.id, label: item.name };
      });
      setDefaultOptions(items);
    }
  }, [defaultQuery.data]);

  const loadSelectOptions = async (query: string) => {
    const data = await fetchAdminClients({ query: query, page: 0, size: 25 });
    return data.items.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  const [clientItem, setClientItem] = useState<SearchableItem | undefined>(
    undefined
  );

  return (
    <SearchableSelect
      name={"clients"}
      placeholder={"Select a client"}
      value={clientItem}
      defaultOptions={defaultOptions}
      loadOptions={loadSelectOptions}
      onSelect={(item: SearchableItem) => {
        setClientItem(item);
        setClient(parseInt(item.value), item.label);
      }}
    />
  );
};

type ContractSelectTableProps = {
  activeContractId: number;
  contracts: Contract[];
  setContractId: (contractId: number) => void;
};

const ContractSelectTable = ({
  activeContractId,
  contracts,
  setContractId,
}: ContractSelectTableProps) => {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Count</Th>
          <Th>Miners</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {contracts.map((contract) => {
          const activatedCount = contract.miner_groups.reduce(
            (sum, item) => sum + item.activated,
            0
          );
          const totalCount = contract.miner_groups.reduce(
            (sum, item) => sum + item.total,
            0
          );

          return (
            <Tr
              key={contract.id}
              bg={activeContractId === contract.id ? "green.300" : undefined}
              _hover={{
                bg:
                  activeContractId === contract.id
                    ? undefined
                    : "blackAlpha.200",
              }}
              onClick={() => setContractId(contract.id)}
            >
              <Td>{contract.name}</Td>
              <Td>
                {activatedCount} / {totalCount}
              </Td>
              <Td>
                {contract.miner_groups.map((group) => (
                  <Box key={group.id}>
                    <b>{group.name}</b>: {group.activated} / {group.total}
                  </Box>
                ))}
              </Td>
              {(contract.status === "Created" ||
                contract.status === "Sent" ||
                contract.status === "Partially Signed") && (
                <Td>
                  <Badge colorScheme={"red"} variant="solid">
                    Not Signed
                  </Badge>
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const contractIsActivated = (contract: Contract): boolean => {
  const activatedCount = contract.miner_groups.reduce(
    (sum, item) => sum + item.activated,
    0
  );
  const totalCount = contract.miner_groups.reduce(
    (sum, item) => sum + item.total,
    0
  );

  return activatedCount >= totalCount;
};

type ContractSelectProps = {
  clientName: string | undefined;
  setContractId: (contractId: number) => void;
  activationComplete: boolean | null;
};

const ContractSelect = ({
  clientName,
  setContractId,
  activationComplete,
}: ContractSelectProps) => {
  const query = useContractsQuery({
    status: null,
    query: clientName !== undefined ? clientName : "",
    page: 0,
    size: 100,
  });
  const [activeContractId, setActiveContractId] = useState<number>(0);

  let contracts: Contract[] = [];
  if (query.data != null) {
    contracts = query.data.items.filter(
      (contract) =>
        contract.status !== "Completed" &&
        contract.status !== "Terminated" &&
        contract.status !== "Voided"
    );
    if (activationComplete !== null) {
      contracts = contracts.filter(
        (contract) => contractIsActivated(contract) === activationComplete
      );
    }
  }

  return (
    <Box h="16rem">
      {clientName === undefined && (
        <Text textAlign="center" pt="4rem">
          You must choose a client
        </Text>
      )}
      {clientName !== undefined && contracts.length === 0 && (
        <Text textAlign="center" pt="4rem">
          This client has no contracts
        </Text>
      )}
      {clientName !== undefined && contracts.length > 0 && (
        <Box overflowY="auto" maxH="16rem">
          <HStack alignItems="start">
            <ContractSelectTable
              activeContractId={activeContractId}
              setContractId={(contractId: number) => {
                setContractId(contractId);
                setActiveContractId(contractId);
              }}
              contracts={contracts.filter((_, idx) => idx % 2 === 0)}
            />
            <ContractSelectTable
              activeContractId={activeContractId}
              setContractId={(contractId: number) => {
                setContractId(contractId);
                setActiveContractId(contractId);
              }}
              contracts={contracts.filter((_, idx) => idx % 2 === 1)}
            />
          </HStack>
        </Box>
      )}
    </Box>
  );
};

type Props = {
  setClientId: (clientId: number) => void;
  setContractId: (contractId: number | null) => void;
};

export const ContractPanel = ({ setContractId, setClientId }: Props) => {
  const [client, setClient] = useState<
    { id: number; name: string } | undefined
  >(undefined);
  const [activationComplete, setActivationComplete] = useState<boolean | null>(
    false
  );

  return (
    <Panel overflow="hidden">
      <HStack flex={1} justifyContent="stretch" mb={3}>
        <FormControl maxW="50rem" pl={1}>
          <FormLabel>Client Name</FormLabel>
          <ClientSelect
            setClient={(id: number, name: string) => {
              setClient({ id: id, name: name });
              setClientId(id);
              setContractId(null);
            }}
          />
        </FormControl>
        <FormControl maxW="20rem">
          <FormLabel>Status</FormLabel>
          <Select
            value={activationComplete === null ? 0 : activationComplete ? 1 : 2}
            onChange={(e) => {
              const value = Number(e.target.value);
              setActivationComplete(
                value === 0 ? null : value === 1 ? true : false
              );
            }}
          >
            <option value={0}>All</option>
            <option value={1}>Activation Complete</option>
            <option value={2}>Activation Incomplete</option>
          </Select>
        </FormControl>
      </HStack>

      <ContractSelect
        clientName={client !== undefined ? client.name : undefined}
        setContractId={setContractId}
        activationComplete={activationComplete}
      />
    </Panel>
  );
};
