import { chakra } from "@chakra-ui/system";
import { Flex, Box, useRadio, useRadioGroup, Stack } from "@chakra-ui/react";

type RadioCardGroupProps = {
  keys: string[];
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
};

const capitalize = (value: string): string =>
  value
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");

export const RadioCardGroup = ({
  keys,
  value,
  onChange,
  isDisabled = false,
}: RadioCardGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: onChange,
    value: value,
  });

  return (
    <Stack {...getRootProps()}>
      <Flex flexWrap={"wrap"} justifyContent="center">
        {keys.map((key) => {
          return (
            <RadioCard
              key={key}
              label={key}
              isDisabled={isDisabled}
              {...getRadioProps({ value: key })}
            />
          );
        })}
      </Flex>
    </Stack>
  );
};

export const RadioCard = (props: any) => {
  const { label, isDisabled, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps } =
    useRadio(radioProps);

  return (
    <chakra.label {...htmlProps} mb={1} mx={1} cursor="pointer">
      <input {...getInputProps({})} disabled={isDisabled} hidden />
      <Box
        {...getCheckboxProps()}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        borderWidth="1px"
        borderRadius="md"
        boxShadow={!isDisabled && state.isHovered ? "outline" : "none"}
        bg={state.isChecked ? "teal" : "gray.200"}
        color={state.isChecked ? "white" : "black"}
        borderColor={state.isChecked ? "teal" : "gray.200"}
        opacity={isDisabled ? 0.4 : 1}
        px={3}
        py={3}
      >
        {capitalize(label)}
      </Box>
    </chakra.label>
  );
};
