import { useParams } from "react-router";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { BreadcrumbValues, Page } from "src/components/Page";
import { useDataMinerGroupQuery } from "src/api/queries/fetchDataMinerGroup";
import { Panel } from "src/components/Panel";
import { MinerPreviewList } from "src/components/MinerPreviewList";
import { SearchIcon } from "@chakra-ui/icons";
import { useDebounce } from "src/hooks/useDebounce";

export const MinerGroupPage = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const query = useDataMinerGroupQuery(groupId);
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, 250);

  let crumbs: BreadcrumbValues[] = [
    {
      text: "Miner Groups",
      to: "/groups",
    },
    {
      text: query.data?.name || "Group",
      to: undefined,
    },
  ];

  return (
    <Page title={query.data?.name || "..."} breadcrumbs={crumbs}>
      <Panel query={query}>
        {(data) => (
          <>
            <FormControl mb={4} maxW="30rem">
              <FormLabel>Search Miners</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <MinerPreviewList
              miners={data.miners.filter((miner) =>
                miner.name.toLowerCase().includes(debouncedFilter.toLowerCase())
              )}
            />
          </>
        )}
      </Panel>
    </Page>
  );
};
