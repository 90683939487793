import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  first_name: string;
  last_name: string;
  email: string;
  client_id: number;
  is_billing_contact: boolean;
};

export const adminInviteUser = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/admin/invite", body);
};
