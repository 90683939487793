import { useEffect, useState, useRef, ChangeEvent } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Table,
  Tbody,
  Td,
  Select,
  Th,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Switch,
  IconButton,
  FormControl,
  FormLabel,
  Thead,
  Badge,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useCreateWatchmanMutation,
  useUpdateWatchmanMutation,
  useDeleteWatchmanMutation,
} from "src/api/mutations/dataWatchman";
import {
  Watchman,
  useDataWatchmansQuery,
} from "src/api/queries/fetchDataWatchmans";
import { Panel } from "src/components/Panel";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SimplePaginator } from "src/components/SimplePaginator";
import { AddIcon, SearchIcon, EditIcon } from "@chakra-ui/icons";

const formatColor = (value: number, warn: number, alert: number) => {
  if (value === 0) {
    return "gray";
  } else if (value >= alert) {
    return "red";
  } else if (value >= warn) {
    return "#D69E2E";
  }
  return undefined;
};

type NewWatchman = {
  name?: string;
  host?: string;
  api_key?: string;
  enabled: boolean;
};

const CreateWatchmanButton = () => {
  const [state, setState] = useState<NewWatchman>({
    enabled: true,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateWatchmanMutation();

  const handleCreate = () => {
    if (
      state.name !== undefined &&
      state.host !== undefined &&
      state.api_key !== undefined
    ) {
      mutation.mutate(
        {
          name: state.name,
          host: state.host,
          api_key: state.api_key,
          enabled: state.enabled,
        },
        {
          onSuccess: onClose,
        }
      );
    }
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({ enabled: true });
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <Text minW="10rem" align="center" fontWeight="medium">
        Create a new Watchman
      </Text>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Watchman"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Watchman</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Host</FormLabel>
                <Input
                  type="name"
                  value={state.host}
                  onChange={(e) =>
                    setState((s) => ({ ...s, host: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>API Key</FormLabel>
                <Input
                  type="name"
                  value={state.api_key}
                  onChange={(e) =>
                    setState((s) => ({ ...s, api_key: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Enabled</FormLabel>
                <Switch
                  isChecked={state.enabled}
                  onChange={(e) =>
                    setState((s) => ({ ...s, enabled: e.target.checked }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={
                state.name === undefined ||
                state.host === undefined ||
                state.api_key === undefined
              }
              onClick={handleCreate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const EditWatchmanButton = ({ watchman }: { watchman: Watchman }) => {
  const [state, setState] = useState<Watchman>(watchman);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateWatchmanMutation();

  const handleUpdate = () => {
    mutation.mutate(
      {
        watchman_id: state.id,
        name: state.name,
        host: state.host,
        api_key: state.api_key,
        enabled: state.enabled,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(watchman);
    }
  }, [isOpen, mutation, watchman]);

  useEffect(() => {
    if (
      !isOpen &&
      (watchman.name !== state.name ||
        watchman.host !== state.host ||
        watchman.api_key !== state.api_key ||
        watchman.enabled !== state.enabled)
    ) {
      setState(watchman);
    }
  }, [isOpen, watchman, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Watchman"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Watchman</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Host</FormLabel>
                <Input
                  type="name"
                  value={state.host}
                  onChange={(e) =>
                    setState((s) => ({ ...s, host: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>API Key</FormLabel>
                <Input
                  type="name"
                  value={state.api_key}
                  onChange={(e) =>
                    setState((s) => ({ ...s, api_key: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Enabled</FormLabel>
                <Switch
                  isChecked={state.enabled}
                  onChange={(e) =>
                    setState((s) => ({ ...s, enabled: e.target.checked }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUpdate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteWatchmanButton = ({ watchman }: { watchman: Watchman }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useDeleteWatchmanMutation();

  const handleDelete = () => {
    mutation.mutate(
      {
        watchman_id: watchman.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Watchman
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete the Watchman{" "}
                  <b>
                    {watchman.name} ({watchman.location})
                  </b>
                  ?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const WatchmansPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: string | undefined;
    networkId: number | undefined;
    online: boolean | undefined;
  }>({
    page: 0,
    size: 10,
    query: undefined,
    networkId: undefined,
    online: undefined,
  });

  const query = useDataWatchmansQuery({
    page: state.page,
    size: state.size,
    query: state.query,
    networkId: state.networkId,
    online: state.online,
  });

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query:
        e.target.value != null && e.target.value.length > 0
          ? e.target.value
          : undefined,
    }));
  };

  return (
    <Panel title={"Watchmans"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={searchCallback}
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
            </HStack>
            <CreateWatchmanButton />
          </HStack>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>Location</Th>
                <Th>Host</Th>
                <Th>Name</Th>
                <Th>API Key</Th>
                <Th>Enabled</Th>
                <Th>Online</Th>
                <Th>Temp 1</Th>
                <Th>Temp 2</Th>
                <Th>Temp 3</Th>
                <Th>Humidity</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((watchman) => {
                return (
                  <Tr key={`watchman-${watchman.id}`}>
                    <Td>{watchman.location}</Td>
                    <Td>
                      <a
                        href={`http://${watchman.host}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {watchman.host}
                      </a>
                    </Td>
                    <Td>{watchman.name}</Td>
                    <Td>{watchman.api_key}</Td>
                    <Td>
                      <Badge
                        variant="solid"
                        colorScheme={watchman.enabled ? "green" : "red"}
                      >
                        {watchman.enabled ? "enabled" : "disabled"}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge
                        variant="solid"
                        colorScheme={watchman.online ? "green" : "red"}
                      >
                        {watchman.online ? "online" : "offline"}
                      </Badge>
                    </Td>
                    <Td>
                      {watchman.online && watchman.temp1 != null && (
                        <Text
                          textAlign="center"
                          textColor={formatColor(watchman.temp1, 100, 110)}
                        >
                          {`${watchman.temp1.toFixed(1)}°C`}
                        </Text>
                      )}
                    </Td>
                    <Td>
                      {watchman.online && watchman.temp2 != null && (
                        <Text
                          textAlign="center"
                          textColor={formatColor(watchman.temp2, 100, 110)}
                        >
                          {`${watchman.temp2.toFixed(1)}°C`}
                        </Text>
                      )}
                    </Td>
                    <Td>
                      {watchman.online && watchman.temp3 != null && (
                        <Text
                          textAlign="center"
                          textColor={formatColor(watchman.temp3, 100, 110)}
                        >
                          {`${watchman.temp3.toFixed(1)}°C`}
                        </Text>
                      )}
                    </Td>
                    <Td>
                      {watchman.online && watchman.humidity != null && (
                        <Text
                          textAlign="center"
                          textColor={formatColor(watchman.humidity, 80, 90)}
                        >
                          {`${watchman.humidity.toFixed(1)}%`}
                        </Text>
                      )}
                    </Td>
                    <Td>
                      <EditWatchmanButton watchman={watchman} />
                      <DeleteWatchmanButton watchman={watchman} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
