import { useState, useRef, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  FormControl,
  FormLabel,
  Stack,
  HStack,
  VStack,
  Button,
  Thead,
  Code,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
  Select,
  Switch,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateMonitoringConfigMutation } from "src/api/mutations/monitoringConfig";
import {
  Network,
  useMonitoringConfigQuery,
} from "src/api/queries/fetchMonitoringConfig";
import { useMonitoringLogsQuery } from "src/api/queries/fetchMonitoringLogs";
import { MutationFeedback } from "src/components/MutationFeedback";
import { WarningIcon, SearchIcon, EditIcon } from "@chakra-ui/icons";
import { Panel } from "src/components/Panel";

const EditNetworkButton = ({ network }: { network: Network }) => {
  const [state, setState] = useState<Network>(network);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateMonitoringConfigMutation();

  const handleUpdate = () => {
    mutation.mutate(
      {
        network_id: state.id,
        ip_range_start: state.ip_range_start,
        ip_range_end: state.ip_range_end,
        secondary_ip_range_start: state.secondary_ip_range_start,
        secondary_ip_range_end: state.secondary_ip_range_end,
        autofix_enabled: state.autofix_enabled,
        capacity: state.capacity != null ? state.capacity.value : undefined,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(network);
    }
  }, [isOpen, mutation, network]);

  useEffect(() => {
    if (
      !isOpen &&
      (network.ip_range_start !== state.ip_range_start ||
        network.ip_range_end !== state.ip_range_end ||
        network.secondary_ip_range_start !== state.secondary_ip_range_start ||
        network.secondary_ip_range_end !== state.secondary_ip_range_end ||
        network.autofix_enabled !== state.autofix_enabled ||
        network.capacity?.value !== state.capacity?.value)
    ) {
      setState(network);
    }
  }, [isOpen, network, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Network"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Network</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Capacity</FormLabel>
                <NumberInput
                  value={state.capacity?.value}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      capacity: {
                        formatted: "",
                        value: isNaN(parseInt(e)) ? 0 : parseInt(e),
                      },
                    }))
                  }
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl id="name">
                <FormLabel>IP Range Start</FormLabel>
                <Input
                  type="name"
                  value={state.ip_range_start}
                  onChange={(e) =>
                    setState((s) => ({ ...s, ip_range_start: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>IP Range End</FormLabel>
                <Input
                  type="name"
                  value={state.ip_range_end}
                  onChange={(e) =>
                    setState((s) => ({ ...s, ip_range_end: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>Secondary IP Range Start</FormLabel>
                <Input
                  type="name"
                  value={
                    state.secondary_ip_range_start === null
                      ? undefined
                      : state.secondary_ip_range_start
                  }
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      secondary_ip_range_start:
                        e.target.value.length === 0 ? null : e.target.value,
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Secondary IP Range End</FormLabel>
                <Input
                  type="name"
                  value={
                    state.secondary_ip_range_end === null
                      ? undefined
                      : state.secondary_ip_range_end
                  }
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      secondary_ip_range_end:
                        e.target.value.length === 0 ? null : e.target.value,
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Autofix Enabled</FormLabel>
                <Switch
                  isChecked={
                    state.autofix_enabled != null
                      ? state.autofix_enabled
                      : undefined
                  }
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      autofix_enabled: e.target.checked,
                    }))
                  }
                  isDisabled={
                    mutation.isLoading || network.autofix_enabled == null
                  }
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUpdate}
              isDisabled={
                state.ip_range_start.length === 0 ||
                state.ip_range_end.length === 0 ||
                (state.secondary_ip_range_start === null) !==
                  (state.secondary_ip_range_end === null)
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const UpdateNodeButton = ({ network }: { network: Network }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useUpdateMonitoringConfigMutation();

  const handleUpdate = () => {
    mutation.mutate(
      {
        network_id: network.id,
        ip_range_start: network.ip_range_start,
        ip_range_end: network.ip_range_end,
        secondary_ip_range_start: network.secondary_ip_range_start,
        secondary_ip_range_end: network.secondary_ip_range_end,
        autofix_enabled: network.autofix_enabled,
        update: true,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button size="xs" isLoading={mutation.isLoading} onClick={onOpen}>
        Update Node
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Update Node
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>Are you sure you want to update this node?</Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="green"
                isLoading={mutation.isLoading}
                onClick={handleUpdate}
                ml={3}
              >
                Update
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const ShowLogsButton = ({ networkId }: { networkId: number }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [count, setCount] = useState<number>(100);
  const [filter, setFilter] = useState<string>("");
  const logsQuery = useMonitoringLogsQuery(networkId, count, isOpen);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Show Logs"
        icon={<WarningIcon />}
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Show Logs</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack mb={4}>
              <FormControl maxW="30rem">
                <FormLabel>Search Logs</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem" pl={2}>
                <FormLabel>Page Size</FormLabel>
                <Select
                  value={String(count)}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (!isNaN(value)) {
                      setCount(value);
                    }
                  }}
                >
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </Select>
              </FormControl>
            </HStack>
            <Stack mb={4}>
              {logsQuery.data != null &&
                logsQuery.data
                  .filter((item) =>
                    item.msg.toLowerCase().includes(filter.toLowerCase())
                  )
                  .map((item, idx) => (
                    <HStack key={`log-${idx}`}>
                      <Code colorScheme="green" minWidth="200px">
                        {new Date(item.timestamp * 1000).toLocaleString()}
                      </Code>
                      <Code
                        colorScheme={item.level === "error" ? "red" : "yellow"}
                      >
                        {item.level}
                      </Code>
                      <Code>{item.msg}</Code>
                    </HStack>
                  ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const ConfigPanel = () => {
  const query = useMonitoringConfigQuery();

  return (
    <Panel title={"Networks"} flex={3} query={query}>
      {(data) => (
        <>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Host</Th>
                <Th>Domain</Th>
                <Th>Capacity</Th>
                <Th>IP Ranges</Th>
                <Th>AwesomeMiner Online</Th>
                <Th>AutoFix Enabled</Th>
                <Th>Node Version</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((network) => (
                <Tr key={network.id}>
                  <Td>
                    {network.name} ({network.alias})
                  </Td>
                  <Td>{network.host}</Td>
                  <Td>{network.domain}</Td>
                  <Td>{network.capacity?.formatted}</Td>
                  <Td>
                    <VStack>
                      <div>
                        {network.ip_range_start} - {network.ip_range_end}
                      </div>
                      {network.secondary_ip_range_start !== null &&
                        network.secondary_ip_range_end !== null && (
                          <div>
                            {network.secondary_ip_range_start} -{" "}
                            {network.secondary_ip_range_end}
                          </div>
                        )}
                    </VStack>
                  </Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={
                        network.awesomeminer_down == null
                          ? "gray"
                          : !network.awesomeminer_down
                          ? "green"
                          : "red"
                      }
                    >
                      {network.awesomeminer_down == null
                        ? "N/A"
                        : !network.awesomeminer_down
                        ? "online"
                        : "offline"}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={
                        network.autofix_enabled == null
                          ? "gray"
                          : network.autofix_enabled
                          ? "green"
                          : "red"
                      }
                    >
                      {network.autofix_enabled == null
                        ? "N/A"
                        : network.autofix_enabled
                        ? "enabled"
                        : "disabled"}
                    </Badge>
                  </Td>
                  <Td>
                    {network.node_version != null && (
                      <HStack>
                        <Badge variant="solid" colorScheme="green">
                          {network.node_version}
                        </Badge>
                        <UpdateNodeButton network={network} />
                        <ShowLogsButton networkId={network.id} />
                      </HStack>
                    )}
                  </Td>
                  <Td>
                    <EditNetworkButton network={network} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Panel>
  );
};
