import { useEffect, useState } from "react";
import {
  Button,
  NumberInput,
  NumberInputField,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { Ticket } from "src/api/queries/fetchTicket";
import {
  useTicketCostModelsQuery,
  TicketCostModel,
} from "src/api/queries/fetchTicketCostModels";
import {
  useUpdateTicketMutation,
  CostArgs,
} from "src/api/mutations/ticketCreate";
import {
  SearchableItem,
  SearchableSelect,
} from "src/components/SearchableSelect";
import { MutationFeedback } from "src/components/MutationFeedback";

export const CostsPanel = ({ ticket }: { ticket: Ticket }) => {
  const [selectedCost, setSelectedCost] = useState<SearchableItem | undefined>(
    undefined
  );

  const [cost, setCost] = useState<CostArgs | undefined>(undefined);
  const [costModels, setCostModels] = useState<TicketCostModel[]>([]);
  const [costOptions, setCostOptions] = useState<SearchableItem[]>([]);
  const [clearCost, setClearCost] = useState<boolean>(false);
  const query = useTicketCostModelsQuery();
  const mutation = useUpdateTicketMutation();

  useEffect(() => {
    if (query.data == null) {
      return;
    }
    setCostModels(query.data.items.map((category) => category.models).flat());
    let items: SearchableItem[] = [];
    query.data.items.forEach((category) => {
      category.models.forEach((model) => {
        items.push({
          value: String(model.id),
          label: category.name + " > " + model.name,
        });
      });
    });

    setCostOptions(items);
  }, [query.data]);

  const loadCostOptions = async (query: string) => {
    return costOptions.filter((costOption) =>
      costOption.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <Panel title={"Costs"}>
      <MutationFeedback mutation={mutation} />
      <Table size="sm" mb={4} variant={"striped"}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Quantity</Th>
            <Th>Unit Price</Th>
            <Th>Cost</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {ticket.costs?.map((cost) => (
            <Tr key={`cost-${cost.id}`}>
              <Td>{cost.name}</Td>
              <Td>{cost.quantity}</Td>
              <Td>${cost.cost}</Td>
              <Td>${parseFloat(cost.cost) * cost.quantity}</Td>
              <Td>
                {!ticket.checked && (
                  <Button
                    colorScheme="red"
                    isDisabled={mutation.isLoading}
                    onClick={() =>
                      mutation.mutate({
                        ticket_id: ticket.id,
                        assignee_id: ticket.assignee_id,
                        name: ticket.name,
                        status: ticket.status_id,
                        categories: [],
                        delete_categories: [],
                        costs: [],
                        delete_costs: [cost.id],
                        repair_facility_id: ticket.repair_facility_id,
                        rma_id: ticket.rma_id,
                        checked: ticket.checked,
                      })
                    }
                  >
                    Delete
                  </Button>
                )}
              </Td>
            </Tr>
          ))}
          {!ticket.checked && (
            <Tr>
              <Td minW="350px">
                <SearchableSelect
                  name={"costs"}
                  placeholder={"Search a cost"}
                  value={selectedCost}
                  defaultOptions={costOptions}
                  loadOptions={loadCostOptions}
                  shouldClear={clearCost}
                  onSelect={(item) => {
                    const costModel = costModels.find(
                      (costModel) => String(costModel.id) === item.value
                    );
                    if (costModel != null) {
                      setCost({
                        model_id: costModel.id,
                        cost: costModel.cost,
                        quantity: 1,
                      });
                      setSelectedCost(item);
                    }
                  }}
                />
              </Td>
              <Td maxW="100px">
                <NumberInput
                  textAlign="center"
                  maxW={"5rem"}
                  p={0}
                  value={cost?.quantity == null ? 0 : cost.quantity}
                  onChange={(e) => {
                    let value = parseInt(e);
                    if (isNaN(value)) {
                      value = 0;
                    }
                    if (cost != null) {
                      setCost((s) => ({ ...s!, quantity: value }));
                    }
                  }}
                  isDisabled={cost == null || mutation.isLoading}
                >
                  <NumberInputField p={0} pl={2} />
                </NumberInput>
              </Td>
              <Td maxW="100px">
                <NumberInput
                  textAlign="center"
                  minW={"3rem"}
                  maxW={"10rem"}
                  p={0}
                  value={"$" + (cost == null ? "0" : cost.cost)}
                  onChange={(e) => {
                    if (cost != null) {
                      setCost((s) => ({ ...s!, cost: e }));
                    }
                  }}
                  isDisabled={cost == null || mutation.isLoading}
                >
                  <NumberInputField p={0} pl={1} />
                </NumberInput>
              </Td>
              <Td>
                {"$" +
                  (cost != null ? parseFloat(cost.cost) * cost.quantity : "0")}
              </Td>
              <Td>
                <Button
                  colorScheme="teal"
                  isDisabled={
                    cost == null || cost.quantity <= 0 || mutation.isLoading
                  }
                  onClick={() =>
                    mutation.mutate(
                      {
                        ticket_id: ticket.id,
                        assignee_id: ticket.assignee_id,
                        name: ticket.name,
                        status: ticket.status_id,
                        categories: [],
                        delete_categories: [],
                        costs: [cost!],
                        delete_costs: [],
                        repair_facility_id: ticket.repair_facility_id,
                        rma_id: ticket.rma_id,
                        checked: ticket.checked,
                      },
                      {
                        onSuccess: () => {
                          setSelectedCost(undefined);
                          setCost(undefined);
                          setClearCost(true);
                          setTimeout(() => setClearCost(false), 250);
                        },
                      }
                    )
                  }
                >
                  Add
                </Button>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Panel>
  );
};
