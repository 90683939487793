import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { ConsultingBuilderPanel } from "./panels/ConsultingBuilderPanel";

export const ConsultingAgreementBuilderPage = () => {
  return (
    <Page title="Consulting Agreement Builder">
      <PanelRow>
        <ConsultingBuilderPanel />
      </PanelRow>
    </Page>
  );
};
