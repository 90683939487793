import { useEffect } from "react";
import { useLocation } from "react-router";
import { useUserContext } from "src/contexts/UserContext";

declare global {
  interface Window {
    gtag: any;
  }
}

export const useGoogleAnalytics = () => {
  const { user } = useUserContext();

  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "G-50BBYPK4RN", {
      user_id: user.id,
    });
  }, [user.id]);

  useEffect(() => {
    window.gtag("set", "user_properties", user);
  }, [user]);

  const location = useLocation();
  useEffect(() => {
    window.gtag("event", "page_view");
  }, [location]);
};
