import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  page: number;
  size: number;
};

export type AffiliateModel = {
  id: number;
  name: string;
  commission_power_rate?: number;
  commission_percentage?: number;
  created_at: string;
};

export const fetchContractAffiliateModels = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<AffiliateModel>>("/contract/affiliate/models", {
      params: args,
    })
    .then((res) => res.data.data);

export const useContractAffiliateModelsQuery = (args: QueryArgs) =>
  useQuery(
    ["/contract/affiliate/models", args],
    () => fetchContractAffiliateModels(args),
    { keepPreviousData: true }
  );
