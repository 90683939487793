import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  HStack,
  Link,
  FormControl,
  FormLabel,
  Thead,
  Badge,
  VStack,
} from "@chakra-ui/react";
import { useDataMinersWithIssuesQuery } from "src/api/queries/fetchDataMinersWithIssues";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";

export const MinersWithIssuesPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    type: string;
  }>({
    page: 0,
    size: 10,
    type: "duplicate_name",
  });

  const query = useDataMinersWithIssuesQuery({
    page: state.page,
    size: state.size,
    type: state.type,
  });

  return (
    <Panel title={"Miners With Issues"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"start"}>
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </FormControl>
            <FormControl maxW="15rem">
              <FormLabel>Status</FormLabel>
              <Select
                value={state.type}
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    page: 0,
                    type: e.target.value,
                  }))
                }
              >
                <option value={"duplicate_name"}>Duplicate Name</option>
                <option value={"wrong_alias"}>Wrong Alias</option>
                <option value={"not_ided"}>Not ID'ed</option>
              </Select>
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>IP Address</Th>
                <Th>Status</Th>
                <Th>Location Name</Th>
                <Th>Location Alias</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((miner) => {
                return (
                  <Tr key={`miner-${miner.id}`}>
                    <Td>
                      <Link
                        as={ReactRouterLink}
                        to={`/miners/${miner.id}`}
                        fontWeight="bold"
                      >
                        {miner.id}
                      </Link>
                    </Td>
                    <Td>{miner.name}</Td>
                    <Td>
                      <a
                        href={`http://${miner.ip_address}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {miner.ip_address}
                      </a>
                    </Td>
                    <Td>
                      <VStack>
                        <Badge
                          variant="solid"
                          colorScheme={miner.enabled ? "green" : "red"}
                        >
                          {miner.enabled ? "enabled" : "disabled"}
                        </Badge>
                        <Badge
                          variant="solid"
                          colorScheme={miner.online ? "green" : "red"}
                        >
                          {miner.online ? "online" : "offline"}
                        </Badge>
                      </VStack>
                    </Td>
                    <Td>{miner.location_name}</Td>
                    <Td>{miner.location_alias}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
