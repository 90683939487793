import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  code: string; // users 2fa code
};

export const userChange2FA = (body: PostBody) => {
  return apiClient.post<
    APIResponse<{
      "2fa_seed": string;
    }>
  >("/user/change_2fa", body);
};
