import { Button } from "@chakra-ui/react";
import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { ChangePasswordPanel } from "./panels/ChangePasswordPanel";
import { Reset2FAPanel } from "./panels/Reset2FAPanel";
import { ChangeEmailAddressPanel } from "./panels/ChangeEmailAddressPanel";
import { useUserContext } from "src/contexts/UserContext";

export const SettingsPage = () => {
  const { logout } = useUserContext();
  return (
    <Page
      title="Settings"
      cornerContent={
        <Button colorScheme="red" onClick={logout}>
          Sign out
        </Button>
      }
    >
      <PanelRow>
        <ChangePasswordPanel />
        <Reset2FAPanel />
      </PanelRow>
      <PanelRow>
        <ChangeEmailAddressPanel />
      </PanelRow>
    </Page>
  );
};
