import { Link as ReactRouterLink } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import {
  Tr,
  Td,
  Text,
  Flex,
  LinkBox,
  LinkOverlay,
  Center,
  Badge,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Button,
  IconButton,
  Checkbox,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Invoice } from "src/api/queries/fetchInvoices";

export enum ShowMinerType {
  Hide = "hide",
  UptimeUnchecked = "uptime unchecked",
  BitfixUnchecked = "bitfix unchecked",
  Ticketed = "ticketed",
  All = "all",
}

export type InvoicePanelState = {
  startDate: Date;
  endDate: Date;
  query: null | string;
  term: null | number;
  status: null | number;
  page: number;
  size: number;
  showOnlyBatched?: boolean | null;
  showOnlyOverdue: boolean;
  showOnlyChecked?: boolean | null;
  showMiners: ShowMinerType;
  showSingleMiner: null | number;
  editModeEnabled: boolean;
};

type InvoiceRowProps = {
  isBatched?: boolean;
  invoice: Invoice;
  state: InvoicePanelState;
  setShowSingleMiner: (singleMiner: number | null) => void;
  coverages: Record<number, string | undefined>;
  setCoverages: Dispatch<SetStateAction<Record<number, string | undefined>>>;
  uptimes: Record<number, string | undefined>;
  setUptimes: Dispatch<SetStateAction<Record<number, string | undefined>>>;
  notes: Record<number, string | undefined>;
  setNotes: Dispatch<SetStateAction<Record<number, string | undefined>>>;
  minerMutation: any;
  invoiceMutation: any;
};

export const InvoiceRow = ({
  isBatched = false,
  invoice,
  state,
  setShowSingleMiner,
  coverages,
  setCoverages,
  uptimes,
  setUptimes,
  notes,
  setNotes,
  minerMutation,
  invoiceMutation,
}: InvoiceRowProps) => {
  return (
    <>
      <Tr>
        {isBatched && <Td />}
        <Td>{invoice.contract_name}</Td>
        {!isBatched && <Td>{invoice.client_name}</Td>}
        <Td>
          {invoice.idx > 0
            ? `#${invoice.idx}`
            : invoice.idx === 0
            ? "Deposit"
            : "End"}
        </Td>
        <Td>
          ${invoice.balance} / ${invoice.cost} / ${invoice.estimated_cost}
        </Td>
        <Td>
          {invoice.idx === 0
            ? "N/A"
            : invoice.uptime == null
            ? ""
            : `${invoice.uptime.toFixed(2)}%`}
        </Td>
        <Td>
          {invoice.quickbooks_id == null || invoice.name == null ? (
            <b>Not found</b>
          ) : invoice.quickbooks_id === "0" ? (
            <b>Virtual Invoice</b>
          ) : (
            <a
              href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.quickbooks_id}`}
              target="_blank"
              rel="noreferrer"
            >
              <b>{invoice.name}</b>
            </a>
          )}
        </Td>
        <Td>
          {invoice.quickbooks_id !== "0" && (
            <>
              <Text>{invoice.status}</Text>
              {!isBatched && invoice.overdue && (
                <Badge variant="solid" size="xm" colorScheme={"red"}>
                  {"overdue"}
                </Badge>
              )}
            </>
          )}
        </Td>
        {!isBatched && (
          <Td>
            {new Date(invoice.due_at).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })}
          </Td>
        )}
        <Td>
          {invoice.online_miner_count} / {invoice.miner_count} /{" "}
          {invoice.qbo_miner_count}
        </Td>
        <Td>
          <Center>
            <Checkbox
              isChecked={invoice.checked}
              onChange={(e) =>
                invoiceMutation.mutate({
                  invoice_id: invoice.id,
                  checked: e.target.checked,
                })
              }
            />
          </Center>
        </Td>
        <Td>
          <VStack>
            {invoice.groups?.map((group) =>
              group.miners
                .filter((miner) => miner.note != null)
                .map((miner) => (
                  <Text wordBreak="break-all">
                    {miner.name != null ? miner.name + ": " : "Unknown: "}
                    {miner.note}
                  </Text>
                ))
            )}
          </VStack>
        </Td>
        <Td>
          <LinkBox
            bg="blackAlpha.100"
            p={3}
            borderRadius="md"
            _hover={{ bg: "blackAlpha.200" }}
            width="fit-content"
            minWidth="100%"
          >
            <LinkOverlay
              fontWeight="bold"
              as={ReactRouterLink}
              to={`/invoices/${invoice.id}`}
            >
              View
            </LinkOverlay>
          </LinkBox>
        </Td>
        <Td>
          <IconButton
            size="xs"
            colorScheme="blackAlpha"
            variant="ghost"
            aria-label="Show miners"
            icon={
              state.showMiners === ShowMinerType.All ||
              state.showSingleMiner === invoice.id ? (
                <TriangleUpIcon />
              ) : (
                <TriangleDownIcon />
              )
            }
            onClick={() => {
              if (state.showSingleMiner === invoice.id) {
                setShowSingleMiner(null);
              } else {
                setShowSingleMiner(invoice.id);
              }
            }}
          />
        </Td>
      </Tr>
      {((state.showMiners !== ShowMinerType.Hide &&
        state.showSingleMiner === null) ||
        state.showSingleMiner === invoice.id) && (
        <>
          {invoice.groups?.map((group) => {
            let miners = group.miners;
            if (state.showSingleMiner == null) {
              if (state.showMiners === ShowMinerType.UptimeUnchecked) {
                miners = miners.filter((miner) => !miner.uptime_checked);
              } else if (state.showMiners === ShowMinerType.BitfixUnchecked) {
                miners = miners.filter((miner) => !miner.bitfix_checked);
              } else if (state.showMiners === ShowMinerType.Ticketed) {
                miners = miners.filter((miner) => miner.tickets.length! > 0);
              }
            }
            const uncheckedMinerIDs = miners
              .filter((miner) => !miner.uptime_checked)
              .map((miner) => miner.id);

            return miners.length === 0 ? (
              <></>
            ) : (
              <Tr key={group.id}>
                <Td colSpan={isBatched ? 13 : 13} pr={0}>
                  <Flex mx={8} alignItems="center" justifyContent="flex-start">
                    <Text>
                      <b>{group.name}</b> @ {group.power_draw}W |{" "}
                      {group.activated} / {group.total}
                    </Text>
                    <Button
                      mx={4}
                      size="sm"
                      colorScheme="green"
                      disabled={
                        uncheckedMinerIDs.length === 0 || !state.editModeEnabled
                      }
                      onClick={() =>
                        minerMutation.mutate({
                          invoice_id: invoice.id,
                          miner_ids: uncheckedMinerIDs,
                          uptime_checked: true,
                        })
                      }
                    >
                      Check All (Uptime)
                    </Button>
                  </Flex>
                  <Flex flexWrap={"wrap"} justifyContent="left">
                    {miners.map((miner) => (
                      <HStack
                        key={miner.id}
                        p={2}
                        m={1}
                        borderRadius={"md"}
                        borderWidth="1px"
                        bg={"whiteAlpha.500"}
                        textAlign="center"
                        alignItems="center"
                      >
                        <VStack w="10rem">
                          <Text
                            p={1}
                            w="full"
                            textAlign="center"
                            wordBreak="break-all"
                          >
                            <b>{miner.name}</b>
                          </Text>
                          <Text
                            p={1}
                            w="full"
                            textAlign="center"
                            wordBreak="break-all"
                          >
                            {miner.activated_at != null
                              ? `Activated: ${new Date(
                                  miner.activated_at
                                ).toLocaleDateString()}`
                              : "Activated: Unknown"}
                          </Text>
                          {miner.tickets?.map((ticket) => (
                            <>
                              <Text
                                py={1}
                                w="full"
                                textAlign="center"
                                wordBreak="break-all"
                              >
                                <a
                                  href={`https://app.bitcap.co/tickets/${ticket.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i>Ticket #{ticket.id}</i>
                                </a>
                                {`: ${ticket.status}`}
                              </Text>
                            </>
                          ))}
                          <HStack>
                            <Badge
                              variant="solid"
                              size="xm"
                              colorScheme={miner.online ? "green" : "red"}
                            >
                              {miner.online ? "online" : "offline"}
                            </Badge>
                            {miner.is_unpaid && (
                              <Badge
                                variant="solid"
                                size="xm"
                                colorScheme={"red"}
                              >
                                unpaid
                              </Badge>
                            )}
                            {miner.is_bitfix && (
                              <Badge
                                variant="solid"
                                size="xm"
                                colorScheme={"red"}
                              >
                                bitfix
                              </Badge>
                            )}
                          </HStack>
                          <Badge
                            variant="solid"
                            size="xm"
                            colorScheme={miner.uptime_checked ? "green" : "red"}
                          >
                            {miner.uptime_checked
                              ? "uptime checked"
                              : "uptime unchecked"}
                          </Badge>
                          <Badge
                            variant="solid"
                            size="xm"
                            colorScheme={miner.bitfix_checked ? "green" : "red"}
                          >
                            {miner.bitfix_checked
                              ? "bitfix checked"
                              : "bitfix unchecked"}
                          </Badge>
                        </VStack>
                        <VStack w="10rem">
                          <InputGroup size="sm">
                            <InputLeftAddon>On</InputLeftAddon>
                            <NumberInput
                              min={0}
                              max={100}
                              value={
                                coverages[miner.id] != null
                                  ? coverages[miner.id]
                                  : miner.coverage != null
                                  ? miner.coverage.toFixed(1)
                                  : "0"
                              }
                              onChange={(e) =>
                                setCoverages((s) => ({
                                  ...s,
                                  [miner.id]: e,
                                }))
                              }
                            >
                              <NumberInputField
                                padding={0}
                                textAlign="center"
                              />
                            </NumberInput>
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                          <InputGroup size="sm">
                            <InputLeftAddon>Up</InputLeftAddon>
                            <NumberInput
                              min={0}
                              max={100}
                              value={
                                uptimes[miner.id] != null
                                  ? uptimes[miner.id]
                                  : miner.uptime != null
                                  ? miner.uptime.toFixed(1)
                                  : "0"
                              }
                              onChange={(e) =>
                                setUptimes((s) => ({
                                  ...s,
                                  [miner.id]: e,
                                }))
                              }
                            >
                              <NumberInputField
                                padding={0}
                                textAlign="center"
                              />
                            </NumberInput>
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                          <InputGroup size="sm">
                            <InputLeftAddon>Up Disc</InputLeftAddon>
                            <NumberInput
                              value={
                                miner.uptime_discount != null
                                  ? miner.uptime_discount.toFixed(1)
                                  : "0"
                              }
                            >
                              <NumberInputField
                                padding={0}
                                textAlign="center"
                              />
                            </NumberInput>
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                          <InputGroup size="sm">
                            <InputLeftAddon>Act Disc</InputLeftAddon>
                            <NumberInput
                              value={
                                miner.activation_discount != null
                                  ? miner.activation_discount.toFixed(1)
                                  : "0"
                              }
                            >
                              <NumberInputField
                                padding={0}
                                textAlign="center"
                              />
                            </NumberInput>
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                          <InputGroup size="sm">
                            <InputLeftAddon>Fix Disc</InputLeftAddon>
                            <NumberInput
                              value={
                                miner.bitfix_discount != null
                                  ? miner.bitfix_discount.toFixed(1)
                                  : "0"
                              }
                            >
                              <NumberInputField
                                padding={0}
                                textAlign="center"
                              />
                            </NumberInput>
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                          <InputGroup size="sm">
                            <InputLeftAddon>Unp Disc</InputLeftAddon>
                            <NumberInput
                              value={
                                miner.unpaid_discount != null
                                  ? miner.unpaid_discount.toFixed(1)
                                  : "0"
                              }
                            >
                              <NumberInputField
                                padding={0}
                                textAlign="center"
                              />
                            </NumberInput>
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                          <InputGroup size="xs">
                            <Input
                              p="0px"
                              textAlign="center"
                              value={`C:${miner.internal_coverage?.toFixed(
                                1
                              )}% U:${miner.internal_uptime?.toFixed(1)}%`}
                              disabled={true}
                            />
                          </InputGroup>
                          <InputGroup size="sm">
                            <Input
                              placeholder="Write a note"
                              textAlign="center"
                              value={
                                notes[miner.id] != null
                                  ? notes[miner.id]!
                                  : miner.note
                              }
                              onChange={(e) => {
                                setNotes((s) => ({
                                  ...s,
                                  [miner.id]: e.target.value,
                                }));
                              }}
                            />
                          </InputGroup>
                          <Button
                            size="sm"
                            disabled={
                              !state.editModeEnabled ||
                              (coverages[miner.id] == null &&
                                uptimes[miner.id] == null &&
                                notes[miner.id] == null) ||
                              (coverages[miner.id] != null &&
                                isNaN(parseFloat(coverages[miner.id]!))) ||
                              (uptimes[miner.id] != null &&
                                isNaN(parseFloat(uptimes[miner.id]!)))
                            }
                            onClick={() => {
                              minerMutation.mutate({
                                invoice_id: invoice.id,
                                miner_ids: [miner.id],
                                coverage:
                                  coverages[miner.id] != null
                                    ? parseFloat(coverages[miner.id]!)
                                    : undefined,
                                uptime:
                                  uptimes[miner.id] != null
                                    ? parseFloat(uptimes[miner.id]!)
                                    : undefined,
                                note: notes[miner.id],
                              });
                              setCoverages((s) => ({
                                ...s,
                                [miner.id]: undefined,
                              }));
                              setUptimes((s) => ({
                                ...s,
                                [miner.id]: undefined,
                              }));
                              setNotes((s) => ({
                                ...s,
                                [miner.id]: undefined,
                              }));
                            }}
                          >
                            Update
                          </Button>
                          <Button
                            size="sm"
                            colorScheme="green"
                            disabled={!state.editModeEnabled}
                            onClick={() =>
                              minerMutation.mutate({
                                invoice_id: invoice.id,
                                miner_ids: [miner.id],
                                uptime_checked: !miner.uptime_checked,
                              })
                            }
                          >
                            {miner.uptime_checked
                              ? "Uncheck Uptime"
                              : "Check Uptime"}
                          </Button>
                          <Button
                            size="sm"
                            colorScheme="green"
                            disabled={!state.editModeEnabled}
                            onClick={() =>
                              minerMutation.mutate({
                                invoice_id: invoice.id,
                                miner_ids: [miner.id],
                                bitfix_checked: !miner.bitfix_checked,
                              })
                            }
                          >
                            {miner.bitfix_checked
                              ? "Uncheck Bitfix"
                              : "Check Bitfix"}
                          </Button>
                        </VStack>
                      </HStack>
                    ))}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </>
      )}
    </>
  );
};
