import { FC } from "react";
import { Box, Code, Stack } from "@chakra-ui/react";

export const StaticField: FC<{
  label: string;
  value: string | null;
  pending: boolean;
}> = ({ label, value, pending = false }) => {
  if (value === null) {
    return null;
  }
  return (
    <Stack spacing={1} mb={2}>
      <Box fontStyle={pending ? "italic" : "initial"} mr="4">
        {label}:
      </Box>
      <Box flex="1">
        <Code>{value}</Code>
      </Box>
    </Stack>
  );
};
