import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useUserContext } from "src/contexts/UserContext";
import { UserType } from "src/types";
import { SidebarLayout } from "src/components/SidebarLayout";
import { TasksPage } from "./admin/TasksPage";
import { UsersPage } from "./admin/UsersPage";
import { MonitoringPage } from "./admin/MonitoringPage";
import { TicketsPage } from "./admin/TicketsPage";
import { TicketPage } from "./admin/TicketPage";
import { LogisticsPage } from "./admin/LogisticsPage";
import { PduPage } from "./admin/PduPage";
import { ContractsPage } from "./admin/ContractsPage";
import { ContractBuilderPage } from "./admin/ContractBuilderPage";
import { ConsultingAgreementBuilderPage } from "./admin/ConsultingAgreementBuilderPage";
import { ConsultingAgreementsPage } from "./admin/ConsultingAgreementsPage";
import { InvoicesPage } from "./admin/InvoicesPage";
import { InvoicePage } from "./admin/InvoicePage";
import { CombinedBuilderPage } from "./admin/CombinedBuilderPage";
import { ActivationPage } from "./admin/ActivationPage";
import { RevenuePage } from "./admin/RevenuePage";
import { OverviewPage } from "./client/OverviewPage";
import { MinerPage } from "./client/MinerPage";
import { ClientMinersPage } from "./client/ClientMinersPage";
import { ClientContractGroupsPage } from "./client/ClientContractGroupsPage";
import { ClientContractGroupPage } from "./client/ClientContractGroupPage";
import { SettingsPage } from "./client/SettingsPage";
import { MinerIssuesPage } from "./admin/MinerIssuesPage";
import { MinerGroupsPage } from "./admin/MinerGroupsPage";
import { MinerGroupPage } from "./admin/MinerGroupPage";
import { FourOhFourPage } from "../FourOhFourPage";

type AuthedRouteConfig = {
  path: string;
  component: () => JSX.Element;
  sidebarLabel: string;
  userTypesToSidebar: UserType[];
  userTypesAllowed: UserType[];
};
export const authedRoutes: AuthedRouteConfig[] = [
  {
    path: "/",
    component: OverviewPage,
    sidebarLabel: "Overview",
    userTypesToSidebar: ["client", "admin"],
    userTypesAllowed: ["client", "admin"],
  },
  {
    path: "/groups",
    component: MinerGroupsPage,
    sidebarLabel: "Miner Groups",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/groups/filter/:id",
    component: MinerGroupsPage,
    sidebarLabel: "Miner Groups",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/groups/:id",
    component: MinerGroupPage,
    sidebarLabel: "Miner Group",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/issues",
    component: MinerIssuesPage,
    sidebarLabel: "Miner Issues",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/contracts",
    component: ClientContractGroupsPage,
    sidebarLabel: "Contracts",
    userTypesToSidebar: ["client"],
    userTypesAllowed: ["client"],
  },
  {
    path: "/contracts/:id",
    component: ClientContractGroupPage,
    sidebarLabel: "Contract",
    userTypesToSidebar: [],
    userTypesAllowed: ["client"],
  },
  {
    path: "/miners/:id",
    component: MinerPage,
    sidebarLabel: "Miner",
    userTypesToSidebar: [],
    userTypesAllowed: ["client", "admin"],
  },
  {
    path: "/miners",
    component: ClientMinersPage,
    sidebarLabel: "Miners",
    userTypesToSidebar: ["client"],
    userTypesAllowed: ["client"],
  },
  {
    path: "/monitoring",
    component: MonitoringPage,
    sidebarLabel: "Monitoring",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/contracts",
    component: ContractsPage,
    sidebarLabel: "Contracts",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/contract-builder",
    component: ContractBuilderPage,
    sidebarLabel: "Contract Builder",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/contract-builder/:id",
    component: ContractBuilderPage,
    sidebarLabel: "Contract Builder",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/consulting-agreements",
    component: ConsultingAgreementsPage,
    sidebarLabel: "Consulting",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/consulting-agreement-builder",
    component: ConsultingAgreementBuilderPage,
    sidebarLabel: "Consulting Agreement Builder",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/invoices",
    component: InvoicesPage,
    sidebarLabel: "Invoices",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/invoices/:id",
    component: InvoicePage,
    sidebarLabel: "Invoices",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/tickets",
    component: TicketsPage,
    sidebarLabel: "Tickets",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/tickets/:id",
    component: TicketPage,
    sidebarLabel: "Ticket",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/logistics",
    component: LogisticsPage,
    sidebarLabel: "Logistics",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/combined-builder",
    component: CombinedBuilderPage,
    sidebarLabel: "Combined Builder",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/revenue",
    component: RevenuePage,
    sidebarLabel: "Revenue",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/activation",
    component: ActivationPage,
    sidebarLabel: "Activation",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/pdus/:id",
    component: PduPage,
    sidebarLabel: "PDU",
    userTypesToSidebar: [],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/tasks",
    component: TasksPage,
    sidebarLabel: "Admin Tasks",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/users",
    component: UsersPage,
    sidebarLabel: "Manage Users",
    userTypesToSidebar: ["admin"],
    userTypesAllowed: ["admin"],
  },
  {
    path: "/settings",
    component: SettingsPage,
    sidebarLabel: "Settings",
    userTypesToSidebar: ["client", "admin"],
    userTypesAllowed: ["client", "admin"],
  },
];

declare global {
  interface Window {
    $chatwoot: any;
  }
}

export const AuthenticatedRouter = () => {
  const { user } = useUserContext();
  useEffect(() => {
    if (user != null && !user.emulated) {
      window.$chatwoot.setUser(user.id, {
        email: user.email.current,
        name: user.client_name
          ? user.client_name
          : user.first_name + " " + user.last_name,
      });
    }
  }, [user]);

  return (
    <>
      <Switch>
        {authedRoutes.map((route) => {
          if (!user) {
            return <Redirect to="/login" />;
          } else if (!route.userTypesAllowed.includes(user.type)) {
            return null;
          } else {
            return (
              <Route
                exact
                key={route.path}
                path={route.path}
                render={() => {
                  return (
                    <SidebarLayout>
                      <route.component />
                    </SidebarLayout>
                  );
                }}
              />
            );
          }
        })}
        <Route path="*">
          <FourOhFourPage />
        </Route>
      </Switch>
    </>
  );
};
