import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { useDataDashboardQuery } from "src/api/queries/fetchDataDashboard";
import { Panel } from "src/components/Panel";

export const AlgorithmsPanel = () => {
  const query = useDataDashboardQuery();

  return (
    <Panel title="Algorithms" flex={2} query={query}>
      {(data) => (
        <Table size="sm" variant="striped">
          <Tbody>
            {data.algorithms?.map((algorithm) => (
              <Tr key={algorithm.name}>
                <Td>{algorithm.name}</Td>
                <Td isNumeric>{algorithm.count} Active</Td>
                <Td isNumeric>{algorithm.hashrate.formatted}</Td>
                <Td isNumeric>{algorithm.revenue.formatted}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Panel>
  );
};
