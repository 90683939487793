import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  page: number;
  size: number;
};

export type ConsultingAgreement = {
  id: number;
  name: string;
  client_id: number;
  client_name: string;
  creator_id: number;
  creator_name: string;
  hellosign_id: string | null;
  file_id: string;
  status: string;
  cost: string;
  created_at: string;
};

export const fetchConsultingAgreements = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<ConsultingAgreement>>("/consulting_agreements", {
      params: args,
    })
    .then((res) => res.data.data);

export const useConsultingAgreementsQuery = (args: QueryArgs) =>
  useQuery(
    ["/consulting_agreements", args],
    () => fetchConsultingAgreements(args),
    {
      keepPreviousData: true,
    }
  );
