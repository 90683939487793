import { Box, CheckboxGroup, VStack, Checkbox, Text } from "@chakra-ui/react";
import { useAdminGroupsUnclaimedQuery } from "src/api/queries/fetchAdminGroupsUnclaimed";

type Props = {
  value: string[];
  onChange: (group_ids: string[]) => void;
};

export const GroupsInput = ({ value, onChange }: Props) => {
  const groupsQuery = useAdminGroupsUnclaimedQuery();
  return (
    <Box
      bg="gray.50"
      height={40}
      overflowY="scroll"
      borderWidth={1}
      borderRadius="md"
      p={4}
    >
      <CheckboxGroup value={value} onChange={onChange}>
        <VStack alignItems="baseline">
          {groupsQuery.data?.items
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => (
              <Checkbox key={group.id} value={group.id}>
                {group.name}
              </Checkbox>
            ))}
          {groupsQuery.data?.items.length === 0 && (
            <Text>No Unclaimed Groups</Text>
          )}
        </VStack>
      </CheckboxGroup>
    </Box>
  );
};
