import { useState, ChangeEvent } from "react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useMutation } from "react-query";
import {
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Switch,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from "@chakra-ui/icons";
import {
  useInvoicesQuery,
  fetchInvoicesExport,
} from "src/api/queries/fetchInvoices";
import { ShowMinerType, InvoicePanelState, InvoiceRow } from "./InvoiceRow";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { RadioCardGroup } from "src/components/RadioCard";
import { invoiceStatuses } from "src/types";
import { useUpdateInvoiceMutation } from "src/api/mutations/invoiceUpdate";
import { useUpdateInvoiceMinerMutation } from "src/api/mutations/invoiceMinerUpdate";
import { downloadCSV } from "src/lib/downloadFile";
import { trimDateToUTC } from "src/lib/standardizeDate";

export const InvoicesPanel = () => {
  const currentDate = new Date();
  const defaultStartDate = new Date().setDate(
    currentDate.getDate() - currentDate.getDay() + 22
  );
  const defaultEndDate = new Date(defaultStartDate).setDate(
    new Date(defaultStartDate).getDate() + 6
  );

  const [uptimes, setUptimes] = useState<Record<number, string | undefined>>(
    {}
  );
  const [coverages, setCoverages] = useState<
    Record<number, string | undefined>
  >({});
  const [notes, setNotes] = useState<Record<number, string | undefined>>({});

  const [state, setState] = useState<InvoicePanelState>({
    startDate: new Date(defaultStartDate),
    endDate: new Date(defaultEndDate),
    term: null,
    query: null,
    status: null,
    page: 0,
    size: 10,
    showOnlyBatched: false,
    showOnlyOverdue: false,
    showOnlyChecked: null,
    showMiners: ShowMinerType.Hide,
    showSingleMiner: null,
    editModeEnabled: false,
  });

  const query = useInvoicesQuery({
    start_date: trimDateToUTC(state.startDate),
    end_date: trimDateToUTC(state.endDate),
    term: state.term,
    query: state.query,
    status: state.status,
    page: state.page,
    size: state.size,
    batched: state.showOnlyBatched ? true : state.term != null ? false : null,
    overdue: state.showOnlyOverdue ? true : null,
    checked: state.showOnlyChecked,
  });

  const invoiceMutation = useUpdateInvoiceMutation();
  const minerMutation = useUpdateInvoiceMinerMutation();
  const downloadMutation = useMutation(fetchInvoicesExport, {
    onSuccess: (response) => downloadCSV("invoices.csv", response.data),
  });

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query:
        e.target.value != null && e.target.value.length > 0
          ? e.target.value
          : null,
    }));
  };

  return (
    <Panel title={"Invoices"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"center"}>
            <FormControl maxW="30rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={searchCallback}
                />
              </InputGroup>
            </FormControl>
            <Button
              style={{ marginTop: "2rem" }}
              leftIcon={<ArrowLeftIcon fontSize={"sm"} />}
              onClick={() => {
                const startDate = new Date(state.startDate);
                const newStartDate = new Date(
                  new Date(startDate).setDate(startDate.getDate() - 7)
                );
                const newEndDate = new Date(
                  new Date(newStartDate).setDate(newStartDate.getDate() + 6)
                );
                setState((s) => ({
                  ...s,
                  page: 0,
                  startDate: newStartDate,
                  endDate: newEndDate,
                }));
              }}
            />
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <SingleDatepicker
                name={`invoices-start-date`}
                usePortal={true}
                date={state.startDate}
                minDate={
                  new Date(
                    new Date(state.endDate).setDate(
                      state.endDate.getDate() - 34
                    )
                  )
                }
                maxDate={
                  new Date(
                    new Date(state.endDate).setDate(state.endDate.getDate() - 1)
                  )
                }
                onDateChange={(date) =>
                  setState((s) => ({ ...s, page: 0, startDate: date }))
                }
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                    borderColor: "grayAlpha.500",
                    bg: "whiteAlpha.400",
                  },
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <SingleDatepicker
                name={`invoices-end-date`}
                usePortal={true}
                date={state.endDate}
                minDate={state.startDate}
                maxDate={
                  new Date(
                    new Date(state.startDate).setDate(
                      state.startDate.getDate() + 34
                    )
                  )
                }
                onDateChange={(date) =>
                  setState((s) => ({ ...s, page: 0, endDate: date }))
                }
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                    borderColor: "grayAlpha.500",
                    bg: "whiteAlpha.400",
                  },
                }}
              />
            </FormControl>
            <Button
              style={{ marginTop: "2rem" }}
              rightIcon={<ArrowRightIcon fontSize={"sm"} />}
              onClick={() => {
                const startDate = new Date(state.startDate);
                const newStartDate = new Date(
                  new Date(startDate).setDate(startDate.getDate() + 7)
                );
                const newEndDate = new Date(
                  new Date(newStartDate).setDate(newStartDate.getDate() + 6)
                );
                setState((s) => ({
                  ...s,
                  page: 0,
                  startDate: newStartDate,
                  endDate: newEndDate,
                }));
              }}
            />
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
                <option value={500}>500</option>
              </Select>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Status</FormLabel>
              <Select
                value={state.status != null ? state.status : undefined}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    status: value > 0 ? value : null,
                  }));
                }}
              >
                <option value={0}>All</option>
                {invoiceStatuses.map((invoiceStatus) => (
                  <option key={invoiceStatus.id} value={invoiceStatus.id}>
                    {invoiceStatus.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <HStack mb={4} justify={"center"}>
            <FormControl maxW="25rem">
              <FormLabel textAlign="center">Contract Type</FormLabel>
              <RadioCardGroup
                keys={["All", "Monthly", "Annual", "Combined"]}
                value={
                  state.showOnlyBatched
                    ? "Combined"
                    : state.term == null
                    ? "All"
                    : state.term === 1
                    ? "Annual"
                    : "Monthly"
                }
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    term:
                      e === "Combined" || e === "All"
                        ? null
                        : e === "Annual"
                        ? 1
                        : 2,
                    showOnlyBatched:
                      e === "All" ? null : e === "Combined" ? true : false,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel textAlign="center">Show Paid Status</FormLabel>
              <RadioCardGroup
                keys={["All", "Overdue"]}
                value={state.showOnlyOverdue ? "Overdue" : "All"}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    showOnlyOverdue: e === "Overdue" ? true : false,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel textAlign="center">Show Checked Status</FormLabel>
              <RadioCardGroup
                keys={["All", "Checked", "Unchecked"]}
                value={
                  state.showOnlyChecked == null
                    ? "All"
                    : state.showOnlyChecked
                    ? "Checked"
                    : "Unchecked"
                }
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    showOnlyChecked:
                      e === "All" ? undefined : e === "Checked" ? true : false,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="23rem">
              <FormLabel textAlign="center">Show Miners</FormLabel>
              <RadioCardGroup
                keys={[
                  ShowMinerType.Hide,
                  ShowMinerType.All,
                  ShowMinerType.Ticketed,
                  ShowMinerType.UptimeUnchecked,
                  ShowMinerType.BitfixUnchecked,
                ]}
                value={state.showMiners}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    showMiners: e as ShowMinerType,
                    showSingleMiner: null,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="8rem">
              <FormLabel>Edit Mode Enabled</FormLabel>
              <Switch
                size="lg"
                h="3rem"
                isChecked={state.editModeEnabled}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    editModeEnabled: e.target.checked,
                  }))
                }
              />
            </FormControl>
            <Button
              colorScheme="teal"
              size="lg"
              isLoading={downloadMutation.isLoading}
              isDisabled={downloadMutation.isLoading}
              onClick={() =>
                downloadMutation.mutate({
                  start_date: trimDateToUTC(state.startDate),
                  end_date: trimDateToUTC(state.endDate),
                  page: state.page,
                  size: state.size,
                })
              }
            >
              Export
            </Button>
          </HStack>
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th>Contract</Th>
                <Th>Client</Th>
                <Th>Index</Th>
                <Th>Balance / Cost / Contract Cost</Th>
                <Th>Uptime</Th>
                <Th>QBO Invoice</Th>
                <Th>Status</Th>
                <Th>Due Date</Th>
                <Th>Miners (ON / K / QBO)</Th>
                <Th>Checked</Th>
                <Th>Notes</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((invoice) => (
                <InvoiceRow
                  invoice={invoice}
                  state={state}
                  setShowSingleMiner={(showSingleMiner: number | null) =>
                    setState((s) => ({ ...s, showSingleMiner }))
                  }
                  coverages={coverages}
                  setCoverages={setCoverages}
                  uptimes={uptimes}
                  setUptimes={setUptimes}
                  notes={notes}
                  setNotes={setNotes}
                  minerMutation={minerMutation}
                  invoiceMutation={invoiceMutation}
                />
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
