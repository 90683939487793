import { Box } from "@chakra-ui/react";

type TemperatureProps = {
  value: string;
};

export const Temperature = ({ value }: TemperatureProps) => {
  return (
    <Box whiteSpace="nowrap" fontWeight="medium" display="inline-block">
      {value}
    </Box>
  );
};
