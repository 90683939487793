import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  user_id: number;
};

export const adminToggleBillingUser = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/admin/toggle_billing_user", {
    ...body,
  });
};
