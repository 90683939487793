import { useState } from "react";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Switch,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { MutationFeedback } from "src/components/MutationFeedback";
import { useCreateClientMutation } from "src/api/mutations/adminClients";
import { GroupsInput } from "src/components/GroupsInput";

type Form = {
  name: string;
  is_company: boolean;
  group_ids: string[];
};

export const CreateClientPanel = () => {
  const [form, setForm] = useState<Form>({
    name: "",
    is_company: false,
    group_ids: [],
  });

  const mutation = useCreateClientMutation();
  const formIsDisabled = !form.name;

  return (
    <Panel title="Create Client">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate(form, {
            onSuccess: () => {
              setForm({
                name: "",
                is_company: false,
                group_ids: [],
              });
            },
          });
        }}
      >
        <Stack spacing={4} maxW="md" mb={4}>
          <FormControl id="name">
            <FormLabel>Client Name</FormLabel>
            <Input
              type="name"
              value={form.name}
              onChange={(e) => {
                const name = e.target.value;
                setForm((f) => ({
                  ...f,
                  name,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Is a Company</FormLabel>
            <Switch
              isChecked={form.is_company}
              onChange={(e) =>
                setForm((f) => ({ ...f, is_company: e.target.checked }))
              }
              isDisabled={mutation.isLoading}
            />
          </FormControl>
          <FormControl id="group">
            <FormLabel>Groups</FormLabel>
            <GroupsInput
              value={form.group_ids}
              onChange={(group_ids) => setForm((f) => ({ ...f, group_ids }))}
            />
          </FormControl>
          <MutationFeedback mutation={mutation} successMsg="Client Created" />
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={mutation.isLoading}
            disabled={mutation.isLoading || formIsDisabled}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Panel>
  );
};
