import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { UnboundMinersPanel } from "./panels/UnboundMinersPanel";
import { PoolMinersPanel } from "./panels/PoolMinersPanel";
import { FlaggedMinersPanel } from "./panels/FlaggedMinersPanel";
import { MinersWithIssuesPanel } from "./panels/MinersWithIssuesPanel";

export const MinerIssuesPage = () => {
  return (
    <Page title={`Miner Issues`} maxW={"container.2xl"}>
      <PanelRow>
        <UnboundMinersPanel />
      </PanelRow>
      <PanelRow>
        <PoolMinersPanel />
      </PanelRow>
      <PanelRow>
        <MinersWithIssuesPanel />
      </PanelRow>
      <PanelRow>
        <FlaggedMinersPanel />
      </PanelRow>
    </Page>
  );
};
