import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type Watchman = {
  id: number;
  location: string;
  host: string;
  name: string;
  api_key: string;
  enabled: boolean;
  online: boolean;
  temp1?: number | null;
  temp2?: number | null;
  temp3?: number | null;
  humidity?: number | null;
  timestamp: number;
};

export const fetchDataWatchmans = (
  page: number = 0,
  size: number = 10,
  query?: string,
  networkId?: number,
  online?: boolean
) => {
  let qs = `page=${page}&size=${size}`;
  if (query != null) {
    qs += `&query=${query}`;
  }
  if (networkId != null) {
    qs += `&network_id=${networkId}`;
  }
  if (online != null) {
    qs += `&online=${online}`;
  }

  return apiClient
    .get<APIResponsePaginated<Watchman>>(`/data/watchmans?${qs}`)
    .then((res) => res.data.data);
};

export const useDataWatchmansQuery = ({
  page = 0,
  size = 10,
  query,
  networkId,
  online,
}: {
  page?: number;
  size?: number;
  query?: string | undefined;
  networkId?: number | undefined;
  online?: boolean | undefined;
} = {}) => {
  return useQuery(
    ["/data/watchmans", page, size, query, networkId, online],
    () => fetchDataWatchmans(page, size, query, networkId, online),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
