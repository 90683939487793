import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type UpdateBody = {
  network_id: number;
  ip_range_start: string;
  ip_range_end: string;
  secondary_ip_range_start?: string | null;
  secondary_ip_range_end?: string | null;
  autofix_enabled?: boolean | null;
  capacity?: number | null;
  update?: boolean | null;
};

export const useUpdateMonitoringConfigMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateBody) =>
      apiClient.post<APIResponse<null>>("/monitoring/config", {
        ...body,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries("/monitoring/config");
    },
  });
};
