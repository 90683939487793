import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type TicketCategory = {
  id: number;
  name: string;
};

export type TicketItem = {
  id: number;
  type_id: number;
  type_name: string;
  operation_id: number;
  operation_name: string;
  index: number;
  serial: string;
  created_at: Date;
  updated_at: Date;
};

export type TicketCost = {
  id: number;
  model_id: number;
  name: string;
  cost: string;
  quantity: number;
};

export type TicketMessageAttachment = {
  id: number;
  file_name: string;
  content_type: string;
  url: string;
  expiry: Date;
  created_at: Date;
};

export type TicketMessage = {
  id: number;
  creator_id: number;
  creator_name: string;
  creator_email: string;
  public: boolean;
  message?: string;
  attachments: TicketMessageAttachment[];
  created_at: Date;
  updated_at: Date;
};

export type Ticket = {
  id: number;
  name: string;
  client_id: number;
  client_name: string;
  creator_id: number;
  creator_name: string;
  assignee_id?: number;
  assignee_name?: string;
  location_id?: number;
  location_name?: string;
  repair_facility_id?: number;
  repair_facility_name?: string;
  rma_id?: string;
  miner_id: string;
  miner_set_id: number;
  miner_name: string;
  status_id: number;
  status_name: string;
  cost: string;
  categories: TicketCategory[];
  items?: TicketItem[];
  costs?: TicketCost[];
  messages: TicketMessage[];
  checked: boolean;
  created_at: Date;
  updated_at: Date;
  assigned_at?: Date;
  testing_at?: Date;
  completed_at?: Date;
  billed_at?: Date;
  archived_at?: Date;
};

export const fetchTicket = (ticketId: number) =>
  apiClient
    .get<APIResponse<Ticket>>(`/ticket?id=${ticketId}`)
    .then((res) => res.data.data);

export const useTicketQuery = (ticketId: number) =>
  useQuery(["/ticket", ticketId], () => fetchTicket(ticketId));
