import { useState } from "react";
import { useMutation } from "react-query";
import QRCode from "react-qr-code";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Code,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { userChange2FA } from "src/api/mutations/userChange2FA";
import { MutationFeedback } from "src/components/MutationFeedback";

type Form = {
  code: string; // users 2fa code
};

export const Reset2FAPanel = () => {
  const [form, setForm] = useState<Form>({ code: "" });

  const mutation = useMutation(userChange2FA);

  const formIsDisabled = form.code === "";

  return (
    <Panel title="Reset 2FA">
      {mutation.isSuccess ? (
        <Stack spacing={4}>
          <Stack spacing={0}>
            <b>New 2FA Seed:</b>
            <Code p={4}>{mutation.data?.data.data["2fa_seed"]}&nbsp;</Code>
          </Stack>
          <Stack spacing={1} pb={1}>
            <b>2FA Seed as QR Code:</b>
            <QRCode
              value={`otpauth://totp/BitCap?secret=${mutation.data?.data.data["2fa_seed"]}`}
            />
          </Stack>
        </Stack>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            mutation.mutate(form);
          }}
        >
          <Stack spacing={4}>
            <FormControl id="2fa_code">
              <FormLabel>2FA Code</FormLabel>
              <Input
                value={form.code}
                onChange={(e) => {
                  const code = e.target.value;
                  setForm((f) => ({
                    ...f,
                    code,
                  }));
                }}
                disabled={mutation.isLoading}
              />
            </FormControl>
            <MutationFeedback mutation={mutation} />
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={mutation.isLoading}
              disabled={
                mutation.isSuccess || mutation.isLoading || formIsDisabled
              }
            >
              Submit
            </Button>
          </Stack>
        </form>
      )}
    </Panel>
  );
};
