import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  page: number;
  size: number;
  worker_name: string;
};

type PoolMiner = {
  id: string;
  name: string;
  ip_address?: string;
  client_id: number;
  client_name: string;
  enabled: boolean;
  online: boolean;
  pool_url: string;
  pool_worker: string;
};

export const fetchDataPoolMiners = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<PoolMiner>>("/data/miner/pool", {
      params: args,
    })
    .then((res) => res.data.data);

export const useDataPoolMinersQuery = (args: QueryArgs) =>
  useQuery(["/data/miner/pool", args], () => fetchDataPoolMiners(args), {
    keepPreviousData: true,
  });
