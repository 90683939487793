import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { useUserContext } from "src/contexts/UserContext";
import { ActiveDevicesPanel } from "./panels/ActiveDevicesPanel";
import { ActiveMinersPanel } from "./panels/ActiveMinersPanel";
import { AlgorithmsPanel } from "./panels/AlgorithmsPanel";
import { PricePanel } from "./panels/PricePanel";
import { CoinsPanel } from "./panels/CoinsPanel";
import { DailyRevenuePanel } from "./panels/DailyRevenuePanel";
import { MonthlyRevenuePanel } from "./panels/MonthlyRevenuePanel";
import { PowerUsagePanel } from "./panels/PowerUsagePanel";

export const OverviewPage = () => {
  return (
    <Page title="Overview">
      <PanelRow>
        <ActiveMinersPanel />
        <ActiveDevicesPanel />
      </PanelRow>
      <SmallerMetricPanels />
      <PanelRow>
        <CoinsPanel />
      </PanelRow>
      <PanelRow>
        <AlgorithmsPanel />
      </PanelRow>
    </Page>
  );
};

const SmallerMetricPanels = () => {
  const { user } = useUserContext();
  const showInOneRow = useBreakpointValue({
    base: false,
    lg: true,
  });

  if (showInOneRow) {
    return (
      <PanelRow>
        <PricePanel />
        <DailyRevenuePanel />
        {user.type !== "client" && <PowerUsagePanel />}
        <MonthlyRevenuePanel />
      </PanelRow>
    );
  } else {
    return (
      <>
        <PanelRow>
          <PricePanel />
          {user.type !== "client" && <PowerUsagePanel />}
        </PanelRow>
        <PanelRow>
          <DailyRevenuePanel />
          <MonthlyRevenuePanel />
        </PanelRow>
      </>
    );
  }
};
