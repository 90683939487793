import { Stat, StatNumber } from "@chakra-ui/react";
import { useDataDashboardQuery } from "src/api/queries/fetchDataDashboard";
import { DollarsLabelled } from "src/components/DollarsLabelled";
import { Panel } from "src/components/Panel";

export const PricePanel = () => {
  const query = useDataDashboardQuery();

  return (
    <Panel title="Exchange Rates" flex={2} query={query}>
      {(data) => (
        <Stat>
          <StatNumber>
            {data.btc_price !== null && (
              <DollarsLabelled
                label={"BTC"}
                value={data.btc_price?.formatted}
              />
            )}
          </StatNumber>
          <StatNumber>
            {data.eth_price !== null && (
              <DollarsLabelled
                label={"ETH"}
                value={data.eth_price?.formatted}
              />
            )}
          </StatNumber>
        </Stat>
      )}
    </Panel>
  );
};
