import { Link as ReactRouterLink } from "react-router-dom";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  LinkBox,
  LinkOverlay,
  Select,
  Text,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  HStack,
  VStack,
  Badge,
} from "@chakra-ui/react";
import { useOverdueInvoicesQuery } from "src/api/queries/fetchInvoices";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { RadioCardGroup } from "src/components/RadioCard";

export type OverdueState = {
  sortByClient: boolean;
  page: number;
  size: number;
};

export const OverduePanel = () => {
  const [state, setState] = useState<OverdueState>({
    sortByClient: false,
    page: 0,
    size: 10,
  });

  const query = useOverdueInvoicesQuery({
    sort_by_client: state.sortByClient,
    page: state.page,
    size: state.size,
  });

  return (
    <Panel title={"Overdue Invoices"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"left"}>
            <FormControl maxW="20rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
                <option value={500}>500</option>
              </Select>
            </FormControl>
            <FormControl maxW="20rem">
              <FormLabel textAlign="center">Sort By Client</FormLabel>
              <RadioCardGroup
                keys={["True", "False"]}
                value={state.sortByClient ? "True" : "False"}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    sortByClient: e === "True" ? true : false,
                  }))
                }
              />
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th>Contract</Th>
                <Th>Client</Th>
                <Th>Type</Th>
                <Th>Balance / Cost</Th>
                <Th>QBO Invoice</Th>
                <Th>Status</Th>
                <Th>Due Date</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((invoice) => (
                <Tr>
                  <Td>{invoice.contract_name}</Td>
                  <Td>{invoice.client_name}</Td>
                  <Td>
                    {invoice.idx === 0
                      ? "Deposit"
                      : invoice.idx === -1
                      ? "End"
                      : `Month #${invoice.idx}`}
                  </Td>
                  <Td>
                    ${invoice.balance} / ${invoice.cost}
                  </Td>
                  <Td>
                    {invoice.quickbooks_id == null || invoice.name == null ? (
                      <b>Not found</b>
                    ) : invoice.quickbooks_id === "0" ? (
                      <b>Virtual Invoice</b>
                    ) : (
                      <a
                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.quickbooks_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <b>{invoice.name}</b>
                      </a>
                    )}
                  </Td>
                  <Td>
                    <VStack>
                      <Badge
                        variant="solid"
                        size="xm"
                        colorScheme={invoice.contract_active ? "green" : "gray"}
                      >
                        {invoice.contract_active ? "active" : "inactive"}
                      </Badge>
                      <Badge
                        variant="solid"
                        size="xm"
                        colorScheme={invoice.contract_miners ? "green" : "gray"}
                      >
                        {invoice.contract_mining_miners
                          ? "mining to self"
                          : invoice.contract_miners
                          ? "mining to bitcap"
                          : "no active miners"}
                      </Badge>
                    </VStack>
                  </Td>
                  <Td>
                    {invoice.quickbooks_id !== "0" && (
                      <>
                        <Text>{invoice.status}</Text>
                        {invoice.overdue && (
                          <Badge variant="solid" size="xm" colorScheme={"red"}>
                            {"overdue"}
                          </Badge>
                        )}
                      </>
                    )}
                  </Td>
                  <Td>
                    {new Date(invoice.due_at).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })}
                  </Td>
                  <Td>
                    <HStack>
                      <LinkBox
                        bg="blackAlpha.100"
                        p={3}
                        borderRadius="md"
                        _hover={{ bg: "blackAlpha.200" }}
                        width="fit-content"
                        maxW="5rem"
                      >
                        <LinkOverlay
                          fontWeight="bold"
                          as={ReactRouterLink}
                          to={`/invoices/${invoice.id}`}
                        >
                          View
                        </LinkOverlay>
                      </LinkBox>
                      {invoice.file_id != null && (
                        <LinkBox
                          bg="blackAlpha.100"
                          p={3}
                          borderRadius="md"
                          _hover={{ bg: "blackAlpha.200" }}
                          textAlign="center"
                          maxW="5rem"
                        >
                          <LinkOverlay
                            fontWeight="bold"
                            as={ReactRouterLink}
                            to={`/pdf/invoice/${invoice.file_id}`}
                            isExternal
                          >
                            PDF
                          </LinkOverlay>
                        </LinkBox>
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
