import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type SingleContractSummaryResponse = {
  contracts_created: number;
  contracts_signed: number;
  contracts_activated: number;
  renewals_created: number;
  renewals_signed: number;
  renewals_activated: number;
  deposits_total: string;
  deposits_paid: string;
  deposits_unpaid: string;
  miners_new_total: number;
  miners_new_activated: number;
  renewed_new_total: number;
  renewed_new_activated: number;
  terminated_new_total: number;
  terminated_new_activated: number;
};

type ContractsSummaryResponse = {
  month: SingleContractSummaryResponse;
  year: SingleContractSummaryResponse;
};

export const fetchContractsSummary = () =>
  apiClient
    .get<APIResponse<ContractsSummaryResponse>>(`/contracts/summary`)
    .then((res) => res.data.data);

export const useContractsSummaryQuery = () => {
  return useQuery(["/contracts/summary"], () => fetchContractsSummary());
};
