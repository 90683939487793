import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  email: string;
};

export const authResetPassword = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/auth/reset_password", {
    ...body,
  });
};
