import { useQuery } from "react-query";
import { APIResponsePaginated, FormattedNumber } from "src/types";
import { apiClient } from "..";

export type Network = {
  id: number;
  name: string;
  alias: string;
  host: string;
  domain: string;
  address?: string;
  capacity?: FormattedNumber;
  ip_range_start: string;
  ip_range_end: string;
  secondary_ip_range_start?: string | null;
  secondary_ip_range_end?: string | null;
  awesomeminer_down?: boolean | null;
  autofix_enabled?: boolean | null;
  node_version?: string | null;
  timestamp: number;
};

export const fetchMonitoringConfig = () =>
  apiClient
    .get<APIResponsePaginated<Network>>(`/monitoring/config`)
    .then((res) => res.data.data);

export const useMonitoringConfigQuery = () => {
  return useQuery(["/monitoring/config"], () => fetchMonitoringConfig(), {
    keepPreviousData: true,
  });
};
