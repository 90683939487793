import { useState } from "react";
import { Panel } from "src/components/Panel";
import {
  Flex,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
} from "@chakra-ui/react";
import { useTicketKPIsQuery } from "src/api/queries/fetchTicketKPIs";
import { RadioCardGroup } from "src/components/RadioCard";

let periodKeysIdx: Record<string, string> = {
  "1w": "1 Week",
  "2w": "2 Weeks",
  "4w": "1 Month",
  "3m": "3 Months",
  "6m": "6 Months",
};
let periodKeysInverseIdx: Record<string, string> = {
  "1 Week": "1w",
  "2 Weeks": "2w",
  "1 Month": "4w",
  "3 Months": "3m",
  "6 Months": "6m",
};

export const TicketKPIsPanel = () => {
  const [period, setPeriod] = useState<string>("4w");
  const query = useTicketKPIsQuery({ period: period });

  return (
    <Panel title={"KPIs"} query={query}>
      {(data) => (
        <>
          <VStack>
            <HStack mb={4} justify={"space-between"}>
              <FormControl>
                <FormLabel textAlign="center">Period</FormLabel>
                <RadioCardGroup
                  keys={Object.keys(periodKeysInverseIdx)}
                  value={periodKeysIdx[period]}
                  onChange={(e) => setPeriod(periodKeysInverseIdx[e])}
                />
              </FormControl>
            </HStack>
            <Flex flexWrap={"wrap"} justifyContent="center">
              <VStack>
                <Heading size="md" mt="2">
                  Current
                </Heading>
                <StatGroup>
                  <Stat mx="6" my="4">
                    <StatLabel>Created</StatLabel>
                    <StatNumber minW="10rem">{data.current.created}</StatNumber>
                    <StatNumber minW="10rem">
                      {data.current.created_age}d old (avg)
                    </StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Assigned</StatLabel>
                    <StatNumber minW="10rem">
                      {data.current.assigned}
                    </StatNumber>
                    <StatNumber minW="10rem">
                      {data.current.assigned_age}d old (avg)
                    </StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>In Testing</StatLabel>
                    <StatNumber minW="10rem">{data.current.testing}</StatNumber>
                    <StatNumber minW="10rem">
                      {data.current.testing_age}d old (avg)
                    </StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Repair Distribution</StatLabel>
                    <StatNumber minW="10rem">
                      Internal: {data.current.internal}
                    </StatNumber>
                    <StatNumber minW="10rem">
                      External: {data.current.external}
                    </StatNumber>
                  </Stat>
                </StatGroup>
              </VStack>
              <VStack>
                <Heading size="md" mt="2">
                  Last {periodKeysIdx[period]}
                </Heading>
                <StatGroup>
                  <Stat mx="6" my="4">
                    <StatLabel>Created</StatLabel>
                    <StatNumber>{data.period.created}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Completed</StatLabel>
                    <StatNumber>{data.period.completed}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Billed</StatLabel>
                    <StatNumber>{data.period.billed}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Avg Days To Complete</StatLabel>
                    <StatNumber>{data.period.days_to_complete}d</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Avg Cost Per Ticket</StatLabel>
                    <StatNumber>${data.period.avg_cost}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Sum Cost</StatLabel>
                    <StatNumber>${data.period.total_cost}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Repair Distribution</StatLabel>
                    <StatNumber minW="10rem">
                      Internal: {data.period.internal}
                    </StatNumber>
                    <StatNumber minW="10rem">
                      External: {data.period.external}
                    </StatNumber>
                  </Stat>
                </StatGroup>
              </VStack>
              <VStack>
                <Heading size="md" mt="2">
                  Total
                </Heading>
                <StatGroup>
                  <Stat mx="6" my="4">
                    <StatLabel>Created</StatLabel>
                    <StatNumber>{data.total.created}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Completed</StatLabel>
                    <StatNumber>{data.total.completed}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Billed</StatLabel>
                    <StatNumber>{data.total.billed}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Avg Days To Complete</StatLabel>
                    <StatNumber>{data.total.days_to_complete}d</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Avg Cost Per Ticket</StatLabel>
                    <StatNumber>${data.total.avg_cost}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Sum Cost</StatLabel>
                    <StatNumber>${data.total.total_cost}</StatNumber>
                  </Stat>
                  <Stat mx="6" my="4">
                    <StatLabel>Repair Distribution</StatLabel>
                    <StatNumber minW="10rem">
                      Internal: {data.total.internal}
                    </StatNumber>
                    <StatNumber minW="10rem">
                      External: {data.total.external}
                    </StatNumber>
                  </Stat>
                </StatGroup>
              </VStack>
            </Flex>
          </VStack>
        </>
      )}
    </Panel>
  );
};
