import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  start_date: Date;
  end_date: Date;
  sort_order: string;
  status?: string;
  query?: null | string;
  page: number;
  size: number;
};

export type ContractMinerGroup = {
  id: number;
  name: string;
  power_draw: number;
  activated: number;
  total: number;
  monthly_cost: string;
};

type ExpiringContract = {
  id: number;
  revision_id: number;
  name: string;
  client_id: number;
  client_name: string;
  client_email?: string;
  creator_id: number;
  creator_name: string;
  subclient_name?: string;
  status: string;
  miner_groups: ContractMinerGroup[];
  renewal_id?: number | null;
  renewal_name?: string | null;
  renewal_sent: boolean;
  renewal_signed: boolean;
  renewal_activated: boolean;
  renewal_declined: boolean;
  renewal_miner_groups?: ContractMinerGroup[];
  created_at: string;
  activated_at: string;
  terminates_at: string;
};

export const fetchExpiringContracts = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<ExpiringContract>>("/contracts/expiring", {
      params: args,
    })
    .then((res) => res.data.data);

export const useExpiringContractsQuery = (args: QueryArgs) =>
  useQuery(["/contracts/expiring", args], () => fetchExpiringContracts(args), {
    keepPreviousData: true,
  });
