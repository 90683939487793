import { useQuery } from "react-query";
import { APIResponse, User } from "src/types";
import { apiClient } from "../";

export const fetchUser = () =>
  apiClient.get<APIResponse<User>>("/user").then((res) => ({
    ...res.data.data,
    groups: res.data.data.groups === null ? [] : res.data.data.groups,
  }));

const TWENTY_FOUR_HOURS_MS = 1000 * 60 * 60 * 24;

export const useUserQuery = () =>
  useQuery("/user", fetchUser, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: TWENTY_FOUR_HOURS_MS,
  });
