import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  token: string;
  user_id: number;
};

export const authConfirmChangeEmail = async (body: PostBody) => {
  return apiClient
    .post<APIResponse<null>>("/auth/confirm_change_email", body)
    .then((res) => res.data.data);
};
