import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  name: string;
  host?: string | null;
  enabled?: boolean | null;
  smart: boolean;
  network_id?: number | null;
  type?: number | null;
  lines?: number | null;
  branches?: number | null;
  outlets?: number | null;
};

export const useCreatePDUMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PostBody) => {
      return apiClient.post<APIResponse<null>>("/data/pdu", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/pdus");
    },
  });
};

type PatchBody = {
  pdu_id: number;
  name?: string | null;
  host?: string | null;
  enabled?: boolean | null;
  network_id?: number | null;
  type?: number | null;
};

export const useUpdatePDUMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PatchBody) => {
      return apiClient.patch<APIResponse<null>>("/data/pdu", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/pdus");
    },
  });
};

type DeleteBody = {
  pdu_id: number;
};

export const useDeletePDUMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteBody) => {
      return apiClient.delete<APIResponse<null>>("/data/pdu", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/pdus");
    },
  });
};

type UpdateOutletBody = {
  pdu_id: number;
  id: string;
  miner_id?: string | null;
};

export const useUpdateOutletMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateOutletBody) => {
      return apiClient.patch<APIResponse<null>>("/data/outlet", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["/data/pdu"]);
    },
  });
};

type RebootOutletBody = {
  pdu_id: number;
  id: string;
};

export const useRebootOutletMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: RebootOutletBody) => {
      return apiClient.post<APIResponse<null>>("/data/outlet/reboot", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["/data/pdu"]);
    },
  });
};
