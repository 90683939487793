import { Box, Grid, Badge, Divider, Stack } from "@chakra-ui/react";
import { useDataMinerQuery } from "src/api/queries/fetchDataMiner";
import { Panel } from "src/components/Panel";

type Props = {
  minerId: string;
};
export const PoolsPanel = ({ minerId }: Props) => {
  const query = useDataMinerQuery(minerId);

  return (
    <Panel title="Pools" query={query}>
      {(data) => (
        <Box fontSize="sm" minW="35rem">
          <Grid
            templateColumns="2fr repeat(3, 1fr)"
            gap={2}
            textTransform="uppercase"
          >
            <Box>Description</Box>
            <Box>Status</Box>
            <Box>Progress</Box>
            <Box>Coin</Box>
          </Grid>
          <Divider my={2} />
          <Stack spacing={2} divider={<Divider my={2} />}>
            {data.pools?.map((pool) => (
              <Grid key={pool.id} templateColumns="2fr repeat(3, 1fr)" gap={2}>
                <Box overflowX="hidden">
                  <Box fontWeight="bold">{pool.name}</Box>
                  <Box>{pool.url}</Box>
                  <Box
                    title={pool.worker}
                    overflowX="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {pool.worker}
                  </Box>
                </Box>
                <Box>
                  <Badge
                    colorScheme={
                      pool.status.toLowerCase() === "enabled"
                        ? "green"
                        : undefined
                    }
                    variant="solid"
                  >
                    {pool.status}
                  </Badge>
                </Box>
                <Box>
                  <Box>Accepted: {pool.shares.accepted}</Box>
                  <Box>Rejected: {pool.shares.rejected}</Box>
                  <Box>HW Errors: {pool.shares.hw_errors}</Box>
                </Box>
                <Box>{pool.coin_name}</Box>
              </Grid>
            ))}
          </Stack>
        </Box>
      )}
    </Panel>
  );
};
