import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import {
  Stack,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
} from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { User } from "src/types";
import { SmolStackLayout } from "src/components/SmolStackLayout";
import { fetchPdfUrl } from "src/api/queries/fetchPdfUrl";
import { useWindowSize } from "src/hooks/useWindowSize";

// var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var isSafari = true;
const BASE_URL = "https://mozilla.github.io/pdf.js/web/viewer.html?file=";
const BACKUP_URL =
  "https://drive.google.com/viewerng/viewer?embedded=true&url=";

type Props = {
  user: User | undefined;
};

type State = {
  email?: string;
  name?: string;
  pdfUrl?: string;
  error?: string;
};

export const PdfPage = ({ user }: Props) => {
  const [mounted, setMounted] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const { fileType, fileId } =
    useParams<{ fileType: string; fileId: string }>();
  const [state, setState] = useState<State>({});
  const { height } = useWindowSize();

  const makeRequest = useCallback(() => {
    if (state.email != null) {
      fetchPdfUrl({ type: fileType, file_id: fileId, email: state.email })
        .then((data) => {
          if (data?.url != null) {
            setState((s) => ({
              ...s,
              name: data.name,
              pdfUrl: data.url,
              error: undefined,
            }));
          }
        })
        .catch((err) => {
          let msg = err?.response?.data?.msg;
          if (msg !== "Too many requests, please try again later") {
            msg = "Authentication failed";
          }

          setState((s) => ({ ...s, error: msg }));
        });
    }
  }, [fileType, fileId, state]);

  useEffect(() => {
    setTimeout(() => setMounted(true), 250);
  }, []);

  useEffect(() => {
    if (user?.email.current != null && state.email == null) {
      setInitializing(true);
      setState((s) => ({
        ...s,
        email: user?.email.current,
      }));
    }
  }, [user, state.email, setState]);

  useEffect(() => {
    if (initializing && state.email != null) {
      setInitializing(false);
      makeRequest();
    }
  }, [initializing, state.email, setState, makeRequest]);

  return (
    <>
      {state.pdfUrl == null && mounted && !initializing && (
        <SmolStackLayout>
          <Heading size="md">Email Authentication</Heading>
          <Text my={4}>Please enter your email to verify your identity.</Text>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={state.email}
                onChange={(e) =>
                  setState((s) => ({ ...s, email: e.target.value }))
                }
              />
            </FormControl>
            <Button
              colorScheme="teal"
              type="submit"
              onClick={() => makeRequest()}
            >
              Submit
            </Button>
            {state.error != null && (
              <Alert status="error">
                <AlertIcon />
                {state.error}
              </Alert>
            )}
          </Stack>
        </SmolStackLayout>
      )}
      {state.pdfUrl != null && height != null && (
        <Box h={height}>
          <iframe
            title={" "}
            src={`${isSafari ? BACKUP_URL : BASE_URL}${encodeURIComponent(
              state.pdfUrl
            )}`}
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
          ></iframe>
        </Box>
      )}
    </>
  );
};
