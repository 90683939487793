import { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Heading } from "@chakra-ui/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";

export type BreadcrumbValues = {
  text: string;
  to: string | undefined;
};

type PageProps = {
  children: ReactNode;
  cornerContent?: ReactNode;
  title: string;
  maxW?: string;
  breadcrumbs?: BreadcrumbValues[];
};

export const Page = ({
  title,
  children,
  maxW = "container.xl",
  breadcrumbs = [],
  cornerContent,
}: PageProps) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container maxW={maxW}>
      {breadcrumbs.length > 0 && (
        <Breadcrumb>
          {breadcrumbs.map((bc) => {
            if (bc.to === undefined) {
              return (
                <BreadcrumbItem key={bc.text} isCurrentPage>
                  <BreadcrumbLink>{bc.text}</BreadcrumbLink>
                </BreadcrumbItem>
              );
            } else {
              return (
                <BreadcrumbItem key={bc.text}>
                  <BreadcrumbLink as={Link} to={bc.to}>
                    {bc.text}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            }
          })}
        </Breadcrumb>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Heading my={4} size="2xl">
          {title}
        </Heading>
        {cornerContent}
      </Flex>
      {children}
    </Container>
  );
};
