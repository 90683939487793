import { LinkBox } from "@chakra-ui/react";
import { MinerPreviewResponse } from "src/api/queries/fetchDataMinerGroup";
import { MinerDescription } from "./MinerDescription";

type MinerPreviewProps = {
  miner: MinerPreviewResponse;
};

export const MinerPreview = ({ miner }: MinerPreviewProps) => {
  return (
    <LinkBox
      bg="blackAlpha.100"
      p={3}
      borderRadius="md"
      _hover={{ bg: "blackAlpha.200" }}
      width="fit-content"
      minWidth="100%"
    >
      <MinerDescription miner={miner} isInLinkBox />
    </LinkBox>
  );
};
