import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type AdminTaskStatus = "pending" | "accepted" | "rejected";

type AdminGroupItem = {
  id: string;
  network_id: number;
  awesomeminer_id: number;
  name: string;
  client_id: null;
  created_at: string;
  updated_at: string;
};

const fetchAdminGroupsUnclaimed = () =>
  apiClient
    .get<APIResponsePaginated<AdminGroupItem>>("/admin/groups/unclaimed")
    .then((res) => res.data.data);

export const useAdminGroupsUnclaimedQuery = () =>
  useQuery("/admin/groups/unclaimed", () => fetchAdminGroupsUnclaimed());
