import { useParams } from "react-router";
import { BreadcrumbValues, Page } from "src/components/Page";
import { useDataPDUQuery } from "src/api/queries/fetchDataPDU";
import { PanelRow } from "src/components/PanelRow";
import { InfoPanel } from "./panels/InfoPanel";
import { LinesPanel } from "./panels/LinesPanel";
import { BranchesPanel } from "./panels/BranchesPanel";
import { OutletsPanel } from "./panels/OutletsPanel";

export const PduPage = () => {
  const { id: pduId } = useParams<{ id: string }>();
  const query = useDataPDUQuery(parseInt(pduId));

  let crumbs: BreadcrumbValues[] = [
    {
      text: "PDUs",
      to: "/monitoring",
    },
    {
      text: query.data?.data.name || "PDU",
      to: undefined,
    },
  ];

  return (
    <Page title={query.data?.data.name || "..."} breadcrumbs={crumbs}>
      {query?.data != null && (
        <>
          <PanelRow>
            <InfoPanel pdu={query.data?.data} />
          </PanelRow>
          <PanelRow>
            <LinesPanel lines={query.data?.data.lines} />
          </PanelRow>
          <PanelRow>
            <BranchesPanel branches={query.data?.data.branches} />
          </PanelRow>
          <PanelRow>
            <OutletsPanel
              pduId={query.data.data.id}
              locationId={query.data.data.network_id}
              outlets={query.data.data.outlets}
            />
          </PanelRow>
        </>
      )}
    </Page>
  );
};
