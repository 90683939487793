import { FC } from "react";
import { Flex } from "@chakra-ui/react";

export const PanelRow: FC<{}> = ({ children }) => {
  return (
    <Flex flexDirection={{ base: "column", md: "row" }} gridGap="4" mb="4">
      {children}
    </Flex>
  );
};
