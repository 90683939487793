import { Link as ReactRouterLink } from "react-router-dom";
import { Flex, Grid, LinkOverlay, Box, Badge } from "@chakra-ui/react";
import { MinerPreviewResponse } from "src/api/queries/fetchDataMinerGroup";
import { Temperature } from "./Temperature";
import { Dollars } from "./Dollars";

type MinerDescriptionProps = {
  isInLinkBox?: boolean;
  miner: MinerPreviewResponse;
};

export const MinerDescription = ({
  isInLinkBox = false,
  miner,
}: MinerDescriptionProps) => {
  return (
    <Grid
      fontSize="sm"
      templateColumns="2fr repeat(4, 1fr)"
      gap={2}
      minW="40rem"
    >
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {isInLinkBox ? (
          <LinkOverlay
            fontWeight="bold"
            as={ReactRouterLink}
            to={`/miners/${miner.compound_id}`}
          >
            {miner.name}
          </LinkOverlay>
        ) : (
          <Box fontWeight="bold">{miner.name}</Box>
        )}
        {miner.hardware != null && (
          <Box>
            <i>{miner.hardware}</i>
          </Box>
        )}
        <Box>
          {miner.host_name}
          {miner.network_ip_address && ` (${miner.network_ip_address})`}
        </Box>
        {miner.mac_address != null && <Box>{miner.mac_address}</Box>}
        <Box>{miner.pool_name}</Box>
        {miner.contract_name != null && <Box>{miner.contract_name}</Box>}
      </Box>
      <Box>
        <Box>
          <Badge
            variant="solid"
            colorScheme={miner.running ? "green" : undefined}
          >
            {miner.status}
          </Badge>
        </Box>
        <Box>
          <Temperature value={miner.avg_temperature.formatted} />
        </Box>
        <Box>{miner.uptime}</Box>
      </Box>
      <Box>
        <Flex>
          <Box minW="60px">Daily</Box>
          <Box>
            <Dollars value={miner.daily_revenue.formatted} />
          </Box>
        </Flex>
        <Flex>
          <Box minW="60px">Monthly</Box>
          <Box>
            <Dollars value={miner.monthly_revenue.formatted} />
          </Box>
        </Flex>
      </Box>
      <Box>
        <Box>{miner.coin || "N/A"}</Box>
        <Box>{miner.hashrate.formatted}</Box>
        <Box>{miner.avg_hashrate.formatted}</Box>
      </Box>
      <Box>
        <Box>Accepted: {miner.shares.accepted}</Box>
        <Box>Rejected: {miner.shares.rejected}</Box>
        <Box>HW Errors: {miner.shares.hw_errors}</Box>
      </Box>
    </Grid>
  );
};
