import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const bitcapTheme = extendTheme({
  styles: {
    global: {
      "html, body": {
        background: "gray.200",
      },
    },
  },
  config,
});
