import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type ContractMiner = {
  id: number;
  miner_id?: string | null;
  miner_group_id?: string | null;
  miner_name?: string | null;
  serial?: string | null;
  mac_address?: string | null;
  enabled?: boolean | null;
  online?: boolean | null;
  activated_at?: number | null;
};

export type ContractMinerGroup = {
  id: number;
  model_id: number;
  name: string;
  power_draw: number;
  activated: number;
  total: number;
  monthly_cost: string;
  miners: ContractMiner[];
};

export type Contract = {
  id: number;
  revision_id: number;
  name: string;
  client_id: number;
  client_name: string;
  client_user_id: number;
  client_user_first_name: string;
  client_user_last_name: string;
  client_user_email: string;
  subclient_name?: string;
  creator_id: number;
  creator_first_name: string;
  creator_last_name: string;
  hellosign_id: string | null;
  file_path: string;
  term: number;
  duration_months: number;
  deposit_months: number;
  pre_payment_months: number;
  power_rate: string;
  wave_deployment_fee: boolean;
  is_batched: boolean;
  active_monthly_cost: string;
  total_monthly_cost: string;
  status: string;
  miner_groups: ContractMinerGroup[];
  created_at: string;
  activated_at?: string;
  terminates_at?: string;
};

export const fetchContract = (contractId: number) =>
  apiClient
    .get<APIResponse<Contract>>(`/contract?contract_id=${contractId}`)
    .then((res) => res.data.data);

export const useContractQuery = (contractId: number) =>
  useQuery(["/contract", contractId], () => fetchContract(contractId));
