import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type MonitoringSummaryResponse = {
  awesomeminer: {
    total: number;
    online: number;
    offline: number;
  };
  eyedro: {
    total: number;
    online: number;
    offline: number;
  };
  watchman: {
    total: number;
    online: number;
    offline: number;
  };
  pdu: {
    total: number;
    smart: number;
    online: number;
    offline: number;
  };
};

export const fetchMonitoringSummary = () =>
  apiClient
    .get<APIResponse<MonitoringSummaryResponse>>(`/monitoring/summary`)
    .then((res) => res.data.data);

export const useMonitoringSummaryQuery = () => {
  return useQuery(["/monitoring/summary"], () => fetchMonitoringSummary());
};
