import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type Miner = {
  id: number;
  quantity: number;
};

export type Client = {
  first_name: string;
  last_name: string;
  email: string;
  company?: string;
};

export type Contract = {
  date?: Date;
  previous_contract_id?: number;
  client_id?: number;
  client_user_id?: number;
  client?: Client;
  subclient?: string;
  term: number;
  duration_months: number;
  deposit_months: number;
  pre_payment_months: number;
  power_rate: string;
  wave_deployment_fee: boolean;
  is_batched: boolean;
  miners: Miner[];
  affiliates: number[];
  extra_titles: string[];
  extra_clauses: string[];
};

export type ContractOperation = {
  operation: number;
  contract_miner_group_id?: number;
  contract_miner_model_id?: number;
  contract_miner_quantity?: number;
  contract_miner_id?: number;
  miner_id?: string;
  activated_at?: Date;
};

export type ContractEdit = {
  contract_id: number;
  revision_id?: number;
  operations: ContractOperation[];
};

export const useCreateContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: Contract) => {
      return apiClient.post<APIResponse<null>>("/contract", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract");
      await queryClient.invalidateQueries("/contracts");
    },
  });
};

export const useSendContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { contract_id: number }) => {
      return apiClient.post<APIResponse<null>>("/contract/send", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract");
      await queryClient.invalidateQueries("/contracts");
    },
  });
};

export const useEditContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ContractEdit) => {
      return apiClient.patch<APIResponse<null>>("/contract", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract");
      await queryClient.invalidateQueries("/contracts");
      await queryClient.invalidateQueries("/data/miner/search");
    },
  });
};

export const useSetContractRenewalStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { contract_id: number; renewal_declined: boolean }) => {
      return apiClient.post<APIResponse<null>>(
        "/contract/renewal_status",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract");
      await queryClient.invalidateQueries("/contracts");
      await queryClient.invalidateQueries("/data/miner/search");
    },
  });
};

export const useTerminateContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { contract_id: number; termination_fee: string }) => {
      return apiClient.post<APIResponse<null>>("/contract/terminate", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract");
      await queryClient.invalidateQueries("/contracts");
    },
  });
};

export const useDeleteContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { contract_id: number }) => {
      return apiClient.delete<APIResponse<null>>("/contract", { data: body });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract");
      await queryClient.invalidateQueries("/contracts");
    },
  });
};

export const previewContract = (body: Contract) =>
  apiClient.post<Uint8Array>(
    "/contract/preview",
    { type: "contract", ...body },
    { responseType: "blob" }
  );
export const previewQuote = (body: Contract) =>
  apiClient.post<Uint8Array>(
    "/contract/preview",
    { type: "quote", ...body },
    { responseType: "blob" }
  );

export const downloadContract = (body: { contract_id: number }) =>
  apiClient.post<Uint8Array>("/contract/download", body, {
    responseType: "blob",
  });
