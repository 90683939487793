import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { userChangeEmail } from "src/api/mutations/userChangeEmail";
import { useUserContext } from "src/contexts/UserContext";
import { StaticField } from "src/components/StaticField";
import { MutationFeedback } from "src/components/MutationFeedback";

type Form = {
  email: string;
};

export const ChangeEmailAddressPanel = () => {
  const queryClient = useQueryClient();
  const { user } = useUserContext();
  const [form, setForm] = useState<Form>({ email: "" });
  const mutation = useMutation(userChangeEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries("/user");
    },
  });

  const formIsDisabled = form.email === "";

  return (
    <Panel title="Change Email Address">
      <Flex flexWrap="wrap" gridGap={8}>
        <Box flex={1} minW={200}>
          <Stack>
            <StaticField
              pending={true}
              label="Pending Email"
              value={user.email.pending}
            />
            <StaticField
              pending={false}
              label="Current Email"
              value={user.email.current}
            />
          </Stack>
        </Box>
        <Box flex={1} minW={200}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutation.mutate({
                user_id: user.id,
                email: form.email,
              });
            }}
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>New Email Address</FormLabel>
                <Input
                  value={form.email}
                  onChange={(e) => {
                    const email = e.target.value;
                    setForm((f) => ({
                      ...f,
                      email,
                    }));
                  }}
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
              <Button
                colorScheme="teal"
                type="submit"
                isLoading={mutation.isLoading}
                disabled={
                  mutation.isSuccess || mutation.isLoading || formIsDisabled
                }
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Flex>
    </Panel>
  );
};
