import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  current_password: string;
  new_password: string;
};

export const userChangePassword = (body: PostBody) => {
  return apiClient.post<APIResponse<null>>("/user/change_password", {
    ...body,
  });
};
