import { useEffect, useState, useRef, ChangeEvent } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Table,
  Tbody,
  Td,
  Select,
  Th,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  HStack,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  Switch,
  IconButton,
  FormControl,
  FormLabel,
  Thead,
  Badge,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useCreateEyedroMutation,
  useUpdateEyedroMutation,
  useDeleteEyedroMutation,
} from "src/api/mutations/dataEyedro";
import { Eyedro, useDataEyedrosQuery } from "src/api/queries/fetchDataEyedros";
import { Panel } from "src/components/Panel";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SimplePaginator } from "src/components/SimplePaginator";
import { AddIcon, SearchIcon, EditIcon } from "@chakra-ui/icons";

const formatColor = (value: number, warn: number, alert: number) => {
  if (value === 0) {
    return "gray";
  } else if (value >= alert) {
    return "red";
  } else if (value >= warn) {
    return "#D69E2E";
  }
  return undefined;
};

type NewEyedro = {
  name?: string;
  host?: string;
  enabled: boolean;
  flipped: boolean;
  pf_enabled: boolean;
  current_capacity: number;
  current_alert: number;
  current_warn: number;
};

const CreateEyedroButton = () => {
  const [state, setState] = useState<NewEyedro>({
    enabled: true,
    flipped: false,
    pf_enabled: true,
    current_capacity: 400,
    current_alert: 380,
    current_warn: 360,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateEyedroMutation();

  const handleCreate = () => {
    if (state.name !== undefined && state.host !== undefined) {
      mutation.mutate(
        {
          name: state.name,
          host: state.host,
          enabled: state.enabled,
          flipped: state.flipped,
          pf_enabled: state.pf_enabled,
          current_capacity: state.current_capacity,
          current_alert: state.current_alert,
          current_warn: state.current_warn,
        },
        {
          onSuccess: onClose,
        }
      );
    }
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({
        enabled: true,
        flipped: false,
        pf_enabled: true,
        current_capacity: 400,
        current_alert: 380,
        current_warn: 360,
      });
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <Text minW="10rem" align="center" fontWeight="medium">
        Create a new Eyedro
      </Text>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Eyedro"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Eyedro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Host</FormLabel>
                <Input
                  type="name"
                  value={state.host}
                  onChange={(e) =>
                    setState((s) => ({ ...s, host: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Enabled</FormLabel>
                  <Switch
                    isChecked={state.enabled}
                    onChange={(e) =>
                      setState((s) => ({ ...s, enabled: e.target.checked }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Flipped</FormLabel>
                  <Switch
                    isChecked={state.flipped}
                    onChange={(e) =>
                      setState((s) => ({ ...s, flipped: e.target.checked }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>PF Enabled</FormLabel>
                  <Switch
                    isChecked={state.pf_enabled}
                    onChange={(e) =>
                      setState((s) => ({ ...s, pf_enabled: e.target.checked }))
                    }
                    isDisabled={mutation.isLoading}
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>Current Capacity</FormLabel>
                <Input
                  type="number"
                  value={state.current_capacity}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      current_capacity: parseInt(e.target.value),
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>Current Alert</FormLabel>
                <Input
                  type="number"
                  value={state.current_alert}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      current_alert: parseInt(e.target.value),
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>Current Warn</FormLabel>
                <Input
                  type="number"
                  value={state.current_warn}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      current_warn: parseInt(e.target.value),
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={state.name === undefined || state.host === undefined}
              onClick={handleCreate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const EditEyedroButton = ({ eyedro }: { eyedro: Eyedro }) => {
  const [state, setState] = useState<Eyedro>(eyedro);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateEyedroMutation();

  const handleUpdate = () => {
    mutation.mutate(
      {
        eyedro_id: state.id,
        name: state.name,
        host: state.host,
        enabled: state.enabled,
        flipped: state.phases_flipped,
        pf_enabled: state.pf_enabled,
        current_capacity: state.current_capacity,
        current_alert: state.current_alert,
        current_warn: state.current_warn,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(eyedro);
    }
  }, [isOpen, mutation, eyedro]);

  useEffect(() => {
    if (
      !isOpen &&
      (eyedro.name !== state.name ||
        eyedro.host !== state.host ||
        eyedro.enabled !== state.enabled)
    ) {
      setState(eyedro);
    }
  }, [isOpen, eyedro, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Eyedro"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Eyedro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Host</FormLabel>
                <Input
                  type="name"
                  value={state.host}
                  onChange={(e) =>
                    setState((s) => ({ ...s, host: e.target.value }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Enabled</FormLabel>
                  <Switch
                    isChecked={state.enabled}
                    onChange={(e) =>
                      setState((s) => ({ ...s, enabled: e.target.checked }))
                    }
                    disabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Flipped</FormLabel>
                  <Switch
                    isChecked={state.phases_flipped}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        phases_flipped: e.target.checked,
                      }))
                    }
                    disabled={mutation.isLoading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>PF Enabled</FormLabel>
                  <Switch
                    isChecked={state.pf_enabled}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        pf_enabled: e.target.checked,
                      }))
                    }
                    disabled={mutation.isLoading}
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>Current Capacity</FormLabel>
                <Input
                  type="number"
                  value={state.current_capacity}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      current_capacity: parseInt(e.target.value),
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>Current Alert</FormLabel>
                <Input
                  type="number"
                  value={state.current_alert}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      current_alert: parseInt(e.target.value),
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel>Current Warn</FormLabel>
                <Input
                  type="number"
                  value={state.current_warn}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      current_warn: parseInt(e.target.value),
                    }))
                  }
                  disabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleUpdate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteEyedroButton = ({ eyedro }: { eyedro: Eyedro }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useDeleteEyedroMutation();

  const handleDelete = () => {
    mutation.mutate(
      {
        eyedro_id: eyedro.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Eyedro
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete the Eyedro{" "}
                  <b>
                    {eyedro.name} ({eyedro.location})
                  </b>
                  ?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const EyedrosPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: string | undefined;
    networkId: number | undefined;
    online: boolean | undefined;
  }>({
    page: 0,
    size: 10,
    query: undefined,
    networkId: undefined,
    online: undefined,
  });

  const query = useDataEyedrosQuery({
    page: state.page,
    size: state.size,
    query: state.query,
    networkId: state.networkId,
    online: state.online,
  });

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query:
        e.target.value != null && e.target.value.length > 0
          ? e.target.value
          : undefined,
    }));
  };

  return (
    <Panel title={"Eyedros"} flex={3} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={searchCallback}
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
            </HStack>
            <CreateEyedroButton />
          </HStack>
          <Table size="sm" mb={4} variant="striped">
            <Thead>
              <Tr>
                <Th>Location</Th>
                <Th>Host</Th>
                <Th>Name</Th>
                <Th>Enabled</Th>
                <Th>Online</Th>
                <Th>Phase 1</Th>
                <Th>Phase 2</Th>
                <Th>Phase 3</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((eyedro) => {
                return (
                  <Tr key={`eyedro-${eyedro.id}`}>
                    <Td>{eyedro.location}</Td>
                    <Td>
                      <a
                        href={`http://${eyedro.host}:8080`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {eyedro.host}
                      </a>
                    </Td>
                    <Td>{eyedro.name}</Td>
                    <Td>
                      <Badge
                        variant="solid"
                        colorScheme={eyedro.enabled ? "green" : "red"}
                      >
                        {eyedro.enabled ? "enabled" : "disabled"}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge
                        variant="solid"
                        colorScheme={eyedro.online ? "green" : "red"}
                      >
                        {eyedro.online ? "online" : "offline"}
                      </Badge>
                    </Td>
                    <Td>
                      <VStack alignItems="center">
                        {eyedro.online && eyedro.phases[0].current != null && (
                          <Text
                            textAlign="center"
                            textColor={formatColor(
                              eyedro.phases[0].current,
                              eyedro.current_warn,
                              eyedro.current_alert
                            )}
                          >
                            {eyedro.phases[0].current.toFixed(0)} A
                          </Text>
                        )}
                        {eyedro.online && eyedro.phases[0].voltage != null && (
                          <Text textAlign="center" textColor="gray">
                            {eyedro.phases[0].voltage.toFixed(0)} V
                          </Text>
                        )}
                        {eyedro.online && eyedro.phases[0].power != null && (
                          <Text textAlign="center" textColor={"gray"}>
                            {eyedro.phases[0].power.toFixed(0)} W
                          </Text>
                        )}
                      </VStack>
                    </Td>
                    <Td>
                      <VStack alignItems="center">
                        {eyedro.online && eyedro.phases[1].current != null && (
                          <Text
                            textAlign="center"
                            textColor={formatColor(
                              eyedro.phases[1].current,
                              eyedro.current_warn,
                              eyedro.current_alert
                            )}
                          >
                            {eyedro.phases[1].current.toFixed(0)} A
                          </Text>
                        )}
                        {eyedro.online && eyedro.phases[1].voltage != null && (
                          <Text textAlign="center" textColor="gray">
                            {eyedro.phases[1].voltage.toFixed(0)} V
                          </Text>
                        )}
                        {eyedro.online && eyedro.phases[1].power != null && (
                          <Text textAlign="center" textColor={"gray"}>
                            {eyedro.phases[1].power.toFixed(0)} W
                          </Text>
                        )}
                      </VStack>
                    </Td>
                    <Td>
                      <VStack alignItems="center">
                        {eyedro.online && eyedro.phases[2].current != null && (
                          <Text
                            textAlign="center"
                            textColor={formatColor(
                              eyedro.phases[2].current,
                              eyedro.current_warn,
                              eyedro.current_alert
                            )}
                          >
                            {eyedro.phases[2].current.toFixed(0)} A
                          </Text>
                        )}
                        {eyedro.online && eyedro.phases[2].voltage != null && (
                          <Text textAlign="center" textColor="gray">
                            {eyedro.phases[2].voltage.toFixed(0)} V
                          </Text>
                        )}
                        {eyedro.online && eyedro.phases[2].power != null && (
                          <Text textAlign="center" textColor={"gray"}>
                            {eyedro.phases[2].power.toFixed(0)} W
                          </Text>
                        )}
                      </VStack>
                    </Td>
                    <Td>
                      <EditEyedroButton eyedro={eyedro} />
                      <DeleteEyedroButton eyedro={eyedro} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
