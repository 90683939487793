import { useParams } from "react-router";
import { BreadcrumbValues, Page } from "src/components/Page";
import { useTicketQuery } from "src/api/queries/fetchTicket";
import { PanelRow } from "src/components/PanelRow";
import { SummaryPanel } from "./panels/SummaryPanel";
import { ItemsPanel } from "./panels/ItemsPanel";
import { CostsPanel } from "./panels/CostsPanel";
import { MessagesPanel } from "./panels/MessagesPanel";
import { TicketHistoryPanel } from "./panels/TicketHistoryPanel";

export const TicketPage = () => {
  const { id: ticketId } = useParams<{ id: string }>();
  const query = useTicketQuery(parseInt(ticketId));

  let crumbs: BreadcrumbValues[] = [
    {
      text: "Tickets",
      to: "/tickets",
    },
    {
      text: query.data?.name || "Ticket",
      to: undefined,
    },
  ];

  return (
    <Page title={`Ticket ${query.data?.name}` || "..."} breadcrumbs={crumbs}>
      {query?.data != null && (
        <>
          <PanelRow>
            <SummaryPanel ticket={query.data} />
          </PanelRow>
          <PanelRow>
            <TicketHistoryPanel
              ticketId={query.data.id}
              minerSetId={query.data.miner_set_id}
            />
          </PanelRow>
          <PanelRow>
            <ItemsPanel ticket={query.data} />
          </PanelRow>
          <PanelRow>
            <CostsPanel ticket={query.data} />
          </PanelRow>
          <PanelRow>
            <MessagesPanel
              ticketId={query.data.id}
              messages={query.data.messages}
            />
          </PanelRow>
        </>
      )}
    </Page>
  );
};
