import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type DeleteBody = {
  client_id: number;
};

export const useAdminDeleteClientMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteBody) => {
      return apiClient.delete<APIResponse<null>>("/admin/clients", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/admin/clients");
      await queryClient.invalidateQueries("/admin/groups/unclaimed");
    },
  });
};

type PostBody = {
  name: string;
  is_company: boolean;
  group_ids: string[];
};

export const useCreateClientMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PostBody) => {
      return apiClient.post<APIResponse<null>>("/admin/clients", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/admin/clients");
      await queryClient.invalidateQueries("/admin/groups/unclaimed");
    },
  });
};

type PatchBody = {
  client_id: number;
  name: string;
};

export const useUpdateClientMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PatchBody) => {
      return apiClient.patch<APIResponse<null>>("/admin/clients", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/admin/clients");
      await queryClient.invalidateQueries("/admin/users");
    },
  });
};
