import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type CreateMinerModel = {
  name: string;
  deployment_type: number;
  employee: boolean;
  power_draw: number;
  hashrate?: string | null;
};

export const useCreateContractMinerModelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: CreateMinerModel) => {
      return apiClient.post<APIResponse<null>>("/contract/miner/model", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract/miner/models");
    },
  });
};

export type UpdateMinerModel = {
  id: number;
  name: string;
  deployment_type: number;
  power_draw: number;
  employee: boolean;
  hashrate?: string | null;
};

export const useUpdateContractMinerModelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: UpdateMinerModel) => {
      return apiClient.patch<APIResponse<null>>("/contract/miner/model", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract/miner/models");
    },
  });
};
