import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export const useCreateRepairFacilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { name: string }) => {
      return apiClient.post<APIResponse<null>>(
        "/logistics/repair_facility",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/repair_facilities");
    },
  });
};

export const useUpdateRepairFacilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number; name: string }) => {
      return apiClient.patch<APIResponse<null>>(
        "/logistics/repair_facility",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/repair_facilities");
    },
  });
};

export const useDeleteRepairFacilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { id: number }) => {
      return apiClient.delete<APIResponse<null>>("/logistics/repair_facility", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/logistics/repair_facilities");
    },
  });
};
