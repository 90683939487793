import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Panel } from "src/components/Panel";
import {
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Badge,
} from "@chakra-ui/react";
import { SimplePaginator } from "src/components/SimplePaginator";
import { useTicketsQuery } from "src/api/queries/fetchTickets";

export const TicketHistoryPanel = ({
  ticketId,
  minerSetId,
}: {
  ticketId: number;
  minerSetId: number;
}) => {
  const [state, setState] = useState<{
    page: number;
    size: number;
  }>({ page: 0, size: 10 });

  const query = useTicketsQuery({
    query: null,
    page: state.page,
    size: state.size,
    miner_set_id: minerSetId,
  });

  return (
    <Panel title={"Ticket History"} minW="300px" query={query}>
      {(data) => (
        <>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Status</Th>
                <Th>Costs</Th>
                <Th>Created At</Th>
                <Th>Completed At</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items
                .filter((ticket) => ticket.id !== ticketId)
                .map((ticket) => (
                  <Tr>
                    <Td>
                      <Link
                        as={ReactRouterLink}
                        to={`/tickets/${ticket.id}`}
                        fontWeight="bold"
                      >
                        {ticket.name}
                      </Link>
                    </Td>
                    <Td>{ticket.status_name}</Td>
                    <Td>
                      <Flex>
                        {ticket.costs
                          ?.filter(
                            (cost) =>
                              !cost.name.toLowerCase().includes("logistics")
                          )
                          .map((cost) => (
                            <Badge
                              variant="solid"
                              colorScheme={"green"}
                              m={1}
                              key={`cost-status-${cost.id}`}
                            >
                              {cost.name}
                            </Badge>
                          ))}
                      </Flex>
                    </Td>
                    <Td>{new Date(ticket.created_at).toLocaleDateString()}</Td>
                    <Td>
                      {ticket.completed_at != null
                        ? new Date(ticket.completed_at).toLocaleDateString()
                        : ""}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          {query.data &&
            query.data.items.filter((ticket) => ticket.id !== ticketId).length >
              0 && (
              <SimplePaginator
                pageSize={data.size}
                currentPage={data.page}
                totalItems={data.results}
                onPageChange={(page) => {
                  setState((s) => ({ ...s, page }));
                }}
              />
            )}
        </>
      )}
    </Panel>
  );
};
