import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type AffiliateModel = {
  name: string;
  commission_power_rate?: string | null;
  commission_percentage?: string | null;
};

export const useCreateContractAffiliateModelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: AffiliateModel) => {
      return apiClient.post<APIResponse<null>>(
        "/contract/affiliate/model",
        body
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/contract/affiliate/models");
    },
  });
};
