import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type InvoiceMinerTicket = {
  id: number;
  status: string;
  start_date: Date;
  solve_date?: Date;
};

export type InvoiceMiner = {
  id: number;
  miner_id?: string;
  name?: string;
  enabled: boolean;
  online: boolean;
  hashrate?: number;
  expected_hashrate: number;
  uptime?: number;
  coverage?: number;
  internal_uptime?: number;
  internal_coverage?: number;
  uptime_discount?: number;
  activation_discount?: number;
  bitfix_discount?: number;
  unpaid_discount?: number;
  note?: string;
  uptime_checked: boolean;
  bitfix_checked: boolean;
  is_unpaid: boolean;
  is_bitfix: boolean;
  activated_at?: Date;
  tickets?: InvoiceMinerTicket[];
};

export type InvoiceMinerGroup = {
  id: number;
  name: string;
  power_draw: number;
  activated: number;
  total: number;
  monthly_cost: string;
  total_monthly_cost: string;
  miners: InvoiceMiner[];
};

export type Invoice = {
  id: number;
  quickbooks_id: string;
  file_id?: string;
  contract_id: number;
  contract_name: string;
  client_name: string;
  name: string;
  idx: number;
  status: string;
  overdue: boolean;
  power_rate: string;
  estimated_cost: string;
  cost: string;
  balance: string;
  qbo_miner_count: number;
  uptime?: number;
  checked: boolean;
  invoice_at: string;
  due_at: string;
  groups?: InvoiceMinerGroup[];
};

export const fetchInvoice = (invoiceId: number) =>
  apiClient
    .get<APIResponse<Invoice>>(`/invoice?invoice_id=${invoiceId}`)
    .then((res) => res.data.data);

export const useInvoiceQuery = (invoiceId: number) =>
  useQuery(["/invoice", invoiceId], () => fetchInvoice(invoiceId));
