import { useState } from "react";
import { useMutation } from "react-query";
import { Link as ReactRouterLink, Redirect } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  ButtonGroup,
  Text,
  Button,
} from "@chakra-ui/react";
import { User } from "src/types";
import { authResetPassword } from "src/api/mutations/authResetPassword";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SmolStackLayout } from "src/components/SmolStackLayout";

type Form = {
  email: string;
};

type ForgotPasswordPageProps = {
  user: User | undefined;
};

export const ForgotPasswordPage = ({ user }: ForgotPasswordPageProps) => {
  const [form, setForm] = useState<Form>({
    email: "",
  });

  const mutation = useMutation(authResetPassword);

  if (user !== undefined) {
    return <Redirect to="/" />;
  }

  return (
    <SmolStackLayout>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate(form);
        }}
      >
        <Heading size="md">Forgot your password?</Heading>
        <Text my={4}>You will be sent a password reset link via email.</Text>
        <Stack spacing={4}>
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={form.email}
              onChange={(e) => {
                const email = e.target.value;
                setForm((f) => ({
                  ...f,
                  email,
                }));
              }}
              disabled={mutation.isLoading}
            />
          </FormControl>
          <MutationFeedback
            mutation={mutation}
            successMsg="Password reset has been requested. Check your email!"
          />
          <Stack spacing={10}>
            <ButtonGroup>
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={mutation.isLoading}
                disabled={
                  !form.email || mutation.isSuccess || mutation.isLoading
                }
              >
                Request Password Reset
              </Button>
              <Button as={ReactRouterLink} to="/login">
                Return to Login
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </form>
    </SmolStackLayout>
  );
};
