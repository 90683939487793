import { StatGroup, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { useDataDashboardQuery } from "src/api/queries/fetchDataDashboard";
import { Panel } from "src/components/Panel";

export const ActiveDevicesPanel = () => {
  const query = useDataDashboardQuery();

  return (
    <Panel title="Active Devices" flex={3} query={query}>
      {(data) => (
        <StatGroup>
          <Stat>
            <StatLabel>GPU</StatLabel>
            <StatNumber>{data.gpu_count}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>CPU</StatLabel>
            <StatNumber>{data.cpu_count}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>ASIC</StatLabel>
            <StatNumber>{data.asic_count}</StatNumber>
          </Stat>
        </StatGroup>
      )}
    </Panel>
  );
};
