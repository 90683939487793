import { Box, Grid, Badge, Divider, Stack } from "@chakra-ui/react";
import { useDataMinerQuery } from "src/api/queries/fetchDataMiner";
import { Panel } from "src/components/Panel";
import { Temperature } from "src/components/Temperature";

type DeviceType = "GPU" | "ASIC";

type Props = {
  deviceType: DeviceType;
  minerId: string;
};
export const DevicesPanel = ({ deviceType, minerId }: Props) => {
  const query = useDataMinerQuery(minerId);

  return (
    <Panel title={`${deviceType}s`} query={query}>
      {(data) => {
        const items = deviceType === "ASIC" ? data.asics : data.gpus;
        const templateColumns =
          deviceType === "ASIC"
            ? "2fr 2.5fr 1fr 1fr 1fr"
            : "2fr 1fr 1fr 1fr 1fr 1fr";

        return (
          <Box fontSize="sm" minW="45rem">
            <Grid
              templateColumns={templateColumns}
              gap={2}
              textTransform="uppercase"
            >
              <Box>Description</Box>
              {deviceType !== "ASIC" && <Box>Status</Box>}
              <Box>Fan</Box>
              <Box>Temp</Box>
              <Box>Progress</Box>
              <Box>Performance</Box>
            </Grid>
            <Divider my={2} />
            <Stack spacing={2} divider={<Divider my={2} />} whiteSpace="nowrap">
              {items?.map((device) => (
                <Grid
                  key={device.name}
                  templateColumns={templateColumns}
                  gap={2}
                >
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <Box
                      fontWeight="bold"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {device.name}
                    </Box>
                    <Box>
                      <Badge
                        colorScheme={
                          device.status.toLowerCase() === "enabled"
                            ? "green"
                            : undefined
                        }
                        variant="solid"
                      >
                        {device.status}
                      </Badge>
                    </Box>
                    <Box>
                      Load: {device.activity}% | Intensity: {device.intensity}
                    </Box>
                  </Box>
                  {deviceType !== "ASIC" && (
                    <Box>
                      <Box>GPU: {device.clock}</Box>
                      <Box>Memory: {device.memory_clock}</Box>
                    </Box>
                  )}
                  <Box>
                    <Box>
                      {device.fans_rpm != null
                        ? device.fans_rpm
                        : device.fan_percent.formatted}
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Temperature value={device.temperature.formatted} />
                    </Box>
                  </Box>
                  <Box>
                    {deviceType !== "ASIC" && (
                      <>
                        <Box>Accepted: {device.shares.accepted}</Box>
                        <Box>Rejected: {device.shares.rejected}</Box>
                      </>
                    )}
                    <Box>HW Errors: {device.shares.hw_errors}</Box>
                  </Box>
                  <Box>
                    <Box>{device.hashrate.formatted}</Box>
                    <Box>Avg: {device.avg_hashrate.formatted}</Box>
                  </Box>
                </Grid>
              ))}
            </Stack>
          </Box>
        );
      }}
    </Panel>
  );
};
