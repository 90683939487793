import { FC } from "react";
import { Box, Flex, Stack, Image } from "@chakra-ui/react";
import logoSrc from "src/images/bitcap_logo.png";

export const SmolStackLayout: FC = ({ children }) => {
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bgColor="gray.900">
      <Stack
        spacing={8}
        mx={"auto"}
        px={4}
        minW={["full", "md"]}
        m={4}
        maxW={"lg"}
      >
        <Stack align={"center"}>
          <Image maxH="12" src={logoSrc} />
        </Stack>
        <Box
          rounded={"lg"}
          bg={"white"}
          borderWidth={1}
          p={8}
          borderColor="whiteAlpha.200"
        >
          {children}
        </Box>
      </Stack>
    </Flex>
  );
};
