import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  page: number;
  size: number;
  miner_set_id?: number;
  status_ids?: string;
  category_ids?: string;
  miners_online?: boolean;
  checked?: boolean;
};

type TicketCategory = {
  id: number;
  name: string;
};

export type TicketCost = {
  id: number;
  model_id: number;
  name: string;
  cost: string;
  quantity: number;
};

export type Ticket = {
  id: number;
  name: string;
  client_id: number;
  client_name: string;
  creator_id: number;
  creator_name: string;
  assignee_id?: number;
  assignee_name?: string;
  rma_id?: string;
  miner_name: string;
  miner_id: string;
  status_id: number;
  status_name: string;
  categories: TicketCategory[];
  cost: string;
  costs?: TicketCost[];
  checked: boolean;
  created_at: Date;
  updated_at: Date;
  assigned_at?: Date;
  testing_at?: Date;
  completed_at?: Date;
  billed_at?: Date;
  archived_at?: Date;
};

export const fetchTickets = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Ticket>>("/tickets", { params: args })
    .then((res) => res.data.data);

export const useTicketsQuery = (args: QueryArgs) => {
  return useQuery(["/tickets", args], () => fetchTickets(args), {
    keepPreviousData: true,
  });
};
