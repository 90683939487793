import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type TicketCategory = {
  id: number;
  name: string;
};

export const fetchTicketCategories = () =>
  apiClient
    .get<APIResponsePaginated<TicketCategory>>("/tickets/categories")
    .then((res) => res.data.data);

export const useTicketCategoriesQuery = () => {
  return useQuery(["/tickets/categories"], () => fetchTicketCategories(), {
    keepPreviousData: true,
  });
};
