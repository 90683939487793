import { Stat, StatNumber } from "@chakra-ui/react";
import { useDataDashboardQuery } from "src/api/queries/fetchDataDashboard";
import { Dollars } from "src/components/Dollars";
import { Panel } from "src/components/Panel";

export const DailyRevenuePanel = () => {
  const query = useDataDashboardQuery();

  return (
    <Panel title="Daily Revenue" flex={2} query={query}>
      {(data) => (
        <Stat>
          <StatNumber>
            <Dollars
              value={
                data.daily_revenue != null
                  ? data.daily_revenue.formatted
                  : "$0.00"
              }
            />
          </StatNumber>
        </Stat>
      )}
    </Panel>
  );
};
