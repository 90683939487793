import { Box, Grid, Divider } from "@chakra-ui/react";
import { useDataMinerQuery } from "src/api/queries/fetchDataMiner";
import { MinerDescription } from "src/components/MinerDescription";
import { Panel } from "src/components/Panel";

type DescriptionPanelProps = {
  minerId: string;
};
export const DescriptionPanel = ({ minerId }: DescriptionPanelProps) => {
  const query = useDataMinerQuery(minerId);
  const title =
    query?.data == null
      ? "Miner"
      : `Miner (updated ${new Date(query.data.updated_at).toLocaleString()})`;

  return (
    <Panel query={query} title={title}>
      {(data) => (
        <Box minW="40rem">
          <Grid
            templateColumns="2fr repeat(4, 1fr)"
            gap={2}
            textTransform="uppercase"
            fontSize="sm"
          >
            <Box>Description</Box>
            <Box>Status</Box>
            <Box>Earnings</Box>
            <Box>Hashrate</Box>
            <Box>Progress</Box>
          </Grid>
          <Divider my={2} />
          <MinerDescription miner={{ ...data, gpus: null, pools: null }} />
        </Box>
      )}
    </Panel>
  );
};
