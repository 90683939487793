import { Link as ReactRouterLink } from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { Panel } from "src/components/Panel";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  Textarea,
  Stack,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  InputLeftElement,
  Input,
  InputGroup,
  LinkBox,
  LinkOverlay,
  Select,
  Switch,
  Button,
  IconButton,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import {
  SearchIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  CopyIcon,
  createIcon,
} from "@chakra-ui/icons";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SimplePaginator } from "src/components/SimplePaginator";
import {
  SearchableItem,
  SearchableSelect,
} from "src/components/SearchableSelect";
import { networks } from "src/types";
import {
  Shipment,
  ShipmentMiner,
  useShipmentsQuery,
} from "src/api/queries/fetchLogisticsShipments";
import {
  UpdateShipmentArgs,
  useCreateShipmentMutation,
  useUpdateShipmentMutation,
  useUpdateShipmentMinersMutation,
  useGenerateShipmentInvoiceMutation,
  useDeleteShipmentMutation,
} from "src/api/mutations/shipment";
import {
  useAdminClientsQuery,
  fetchAdminClients,
} from "src/api/queries/fetchAdminClients";
import {
  useDataMinerSetsQuery,
  fetchDataMinerSets,
  MinerSet,
} from "src/api/queries/fetchDataMinerSets";
import { useRepairFacilitiesQuery } from "src/api/queries/fetchLogisticsRepairFacilities";

type ClientSelectProps = {
  clientItem: SearchableItem | undefined;
  setClient: (item: SearchableItem) => void;
};

export const ClientSelect = ({ clientItem, setClient }: ClientSelectProps) => {
  const defaultQuery = useAdminClientsQuery();
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);

  useEffect(() => {
    if (defaultQuery?.data != null) {
      const items = defaultQuery.data.items.map((item: any) => {
        return { value: item.id, label: item.name };
      });
      setDefaultOptions(items);
    }
  }, [defaultQuery.data]);

  const loadSelectOptions = async (query: string) => {
    const data = await fetchAdminClients({ query: query, page: 0, size: 25 });
    return data.items.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  const [clear, setClear] = useState<boolean>(false);
  useEffect(() => {
    if (clientItem == null) {
      setClear(true);
      setTimeout(() => setClear(false), 250);
    }
  }, [clientItem]);

  return (
    <SearchableSelect
      name={"clients"}
      placeholder={"N/A"}
      value={clientItem}
      defaultOptions={defaultOptions}
      loadOptions={loadSelectOptions}
      onSelect={(item: SearchableItem) => setClient(item)}
      shouldClear={clear}
    />
  );
};

const CreateOrUpdateShipmentButton = ({
  shipment,
}: {
  shipment: Shipment | undefined;
}) => {
  const [state, setState] = useState<UpdateShipmentArgs>({
    shipment_id: shipment != null ? shipment.id : 0,
    date: new Date(),
    from_location:
      shipment != null
        ? {
            network_id: shipment.from_location.network_id,
            client_id: shipment.from_location.client_id,
            repair_facility_id: shipment.from_location.repair_facility_id,
          }
        : {},
    to_location:
      shipment != null
        ? {
            network_id: shipment.to_location.network_id,
            client_id: shipment.to_location.client_id,
            repair_facility_id: shipment.to_location.repair_facility_id,
          }
        : {},
    tracking_number: shipment?.tracking_number,
  });
  const [fromClientItem, setFromClientItem] = useState<
    SearchableItem | undefined
  >(
    shipment?.from_location.client_id != null
      ? {
          value: String(shipment.from_location.client_id),
          label: shipment.from_location.name,
        }
      : undefined
  );
  const [toClientItem, setToClientItem] = useState<SearchableItem | undefined>(
    shipment?.to_location.client_id != null
      ? {
          value: String(shipment.to_location.client_id),
          label: shipment.to_location.name,
        }
      : undefined
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const createMutation = useCreateShipmentMutation();
  const updateMutation = useUpdateShipmentMutation();
  const repairFacilitiesQuery = useRepairFacilitiesQuery();

  const resetState = useCallback(() => {
    createMutation.reset();
    updateMutation.reset();
    setState({
      date: new Date(),
      shipment_id: shipment != null ? shipment.id : 0,
      from_location:
        shipment != null
          ? {
              network_id: shipment.from_location.network_id,
              client_id: shipment.from_location.client_id,
              repair_facility_id: shipment.from_location.repair_facility_id,
            }
          : {},
      to_location:
        shipment != null
          ? {
              network_id: shipment.to_location.network_id,
              client_id: shipment.to_location.client_id,
              repair_facility_id: shipment.to_location.repair_facility_id,
            }
          : {},
      tracking_number: shipment?.tracking_number,
    });
    setFromClientItem(
      shipment?.from_location.client_id != null
        ? {
            value: String(shipment.from_location.client_id),
            label: shipment.from_location.name,
          }
        : undefined
    );
    setToClientItem(
      shipment?.to_location.client_id != null
        ? {
            value: String(shipment.to_location.client_id),
            label: shipment.to_location.name,
          }
        : undefined
    );
  }, [createMutation, updateMutation, shipment]);

  useEffect(() => {
    if (!isOpen && (!createMutation.isIdle || !updateMutation.isIdle)) {
      resetState();
    }
  }, [isOpen, createMutation, updateMutation, resetState]);

  useEffect(() => {
    const wasCreate = state.shipment_id === 0;
    const isCreate = shipment == null;

    if (
      !isOpen &&
      (wasCreate !== isCreate ||
        (shipment != null &&
          (shipment.tracking_number !== state.tracking_number ||
            shipment.from_location.client_id !==
              state.from_location.client_id ||
            shipment.from_location.network_id !==
              state.from_location.network_id ||
            shipment.from_location.repair_facility_id !==
              state.from_location.repair_facility_id ||
            shipment.to_location.client_id !== state.to_location.client_id ||
            shipment.to_location.network_id !== state.to_location.network_id ||
            shipment.to_location.repair_facility_id !==
              state.to_location.repair_facility_id)))
    ) {
      resetState();
    }
  }, [isOpen, shipment, state, resetState]);

  return (
    <Stack>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label={shipment != null ? "Update Shipment" : "Create Shipment"}
        icon={shipment != null ? <EditIcon /> : <AddIcon />}
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          resetState();
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {shipment != null ? "Update " : "Create "}Shipment
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel>Date</FormLabel>
                <SingleDatepicker
                  name={`date`}
                  date={state.date}
                  maxDate={new Date()}
                  onDateChange={(date) => setState((s) => ({ ...s, date }))}
                  propsConfigs={{
                    inputProps: {
                      textAlign: "center",
                      borderColor: "grayAlpha.500",
                      bg: "whiteAlpha.400",
                    },
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <b>From Location</b>
                </FormLabel>
                <VStack>
                  <HStack>
                    <FormLabel w="5rem">Location</FormLabel>
                    <Select
                      w="15rem"
                      value={
                        state.from_location.network_id == null
                          ? "N/A"
                          : state.from_location.network_id
                      }
                      onChange={(e) => {
                        let value: number | undefined = parseInt(
                          e.target.value
                        );
                        if (isNaN(value)) {
                          value = undefined;
                        }

                        setFromClientItem(undefined);
                        setState((s) => ({
                          ...s,
                          from_location: { network_id: value },
                        }));
                      }}
                    >
                      <option value={undefined}>N/A</option>
                      {networks.map((network) => (
                        <option
                          key={`network-${network.id}`}
                          value={network.id}
                        >
                          {network.name}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                  <HStack>
                    <FormLabel w="5rem">Client</FormLabel>
                    <Box width="15rem">
                      <ClientSelect
                        clientItem={fromClientItem}
                        setClient={(item: SearchableItem) => {
                          setFromClientItem(item);
                          setToClientItem(undefined);
                          setState((s) => ({
                            ...s,
                            from_location: { client_id: parseInt(item.value) },
                            to_location: {
                              ...s.to_location,
                              client_id: undefined,
                            },
                          }));
                        }}
                      />
                    </Box>
                  </HStack>
                  <HStack>
                    <FormLabel w="5rem">Repair Facility</FormLabel>
                    <Select
                      w="15rem"
                      value={
                        state.from_location.repair_facility_id == null
                          ? "N/A"
                          : state.from_location.repair_facility_id
                      }
                      onChange={(e) => {
                        let value: number | undefined = parseInt(
                          e.target.value
                        );
                        if (isNaN(value)) {
                          value = undefined;
                        }

                        setFromClientItem(undefined);
                        setState((s) => ({
                          ...s,
                          from_location: { repair_facility_id: value },
                        }));
                      }}
                    >
                      <option value={undefined}>N/A</option>
                      {repairFacilitiesQuery.data?.items.map(
                        (repairFacility) => (
                          <option
                            key={`repair-facility-${repairFacility.id}`}
                            value={repairFacility.id}
                          >
                            {repairFacility.name}
                          </option>
                        )
                      )}
                    </Select>
                  </HStack>
                </VStack>
              </FormControl>
              <FormControl>
                <FormLabel>
                  <b>To Location</b>
                </FormLabel>
                <VStack>
                  <HStack>
                    <FormLabel w="5rem">Location</FormLabel>
                    <Select
                      w="15rem"
                      value={
                        state.to_location.network_id == null
                          ? "N/A"
                          : state.to_location.network_id
                      }
                      onChange={(e) => {
                        let value: number | undefined = parseInt(
                          e.target.value
                        );
                        if (isNaN(value)) {
                          value = undefined;
                        }

                        setToClientItem(undefined);
                        setState((s) => ({
                          ...s,
                          to_location: { network_id: value },
                        }));
                      }}
                    >
                      <option value={undefined}>N/A</option>
                      {networks.map((network) => (
                        <option
                          key={`network-${network.id}`}
                          value={network.id}
                        >
                          {network.name}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                  <HStack>
                    <FormLabel w="5rem">Client</FormLabel>
                    <Box width="15rem">
                      <ClientSelect
                        clientItem={toClientItem}
                        setClient={(item: SearchableItem) => {
                          setFromClientItem(undefined);
                          setToClientItem(item);
                          setState((s) => ({
                            ...s,
                            from_location: {
                              ...s.from_location,
                              client_id: undefined,
                            },
                            to_location: { client_id: parseInt(item.value) },
                          }));
                        }}
                      />
                    </Box>
                  </HStack>
                  <HStack>
                    <FormLabel w="5rem">Repair Facility</FormLabel>
                    <Select
                      w="15rem"
                      value={
                        state.to_location.repair_facility_id == null
                          ? "N/A"
                          : state.to_location.repair_facility_id
                      }
                      onChange={(e) => {
                        let value: number | undefined = parseInt(
                          e.target.value
                        );
                        if (isNaN(value)) {
                          value = undefined;
                        }

                        setToClientItem(undefined);
                        setState((s) => ({
                          ...s,
                          to_location: { repair_facility_id: value },
                        }));
                      }}
                    >
                      <option value={undefined}>N/A</option>
                      {repairFacilitiesQuery.data?.items.map(
                        (repairFacility) => (
                          <option
                            key={`repair-facility-${repairFacility.id}`}
                            value={repairFacility.id}
                          >
                            {repairFacility.name}
                          </option>
                        )
                      )}
                    </Select>
                  </HStack>
                </VStack>
              </FormControl>
              <FormControl>
                <FormLabel>Tracking Number</FormLabel>
                <Input
                  type="name"
                  value={state.tracking_number}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      tracking_number: e.target.value,
                    }))
                  }
                  isDisabled={
                    createMutation.isLoading || updateMutation.isLoading
                  }
                />
              </FormControl>

              <MutationFeedback mutation={createMutation} />
              <MutationFeedback mutation={updateMutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={createMutation.isLoading || updateMutation.isLoading}
              onClick={() => {
                resetState();
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={createMutation.isLoading || updateMutation.isLoading}
              isDisabled={
                createMutation.isLoading ||
                updateMutation.isLoading ||
                (state.from_location.network_id == null &&
                  state.from_location.client_id == null &&
                  state.from_location.repair_facility_id == null) ||
                (state.to_location.network_id == null &&
                  state.to_location.client_id == null &&
                  state.to_location.repair_facility_id == null) ||
                (state.from_location.client_id != null &&
                  state.to_location.client_id != null) ||
                (state.from_location.network_id != null &&
                  state.from_location.network_id ===
                    state.to_location.network_id) ||
                (state.from_location.repair_facility_id != null &&
                  state.from_location.repair_facility_id ===
                    state.to_location.repair_facility_id)
              }
              onClick={() => {
                if (shipment != null) {
                  updateMutation.mutate(state, {
                    onSuccess: () => {
                      resetState();
                      onClose();
                    },
                  });
                } else {
                  createMutation.mutate(
                    {
                      date: state.date,
                      from_location: state.from_location,
                      to_location: state.to_location,
                      tracking_number: state.tracking_number,
                    },
                    {
                      onSuccess: () => {
                        resetState();
                        onClose();
                      },
                    }
                  );
                }
              }}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const AddBulkShipmentMinersButton = ({ shipment }: { shipment: Shipment }) => {
  const [text, setText] = useState<string[]>([]);
  const [minerSets, setMinerSets] = useState<(MinerSet | undefined)[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [needsCalculating, setNeedsCalculating] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateShipmentMinersMutation();

  const resetState = useCallback(() => {
    setText([]);
    setMinerSets([]);
    setErrors([]);
  }, [setText, setMinerSets, setErrors]);

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      resetState();
    }
  }, [isOpen, mutation, resetState]);

  const calculateMinerSets = async () => {
    setIsCalculating(true);
    let newMinerSets = [...minerSets];
    if (newMinerSets.length > text.length) {
      newMinerSets = newMinerSets.slice(0, text.length);
    } else if (newMinerSets.length < text.length) {
      newMinerSets = newMinerSets.concat(
        Array(text.length - newMinerSets.length).fill(undefined)
      );
    }

    let newErrors = [...errors];
    if (newErrors.length > text.length) {
      newErrors = newErrors.slice(0, text.length);
    } else if (newErrors.length < text.length) {
      newErrors = newErrors.concat(
        Array(text.length - newErrors.length).fill(undefined)
      );
    }

    for (let i = 0; i < text.length; i++) {
      let serial = text[i].toLowerCase();
      if (serial.length < 5) {
        newErrors[i] = "Invalid serial";
        continue;
      } else if (minerSets.length - 1 < i) {
        continue;
      } else if (newErrors[i] != null) {
        newErrors[i] = "";
      }

      if (newMinerSets[i] != null) {
        const minerSetSerial = newMinerSets[i]!.serial?.toLowerCase();
        const minerSetSlug =
          newMinerSets[i]!.name.length > 5
            ? newMinerSets[i]!.name.substr(newMinerSets[i]!.name.length - 5)
            : "";
        if (
          minerSetSerial === serial ||
          serial.substr(serial.length - 5) === minerSetSlug
        ) {
          continue;
        }

        newMinerSets[i] = undefined;
      }

      const data = await fetchDataMinerSets({
        query: serial.substr(serial.length - 5),
        ticketed: true,
        page: 0,
        size: 5,
      });
      if (data.items.length === 0) {
        newErrors[i] = "Miner not found";
      } else if (data.items.length > 1) {
        newErrors[i] = `${
          data.items.length === 5 ? "5+" : data.items.length
        } miners found`;
      } else {
        newMinerSets[i] = data.items[0];
      }
    }

    setMinerSets(newMinerSets);
    setErrors(newErrors);
    setIsCalculating(false);
    setNeedsCalculating(false);
  };

  return (
    <Stack w="full">
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Add Bulk Miners"
        icon={<CopyIcon />}
        onClick={onOpen}
      />

      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          resetState();
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bulk Miners</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Stack w="100%" alignItems="center">
                <FormLabel textAlign="center" m="auto">
                  Miner Serials
                </FormLabel>
                <Textarea
                  value={text.join("\n")}
                  onChange={(e) => setText(e.target.value.split("\n"))}
                  isDisabled={mutation.isLoading}
                />
              </Stack>
              <MutationFeedback mutation={mutation} />
              <Table size="sm" mb={4} variant={"striped"}>
                <Thead>
                  <Tr>
                    <Th>Serial</Th>
                    <Th>Name</Th>
                    <Th>Error</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {text.map((serial, idx) => {
                    let minerSet =
                      minerSets.length - 1 >= idx ? minerSets[idx] : undefined;
                    let error =
                      errors.length - 1 >= idx ? errors[idx] : undefined;

                    return (
                      <Tr key={`serial-${idx}`}>
                        <Td>
                          {minerSet?.serial != null ? minerSet.serial : serial}
                        </Td>
                        <Td>
                          {minerSet?.miner_id != null ? (
                            <Link
                              as={ReactRouterLink}
                              to={`/miners/${minerSet.miner_id}`}
                              fontWeight="bold"
                            >
                              {minerSet?.name}
                            </Link>
                          ) : (
                            minerSet?.name
                          )}
                        </Td>
                        <Td>{error}</Td>
                        <Td>
                          <IconButton
                            size="sm"
                            mr={4}
                            colorScheme="red"
                            variant="ghost"
                            aria-label="delete miner"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              setText(
                                text.filter((_, textIdx) => idx !== textIdx)
                              );
                              setMinerSets(
                                minerSets.filter(
                                  (_, minerSetIdx) => idx !== minerSetIdx
                                )
                              );
                              setErrors(
                                errors.filter((_, errorIdx) => idx !== errorIdx)
                              );
                              setNeedsCalculating(true);
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={mutation.isLoading}
              isLoading={isCalculating}
              onClick={calculateMinerSets}
              ml={3}
            >
              Calculate
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={
                mutation.isLoading ||
                needsCalculating ||
                isCalculating ||
                text.length === 0 ||
                minerSets.length !== text.length ||
                errors.length !== text.length
              }
              onClick={() => {}}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export const InvoiceIcon = createIcon({
  displayName: "InvoiceIcon",
  viewBox: "0 0 512.002 512.002",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <g>
        <g>
          <path
            d="M128.257,392.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533s3.866,8.533,8.576,8.533s8.533-3.823,8.533-8.533
        S132.967,392.533,128.257,392.533z"
          />
          <path
            d="M179.457,392.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533s3.866,8.533,8.576,8.533s8.533-3.823,8.533-8.533
        S184.167,392.533,179.457,392.533z"
          />
          <path
            d="M247.468,273.067h-68.267c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h68.267
        c4.719,0,8.533-3.823,8.533-8.533S252.187,273.067,247.468,273.067z"
          />
          <path
            d="M213.334,324.267h-34.133c-4.719,0-8.533,3.823-8.533,8.533c0,4.71,3.814,8.533,8.533,8.533h34.133
        c4.719,0,8.533-3.823,8.533-8.533C221.868,328.09,218.053,324.267,213.334,324.267z"
          />
          <path
            d="M358.401,298.667c-9.412,0-17.067-7.654-17.067-17.067c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533
        c0,15.855,10.914,29.107,25.6,32.922v1.212c0,4.71,3.814,8.533,8.533,8.533c4.719,0,8.533-3.823,8.533-8.533v-1.212
        c14.686-3.814,25.6-17.067,25.6-32.922c0-18.825-15.309-34.133-34.133-34.133c-9.412,0-17.067-7.654-17.067-17.067
        c0-9.412,7.654-17.067,17.067-17.067c9.412,0,17.067,7.654,17.067,17.067c0,4.71,3.814,8.533,8.533,8.533
        s8.533-3.823,8.533-8.533c0-15.855-10.914-29.107-25.6-32.922v-1.212c0-4.71-3.814-8.533-8.533-8.533
        c-4.719,0-8.533,3.823-8.533,8.533v1.212c-14.686,3.814-25.6,17.067-25.6,32.922c0,18.825,15.309,34.133,34.133,34.133
        c9.412,0,17.067,7.654,17.067,17.067C375.468,291.012,367.813,298.667,358.401,298.667z"
          />
          <path
            d="M333.057,392.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533s3.866,8.533,8.576,8.533c4.71,0,8.533-3.823,8.533-8.533
        S337.768,392.533,333.057,392.533z"
          />
          <path
            d="M435.457,409.6c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533
        S430.747,409.6,435.457,409.6z"
          />
          <path
            d="M384.257,392.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533s3.866,8.533,8.576,8.533c4.71,0,8.533-3.823,8.533-8.533
        S388.968,392.533,384.257,392.533z"
          />
          <path
            d="M349.868,102.4h34.133c4.719,0,8.533-3.823,8.533-8.533c0-4.71-3.814-8.533-8.533-8.533h-34.133
        c-4.719,0-8.533,3.823-8.533,8.533C341.334,98.577,345.149,102.4,349.868,102.4z"
          />
          <path
            d="M230.657,392.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533s3.866,8.533,8.576,8.533s8.533-3.823,8.533-8.533
        S235.367,392.533,230.657,392.533z"
          />
          <path
            d="M128.001,290.133h17.067c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-17.067
        c-4.719,0-8.533,3.823-8.533,8.533S123.282,290.133,128.001,290.133z"
          />
          <path
            d="M128.001,341.333h17.067c4.719,0,8.533-3.823,8.533-8.533c0-4.71-3.814-8.533-8.533-8.533h-17.067
        c-4.719,0-8.533,3.823-8.533,8.533C119.468,337.51,123.282,341.333,128.001,341.333z"
          />
          <path
            d="M469.334,0H42.668c-4.719,0-8.533,3.823-8.533,8.533v494.933c0,3.447,2.074,6.562,5.265,7.885
        c1.058,0.435,2.167,0.648,3.268,0.648c2.219,0,4.403-0.87,6.033-2.5l45.167-45.167l45.167,45.167
        c3.337,3.337,8.73,3.337,12.066,0l28.1-28.1l28.1,28.1c3.337,3.337,8.73,3.337,12.066,0l28.1-28.1l11.034,11.034
        c3.337,3.336,8.73,3.336,12.066,0l28.1-28.1l28.1,28.1c3.337,3.336,8.73,3.336,12.066,0l19.567-19.567l19.567,19.567
        c3.337,3.336,8.73,3.336,12.066,0l45.167-45.167l28.1,28.1c0.171,0.179,0.35,0.341,0.538,0.495c0,0.009,0.008,0.009,0.008,0.009
        v0.009c1.399,1.169,3.174,1.894,5.112,1.98c0.316,0.009,0.631,0.009,0.964-0.009h0.009c1.724-0.128,3.311-0.751,4.599-1.749
        c0.461-0.35,0.879-0.751,1.254-1.186c1.289-1.493,2.074-3.43,2.082-5.564v-0.017V8.533C477.868,3.823,474.053,0,469.334,0z
         M460.801,448.734l-19.567-19.567c-3.337-3.336-8.73-3.336-12.066,0l-45.167,45.167l-19.567-19.567
        c-3.336-3.337-8.73-3.337-12.066,0l-19.567,19.567l-28.1-28.1c-3.336-3.337-8.73-3.337-12.066,0l-28.1,28.1L253.501,463.3
        c-3.337-3.337-8.73-3.337-12.066,0l-28.1,28.1l-28.1-28.1c-1.664-1.664-3.849-2.5-6.033-2.5c-2.185,0-4.369,0.836-6.033,2.5
        l-28.1,28.1l-45.167-45.167c-3.337-3.337-8.73-3.337-12.066,0l-36.634,36.634V17.067h409.6V448.734z"
          />
          <path
            d="M77.057,409.6c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533
        S72.347,409.6,77.057,409.6z"
          />
          <path
            d="M128.001,238.933h17.067c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-17.067
        c-4.719,0-8.533,3.823-8.533,8.533S123.282,238.933,128.001,238.933z"
          />
          <path
            d="M281.857,409.6c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-0.085c-4.71,0-8.491,3.823-8.491,8.533
        S277.147,409.6,281.857,409.6z"
          />
          <path
            d="M179.201,187.733h51.2c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-51.2
        c-4.719,0-8.533,3.823-8.533,8.533S174.482,187.733,179.201,187.733z"
          />
          <path
            d="M128.001,187.733h17.067c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-17.067
        c-4.719,0-8.533,3.823-8.533,8.533S123.282,187.733,128.001,187.733z"
          />
          <path
            d="M264.534,221.867h-85.333c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h85.333
        c4.719,0,8.533-3.823,8.533-8.533S269.253,221.867,264.534,221.867z"
          />
          <path
            d="M128.001,102.4h136.533c4.719,0,8.533-3.823,8.533-8.533c0-4.71-3.814-8.533-8.533-8.533H128.001
        c-4.719,0-8.533,3.823-8.533,8.533C119.468,98.577,123.282,102.4,128.001,102.4z"
          />
        </g>
      </g>
    </g>
  ),
});

const GenerateShipmentInvoice = ({ shipmentId }: { shipmentId: number }) => {
  const [cost, setCost] = useState<number>(80);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useGenerateShipmentInvoiceMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setCost(80);
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Generate Shipment Invoice"
        icon={<InvoiceIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Shipment Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Stack w="100%" alignItems="center">
                <FormLabel textAlign="center" m="auto">
                  Cost
                </FormLabel>
                <NumberInput
                  textAlign="center"
                  maxW={"15rem"}
                  value={"$" + cost}
                  onChange={(e) => {
                    const value = parseFloat(e);
                    if (!isNaN(value) || e.length === 0) {
                      const parts = e.split(".");
                      if (parts.length === 2 && parts[1].length > 4) {
                        e = parts[0] + "." + parts[1].substr(0, 4);
                      }

                      setCost(isNaN(value) ? 0 : value);
                    }
                  }}
                  isDisabled={mutation.isLoading}
                >
                  <NumberInputField />
                </NumberInput>
              </Stack>
              <MutationFeedback mutation={mutation} />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading || cost <= 0}
              onClick={() =>
                mutation.mutate(
                  { shipment_id: shipmentId, cost: cost.toString() },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const DeleteShipmentButton = ({ shipment }: { shipment: Shipment }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useDeleteShipmentMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="sm"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Shipment
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>Are you sure you want delete this shipment?</Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={() =>
                  mutation.mutate(
                    { shipment_id: shipment.id },
                    { onSuccess: onClose }
                  )
                }
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const DeleteShipmentMinerButton = ({
  shipment,
  shipmentMiner,
}: {
  shipment: Shipment;
  shipmentMiner: ShipmentMiner;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useUpdateShipmentMinersMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Shipment
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete {shipmentMiner.name} from this
                  shipment?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={() =>
                  mutation.mutate({
                    shipment_id: shipment.id,
                    miner_set_ids: shipment.miners
                      .filter((miner) => miner.id !== shipmentMiner.id)
                      .map((miner) => miner.id),
                  })
                }
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const ShipmentsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    size: number;
    query: null | string;
    showMiners: boolean;
  }>({ page: 0, size: 10, query: null, showMiners: true });

  const query = useShipmentsQuery({
    query: state.query,
    page: state.page,
    size: state.size,
  });

  const updateMinersMutation = useUpdateShipmentMinersMutation();
  const [minerItems, setMinerItems] = useState<
    Record<number, SearchableItem | undefined>
  >({});
  const [minerClears, setMinerClears] = useState<
    Record<number, boolean | undefined>
  >({});

  const defaultQuery = useDataMinerSetsQuery({
    page: 0,
    size: 50,
  });
  const [defaultMinerOptions, setDefaultMinerOptions] = useState<any[]>([]);

  useEffect(() => {
    if (defaultQuery?.data != null) {
      const items = defaultQuery.data.items.map((item: any) => {
        return {
          value: item.id,
          label:
            item.client_name !== null
              ? `${item.name} (${item.client_name})`
              : item.name,
        };
      });
      setDefaultMinerOptions(items);
    }
  }, [defaultQuery.data]);

  const loadSelectMinerOptions = async (query: string) => {
    const data = await fetchDataMinerSets({
      query: query,
      page: 0,
      size: 50,
    });
    return data.items.map((item: any) => {
      return {
        value: item.id,
        label:
          item.client_name !== null
            ? `${item.name} (${item.client_name})`
            : item.name,
      };
    });
  };

  return (
    <Panel title={"Shipments"} minW="400px" query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <HStack flex={1}>
              <FormControl maxW="30rem" pl={1}>
                <FormLabel>Search</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    color="gray.300"
                    children={<SearchIcon />}
                  />
                  <Input
                    value={state.query ? state.query : ""}
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        page: 0,
                        query:
                          e.target.value != null && e.target.value.length > 0
                            ? e.target.value
                            : null,
                      }))
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl maxW="10rem">
                <FormLabel>Size</FormLabel>
                <Select
                  value={state.size}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      size: !isNaN(value) ? value : s.size,
                    }));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </FormControl>
              <FormControl maxW="10rem" px={4}>
                <FormLabel>Show Miners</FormLabel>
                <Switch
                  size="lg"
                  isChecked={state.showMiners}
                  onChange={(e) =>
                    setState((s) => ({ ...s, showMiners: e.target.checked }))
                  }
                />
              </FormControl>
            </HStack>
            <CreateOrUpdateShipmentButton shipment={undefined} />
          </HStack>
          <MutationFeedback mutation={updateMinersMutation} />
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Location</Th>
                <Th>Invoice</Th>
                <Th>Tracking Number</Th>
                {state.showMiners && <Th>Miner</Th>}
                {state.showMiners && <Th>Client</Th>}
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((shipment) => (
                <>
                  <Tr key={`shipment-${shipment.id}`}>
                    <Td>
                      {new Date(shipment.created_at).toLocaleDateString()}
                    </Td>
                    <Td>{`${shipment.from_location.name} → ${shipment.to_location.name}`}</Td>
                    <Td>
                      {shipment.invoice != null && (
                        <LinkBox
                          bg="blackAlpha.100"
                          p={3}
                          borderRadius="md"
                          _hover={{ bg: "blackAlpha.200" }}
                          width="fit-content"
                          minWidth="100%"
                          textAlign="center"
                          maxW="7rem"
                        >
                          <LinkOverlay
                            fontWeight="bold"
                            as={ReactRouterLink}
                            to={`/invoices/${shipment.invoice.id}`}
                            textAlign="center"
                          >
                            View
                          </LinkOverlay>
                        </LinkBox>
                      )}
                    </Td>
                    <Td>{shipment.tracking_number}</Td>
                    {state.showMiners && <Td />}
                    {state.showMiners && <Td />}
                    <Td>
                      <HStack>
                        <CreateOrUpdateShipmentButton shipment={shipment} />
                        {false && (
                          <AddBulkShipmentMinersButton shipment={shipment} />
                        )}
                        {shipment.to_location.client_id != null &&
                          shipment.invoice == null && (
                            <GenerateShipmentInvoice shipmentId={shipment.id} />
                          )}
                        <DeleteShipmentButton shipment={shipment} />
                      </HStack>
                    </Td>
                  </Tr>
                  {state.showMiners &&
                    shipment.miners.map((miner) => (
                      <Tr key={`shipment-miner-${miner.id}`}>
                        <Td />
                        <Td />
                        <Td />
                        <Td />
                        <Td>
                          <Link
                            as={ReactRouterLink}
                            to={`/miners/${miner.miner_id}`}
                            fontWeight="bold"
                          >
                            {miner.name}
                          </Link>
                        </Td>
                        <Td>{miner.client_name}</Td>
                        <Td>
                          <DeleteShipmentMinerButton
                            shipment={shipment}
                            shipmentMiner={miner}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {state.showMiners && (
                    <Tr>
                      <Td />
                      <Td />
                      <Td />
                      <Td />
                      <Td minW="350px">
                        <SearchableSelect
                          name={"miners"}
                          placeholder={"Search a miner"}
                          value={minerItems[shipment.id]}
                          shouldClear={
                            minerClears[shipment.id] == null
                              ? false
                              : minerClears[shipment.id]
                          }
                          defaultOptions={defaultMinerOptions}
                          loadOptions={loadSelectMinerOptions}
                          onSelect={(item) =>
                            setMinerItems((s) => ({
                              ...s,
                              [shipment.id]: item,
                            }))
                          }
                        />
                      </Td>
                      <Td />
                      <Td>
                        <Button
                          colorScheme="teal"
                          isDisabled={
                            minerItems[shipment.id] == null ||
                            updateMinersMutation.isLoading
                          }
                          onClick={() =>
                            updateMinersMutation.mutate(
                              {
                                shipment_id: shipment.id,
                                miner_set_ids: shipment.miners
                                  .map((miner) => miner.id)
                                  .concat([
                                    parseInt(minerItems[shipment.id]!.value),
                                  ]),
                              },
                              {
                                onSuccess: () => {
                                  setMinerItems((s) => ({
                                    ...s,
                                    [shipment.id]: undefined,
                                  }));
                                  setMinerClears((s) => ({
                                    ...s,
                                    [shipment.id]: true,
                                  }));
                                  setTimeout(
                                    () =>
                                      setMinerClears((s) => ({
                                        ...s,
                                        [shipment.id]: false,
                                      })),
                                    250
                                  );
                                },
                              }
                            )
                          }
                        >
                          Add
                        </Button>
                      </Td>
                    </Tr>
                  )}
                </>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
