import { useState } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Input,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { Ticket } from "src/api/queries/fetchTicket";
import {
  useCreateTicketItemMutation,
  useDeleteTicketItemMutation,
  CreateTicketItemArgs,
} from "src/api/mutations/ticketCreate";
import { MutationFeedback } from "src/components/MutationFeedback";
import { ticketItemTypes, ticketItemOperations } from "src/types";

export const ItemsPanel = ({ ticket }: { ticket: Ticket }) => {
  const createMutation = useCreateTicketItemMutation();
  const deleteMutation = useDeleteTicketItemMutation();
  const [state, setState] = useState<CreateTicketItemArgs>({
    ticket_id: ticket.id,
    type: 0,
    operation: 0,
    index: 1,
    serial: "",
  });

  return (
    <Panel title={"Items"}>
      <MutationFeedback mutation={createMutation} />
      <MutationFeedback mutation={deleteMutation} />
      <Table size="sm" mb={4} variant={"striped"}>
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Operation</Th>
            <Th>Index</Th>
            <Th>Serial</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {ticket.items?.map((item) => (
            <Tr key={`item-${item.id}`}>
              <Td>{item.type_name}</Td>
              <Td>{item.operation_name}</Td>
              <Td>{item.index}</Td>
              <Td>{item.serial}</Td>
              <Td>
                <Button
                  colorScheme="red"
                  isDisabled={
                    createMutation.isLoading || deleteMutation.isLoading
                  }
                  onClick={() => deleteMutation.mutate({ id: item.id })}
                >
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
          {ticket.completed_at == null && (
            <Tr>
              <Td>
                <Select
                  w="15rem"
                  value={state.type}
                  isDisabled={
                    createMutation.isLoading || deleteMutation.isLoading
                  }
                  onChange={(e) => {
                    setState((s) => ({
                      ...s,
                      type: parseInt(e.target.value),
                      index: 1,
                      operation: 0,
                    }));
                  }}
                >
                  <option value={0}>Choose a type</option>
                  {ticketItemTypes.map((ticketItemType) => (
                    <option
                      key={`item-type-${ticketItemType.id}`}
                      value={ticketItemType.id}
                    >
                      {ticketItemType.name}
                    </option>
                  ))}
                </Select>
              </Td>
              <Td>
                <Select
                  w="15rem"
                  value={state.operation}
                  isDisabled={
                    createMutation.isLoading ||
                    deleteMutation.isLoading ||
                    state.type === 0
                  }
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      operation: parseInt(e.target.value),
                    }))
                  }
                >
                  <option value={0}>Choose an operation</option>
                  {ticketItemOperations.map((ticketItemOperation) => (
                    <option
                      key={`item-operation-${ticketItemOperation.id}`}
                      value={ticketItemOperation.id}
                    >
                      {ticketItemOperation.name}
                    </option>
                  ))}
                </Select>
              </Td>
              <Td>
                <Select
                  w="15rem"
                  value={state.index}
                  isDisabled={
                    createMutation.isLoading ||
                    deleteMutation.isLoading ||
                    state.type === 0
                  }
                  onChange={(e) =>
                    setState((s) => ({ ...s, index: parseInt(e.target.value) }))
                  }
                >
                  <option value={1}>1</option>
                  {state.type === 2 && (
                    <>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </>
                  )}
                </Select>
              </Td>
              <Td>
                <Input
                  type="name"
                  value={state.serial}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      serial: e.target.value,
                    }))
                  }
                  isDisabled={
                    createMutation.isLoading ||
                    deleteMutation.isLoading ||
                    state.type === 0
                  }
                />
              </Td>
              <Td>
                <Button
                  colorScheme="teal"
                  isDisabled={
                    state.type === 0 ||
                    state.operation === 0 ||
                    state.index === 0 ||
                    state.serial.length === 0
                  }
                  onClick={() =>
                    createMutation.mutate(
                      {
                        ticket_id: ticket.id,
                        type: state.type,
                        operation: state.operation,
                        index: state.index,
                        serial: state.serial,
                      },
                      {
                        onSuccess: () => {
                          setState({
                            ticket_id: ticket.id,
                            type: 0,
                            operation: 0,
                            index: 1,
                            serial: "",
                          });
                        },
                      }
                    )
                  }
                >
                  Add
                </Button>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Panel>
  );
};
