import { APIResponse, UserType } from "src/types";
import { apiClient } from "..";

type LoginBody = {
  email: string;
  password: string;
  code: string;
};

export const authLogin = (body: LoginBody) => {
  return apiClient.post<
    APIResponse<{
      tokens: {
        auth: string;
        auth_exp: number;
        refresh: string;
        refresh_exp: number;
      };
      type: UserType;
    }>
  >("/auth/login", body);
};
