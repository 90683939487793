import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { Center, ButtonGroup, Button, Flex } from "@chakra-ui/react";

type Props = {
  currentPage: number;
  totalItems: number;
  pageSize: number;
  onPageChange?: (page: number) => void;
};

export const SimplePaginator = ({
  currentPage,
  totalItems,
  pageSize,
  onPageChange = () => null,
}: Props) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  if (totalItems <= 0) {
    return null;
  }
  return (
    <Center>
      <ButtonGroup isAttached size="sm">
        <Button
          disabled={currentPage < 5}
          leftIcon={<ArrowLeftIcon fontSize={"sm"} />}
          onClick={() => onPageChange(currentPage - 5)}
        />
        <Button
          disabled={currentPage <= 0}
          leftIcon={<ChevronLeftIcon fontSize={"2xl"} />}
          onClick={() => onPageChange(currentPage - 1)}
        />
        <Flex
          justifyContent="center"
          alignItems="center"
          px={3}
          fontSize="sm"
          bg="gray.100"
        >
          {currentPage + 1}&nbsp;/&nbsp;{totalPages}
        </Flex>
        <Button
          disabled={currentPage >= totalPages - 1}
          rightIcon={<ChevronRightIcon fontSize={"2xl"} />}
          onClick={() => onPageChange(currentPage + 1)}
        />
        <Button
          disabled={currentPage >= totalPages - 5}
          rightIcon={<ArrowRightIcon fontSize={"sm"} />}
          onClick={() => onPageChange(currentPage + 5)}
        />
      </ButtonGroup>
    </Center>
  );
};
