import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  page: number;
  size: number;
};

export type Client = {
  id: number;
  name: string;
  groups: {
    id: string;
    network_id: number;
    awesomeminer_id: number;
    name: string;
  }[];
  is_company: boolean;
  batched_invoice_day?: number;
  created_at: string;
  updated_at: string;
};

export const fetchAdminClients = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Client>>("/admin/clients", { params: args })
    .then((res) => res.data.data);

export const useAdminClientsQuery = (
  args: QueryArgs = {
    query: null,
    page: 0,
    size: 10000,
  }
) => useQuery(["/admin/clients", args], () => fetchAdminClients(args));
