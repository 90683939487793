import { Button, ButtonGroup } from "@chakra-ui/react";

type SwitchButtonProps<T> = {
  options: {
    value: T;
    label: string;
  }[];
  value: T;
  onChange: (period: T) => void;
  size?: "xs" | "sm" | "lg" | "md";
  isDisabled?: boolean;
};

export const SwitchButton = <T extends unknown>({
  options,
  value,
  onChange,
  size = "xs",
  isDisabled = false,
}: SwitchButtonProps<T>) => {
  return (
    <ButtonGroup
      size={size}
      isAttached
      justifyContent="center"
      my={1}
      isDisabled={isDisabled}
    >
      {options.map((opt) => (
        <Button
          key={opt.label}
          isActive={value === opt.value}
          onClick={() => onChange(opt.value)}
        >
          {opt.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
