import { StatGroup, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { useInvoicesSummaryQuery } from "src/api/queries/fetchInvoicesSummary";
import { Panel } from "src/components/Panel";

export const SummaryPanel = () => {
  const query = useInvoicesSummaryQuery();

  return (
    <Panel title="Summary" flex={3} query={query}>
      {(data) => (
        <>
          <StatGroup>
            <Stat>
              <StatLabel>Active Annual Contracts</StatLabel>
              <StatNumber>{data.contracts.annual}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Active Billed Monthly Contracts</StatLabel>
              <StatNumber>
                {data.contracts.monthly_billed} /{" "}
                {data.contracts.monthly_billed +
                  data.contracts.monthly_unbilled}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Active Batched Monthly Contracts</StatLabel>
              <StatNumber>{data.contracts.monthly_batched}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Awaiting Activation</StatLabel>
              <StatNumber>{data.contracts.awaiting}</StatNumber>
            </Stat>
          </StatGroup>
        </>
      )}
    </Panel>
  );
};
