import { useState, useEffect, useRef } from "react";
import { Panel } from "src/components/Panel";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  HStack,
  Button,
  Input,
  Select,
  IconButton,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { MutationFeedback } from "src/components/MutationFeedback";
import {
  TicketCostModel,
  TicketCostModelCategory,
  useTicketCostModelsQuery,
} from "src/api/queries/fetchTicketCostModels";
import {
  useCreateTicketCostModelCategoryMutation,
  useUpdateTicketCostModelCategoryMutation,
  useDeleteTicketCostModelCategoryMutation,
  useCreateTicketCostModelMutation,
  useUpdateTicketCostModelMutation,
  useDeleteTicketCostModelMutation,
} from "src/api/mutations/ticketCreate";

const CreateCostCategoryButton = () => {
  const [name, setName] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateTicketCostModelCategoryMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setName("");
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Cost Category"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Create Cost Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading || name.length === 0}
              onClick={() =>
                mutation.mutate({ name: name }, { onSuccess: onClose })
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const CreateCostButton = ({ costCategoryId }: { costCategoryId: number }) => {
  const [name, setName] = useState<string>("");
  const [cost, setCost] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateTicketCostModelMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setName("");
      setCost("");
    }
  }, [isOpen, mutation]);

  return (
    <Stack>
      <IconButton
        size="sm"
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Cost"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Create Cost</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cost</FormLabel>
                <NumberInput
                  maxW={"10rem"}
                  value={"$" + cost}
                  onChange={(e) => setCost(e)}
                  isDisabled={mutation.isLoading}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={
                mutation.isLoading ||
                name.length === 0 ||
                isNaN(parseFloat(cost)) ||
                parseFloat(cost) === 0
              }
              onClick={() =>
                mutation.mutate(
                  { cost_category_id: costCategoryId, name: name, cost: cost },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const EditCostCategoryButton = ({
  category,
}: {
  category: TicketCostModelCategory;
}) => {
  const [state, setState] = useState<TicketCostModelCategory>(category);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateTicketCostModelCategoryMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(category);
    }
  }, [isOpen, mutation, category]);

  useEffect(() => {
    if (!isOpen && category.name !== state.name) {
      setState(category);
    }
  }, [isOpen, category, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Cost Category"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Edit Cost Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={state.name.length === 0}
              onClick={() =>
                mutation.mutate(
                  { id: state.id, name: state.name },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditCostButton = ({
  categories,
  model,
}: {
  categories: TicketCostModelCategory[];
  model: TicketCostModel;
}) => {
  const [state, setState] = useState<TicketCostModel>(model);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useUpdateTicketCostModelMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState(model);
    }
  }, [isOpen, mutation, model]);

  useEffect(() => {
    if (
      !isOpen &&
      (model.cost_category_id !== state.cost_category_id ||
        model.name !== state.name ||
        model.cost !== state.cost)
    ) {
      setState(model);
    }
  }, [isOpen, model, state]);

  return (
    <>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Edit Cost"
        icon={<EditIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="auto">
          <ModalHeader>Edit Cost</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="category">
                <FormLabel>Cost Category</FormLabel>
                <Select
                  value={state.cost_category_id}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setState((s) => ({
                      ...s,
                      cost_category_id: value,
                    }));
                  }}
                >
                  {categories.map((category) => (
                    <option
                      key={`category-select-${category.id}`}
                      value={category.id}
                    >
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cost</FormLabel>
                <NumberInput
                  value={"$" + state.cost}
                  onChange={(e) => setState((s) => ({ ...s, cost: e }))}
                  isDisabled={mutation.isLoading}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <MutationFeedback mutation={mutation} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={state.name.length === 0}
              onClick={() =>
                mutation.mutate(
                  {
                    id: state.id,
                    cost_category_id: state.cost_category_id,
                    name: state.name,
                    cost: state.cost,
                  },
                  { onSuccess: onClose }
                )
              }
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteCostCategoryButton = ({
  category,
}: {
  category: TicketCostModelCategory;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useDeleteTicketCostModelCategoryMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent margin="auto">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Cost Category
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete cost category{" "}
                  <b>{category.name}</b>?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={() =>
                  mutation.mutate({ id: category.id }, { onSuccess: onClose })
                }
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const DeleteCostButton = ({ model }: { model: TicketCostModel }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const mutation = useDeleteTicketCostModelMutation();

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent margin="auto">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Cost
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack>
                <Text>
                  Are you sure you want delete cost category <b>{model.name}</b>
                  ?
                </Text>
                <MutationFeedback mutation={mutation} />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                isDisabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={() =>
                  mutation.mutate({ id: model.id }, { onSuccess: onClose })
                }
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const TicketCostModelsPanel = () => {
  const query = useTicketCostModelsQuery();

  return (
    <Panel title={"Costs"} query={query} flex={1}>
      {(data) => (
        <>
          <HStack justify={"flex-end"}>
            <CreateCostCategoryButton />
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Cost Category</Th>
                <Th>Name</Th>
                <Th>Cost</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((category) => (
                <>
                  <Tr key={`category-${category.id}`}>
                    <Td>
                      <b>{category.name}</b>
                    </Td>
                    <Td />
                    <Td />
                    <Td>
                      <HStack>
                        <CreateCostButton costCategoryId={category.id} />
                        <EditCostCategoryButton category={category} />
                        <DeleteCostCategoryButton category={category} />
                      </HStack>
                    </Td>
                  </Tr>
                  {category.models.map((model) => (
                    <Tr key={`model-${model.id}`}>
                      <Td />
                      <Td>{model.name}</Td>
                      <Td>${model.cost}</Td>
                      <Td>
                        <EditCostButton categories={data.items} model={model} />
                        <DeleteCostButton model={model} />
                      </Td>
                    </Tr>
                  ))}
                </>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Panel>
  );
};
