import { useState } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { Page } from "src/components/Page";
import { Panel } from "src/components/Panel";
import { useDataFarmQuery } from "src/api/queries/fetchDataFarm";
import { SearchIcon } from "@chakra-ui/icons";
import { MinerGroupPreviewList } from "src/components/MinerGroupPreviewList";

export const ClientContractGroupsPage = () => {
  const [filter, setFilter] = useState<string>("");
  const query = useDataFarmQuery("contracts");

  return (
    <Page title="Contract Miners">
      <Panel query={query}>
        {(data) => (
          <>
            <FormControl mb={4} maxW="30rem">
              <FormLabel>Search Miners</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <MinerGroupPreviewList
              isClient={true}
              groups={data.filter((group) =>
                group.name.toLowerCase().includes(filter.toLowerCase())
              )}
            />
          </>
        )}
      </Panel>
    </Page>
  );
};
