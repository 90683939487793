import { Link as ReactRouterLink } from "react-router-dom";
import { useState, ChangeEvent } from "react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import {
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  VStack,
  Badge,
  Switch,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Center,
  Checkbox,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from "@chakra-ui/icons";
import { useCombinedInvoicesQuery } from "src/api/queries/fetchInvoices";
import { ShowMinerType, InvoicePanelState, InvoiceRow } from "./InvoiceRow";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { RadioCardGroup } from "src/components/RadioCard";
import { invoiceStatuses } from "src/types";
import { useUpdateInvoiceMutation } from "src/api/mutations/invoiceUpdate";
import { useUpdateInvoiceMinerMutation } from "src/api/mutations/invoiceMinerUpdate";
import { trimDateToUTC } from "src/lib/standardizeDate";

export const CombinedPanel = () => {
  const currentDate = new Date();
  const defaultStartDate = new Date().setDate(
    currentDate.getDate() - currentDate.getDay() + 22
  );
  const defaultEndDate = new Date(defaultStartDate).setDate(
    new Date(defaultStartDate).getDate() + 6
  );

  const [uptimes, setUptimes] = useState<Record<number, string | undefined>>(
    {}
  );
  const [coverages, setCoverages] = useState<
    Record<number, string | undefined>
  >({});
  const [notes, setNotes] = useState<Record<number, string | undefined>>({});

  const [state, setState] = useState<InvoicePanelState>({
    startDate: new Date(defaultStartDate),
    endDate: new Date(defaultEndDate),
    term: null,
    query: null,
    status: null,
    page: 0,
    size: 10,
    showOnlyBatched: false,
    showOnlyOverdue: false,
    showMiners: ShowMinerType.Hide,
    showSingleMiner: null,
    editModeEnabled: false,
  });

  const query = useCombinedInvoicesQuery({
    start_date: trimDateToUTC(state.startDate),
    end_date: trimDateToUTC(state.endDate),
    query: state.query,
    status: state.status,
    page: state.page,
    size: state.size,
    overdue: state.showOnlyOverdue ? true : null,
  });
  const invoiceMutation = useUpdateInvoiceMutation();
  const minerMutation = useUpdateInvoiceMinerMutation();

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query:
        e.target.value != null && e.target.value.length > 0
          ? e.target.value
          : null,
    }));
  };

  return (
    <Panel title={"Combined Invoices"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"center"}>
            <FormControl maxW="30rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={searchCallback}
                />
              </InputGroup>
            </FormControl>
            <Button
              style={{ marginTop: "2rem" }}
              leftIcon={<ArrowLeftIcon fontSize={"sm"} />}
              onClick={() => {
                const startDate = new Date(state.startDate);
                const newStartDate = new Date(
                  new Date(startDate).setDate(startDate.getDate() - 7)
                );
                const newEndDate = new Date(
                  new Date(newStartDate).setDate(newStartDate.getDate() + 6)
                );
                setState((s) => ({
                  ...s,
                  page: 0,
                  startDate: newStartDate,
                  endDate: newEndDate,
                }));
              }}
            />
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <SingleDatepicker
                name={`invoices-start-date`}
                usePortal={true}
                date={state.startDate}
                minDate={
                  new Date(
                    new Date(state.endDate).setDate(
                      state.endDate.getDate() - 34
                    )
                  )
                }
                maxDate={
                  new Date(
                    new Date(state.endDate).setDate(state.endDate.getDate() - 1)
                  )
                }
                onDateChange={(date) =>
                  setState((s) => ({ ...s, page: 0, startDate: date }))
                }
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                    borderColor: "grayAlpha.500",
                    bg: "whiteAlpha.400",
                  },
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <SingleDatepicker
                name={`invoices-end-date`}
                usePortal={true}
                date={state.endDate}
                minDate={state.startDate}
                maxDate={
                  new Date(
                    new Date(state.startDate).setDate(
                      state.startDate.getDate() + 34
                    )
                  )
                }
                onDateChange={(date) =>
                  setState((s) => ({ ...s, page: 0, endDate: date }))
                }
                propsConfigs={{
                  inputProps: {
                    textAlign: "center",
                    borderColor: "grayAlpha.500",
                    bg: "whiteAlpha.400",
                  },
                }}
              />
            </FormControl>
            <Button
              style={{ marginTop: "2rem" }}
              rightIcon={<ArrowRightIcon fontSize={"sm"} />}
              onClick={() => {
                const startDate = new Date(state.startDate);
                const newStartDate = new Date(
                  new Date(startDate).setDate(startDate.getDate() + 7)
                );
                const newEndDate = new Date(
                  new Date(newStartDate).setDate(newStartDate.getDate() + 6)
                );
                setState((s) => ({
                  ...s,
                  page: 0,
                  startDate: newStartDate,
                  endDate: newEndDate,
                }));
              }}
            />
            <FormControl maxW="10rem">
              <FormLabel>Size</FormLabel>
              <Select
                value={state.size}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    size: !isNaN(value) ? value : s.size,
                  }));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
                <option value={500}>500</option>
              </Select>
            </FormControl>
            <FormControl maxW="10rem">
              <FormLabel>Status</FormLabel>
              <Select
                value={state.status != null ? state.status : undefined}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setState((s) => ({
                    ...s,
                    page: 0,
                    status: value > 0 ? value : null,
                  }));
                }}
              >
                <option value={0}>All</option>
                {invoiceStatuses.map((invoiceStatus) => (
                  <option key={invoiceStatus.id} value={invoiceStatus.id}>
                    {invoiceStatus.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <HStack mb={4} justify={"center"}>
            <FormControl maxW="10rem">
              <FormLabel textAlign="center">Show Paid Status</FormLabel>
              <RadioCardGroup
                keys={["All", "Overdue"]}
                value={state.showOnlyOverdue ? "Overdue" : "All"}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    page: 0,
                    showOnlyOverdue: e === "Overdue" ? true : false,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="23rem">
              <FormLabel textAlign="center">Show Miners</FormLabel>
              <RadioCardGroup
                keys={[
                  ShowMinerType.Hide,
                  ShowMinerType.All,
                  ShowMinerType.Ticketed,
                  ShowMinerType.UptimeUnchecked,
                  ShowMinerType.BitfixUnchecked,
                ]}
                value={state.showMiners}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    showMiners: e as ShowMinerType,
                    showSingleMiner: null,
                  }))
                }
              />
            </FormControl>
            <FormControl maxW="8rem">
              <FormLabel>Edit Mode Enabled</FormLabel>
              <Switch
                size="lg"
                h="3rem"
                isChecked={state.editModeEnabled}
                onChange={(e) =>
                  setState((f) => ({
                    ...f,
                    editModeEnabled: e.target.checked,
                  }))
                }
              />
            </FormControl>
          </HStack>
          <Table size="sm" mb={4} variant={"simple"}>
            <Thead>
              <Tr>
                <Th />
                <Th>Contract</Th>
                <Th>Index</Th>
                <Th>Balance / Cost / Contract Cost</Th>
                <Th>Uptime</Th>
                <Th>QBO Invoice</Th>
                <Th>Status</Th>
                <Th>Miners (ON / K / QBO)</Th>
                <Th>Checked</Th>
                <Th>Notes</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((combinedInvoice) => (
                <>
                  <Tr>
                    <Td>
                      <VStack>
                        <Text>{combinedInvoice.name}</Text>
                        <Text>
                          <b>{combinedInvoice.client_name}</b>
                        </Text>
                        <Text>
                          Due At:
                          <b>
                            {new Date(
                              combinedInvoice.due_at
                            ).toLocaleDateString("en-US", {
                              timeZone: "UTC",
                            })}
                          </b>
                        </Text>
                      </VStack>
                    </Td>
                    <Td />
                    <Td />
                    <Td>
                      ${combinedInvoice.balance} / ${combinedInvoice.cost}
                    </Td>
                    <Td />
                    <Td />
                    <Td>
                      <Text>{combinedInvoice.status}</Text>
                      {combinedInvoice.overdue && (
                        <Badge variant="solid" size="xm" colorScheme={"red"}>
                          {"overdue"}
                        </Badge>
                      )}
                    </Td>
                    <Td />
                    <Td>
                      <Center>
                        <Checkbox
                          isChecked={combinedInvoice.checked}
                          disabled={true}
                        />
                      </Center>
                    </Td>
                    <Td />
                    <Td>
                      {combinedInvoice.file_id != null && (
                        <LinkBox
                          bg="blackAlpha.100"
                          p={2}
                          borderRadius="md"
                          _hover={{ bg: "blackAlpha.200" }}
                          textAlign="center"
                          minW="5rem"
                        >
                          <LinkOverlay
                            fontWeight="bold"
                            as={ReactRouterLink}
                            to={`/pdf/invoice/${combinedInvoice.file_id}`}
                            isExternal
                          >
                            View PDF
                          </LinkOverlay>
                        </LinkBox>
                      )}
                    </Td>
                  </Tr>
                  {combinedInvoice.invoices.map((invoice) => (
                    <InvoiceRow
                      isBatched={true}
                      invoice={invoice}
                      state={state}
                      setShowSingleMiner={(showSingleMiner: number | null) =>
                        setState((s) => ({ ...s, showSingleMiner }))
                      }
                      coverages={coverages}
                      setCoverages={setCoverages}
                      uptimes={uptimes}
                      setUptimes={setUptimes}
                      notes={notes}
                      setNotes={setNotes}
                      minerMutation={minerMutation}
                      invoiceMutation={invoiceMutation}
                    />
                  ))}
                </>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
