import { useEffect, useRef, useState, ChangeEvent } from "react";
import { useQueryClient, useMutation } from "react-query";
import { Redirect } from "react-router-dom";
import {
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Button,
  useDisclosure,
  AlertDialog,
  IconButton,
  Thead,
  Badge,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  FormControl,
  Input,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { adminResendVerifyEmail } from "src/api/mutations/adminResendVerifyEmail";
import { adminToggleBillingUser } from "src/api/mutations/adminToggleBillingUser";
import { AdminUser, useAdminUsersQuery } from "src/api/queries/fetchAdminUsers";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";
import { useAdminDeleteUserMutation } from "src/api/mutations/adminDeleteUser";
import { backupTokens, setTokens, getRefreshToken } from "src/lib/tokenStorage";
import { authEmulateUser } from "src/api/mutations/authEmulateUser";
import { userChangeEmail } from "src/api/mutations/userChangeEmail";
import { useUserQuery } from "src/api/queries/fetchUser";
import { MutationFeedback } from "src/components/MutationFeedback";

export const UsersPanel = () => {
  const [state, setState] = useState<{
    page: number;
    query: null | string;
  }>({ page: 0, query: null });

  const query = useAdminUsersQuery({
    type: "client",
    query: state.query,
    page: state.page,
    size: 10,
  });

  const qc = useQueryClient();
  const resendMutation = useMutation(adminResendVerifyEmail, {
    onSettled: () => {
      qc.invalidateQueries("/admin/users");
    },
  });

  const toggleBillingMutation = useMutation(adminToggleBillingUser, {
    onSettled: () => {
      qc.invalidateQueries("/admin/users");
    },
  });

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query: e.target.value,
    }));
  };

  return (
    <Panel
      title="Users"
      query={query}
      searchState={state.query ? state.query : ""}
      searchCallback={searchCallback}
    >
      {(data) => (
        <>
          <Table size="sm" mb={4}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Pending Email</Th>
                <Th>Client</Th>
                <Th>Billing Contact</Th>
                <Th>Last Login</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((user) => (
                <Tr key={user.id}>
                  <Td>
                    {user.last_name.length === 0
                      ? user.first_name
                      : `${user.first_name} ${user.last_name}`}
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>{user.pending_email}</Td>
                  <Td>{user.client_name}</Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={user.is_billing_contact ? "green" : "red"}
                    >
                      {user.is_billing_contact ? "true" : "false"}
                    </Badge>
                  </Td>
                  <Td>{new Date(user.last_login).toLocaleString()}</Td>
                  <Td>
                    <Stack direction="row">
                      {user.verified === false && (
                        <Button
                          colorScheme="teal"
                          size="xs"
                          disabled={resendMutation.isSuccess}
                          isLoading={resendMutation.isLoading}
                          onClick={() => {
                            resendMutation.mutate({
                              user_id: user.id,
                            });
                          }}
                        >
                          Resend Invite
                        </Button>
                      )}
                      <Button
                        colorScheme="teal"
                        size="xs"
                        disabled={toggleBillingMutation.isLoading}
                        isLoading={toggleBillingMutation.isLoading}
                        onClick={() => {
                          toggleBillingMutation.mutate({
                            user_id: user.id,
                          });
                        }}
                      >
                        Toggle Billing Status
                      </Button>
                      <ChangeUserEmailButton user={user} />
                      <DeleteUserButton user={user} />
                      <EmulateUserButton user={user} />
                    </Stack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
          <MutationFeedback mutation={resendMutation} />
          <MutationFeedback mutation={toggleBillingMutation} />
        </>
      )}
    </Panel>
  );
};

const DeleteUserButton = ({ user }: { user: AdminUser }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const mutation = useAdminDeleteUserMutation();

  const handleDelete = () => {
    mutation.mutate(
      {
        user_id: user.id,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
    }
  }, [isOpen, mutation]);

  return (
    <>
      <Button
        colorScheme="red"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want delete user <b>{user.email}</b>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={mutation.isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={mutation.isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const EmulateUserButton = ({ user }: { user: AdminUser }) => {
  const [completed, setCompleted] = useState(false);
  const userQuery = useUserQuery();

  const mutation = useMutation(authEmulateUser, {
    onSuccess: (axiosData) => {
      const tokens = axiosData.data.data.tokens;
      backupTokens();
      setTokens(tokens.auth, tokens.refresh);
      userQuery.refetch();
      setCompleted(true);
    },
  });

  const refreshToken = getRefreshToken();
  const handleEmulate = () => {
    mutation.mutate({
      refresh_token: refreshToken ? refreshToken : "",
      user_id: user.id,
    });
  };

  if (completed) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <IconButton
        size="xs"
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Rename Client"
        icon={<ViewIcon />}
        onClick={handleEmulate}
      />
    </>
  );
};

const ChangeUserEmailButton = ({ user }: { user: AdminUser }) => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState<string>("");
  const mutation = useMutation(userChangeEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries("/admin/users");
    },
  });

  const handleChange = () => {
    mutation.mutate(
      {
        user_id: user.id,
        email: email,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  return (
    <>
      <Button
        colorScheme="yellow"
        size="xs"
        isLoading={mutation.isLoading}
        onClick={onOpen}
      >
        Change Email
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isDisabled={mutation.isLoading}
              />
            </FormControl>
            <MutationFeedback mutation={mutation} />
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              onClick={handleChange}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
