import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  query: string | null;
  page: number;
  size: number;
  miner_set_id?: number;
};

type Invoice = {
  id: number;
  quickbooks_id: string;
  file_id?: string;
  name: string;
  status: string;
  overdue: boolean;
  cost: string;
  balance: string;
  invoice_at: string;
  due_at: string;
};

type ShipmentLocation = {
  id: number;
  name: string;
  client_id?: number;
  network_id?: number;
  repair_facility_id?: number;
};

export type ShipmentMiner = {
  id: number;
  miner_id: string;
  client_id: number;
  client_name: string;
  name: string;
};

export type Shipment = {
  id: number;
  creator_id: number;
  creator_name: string;
  client_id?: number;
  client_name?: string;
  from_location: ShipmentLocation;
  to_location: ShipmentLocation;
  tracking_number?: string;
  miners: ShipmentMiner[];
  invoice?: Invoice;
  created_at: Date;
  updated_at: Date;
};

export const fetchShipments = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<Shipment>>("/logistics/shipments", {
      params: args,
    })
    .then((res) => res.data.data);

export const useShipmentsQuery = (args: QueryArgs) => {
  return useQuery(["/logistics/shipments", args], () => fetchShipments(args), {
    keepPreviousData: true,
  });
};
