import { useMutation, useQueryClient } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

type PostBody = {
  name: string;
  host: string;
  api_key: string;
  enabled: boolean;
};

export const useCreateWatchmanMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PostBody) => {
      return apiClient.post<APIResponse<null>>("/data/watchman", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/watchmans");
    },
  });
};

type PatchBody = {
  watchman_id: number;
  name?: string | null;
  host?: string | null;
  api_key?: string | null;
  enabled?: boolean | null;
};

export const useUpdateWatchmanMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PatchBody) => {
      return apiClient.patch<APIResponse<null>>("/data/watchman", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/watchmans");
    },
  });
};

type DeleteBody = {
  watchman_id: number;
};

export const useDeleteWatchmanMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteBody) => {
      return apiClient.delete<APIResponse<null>>("/data/watchman", {
        data: body,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("/data/watchmans");
    },
  });
};
