export type Network = {
  id: number;
  name: string;
};

export const networks: Network[] = [
  { id: 1, name: "Rock" },
  { id: 2, name: "Quincy" },
  { id: 3, name: "Malaga" },
  { id: 4, name: "Hut" },
  { id: 5, name: "AntBox" },
  { id: 6, name: "Winchester" },
  { id: 7, name: "Kennewick" },
  { id: 8, name: "Oklahoma City" },
  { id: 9, name: "Immersion" },
  { id: 10, name: "George" },
  { id: 11, name: "Container 1" },
];

export type PDUType = {
  id: number;
  name: string;
};

export const pduTypes: PDUType[] = [
  { id: -1, name: "Unknown" },
  { id: 0, name: "ServerTech PDU" },
  { id: 1, name: "ServerTech CDU" },
  { id: 2, name: "APC" },
  { id: 3, name: "Raritan" },
  { id: 4, name: "MoonShot" },
  { id: 5, name: "Eaton" },
  { id: 6, name: "Panduit" },
  { id: 7, name: "Chingling APDU V1" },
  { id: 8, name: "Chingling APDU V2" },
  { id: 9, name: "Chingling RPDU" },
];

export type ContractStatus = {
  id: number;
  name: string;
};

export const contractStatuses: ContractStatus[] = [
  { id: 1, name: "Created" },
  { id: 2, name: "Sent" },
  { id: 3, name: "Partially Signed" },
  { id: 4, name: "Signed" },
  { id: 5, name: "Partially Activated" },
  { id: 6, name: "Activated" },
  { id: 7, name: "Completed" },
  { id: 8, name: "Voided" },
];

export type InvoiceStatus = {
  id: number;
  name: string;
};

export const invoiceStatuses: InvoiceStatus[] = [
  { id: 1, name: "Created" },
  { id: 2, name: "Sent" },
  { id: 3, name: "Paid" },
  { id: 4, name: "Voided" },
];

export type CombinedClients = {
  id: number;
  name: string;
};

export const combinedClients: CombinedClients[] = [
  { id: 168, name: "Bitwoods, LLC." },
  { id: 200, name: "Digital Dollar Miner" },
  { id: 211, name: "Jakub Hlavenka" },
  { id: 236, name: "Patora, LLC" },
  { id: 244, name: "Agustin Cortes" },
  { id: 250, name: "Pickaxe Mining - Vlad" },
  { id: 283, name: "Green Edge Mining, Inc." },
  { id: 294, name: "ATB Ventures, LLC." },
  { id: 429, name: "Musk Miners" },
  { id: 360, name: "O'Connell Consulting, LLC" },
  { id: 228, name: "Bob Hall" },
];

export type TicketStatus = {
  id: number;
  name: string;
};

export const ticketStatuses: TicketStatus[] = [
  { id: 0, name: "Created" },
  // { id: 1, name: "Approved" },
  { id: 2, name: "Assigned" },
  { id: 3, name: "In Testing" },
  { id: 4, name: "Ready for Deployment" },
  { id: 5, name: "Completed" },
  { id: 6, name: "Billed" },
];

export type TicketItemType = {
  id: number;
  name: string;
};

export const ticketItemTypes: TicketItemType[] = [
  { id: 1, name: "PSU" },
  { id: 2, name: "Hashboard" },
  { id: 3, name: "Control Board" },
];

export type TicketItemOperation = {
  id: number;
  name: string;
};

export const ticketItemOperations: TicketItemOperation[] = [
  { id: 1, name: "Repaired" },
  { id: 2, name: "Removed" },
  { id: 3, name: "Added" },
];
