import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { SummaryPanel } from "./panels/SummaryPanel";
import { ContractsPanel } from "./panels/ContractsPanel";
import { RenewalsPanel } from "./panels/RenewalsPanel";

export const ContractsPage = () => {
  return (
    <Page title="Contracts">
      <PanelRow>
        <SummaryPanel />
      </PanelRow>
      <PanelRow>
        <ContractsPanel />
      </PanelRow>
      <PanelRow>
        <RenewalsPanel />
      </PanelRow>
    </Page>
  );
};
