import { UseMutationResult } from "react-query";
import { Alert, AlertIcon } from "@chakra-ui/alert";

type Props = {
  mutation: UseMutationResult<any, any, any, any>;
  successMsg?: string;
  errorMsg?: string;
};

export const MutationFeedback = ({ mutation, successMsg, errorMsg }: Props) => {
  if (mutation.isSuccess && successMsg) {
    return (
      <Alert status="success">
        <AlertIcon />
        {successMsg}
      </Alert>
    );
  }

  if (mutation.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {extractApiErrorMsg(mutation.error, errorMsg)}
      </Alert>
    );
  }

  return null;
};

export const extractApiErrorMsg = (
  error: any,
  fallback = "There was an error."
): string => {
  return error?.response?.data?.msg || fallback;
};
