import { Page } from "src/components/Page";
import { PanelRow } from "src/components/PanelRow";
import { AdminsPanel } from "./panels/AdminsPanel";
import { ClientsPanel } from "./panels/ClientsPanel";
import { CreateClientPanel } from "./panels/CreateClientPanel";
import { InviteAdminPanel } from "./panels/InviteAdminPanel";
import { InviteUserPanel } from "./panels/InviteUserPanel";
import { UsersPanel } from "./panels/UsersPanel";

export const UsersPage = () => {
  return (
    <Page title="Manage Users">
      <PanelRow>
        <ClientsPanel />
      </PanelRow>
      <PanelRow>
        <UsersPanel />
      </PanelRow>
      <PanelRow>
        <AdminsPanel />
      </PanelRow>
      <PanelRow>
        <InviteUserPanel />
      </PanelRow>
      <PanelRow>
        <CreateClientPanel />
        <InviteAdminPanel />
      </PanelRow>
    </Page>
  );
};
