import { useQueryClient, useMutation } from "react-query";
import { useState, ChangeEvent } from "react";
import {
  Button,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { adminResendVerifyEmail } from "src/api/mutations/adminResendVerifyEmail";
import { useAdminUsersQuery } from "src/api/queries/fetchAdminUsers";
import { Panel } from "src/components/Panel";
import { SimplePaginator } from "src/components/SimplePaginator";

export const AdminsPanel = () => {
  const [state, setState] = useState<{
    page: number;
    query: null | string;
  }>({ page: 0, query: null });

  const query = useAdminUsersQuery({
    type: "admin",
    query: state.query,
    page: state.page,
    size: 10,
  });

  const qc = useQueryClient();
  const resendMutation = useMutation(adminResendVerifyEmail, {
    onSettled: () => {
      qc.invalidateQueries("/admin/users");
    },
  });

  const searchCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({
      ...s,
      page: 0,
      query: e.target.value,
    }));
  };

  return (
    <Panel
      title="Admins"
      query={query}
      searchState={state.query ? state.query : ""}
      searchCallback={searchCallback}
    >
      {(data) => (
        <>
          <Table size="sm" mb={4}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Last Login</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((admin) => (
                <Tr key={admin.id}>
                  <Td>{`${admin.first_name} ${admin.last_name}`}</Td>
                  <Td>{admin.email}</Td>
                  <Td>{new Date(admin.last_login).toLocaleString()}</Td>
                  <Td>
                    <Stack direction="row">
                      {admin.verified === false && (
                        <Button
                          size="xs"
                          disabled={resendMutation.isSuccess}
                          isLoading={resendMutation.isLoading}
                          onClick={() => {
                            resendMutation.mutate({
                              user_id: admin.id,
                            });
                          }}
                        >
                          Resend Invite
                        </Button>
                      )}
                    </Stack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
