import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

type QueryArgs = {
  invoice_id: number;
  page: number;
  size: number;
};

export type InvoiceEvent = {
  id: number;
  type: string;
  user_id: number;
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  ip_address?: string;
  created_at: Date;
};

export const fetchInvoiceEvents = (args: QueryArgs) =>
  apiClient
    .get<APIResponsePaginated<InvoiceEvent>>("/invoice/events", {
      params: args,
    })
    .then((res) => res.data.data);

export const useInvoiceEventsQuery = (args: QueryArgs) =>
  useQuery(["/invoices/events", args], () => fetchInvoiceEvents(args), {
    keepPreviousData: true,
  });
