import { useState, useEffect } from "react";
import { Panel } from "src/components/Panel";
import {
  FormControl,
  FormLabel,
  InputLeftElement,
  Input,
  InputGroup,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  HStack,
  IconButton,
  Button,
  NumberInput,
  NumberInputField,
  useDisclosure,
} from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { useContractAffiliateModelsQuery } from "src/api/queries/fetchContractAffiliateModels";
import { MutationFeedback } from "src/components/MutationFeedback";
import { SimplePaginator } from "src/components/SimplePaginator";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  AffiliateModel,
  useCreateContractAffiliateModelMutation,
} from "src/api/mutations/contractCreateAffiliateModel";

const CreateAffiliateButton = () => {
  const [state, setState] = useState<AffiliateModel>({
    name: "",
    commission_power_rate: null,
    commission_percentage: null,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useCreateContractAffiliateModelMutation();
  const [formError, setFormError] = useState<string | null>(null);

  const handleCreate = () => {
    if (
      state.commission_power_rate?.length ||
      state.commission_percentage?.length
    ) {
      mutation.mutate(
        {
          name: state.name,
          commission_power_rate: state.commission_power_rate?.length
            ? state.commission_power_rate
            : null,
          commission_percentage: state.commission_percentage?.length
            ? state.commission_percentage
            : null,
        },
        {
          onSuccess: onClose,
        }
      );
    }
  };

  useEffect(() => {
    if (!isOpen && !mutation.isIdle) {
      mutation.reset();
      setState({
        name: "",
        commission_power_rate: null,
        commission_percentage: null,
      });
    }
  }, [isOpen, mutation]);

  useEffect(() => {
    if (
      state.commission_power_rate?.length &&
      state.commission_percentage?.length
    ) {
      setFormError("An affiliate can have either a power rate or a percentage");
    } else {
      setFormError(null);
    }
  }, [state.commission_power_rate, state.commission_percentage]);

  return (
    <Stack>
      <Text minW="10rem" align="center" fontWeight="medium">
        Create a new affiliate
      </Text>
      <IconButton
        size="sm"
        mr={4}
        colorScheme="blackAlpha"
        variant="ghost"
        aria-label="Create Affiliate"
        icon={<AddIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Affiliate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="name"
                  value={state.name}
                  onChange={(e) =>
                    setState((s) => ({ ...s, name: e.target.value }))
                  }
                  isDisabled={mutation.isLoading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Commission Power Rate</FormLabel>
                <NumberInput
                  value={
                    state.commission_power_rate != null
                      ? state.commission_power_rate
                      : undefined
                  }
                  onChange={(e) =>
                    setState((s) => ({ ...s, commission_power_rate: e }))
                  }
                  isDisabled={mutation.isLoading}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Commission Percentage</FormLabel>
                <NumberInput
                  value={
                    state.commission_percentage != null
                      ? state.commission_percentage
                      : undefined
                  }
                  onChange={(e) =>
                    setState((s) => ({ ...s, commission_percentage: e }))
                  }
                  isDisabled={mutation.isLoading}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <MutationFeedback mutation={mutation} />
              {formError != null && (
                <Alert status="error">
                  <AlertIcon />
                  {formError}
                </Alert>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button disabled={mutation.isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading || formError != null}
              onClick={handleCreate}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export const AffiliatesPanel = () => {
  const [state, setState] = useState<{
    page: number;
    query: null | string;
  }>({ page: 0, query: null });

  const query = useContractAffiliateModelsQuery({
    query: state.query,
    page: state.page,
    size: 10,
  });

  return (
    <Panel title={"Affiliates"} query={query}>
      {(data) => (
        <>
          <HStack mb={4} justify={"space-between"}>
            <FormControl maxW="30rem" pl={1}>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" children={<SearchIcon />} />
                <Input
                  value={state.query ? state.query : ""}
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      page: 0,
                      query:
                        e.target.value != null && e.target.value.length > 0
                          ? e.target.value
                          : null,
                    }))
                  }
                />
              </InputGroup>
            </FormControl>
            <CreateAffiliateButton />
          </HStack>
          <Table size="sm" mb={4} variant={"striped"}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Power Rate</Th>
                <Th>Power Rate Percentage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((affiliate) => (
                <Tr key={`affiliate-${affiliate.id}`}>
                  <Td>{affiliate.name}</Td>
                  <Td>{affiliate.commission_power_rate}</Td>
                  <Td>{affiliate.commission_percentage}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {query.data && query.data.items.length > 0 && (
            <SimplePaginator
              pageSize={data.size}
              currentPage={data.page}
              totalItems={data.results}
              onPageChange={(page) => {
                setState((s) => ({ ...s, page }));
              }}
            />
          )}
        </>
      )}
    </Panel>
  );
};
