import { useQuery } from "react-query";
import { APIResponsePaginated } from "src/types";
import { apiClient } from "..";

export type TicketCostModel = {
  id: number;
  cost_category_id: number;
  name: string;
  cost: string;
};

export type TicketCostModelCategory = {
  id: number;
  name: string;
  models: TicketCostModel[];
};

export const fetchTicketCostModels = () =>
  apiClient
    .get<APIResponsePaginated<TicketCostModelCategory>>("/tickets/cost_models")
    .then((res) => res.data.data);

export const useTicketCostModelsQuery = () => {
  return useQuery(["/tickets/cost_models"], () => fetchTicketCostModels(), {
    keepPreviousData: true,
  });
};
