import { useQuery } from "react-query";
import { APIResponse, FormattedNumber } from "src/types";
import { apiClient } from "..";

type Device = {
  name: string;
  type: string;
  status: string;
  device_name: string;
  device_type: string;
  activity: number;
  intensity: string;
  clock: number;
  memory_clock: number;
  voltage: string;
  fan_percent: FormattedNumber;
  fans_rpm?: string;
  temperature: FormattedNumber;
  power_usage: FormattedNumber;
  shares: {
    accepted: number;
    rejected: number;
    hw_errors: number;
  };
  hashrate: FormattedNumber;
  avg_hashrate: FormattedNumber;
};

type MinerResponse = {
  id: number;
  compound_id: string;
  group_id: string;
  group_name?: string;
  contract_id?: number;
  contract_name?: string;
  name: string;
  hardware?: string;
  host_name: string;
  network_ip_address?: string;
  mac_address?: string;
  pool_name: string;
  software: string;
  enabled: boolean;
  running: boolean;
  offline: boolean;
  min_temperature: FormattedNumber;
  avg_temperature: FormattedNumber;
  max_temperature: FormattedNumber;
  status: string;
  uptime: string;
  shares: {
    accepted: number;
    rejected: number;
    hw_errors: number;
  };
  hashrate: FormattedNumber;
  avg_hashrate: FormattedNumber;
  power_usage: FormattedNumber;
  coin: string;
  algorithm: string;
  daily_revenue: FormattedNumber;
  monthly_revenue: FormattedNumber;
  gpus?: Device[];
  asics?: Device[];
  pools?: [
    {
      id: number;
      name: string;
      coin_name: string;
      status: string;
      url: string;
      worker: string;
      shares: {
        accepted: number;
        rejected: number;
        hw_errors: number;
      };
    }
  ];
  updated_at: string;
};

export const fetchDataMiner = (minerId: string) =>
  apiClient
    .get<APIResponse<MinerResponse>>("/data/miner", {
      params: { miner_id: minerId },
    })
    .then((res) => res.data.data);

export const useDataMinerQuery = (minerId: string) =>
  useQuery(["/data/miner", minerId], () => fetchDataMiner(minerId));
