import { useQuery } from "react-query";
import { APIResponse } from "src/types";
import { apiClient } from "..";

export type Line = {
  id: string;
  name: string;
  current?: number;
  current_capacity: number;
  current_warn: number;
  current_alert: number;
};

export type Branch = {
  id: string;
  name: string;
  line_1?: string | null;
  line_2?: string | null;
  current: number;
  current_capacity: number;
  current_warn: number;
  current_alert: number;
  power: number;
};

export type Outlet = {
  id: string;
  name: string;
  miner_id?: string | null;
  miner_name?: string | null;
  miner_pending: boolean;
  branch?: string | null;
  online: boolean;
  current: number;
  current_capacity: number;
  current_warn: number;
  current_alert: number;
  power: number;
  power_capacity: number;
};

export type PDU = {
  id: number;
  network_id: number;
  network_name: string;

  name: string;
  host: string;
  type_id: number;
  type_name: string;
  enabled: boolean;
  online: boolean;
  metered: boolean;
  outlet_switched: boolean;
  outlet_metered: boolean;

  model?: string | null;
  firmware?: string | null;
  mac_address?: string | null;
  uptime?: string | null;

  lines: Line[];
  branches: Branch[];
  outlets: Outlet[];
};

export const fetchDataPDU = (pduId: number) =>
  apiClient
    .get<APIResponse<PDU>>(`/data/pdu?pdu_id=${pduId}`)
    .then((res) => res.data);

export const useDataPDUQuery = (pduId: number) => {
  return useQuery(["/data/pdu", pduId], () => fetchDataPDU(pduId), {
    keepPreviousData: true,
  });
};
