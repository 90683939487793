import {
  Text,
  Flex,
  Badge,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Divider,
} from "@chakra-ui/react";
import { Panel } from "src/components/Panel";
import { useInvoiceQuery } from "src/api/queries/fetchInvoice";

export const MinersPanel = ({ invoiceId }: { invoiceId: number }) => {
  const query = useInvoiceQuery(invoiceId);

  return (
    <Panel title={"Miners"} query={query}>
      {(data) => (
        <>
          {data.groups?.map((group, idx) => (
            <VStack
              key={group.id}
              alignItems="start"
              justifyContent="flex-start"
            >
              <Flex
                mx={8}
                mt={4}
                alignItems="start"
                justifyContent="flex-start"
              >
                <Text>
                  <b>{group.name}</b> @ {group.power_draw}W | {group.activated}{" "}
                  / {group.total}
                </Text>
              </Flex>
              <Flex flexWrap={"wrap"} justifyContent="left">
                {group.miners.map((miner) => (
                  <HStack
                    key={miner.id}
                    p={2}
                    m={1}
                    borderRadius={"md"}
                    borderWidth="1px"
                    bg={"whiteAlpha.500"}
                    textAlign="center"
                    alignItems="center"
                  >
                    <VStack w="10rem">
                      <Text
                        p={1}
                        w="full"
                        textAlign="center"
                        wordBreak="break-all"
                      >
                        <b>{miner.name}</b>
                      </Text>
                      <Text
                        p={1}
                        w="full"
                        textAlign="center"
                        wordBreak="break-all"
                      >
                        {miner.activated_at != null
                          ? `Activated: ${new Date(
                              miner.activated_at
                            ).toLocaleDateString()}`
                          : "Activated: Unknown"}
                      </Text>
                      {miner.tickets?.map((ticket) => (
                        <>
                          <Text
                            py={1}
                            w="full"
                            textAlign="center"
                            wordBreak="break-all"
                          >
                            <a
                              href={`https://app.bitcap.co/tickets/${ticket.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i>Ticket #{ticket.id}</i>
                            </a>
                            {`: ${ticket.status}`}
                          </Text>
                        </>
                      ))}
                      <HStack>
                        <Badge
                          variant="solid"
                          size="xm"
                          colorScheme={miner.online ? "green" : "red"}
                        >
                          {miner.online ? "online" : "offline"}
                        </Badge>
                        {miner.is_unpaid && (
                          <Badge variant="solid" size="xm" colorScheme={"red"}>
                            unpaid
                          </Badge>
                        )}
                        {miner.is_bitfix && (
                          <Badge variant="solid" size="xm" colorScheme={"red"}>
                            bitfix
                          </Badge>
                        )}
                      </HStack>
                      {data.idx > 0 && (
                        <>
                          <Badge
                            variant="solid"
                            size="xm"
                            colorScheme={miner.uptime_checked ? "green" : "red"}
                          >
                            {miner.uptime_checked
                              ? "uptime checked"
                              : "uptime unchecked"}
                          </Badge>
                          <Badge
                            variant="solid"
                            size="xm"
                            colorScheme={miner.bitfix_checked ? "green" : "red"}
                          >
                            {miner.bitfix_checked
                              ? "bitfix checked"
                              : "bitfix unchecked"}
                          </Badge>
                        </>
                      )}
                    </VStack>
                    <VStack w="10rem">
                      <InputGroup size="sm">
                        <InputLeftAddon>On</InputLeftAddon>
                        <NumberInput
                          isDisabled={true}
                          value={
                            miner.coverage != null
                              ? miner.coverage.toFixed(1)
                              : "0"
                          }
                        >
                          <NumberInputField padding={0} textAlign="center" />
                        </NumberInput>
                        <InputRightAddon>%</InputRightAddon>
                      </InputGroup>
                      <InputGroup size="sm">
                        <InputLeftAddon>Up</InputLeftAddon>
                        <NumberInput
                          isDisabled={true}
                          value={
                            miner.uptime != null ? miner.uptime.toFixed(1) : "0"
                          }
                        >
                          <NumberInputField padding={0} textAlign="center" />
                        </NumberInput>
                        <InputRightAddon>%</InputRightAddon>
                      </InputGroup>
                      {data.idx > 0 && (
                        <>
                          <InputGroup size="xs">
                            <Input
                              isDisabled={true}
                              p="0px"
                              textAlign="center"
                              value={`C:${miner.internal_coverage?.toFixed(
                                1
                              )}% U:${miner.internal_uptime?.toFixed(1)}%`}
                            />
                          </InputGroup>
                          <InputGroup size="sm">
                            <Input
                              isDisabled={true}
                              textAlign="center"
                              value={miner.note == null ? "" : miner.note}
                            />
                          </InputGroup>
                        </>
                      )}
                    </VStack>
                  </HStack>
                ))}
              </Flex>
              {idx !== data.groups?.length && <Divider m={4} />}
            </VStack>
          ))}
        </>
      )}
    </Panel>
  );
};
